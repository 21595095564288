import { Injectable } from '@angular/core';
import { StoreConfig, Store } from '@datorama/akita';
import * as moment from 'moment-timezone';
import { getTimezone } from '../../storage';

const timezone = getTimezone();
moment.tz.setDefault(timezone);

export interface BeverageItem {
  bookingBeverageId: number,
  bookingId: number,
  customerId: number,
  bookedAt: string,
  bookedAtUtc: string,
  checkedInAt: string,
  beverage: {
    id: number,
    code: string,
    name: string,
    description: string
  },
  firstName: string,
  lastName: string,
  rego: string,
  tableName: string,
  status: string,
  notes: string
  bookingStatus: {
    bookingStatus: {
      description: string,
      code: string
    }
  }
}

export interface BeveragesState {
  viewDate: any;
  searchTerm: string;
}

export function createInitialState(): BeveragesState {
  return {
    viewDate: moment().toDate(),
    searchTerm: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'beverages', resettable: true })
export class BeveragesStore extends Store<BeveragesState> {
  constructor() {
    super(createInitialState());
  }

  updateViewDate(date) {
    this.update({ viewDate: moment(date).toDate() });
  }

  updateSearchTerm(keywork: string) {
    this.update({ searchTerm: keywork });
  }
}