import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class ThumbnailIconService {

    render() {
        return `<svg width="73" height="50" viewBox="0 0 73 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.06641" y="0.734375" width="70.7491" height="47.9268" rx="3" stroke="black"/>
        <path d="M1.06641 41.8155L27.3121 18.9932L41.0054 32.6865L47.8521 23.5576L71.8155 44.0977" stroke="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M58.1216 18.9912C61.2727 18.9912 63.8272 16.4368 63.8272 13.2857C63.8272 10.1346 61.2727 7.58008 58.1216 7.58008C54.9705 7.58008 52.416 10.1346 52.416 13.2857C52.416 16.4368 54.9705 18.9912 58.1216 18.9912Z" stroke="black"/>
        </svg>
        `;
    }
}
