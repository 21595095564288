import { Injectable } from "@angular/core";
import { BookingDetails } from '../booking-details-selected/booking-details-selected.store';
import { ConfigStore, CreditCardPaymentProcess, ConfigQuery } from './config.store';
import { Subject } from 'rxjs';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  constructor(
    private configStore: ConfigStore,
    private configQuery: ConfigQuery
  ) { }
  refreshBookings: Subject<boolean> = new Subject();

  updateclonnedBookingDetailsSelected(bookingDetails: BookingDetails) {
    this.configStore.updateClonnedBookingDetails(bookingDetails);
  }

  /**
   * 
   *
   * @param {*} action  - 'viewBooking' or 'manageBooking'
   * @memberof ConfigService
   */
  updateAction(action) {
    this.configStore.setAction(action);
  }

  setInputVehicle(val) {
    this.configStore.setInputVehicle(val);
  }

  setCreditCardPaymentProcess(val: CreditCardPaymentProcess) {
    let paymentProcess = _.cloneWith(this.configQuery.getValue().creditCardPaymentProcess) || [];

    const idx = paymentProcess.findIndex(item => item.bookingId === val.bookingId);

    if (idx === -1) {
      paymentProcess = [...paymentProcess, val];
    } else {
      paymentProcess[idx] = val;
    }

    this.configStore.setCreditCardPaymentProcess(paymentProcess);
  }

  removeCreditCardPaymentProcess(bookingId: number) {
    let paymentProcess = _.cloneWith(this.configQuery.getValue().creditCardPaymentProcess) || [];
    const idx = paymentProcess.findIndex(item => item.bookingId === bookingId);
    _.pullAt(paymentProcess, idx);
    this.configStore.setCreditCardPaymentProcess(paymentProcess);
  }

  isBookingMakingCreditCardPayment(bookingId: number) {
    const paymentProcess = this.configQuery.getValue().creditCardPaymentProcess;
    const idx = paymentProcess.findIndex(item => item.bookingId === bookingId && item.makingCreditCardPayment);

    return idx !== -1;
  }

  setLoading(loading: boolean) {
    this.configStore.setLoading(loading);
  }
}