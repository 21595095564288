import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CheckCirclePaymentIconService {

  render() {
    return `<svg width="116" height="116" viewBox="0 0 116 116" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
    <circle cx="58" cy="49" r="40" fill="white"/>
    <circle cx="58" cy="49" r="38.5" stroke="#16C072" stroke-width="3"/>
    </g>
    <path d="M98.8109 25.5708C100.727 23.747 100.727 20.758 98.8109 18.9342C96.9368 17.1502 93.9274 17.1502 92.0533 18.9342L58.9596 50.4369L45.7599 37.8719C43.8858 36.0879 40.8764 36.0879 39.0024 37.8719C37.0865 39.6956 37.0865 42.6846 39.0024 44.5084L55.5808 60.2898C57.4549 62.0738 60.4642 62.0738 62.3383 60.2898L98.8109 25.5708Z" fill="url(#paint0_linear)" stroke="#FBFBFB" stroke-width="3"/>
    <defs>
    <filter id="filter0_d" x="0" y="0" width="116" height="116" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
    <feOffset dy="9"/>
    <feGaussianBlur stdDeviation="9"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
    </filter>
    <linearGradient id="paint0_linear" x1="95.9333" y1="18.9129" x2="53.056" y2="57.0513" gradientUnits="userSpaceOnUse">
    <stop stop-color="#087242"/>
    <stop offset="1" stop-color="#16C072"/>
    </linearGradient>
    </defs>
    </svg>
    `;
  }
}
