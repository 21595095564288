import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class FlagIconService {

    render(color = null) {
      return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5 4.22975V12.1465C5.5829 12.0549 6.24675 12 7 12C8.88675 12 10.4411 12.6221 11.643 13.1031C11.6578 13.109 11.6725 13.1149 11.6872 13.1208L11.7428 13.143C13.0305 13.6582 13.9393 14 15 14C15.9292 14 16.5766 13.8875 17 13.7702V5.85354C16.4171 5.94506 15.7533 6 15 6C13.1132 6 11.5589 5.3779 10.357 4.89688C10.3422 4.89096 10.3275 4.88507 10.3128 4.8792L10.2572 4.85695C8.96943 4.34184 8.06075 4 7 4C6.07082 4 5.42339 4.11249 5 4.22975ZM3.875 2.5C4.49998 2.25001 5.49997 2 7 2C8.5 2 9.75054 2.50022 10.9984 2.99936L11.0558 3.02233C12.2859 3.51452 13.5231 4 15 4C16.202 4 17.0826 3.83942 17.7056 3.6475C18.009 3.55407 18.3386 3.61019 18.594 3.79872C18.8493 3.98725 19 4.2858 19 4.6032V14.4619C19 14.8419 18.7846 15.1891 18.4442 15.3579C18.3508 15.4042 18.2447 15.4521 18.125 15.5C17.5 15.75 16.5 16 15 16C13.5 16 12.2495 15.4998 11.0016 15.0006L10.9442 14.9777C9.71415 14.4855 8.47695 14 7 14C5.79796 14 4.91744 14.1606 4.29437 14.3525C3.99103 14.4459 3.66135 14.3898 3.40602 14.2013C3.15068 14.0128 3 13.7142 3 13.3968V3.53805C3 3.15805 3.21538 2.81089 3.55583 2.64211C3.64918 2.59583 3.75528 2.54789 3.875 2.5Z" fill="black"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4 10C4.55228 10 5 10.4477 5 11V22C5 22.5523 4.55228 23 4 23C3.44772 23 3 22.5523 3 22V11C3 10.4477 3.44772 10 4 10Z" fill="black"/>
      </svg>  
      `;
    }
}
