<ng-container>
  <nb-card [nbSpinner]="loading" class="card-detail-dialog">
    <ng-container>
      <nb-card-header>Enter Card Details<nb-icon icon="x-icon" pack="mo-icons" (click)=close()></nb-icon>
      </nb-card-header>
      <nb-card-body>
        <p *ngIf="failedPayment" class="err"><span>
            <nb-icon icon="info" pack="mo-icons"></nb-icon>
          </span>&nbsp;&nbsp;Sorry, we can’t process the payment. Please check your card details or select a different
          payment
          option.
        </p>
        <p class="sub-title">Please enter credit card information</p>
        <div>
          <form (ngSubmit)="pay()" class="card-details-form" [formGroup]="cardDetailsForm">
            <mat-form-field class="full-width box" appearance="legacy" floatLabel="always">
              <mat-label>Card number</mat-label>
              <input placeholder="XXXX XXXX XXXX XXXX" matInput type="text" [cleave]="{creditCard: true}" maxlength="40"
                formControlName="cardNumber">
              <mat-error
                *ngIf="cardDetailsForm.controls.cardNumber.errors && cardDetailsForm.controls.cardNumber.errors.required">
                {{ errorMessage['cardNumberRequired'] }}
              </mat-error>
              <mat-error
                *ngIf="cardDetailsForm.controls.cardNumber.errors && cardDetailsForm.controls.cardNumber.errors.undetectable">
                {{errorMessage['cardTypeUndetectable']}}
              </mat-error>
            </mat-form-field>

            <div class="item d-flex">
              <div class="w-70 pr-3">
                <mat-form-field class="full-width box" appearance="legacy" floatLabel="always">
                  <mat-label>Expired date</mat-label>
                  <input matInput type="text" placeholder="XX/XX" mask="00/00" formControlName="expiredDate">
                  <mat-error
                    *ngIf="cardDetailsForm.controls.expiredDate.errors && cardDetailsForm.controls.expiredDate.errors.required">
                    {{ errorMessage['expiredDateRequired'] }}
                  </mat-error>
                  <mat-error
                    *ngIf="cardDetailsForm.controls.expiredDate.errors && cardDetailsForm.controls.expiredDate.errors.invalidDateCard">
                    {{ errorMessage['invalidDateCard'] }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="w-30">
                <mat-form-field class="full-width box" appearance="legacy" floatLabel="always">
                  <mat-label>CCV</mat-label>
                  <input matInput type="password" maxlength="4" formControlName="ccv">
                  <mat-error
                    *ngIf="cardDetailsForm.controls.ccv.errors && cardDetailsForm.controls.ccv.errors.required">
                    {{ errorMessage['ccvRequired'] }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <mat-form-field class="full-width box" appearance="legacy" floatLabel="always">
              <mat-label>Card name</mat-label>
              <input matInput type="text" placeholder="Enter name" formControlName="cardName">
              <mat-error
                *ngIf="cardDetailsForm.controls.cardName.errors && cardDetailsForm.controls.cardName.errors.required">
                {{ errorMessage['cardNameRequired'] }}
              </mat-error>
            </mat-form-field>

            <button type="submit" class="btn-submit" nbButton status="primary">Pay Now
              <span *ngIf="!discount">{{payingPrices.totalAndPaymentFee | currency}}</span>
              <span *ngIf="discount">{{payingPrices.totalWithDiscountAndPaymentFee | currency}}</span>
            </button>
          </form>
        </div>
      </nb-card-body>
    </ng-container>
  </nb-card>
  <form id="paymentPayloadForm" style="display: none;" #form [action]="paymentUrl" method="post"></form>
</ng-container>