import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';


@Component({
  selector: 'select-vehicle',
  templateUrl: './select-vehicle.component.html',
  styleUrls: ['./select-vehicle.component.scss']
})
export class SelectVehicleComponent implements OnInit {
  searchVehicles = [];
  isResult: boolean = false;
  isSelected: boolean = false;
  isSearchRego: boolean = true;
  selectedValue = {};
  @Output() regoSelected: EventEmitter<any> = new EventEmitter();
  @Input('customerId') customerId;
  state;

  constructor(protected ref: NbDialogRef<SelectVehicleComponent>) { }

  ngOnInit() {
    console.log(this.customerId);
  }

  onSearchRego(val) {
    this.searchResult(val);
  }

  onSearchManual(val) {
    this.searchResult(val);
  }

  searchResult(val) {
    this.isResult = true;
    val.vehicles.forEach(res => {
      Object.assign(res, { isSelected: false });
    });
    this.searchVehicles = val.vehicles;
    this.state = val.selectedState;
  }

  searchAgain() {
    this.isResult = false;
  }

  switchSearch() {
    this.isSearchRego = !this.isSearchRego;
  }

  selectVehicle(val) {
    this.isSelected = true;
    this.searchVehicles.forEach(res => {
      this.selectedValue = res;
      res.isSelected = res.id === val.id && res.cylinder === val.cylinder ? true : false;
    });
  }

  onSelectFromResults() {
    this.ref.close({ vehicle: this.selectedValue, state: this.state });
  }

  closeDlg() {
    this.ref.close();
  }
}
