import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class EditIconService {

    render() {
        return `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0772 1.07757C11.4027 0.752137 11.9303 0.752137 12.2558 1.07757L15.5891 4.41091C15.9145 4.73634 15.9145 5.26398 15.5891 5.58942L6.42243 14.7561C6.26615 14.9124 6.05418 15.0002 5.83317 15.0002H2.49984C2.0396 15.0002 1.6665 14.6271 1.6665 14.1668V10.8335C1.6665 10.6125 1.7543 10.4005 1.91058 10.2442L11.0772 1.07757ZM3.33317 11.1787V13.3335H5.48799L13.8213 5.00016L11.6665 2.84534L3.33317 11.1787Z" fill="#A0A0A0"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.6665 18.3333C1.6665 17.8731 2.0396 17.5 2.49984 17.5H17.4998C17.9601 17.5 18.3332 17.8731 18.3332 18.3333C18.3332 18.7936 17.9601 19.1667 17.4998 19.1667H2.49984C2.0396 19.1667 1.6665 18.7936 1.6665 18.3333Z" fill="#A0A0A0"/>
        </svg>

        `;
    }
}
