import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class SendIconService {

    render() {
        return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.7071 1.29289C23.0976 1.68341 23.0976 2.31658 22.7071 2.7071L11.7071 13.7071C11.3166 14.0976 10.6834 14.0976 10.2929 13.7071C9.90237 13.3166 9.90237 12.6834 10.2929 12.2929L21.2929 1.29289C21.6834 0.902366 22.3166 0.902366 22.7071 1.29289Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.7071 1.29289C22.9787 1.56453 23.0708 1.96776 22.9439 2.33035L15.9439 22.3303C15.8084 22.7173 15.4504 22.9825 15.0408 22.9992C14.6311 23.0159 14.2527 22.7808 14.0862 22.4061L10.2424 13.7576L1.59387 9.91381C1.21919 9.74728 0.984122 9.36891 1.00084 8.95923C1.01755 8.54956 1.28265 8.19159 1.66965 8.05614L21.6697 1.05614C22.0322 0.929234 22.4355 1.02125 22.7071 1.29289ZM4.71108 9.1106L11.4061 12.0862C11.6324 12.1867 11.8133 12.3676 11.9138 12.5939L14.8894 19.2889L20.37 3.62997L4.71108 9.1106Z" fill="black"/>
        </svg>               
        `;
    }
}
