import { Injectable } from '@angular/core';
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Bay, initialBaseState } from './bays.model';

export interface BayState extends EntityState<Bay, number> {
  currentCount: number;
  count: number;
  limit: number;
  page: number;
  searchTerm: string;
  sortColumn: string;
  sorts: any[];
  stateSelected: string;
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'bays', resettable: true })
export class BayStore extends EntityStore<BayState> {
  constructor() {
    super(initialBaseState);
  }

  getSortColumn() {
    return this.getValue().sortColumn;
  }

  getSorts() {
    return this.getValue().sorts;
  }

  getPage() {
    return this.getValue().page;
  }

  getLimit() {
    return this.getValue().limit;
  }

  getSearchTerm() {
    return this.getValue().searchTerm;
  }

  getCount() {
    return this.getValue().count;
  }

  getStateSelected() {
    return this.getValue().stateSelected;
  }

  getIsActive() {
    return this.getValue().isActive;
  }

  updatePage(page: number) {
    this.update({ page });
  }

  updateLimit(limit: number) {
    this.update({ limit });
  }

  updateSearchTerm(searchTerm: string) {
    this.update({ searchTerm });
  }

  updateStateSelected(stateSelected: string) {
    this.update({ stateSelected });
  }

  updateSortColumn(sortColumn: string) {
    this.update({ sortColumn });
  }


  updateSorts(sorts: any[]) {
    this.update({ sorts });
  }

  updateCurrentCount(currentCount: number) {
    this.update({ currentCount });
  }

  updateCount(count: number) {
    this.update({ count })
  }

  updateIsActive(isActive: boolean) {
    this.update({ isActive });
  }
}
