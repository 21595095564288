import { FlightDeckState, FlightDeckStore } from './flightdeck.store';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_PATH } from '../../../../constants/api-urls';
import { forkJoin, Subject } from 'rxjs';
import * as _ from 'lodash';
import { Query } from '@datorama/akita';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { getRepairOrderTime, saveRepairOrderTime } from '../../storage';
export interface RepairOrder {
  bookingId: number
  booking: string
  rego: string
  customerName: string,
  customerLastName: string,
  serviceStatus: any
  recentBay: string
  existingBay: string
  lastSeen: string
  isOverTime: boolean
}

@Injectable({ providedIn: 'root' })
export class FlightDeckService {

  customBayTypes = ['Customer Check-In', 'Car Wash', 'Pick Up', 'Parking'];
  nonOEMcodes = ['logbook', 'premium', 'premium-plus', 'tyre-bonus'];
  timeOffset = new Date().getTimezoneOffset();

  repairOrders = {};
  offgridItems: Subject<object> = new Subject<object>();
  offgridTabSelected: Subject<boolean> = new Subject<boolean>();

  constructor(
    private flightDeckStore: FlightDeckStore,
    private http: HttpClient,
    private router: Router
  ) { }

  getListFlightDeck() {
    const flightDecks = this.http.get(API_PATH.flightDecks.list);
    const currentDate = moment().format('YYYY-MM-DD').toString();
    const flightDecksTracking = this.http.get(API_PATH.flightDecks.tracking + `?bookedFrom=${currentDate}&bookedTo=${currentDate}&isUtc=false&includeBookingWithNoTracking=false&timeOffset=${this.timeOffset}`);

    return forkJoin([
      flightDecks,
      flightDecksTracking,
      this.http.get(API_PATH.types.bookingStatus),
      this.http.get(API_PATH.bookings.serviceTypes)
    ]).subscribe(([res1, res2, res3, res4]) => {
      let statuses = res3['results'] || [];
      let serviceTypes = res4['results'] || [];

      const updatedFlightdecks = [];
      if (res1['count'] && res1['count'] > 0) {
        for (let fd of res1['results']) {
          let dashboard = [];
          let bays = fd.bays;
          bays = _.sortBy(bays, (o) => { return o.x && o.x + o.y });
          bays.map((bay) => {
            const isSpecialName = this.customBayTypes.find(n => bay.bayName.includes(n))
            const bayType = isSpecialName ? bay.bayName.replace(/\s/g, '').replace(/-/g, '').toLowerCase() : "service";
            /*
             * 0 up
             * 2 down
             * 3 left
             * 1 right
             */
            const item = {
              cols: 1,
              rows: 1,
              x: bay.x,
              y: bay.y,
              isDisplayCar: false,
              serviceType: false,
              direction: bay.parkingDirection,
              bayType: bayType,
              // bay: this.customBayTypes.includes(bay.bayName) ? null : bay.bayName,
              bay: null,
              bayId: bay.bayId,
              rego: null,
              percent: null,
              isOverTime: false,
              serviceTime: {},
              id: bay.id
            }
            dashboard.push(item);
          })
          fd = { ...fd, bays: dashboard }
          updatedFlightdecks.push(fd);
        }
      }

      if (res2['count'] && res2['count'] > 0) {
        let trackings = res2['results'];
        trackings = _.map(trackings, (tracking) => {
          tracking.createdAtFormat = tracking.bays[0] ? moment(tracking.bays[0].createdAt).format('YYYY-MM-DDTHH:mm:ss') : null;
          return tracking;
        })

        trackings
          .sort((a, b) => {
            return moment(a.createdAtFormat).isBefore(moment(b.createdAtFormat)) ? 1 : -1;
          })
          .map((tracking) => {
            const serviceType = _.find(serviceTypes, (o) => { return o.id == tracking.serviceTypeId });
            const bookingStatus = _.find(statuses, (o) => { return o.id == tracking.bookingStatusId });
            const lastSeenTime = getRepairOrderTime(tracking.repairOrderId);
            const RO: RepairOrder = {
              bookingId: tracking.id,
              booking: moment(tracking.bookingTime).format("h:mm A DD/MM/YYYY"),
              rego: tracking.rego,
              customerName: tracking.customerName,
              customerLastName: tracking.customerLastName,
              serviceStatus: {
                positionLeft: true,
                isTransparent: true,
                percent: (tracking.serviceProgressPercent * 100) >= 100 ? 100 : Math.floor(tracking.serviceProgressPercent * 100),
                isDisplayPerent: bookingStatus.code != 'ready-for-pickup' ? true : false,
                tag: {
                  types: 'status',
                  code: bookingStatus.code
                }
              },
              recentBay: '',
              existingBay: tracking.bays[0] ? tracking.bays[0].bayName : '',
              lastSeen: lastSeenTime ? lastSeenTime :
                tracking.bays[0] ? moment.utc(tracking.bays[0].createdAt).local().format("h:mm A DD/MM/YYYY") :
                  moment().local().format("h:mm A DD/MM/YYYY"),
              isOverTime: tracking.serviceExceptedTime > 0 && tracking.serviceTime > tracking.serviceExceptedTime && !['ready-for-pickup'].includes(bookingStatus.code)
            }

            if (tracking.bays[0] && tracking.bays[0].isOffGrid) {
              const timeStamp = this.repairOrders[tracking.repairOrderId] ? this.repairOrders[tracking.repairOrderId].lastSeen : RO.lastSeen;
              const oldBay = RO.existingBay;
              this.repairOrders[tracking.repairOrderId] = {
                ...RO,
                recentBay: oldBay,
                existingBay: '',
                lastSeen: timeStamp
              };
              return;
            }

            for (let fd of updatedFlightdecks) {
              const location = tracking.bays[0] ? tracking.bays[0] : null;
              let _index = _.findIndex(fd.bays, (bay) => { return location != null && bay.bayId == location.bayId });

              if (_index != -1) {
                if (fd.bays[_index].bayType != 'service' && fd.bays[_index].bayType != undefined) {
                  const bayType = fd.bays[_index].bayType;
                  _index = _.findIndex(fd.bays, (o) => { return o.bayType == bayType && o.repairOrderId == undefined && o.bayId == location.bayId });
                }



                if (_index != -1 && !!!fd.bays[_index].isDisplayCar) {
                  //   let _serviceIndex = _.findIndex(environment.serviceType, (o) => { return o.id == tracking.serviceTypeId });
                  const percent = Math.floor(tracking.burnOutCirclePercent);
                  fd.bays[_index].bookingId = tracking.id;
                  fd.bays[_index].isDisplayCar = true;
                  //   fd.bays[_index].serviceType = environment.serviceType[_serviceIndex].code;
                  fd.bays[_index].serviceType = serviceType.code;
                  fd.bays[_index].rego = tracking.rego;
                  fd.bays[_index].percent = percent > 100 ? 100 : percent;
                  fd.bays[_index].repairOrderId = tracking.repairOrderId;
                  fd.bays[_index].isOverTime =
                    (tracking.serviceExceptedTime > 0) && tracking.serviceTime > tracking.serviceExceptedTime ? true : false;
                  fd.bays[_index].serviceTime = {
                    time: Math.ceil(tracking.serviceTime) + ' mins',
                    expected: Math.ceil(tracking.serviceExceptedTime) + ' mins',
                    isTimeout: tracking.serviceTime > tracking.serviceExceptedTime ? true : false,
                  };

                  this.repairOrders[tracking.repairOrderId] = {
                    ...RO,
                    lastSeen: moment().local().format("h:mm A DD/MM/YYYY")
                  };
                }
                else {
                  const oldBay = RO.existingBay
                  this.repairOrders[tracking.repairOrderId] = {
                    ...RO,
                    recentBay: oldBay + ' (occupied)',
                    existingBay: '',
                    lastSeen: moment().local().format("h:mm A DD/MM/YYYY")
                  };
                }
              }
            }
          })
      }

      this.flightDeckStore.updateFlightDeck(updatedFlightdecks);
      this.updateROsLocal();
      this.offgridItems.next(this.repairOrders);
    })
  }

  getListFlightDeckForListView() {
    const currentDate = moment().format('YYYY-MM-DD').toString();
    const url = `${API_PATH.flightDecks.tracking}?bookedFrom=${currentDate}&bookedTo=${currentDate}&isUtc=false&includeBookingWithNoTracking=true&timeOffset=${this.timeOffset}`
    return forkJoin([
      this.http.get(API_PATH.types.bookingStatus),
      this.http.get(url),
      this.http.get(API_PATH.bookings.serviceTypes)
    ]).subscribe(([resStatus, res, res2]) => {
      let statuses = resStatus['results'] || [];
      let serviceTypes = res2['results'] || [];
      let results = res['results'] || [];
      const format = 'h:mm A DD/MM/YYYY';
      results = results
        .filter((result) => result.repairOrderId != null)
        .map((item) => {
          let serviceType = _.find(serviceTypes, (o) => { return o.id == item.serviceTypeId });
          item.serviceType = serviceType.description;
          let serviceCode = serviceType.code;
          const bookingStatus = _.find(statuses, (o) => { return o.id == item.bookingStatusId })
          const itemTransform = {
            id: item.id,
            icon: this.nonOEMcodes.includes(serviceCode) ? 'calendar' : serviceCode.includes('oem') ? 'calendar-blue' : 'express',
            rego: item.rego,
            booking: this.router.url.includes('reception') ? moment(item.bookingTime).format(format) : moment(item.bookingTime).format(format) + ` (${item.serviceEstimatedTotalTime} min)`,
            bookingStatusCode: bookingStatus.code,
            estimatedPickUpTime: item.estimatedPickUpTime,
            serviceStatus: {
              positionLeft: true,
              isTransparent: true,
              percent: (item.serviceProgressPercent * 100) >= 100 ? 100 : Math.floor(item.serviceProgressPercent * 100),
              isDisplayPerent: bookingStatus.code != 'ready-for-pickup' ? true : false,
              tag: {
                types: 'status',
                code: bookingStatus.code
              }
            },
            serviceType: {
              icon: this.nonOEMcodes.includes(serviceCode) ? 'calendar' : serviceCode.includes('oem') ? 'calendar-blue' : 'express',
              description: item.serviceType
            },
            customerName: item.customerName.trim(),
            customerLastName: item.customerLastName,
            // bay: item.bays && item.bays.length > 0 ? (item.bays[0].bayName || null) : null,
            bay: item.bayName || null,
            serviceTime: {
              time: this.minsToHms(item.serviceTime),
              isTimeout: item.serviceTime > item.serviceExceptedTime ? true : false,
            },
            totalTime: {
              time: this.minsToHms(item.serviceTotalTime),
              isTimeout: item.serviceTotalTime > item.serviceExceptedTotalTime ? true : false,
            },
            isOverTime: item.serviceExceptedTime > 0 && item.serviceTime > item.serviceExceptedTime && !['ready-for-pickup'].includes(bookingStatus.code),
            isReception: this.router.url.includes('reception')
          }
          return itemTransform;
        });

      let readyItems = [];
      let otherItems = [];
      results.forEach(r => {
        if (r.bookingStatusCode === 'ready-for-pickup') {
            readyItems.push(r);
        } else {
            otherItems.push(r);
        }
      });
      readyItems = readyItems.reverse();
      otherItems = otherItems.sort((a, b) => {
          if (!a.estimatedPickUpTime || !b.estimatedPickUpTime) return 0;
          const timeA = moment(a.estimatedPickUpTime)
          const timeB = moment(b.estimatedPickUpTime)
          return timeA.diff(timeB)
        })
      results = readyItems.concat(otherItems)
      this.flightDeckStore.update(state => ({
        listView: results
      }));
    });
  }

  getListFlightDeckForBeacons() {
    return this.http.get(API_PATH.flightDeckForBeacons).pipe().subscribe((res) => {
      let results = res['results'] || [];
      const format = 'h:mm A DD/MM/YYYY';
      results = results.map((item) => {
        const itemTransform = {
          battery: item.beaconBattery,
          macId: item.beaconMac,
          markerId: item.beaconMarkerId,
          currentAssociatedBooking: {
            bookingId: item.bookingId,
            vehicleRego: item.vehicleRego
          },
          latestDate: item.lastestReportedTime ? moment.utc(item.lastestReportedTime).local().format(format) : '',
          closestGateway: item.gatewayMac,
          status: {
            code: this.getIcons(item.lastestReportedTime),
            text: item.lastestReportedTime ? 'Latest: ' + moment.utc(item.lastestReportedTime).local().format(format) : ''
          },
        }
        return itemTransform;
      });
      this.flightDeckStore.update(state => ({
        listView: results
      }));
    });
  }

  private getIcons(lastestReportedTime) {
    if (!lastestReportedTime) {
      return 'circle-red';
    }
    const minutesDiff = moment.utc().diff(moment.utc(lastestReportedTime), 'minutes');
    if (minutesDiff <= 5) {
      return 'circle-green';
    } else if (minutesDiff > 5 && minutesDiff <= 60) {
      return 'circle-orange';
    } else if (minutesDiff > 60) {
      return 'circle-red';
    }
  }

  private minsToHms(input) {
    const d = Number(Math.ceil(input));
    const today = moment().hours(0).minutes(0).seconds(0).milliseconds(0);
    const sDate = moment(today);
    today.minutes(d);
    const day = today.diff(sDate, 'days');
    const hours = today.hours();
    const mins = today.minutes();
    const dayDisplay = day > 0 ? day + (day == 1 ? " day " : " days ") : "";
    const hDisplay = hours > 0 ? hours + (hours == 1 ? " hour " : " hours ") : "";
    const mDisplay = mins > 0 ? mins + (mins == 1 ? " min" : " mins") : "";
    return dayDisplay + hDisplay + mDisplay;
  }

  pickUpRO(bookingId: number) {
    const repairOrderId = _.findKey(this.repairOrders, (o) => { return o.bookingId === bookingId });
    if (repairOrderId) {
      delete this.repairOrders[repairOrderId];
      this.updateROsLocal();
      this.offgridItems.next(this.repairOrders);
    }
  }

  updateROsLocal() {
    const mappedROs = {};
    for (const roId in this.repairOrders) {
      mappedROs[roId] = this.repairOrders[roId].lastSeen;
    }
    saveRepairOrderTime(mappedROs);
  }
}

@Injectable({ providedIn: 'root' })
export class FlightDeckQuery extends Query<FlightDeckState> {

  listViews$ = this.select('listView');
  listFlightDeck$ = this.select('flightDecks');
  isOnFullScreen$ = this.select('isOnFullScreen');
  flightdeckCode$ = this.select('flightdeckCode');
  isShownBookingTimeline$ = this.select('isShownBookingTimeline');
  constructor(protected store: FlightDeckStore) {
    super(store);
  }

  listViewSearch(value) {
    this.listViews$ = this.select(state => {
      return state.listView.filter((item) => {
        return item.rego.toLowerCase().includes(value.trim().toLowerCase()) ||
          item.customerName.toLowerCase().includes(value.trim().toLowerCase())
      });
    });
  }

  isShownBookingTimeline() {
    return this.store.getValue().isShownBookingTimeline;
  }
}
