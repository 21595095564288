import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class LinkIconService {

    render() {
        return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7752 2.75071C13.9069 1.65776 15.4225 1.05299 16.9957 1.06666C18.5688 1.08033 20.0737 1.71135 21.1862 2.8238C22.2986 3.93624 22.9296 5.44112 22.9433 7.0143C22.957 8.58748 22.3522 10.1031 21.2592 11.2347L21.2471 11.2471L18.2472 14.247C17.6389 14.8555 16.9069 15.326 16.1008 15.6267C15.2947 15.9274 14.4333 16.0512 13.5752 15.9898C12.717 15.9283 11.8821 15.683 11.1271 15.2705C10.372 14.858 9.71453 14.2879 9.19919 13.599C8.86838 13.1567 8.95873 12.53 9.40098 12.1992C9.84323 11.8684 10.4699 11.9588 10.8007 12.401C11.1443 12.8603 11.5826 13.2404 12.086 13.5154C12.5893 13.7904 13.1459 13.9539 13.718 13.9949C14.2902 14.0359 14.8644 13.9533 15.4018 13.7528C15.9392 13.5524 16.4272 13.2387 16.8327 12.833L19.8264 9.83937C20.5514 9.0856 20.9525 8.07773 20.9434 7.03168C20.9342 5.98289 20.5136 4.97964 19.7719 4.23801C19.0303 3.49638 18.0271 3.0757 16.9783 3.06659C15.9318 3.05749 14.9235 3.4589 14.1696 4.18453L12.455 5.88916C12.0633 6.27855 11.4302 6.2767 11.0408 5.88504C10.6514 5.49338 10.6533 4.86021 11.0449 4.47083L12.7649 2.76083L12.7752 2.75071Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.89911 8.37328C8.70522 8.07258 9.56657 7.94876 10.4247 8.01022C11.2829 8.07168 12.1178 8.31698 12.8729 8.72949C13.6279 9.142 14.2854 9.71206 14.8007 10.401C15.1315 10.8433 15.0412 11.47 14.5989 11.8008C14.1567 12.1316 13.53 12.0412 13.1992 11.599C12.8556 11.1397 12.4173 10.7596 11.9139 10.4846C11.4106 10.2096 10.854 10.0461 10.2819 10.0051C9.70976 9.96413 9.13552 10.0467 8.59811 10.2471C8.06071 10.4476 7.5727 10.7613 7.16719 11.167L4.17355 14.1606C3.44851 14.9144 3.04746 15.9223 3.05655 16.9683C3.06566 18.0171 3.48634 19.0203 4.22797 19.762C4.9696 20.5036 5.97285 20.9243 7.02164 20.9334C8.06768 20.9425 9.07555 20.5414 9.82932 19.8164L11.5328 18.1129C11.9234 17.7224 12.5565 17.7224 12.9471 18.1129C13.3376 18.5034 13.3376 19.1366 12.9471 19.5271L11.2371 21.2371L11.2247 21.2493C10.0931 22.3422 8.57744 22.947 7.00426 22.9333C5.43108 22.9197 3.9262 22.2886 2.81376 21.1762C1.70131 20.0637 1.07029 18.5589 1.05662 16.9857C1.04295 15.4125 1.64772 13.8969 2.74067 12.7653L2.75285 12.7529L5.75272 9.75302C5.75268 9.75306 5.75276 9.75297 5.75272 9.75302C6.36096 9.1446 7.09305 8.67396 7.89911 8.37328Z" fill="black"/>
        </svg>                                
        `;
    }
}
