import { Injectable } from "@angular/core";
import { API_PATH } from '../../../../constants/api-urls';
import { HttpClient } from '@angular/common/http';
import { tap, map } from 'rxjs/operators';
import { SearchBookingListStore, BookingListStore } from './bookings.store';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';

import { getTimezone } from '../../storage';
import { APIService } from "../../../utils/api.service";
import { BOOKING_STATUSES } from "../../../../constants/status";
import { BehaviorSubject } from 'rxjs';

const timezone = getTimezone();
moment.tz.setDefault(timezone);

@Injectable({ providedIn: 'root' })
export class BookingListService {
  callApiListBooking$ = new BehaviorSubject<boolean>(true);
  constructor(
    private http: HttpClient,
    private bookingListStore: BookingListStore,
    private searchBookingListStore: SearchBookingListStore,
  ) { }

  resetSearchStore() {
    return this.searchBookingListStore.reset();
  }

  //GET
  getBookings({
    dateFrom = null, 
    dateTo = null, 
    keyword = null, 
    vehicleId = null, 
    includes = null, 
    isSearch = false, 
    isCancel = false, 
    sort = null, 
    filterStatus = null, 
    pageSize = 50, 
    serviceCenterIds = null,
    viewBy = null as "bookingDate" | "createdDate"
  }) {
    let url = API_PATH.bookings.bookings + '?';

    // past, yesterday, today, upcomingToday, upcoming
    if (includes) {
      url += `&includes=${includes}`;
    }

    if (vehicleId) {
      url += `&vehicleId=${vehicleId}`;
    }

    if (dateFrom) {
      url += `&bookedFrom=${dateFrom}`;
    }

    if (dateTo) {
      url += `&bookedTo=${dateTo}`;
    }

    if (keyword) {
      url += `&keyword=${keyword}`;
    }

    if (sort) {
      url += `&sort=${sort}`;
    }

    if (serviceCenterIds && serviceCenterIds.length > 0) {
      url += `&serviceCenters=${serviceCenterIds}`;
    }

    // this forces the booking time ranges (bookedFrom/bookedTo payload) by booking date or created date
    if (viewBy) {
      url += `&viewBy=${viewBy}`;
    }

    if (filterStatus && filterStatus.length > 0) {
      isCancel = filterStatus.some(status => status === BOOKING_STATUSES.cancelled);
      url += `&statuses=${filterStatus}`;
    }

    url += `&pageSize=${pageSize}`;

    return this.http.get(url).pipe(
      map((res: any) => res.results),
      tap((bookings: any) => {
        if (!isCancel) {
          bookings = _.filter(bookings, (o) => { return o.bookingStatus.code != 'cancelled' });
        }

        if (!isSearch) {
          this.bookingListStore.set(bookings);
        } else {
          this.searchBookingListStore.set(bookings);
        }
      })
    );
  }

  getBookingWithUuid(bookingUuid) {
    const url = APIService.prepareURI(API_PATH.bookings.bookingDetailsWithUuid, { bookingUuid });
    return this.http.get<any>(url).pipe();
  }

  getManageBookings(bookedDate) {
    const bookedEndDate = bookedDate + 'T23:59:59';
    return this.getBookings({
        dateFrom: bookedDate, 
        dateTo: bookedEndDate, 
        pageSize: 1000
      });
  }

  getListBookings({
    viewDate,
    viewDateRange = null,
    viewBy = 'bokingDate' as "bookingDate" | "createdDate",
    keyword = null,
    sort = null,
    filter = null,
    includes = null,
    serviceCenterIds = null
  }) {
    let dateFrom = viewDate + 'T00:00:00';
    let dateTo = viewDate + 'T23:59:59';
    
    if(viewDateRange && viewDateRange.length > 0) {
      dateFrom = moment(viewDateRange[0]).format('YYYY-MM-DD') + 'T00:00:00';
      dateTo = moment(viewDateRange[1]).format('YYYY-MM-DD') + 'T23:59:59';
    }

    return this.getBookings({
        dateFrom: dateFrom, 
        dateTo: dateTo,
        viewBy: viewBy,
        keyword: keyword, 
        sort: sort,
        filterStatus: filter, 
        includes: includes, 
        pageSize: 1000, 
        serviceCenterIds,
      });
  }

  updateSortList(fieldSort) {
    this.bookingListStore.update({
      sortColumn: fieldSort
    });
  }

  updateFilterList(status) {
    this.bookingListStore.update({
      filterStatus: status
    });
  }

  updateBookingAt(bookingId, bookedAt, bookedDate, bayId) {
    const url = API_PATH.bookings.bookings + `/${bookingId}`;
    return this.http.put(url, {
      bookedAt: bookedAt,
      bayId: bayId
    }).pipe(
      // tap((data: any) => {
      //   this.getManageBookings(bookedDate).subscribe();
      // }),
    );
  }

  getTotalBookingsAPI() {
    let filterUrl = "includes=today&includes=beforeYesterday&includes=yesterday&includes=lastMonth&includes=currentMonth&includes=lastWeek&includes=currentWeek";
    return this.http.get(API_PATH.bookings.bookingsSummaries + `/?${filterUrl}`)
      .pipe((tap((res: any) => {
        return res;
      })));
  }

  getVehicleConditionType() {
    return this.http.get(API_PATH.vehicles.conditionTypes).pipe((tap((res: any) => {
      return res;
    })));
  }

  getVehiclePositionType() {
    return this.http.get(API_PATH.vehicles.positionTypes).pipe((tap((res: any) => {
      return res;
    })));
  }
  getConditions(id, bookingId) {
    const url = APIService.prepareURI(API_PATH.vehicles.conditions, { vehicleId: id, bookingId });

    return this.http.get(url).pipe((tap((res: any) => {
      return res;
    })));
  }

  getBookingStatus(bookingId) {
    const url = APIService.prepareURI(API_PATH.bookings.bookingStatus, { bookingId });

    return this.http.get(url).pipe((tap((res: any) => {
      return res;
    })));
  }
  
  getImageHandler() {
    return this.http.get(API_PATH.publics.imageHandler).pipe((tap((res: any) => {
      return res.value;
    })));
  }

  getStatusList(){
    return this.http.get(API_PATH.types.bookingStatus).pipe((map((res: any) => {
      return res.results;
    })));
  }

  getStatusListBusiness(){
    return this.http.get(API_PATH.types.bookingProgress).pipe((map((res: any) => {
      return res.results || [];
    })));
  }

  //POST
  addCondition(vehicleId, bookingId, formData) {
    const url = APIService.prepareURI(API_PATH.vehicles.conditions, { vehicleId, bookingId });

    return this.http.post(url, formData);
  }

  //PUT
  updateCondition(vehicleId, conditionId, formData) {
    const url = APIService.prepareURI(API_PATH.vehicles.condition, { vehicleId, vehicleConditionId: conditionId });

    return this.http.put(url, formData);
  }
  checkinBooking(bookingId, data) {
    const url = APIService.prepareURI(API_PATH.bookings.bookingStatusCheckin, { bookingId });
    
    return this.http.put(url, data);
  }

  //DELETE
  deleteCondition(vehicleId, conditionId) {
    const url = APIService.prepareURI(API_PATH.vehicles.condition, { vehicleId, vehicleConditionId: conditionId });

    return this.http.delete(url);
  }

  getEstTimeForBooking(booking) {
    let duration = 0;
    duration += booking.serviceTimeInMinutes || 0;
    for (const option of booking.extraOptions) {
        duration += option.serviceTime || 0;
    }

    if (!duration || duration < 60) {
        duration = 60;
    } else {
        const mod = duration % 10;
        if (mod !== 0) duration += 10 - mod;
    }

    return duration;
  }

  updateAppointmentRef(bookingId, appointmentRef) {
    const url = APIService.prepareURI(API_PATH.bookings.updateAppointmentRef, { bookingId });

    return this.http.put(url, { appointmentRef });
  }

  updateBookingAdminNote(bookingId, adminNote) {
    const url = APIService.prepareURI(API_PATH.bookings.updateBookingAdminNote, { bookingId });

    return this.http.put(url, { note: adminNote });
  }
}