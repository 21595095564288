import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SessionService } from '../@core/akita-stores/stores/session/session.service';
import { API_PATH } from '../constants/api-urls';
import { NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SessionQuery } from '../@core/akita-stores/stores/session/session.query';
import * as _ from 'lodash';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private sessionService: SessionService, private toastrService: NbToastrService, private router: Router, private jwtHelper: JwtHelperService, private sessionQuery: SessionQuery) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401 && !this.isLogging(err) && !this.isHandlingPermissions(err)) {
        if (this.isTokenExpired() || this.isHandlingSelf(err)) {
          // auto logout if 401 response returned from api
          this.sessionService.logout();
          this.router.navigateByUrl('/auth/login');
        } else {
          this.router.navigateByUrl('/pages/no-permissions');
        }
      } else {
        if (!this.isLogging(err) || !this.isHandlingPermissions(err)) {
          const messages = ((err.error.fields || []).map(field => {
            if (_.isObject(field)) {
              return (field.Details || []).join(' \n');
            }
            return field;
          })) || err.error.details || [];
          let errs = messages.join(' \n');
          if (!errs) {
            errs = (err.error.details || []).join(' \n');
          }
          if (errs) {
            setTimeout(() => {
                this.toastrService.danger(errs, 'Error', { destroyByClick: true, hasIcon: false });
            }, 500);  
          }
        }
      }

      return throwError(err);
    }))
  }

  /**
  * Check if it is a request for logging in, if yes, location should not be reloaded, then user can try to login again knowing that the account that they entered was incorrect
  *
  * @memberof ErrorInterceptor
  */
  isLogging(err) {
    return err.url === API_PATH.user.login;
  }

  isHandlingPermissions(err) {
    return err.url.indexOf('/rights/') > -1;
  }

  isTokenExpired() {
    return this.jwtHelper.isTokenExpired(this.sessionQuery.getValue().accessToken);
  }

  /**
   * Check if it is a request for "self", if yes, need to log out if these api get 401 status
   *
   */
  private isHandlingSelf(err) {
    return err.url.indexOf('/self/') > -1 || err.url.indexOf('/entities/') > -1;
  }
}
