import * as _ from 'lodash';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { BookingListService } from '../../../@core/akita-stores/entity-stores/bookings/bookings.service';
import { Router } from '@angular/router';
import { BookingCalendarService, BookingCalendarQuery } from '../../../@core/akita-stores/stores/booking-calendar/booking-calendar.store';
import { takeUntil } from 'rxjs/operators';
import { ServiceCentersQuery } from '../../../@core/akita-stores/entity-stores/sercice-centers/service-centers.query';
import { Subject } from 'rxjs';

@Component({
  selector: 'sidebar-bookings',
  templateUrl: './sidebar-bookings.component.html',
  styleUrls: ['./sidebar-bookings.component.scss']
})
export class SidebarBookingsComponent implements OnInit, OnDestroy {

  @Output() calendarChange: EventEmitter<any> = new EventEmitter();
  public viewCalendar: boolean = true;
  viewCalendar$;
  destroy$ = new Subject<void>();
  hasFDService: boolean = false;
  isDisplayLiteBooking: boolean = true;
  isHeavyWorkflow: boolean = false;

  isBookingStatsReadyShow: boolean = false;

  constructor(private router: Router, 
    private bookingCalendarService: BookingCalendarService,
    public query: BookingCalendarQuery,
    private serviceCentersQuery: ServiceCentersQuery,
  ) { }

  ngOnInit() {
    if (this.viewCalendar$) this.viewCalendar$.unsubscribe();
    this.viewCalendar$ = this.query.viewCalendar$.subscribe(() => {
      this.viewCalendar = this.query.getValue().viewCalendar;
    })
    this.serviceCentersQuery.serviceCenterSeleted$
      .pipe(takeUntil(this.destroy$))
      .subscribe(serviceCenterSeleted => {
        this.hasFDService = _.get(serviceCenterSeleted, "[0].hasFlightDeck", false);
        this.isHeavyWorkflow = _.get(serviceCenterSeleted, "[0].bookingServicesConfigs.useEraPower", false);
        this.isDisplayLiteBooking = _.get(serviceCenterSeleted, "[0].displayLiteBooking", true);
        this.isBookingStatsReadyShow = true;
      });
  }

  onCalendarChange(event) {
    this.calendarChange.emit(event);
  }

  redirectTo(view) {
    if (view === 'pages/booking' && this.hasFDService) {
      this.bookingCalendarService.updateViewCalendar(true);
    }
    else {
      this.bookingCalendarService.updateViewCalendar(false);
    }
    // this.router.navigateByUrl(view);
  }

  ngOnDestroy() {
    if (this.viewCalendar$) this.viewCalendar$.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

}
