import { Injectable } from '@angular/core';
import { Store, StoreConfig, ID } from '@datorama/akita';
import * as storage from '../../storage';

export interface SessionState {
  accessToken: string;
  userId: any;
  entityId?: ID;
}

export function createInitialSessionState(): SessionState {
  return {
    accessToken: '',
    userId: '',
    entityId: storage.getCurrentEntity() || null,
    ...storage.getSession(),
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'session', resettable: true })
export class SessionStore extends Store<SessionState> {

  constructor() {
    super(createInitialSessionState());
  }

  login(session: SessionState) {
    this.update(session);
    storage.saveSession(session);
  }

  logout() {
    storage.clearSession();
    this.update(createInitialSessionState());
    storage.clearStore();
  }
}
