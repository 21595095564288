import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { NbDialogService, NbDialogRef, NbToastrService } from '@nebular/theme';

import { ERROR_MESSAGES } from './../../../constants/error-messages';
import { BookingDetails } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.store';
import { BookingDetailsSelectedQuery } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.query';
import { CommonService } from '../../../@core/utils/common.service';
import { ConfigQuery, ConfigStore } from '../../../@core/akita-stores/stores/config/config.store';
import { PaymentService, CybersourceSignaturePayload } from '../../../@core/utils/payment.service';
import creditCardType from 'credit-card-type';
import { CARD_TYPES } from '../../../constants/card-types';
import { CustomerService } from '../../../@core/akita-stores/stores/customer/customer.service';
import { environment } from '../../../../environments/environment';
import { ConfigService } from '../../../@core/akita-stores/stores/config/config.service';

// import { PaymentResultDialogComponent } from '../payment-result-dialog/payment-result-dialog.component';

@Component({
  selector: 'card-details-dialog',
  templateUrl: './card-details-dialog.component.html',
  styleUrls: ['./card-details-dialog.component.scss']
})
export class CardDetailsDialogComponent implements OnInit {
  errorMessage = ERROR_MESSAGES;
  cardDetailsForm: FormGroup;
  payingPrices;
  @Output() currentState = new EventEmitter<string>();
  @Input('failedPayment') failedPayment;
  bookingDetails: BookingDetails;
  cardType = '';
  inputAmount;
  monthInput;
  yearInput;
  loading: boolean = false;
  @ViewChild('form', { static: true }) form;
  paymentUrl: string;

  /**
   * Whether user clicks on discount box
   */
  @Input('discount') discount;

  constructor(
    private dialogService: NbDialogService,
    protected ref: NbDialogRef<CardDetailsDialogComponent>,
    private bookingDetailsQuery: BookingDetailsSelectedQuery,
    private commonService: CommonService,
    private paymentService: PaymentService,
    private toastrService: NbToastrService,
    private configQuery: ConfigQuery,
    private customerService: CustomerService,
    private configService: ConfigService
  ) {
    this.discount === 'true';
    this.cardDetailsForm = new FormGroup({
      cardNumber: new FormControl('', [Validators.required]),
      expiredDate: new FormControl('', [Validators.required]),
      ccv: new FormControl('', [Validators.required]),
      cardName: new FormControl('', [Validators.required])
    });
    // this.cardDetailsForm = new FormGroup({
    //   cardNumber: new FormControl('4111111111111111', [Validators.required]),
    //   expiredDate: new FormControl('', [Validators.required]),
    //   ccv: new FormControl('333', [Validators.required]),
    //   cardName: new FormControl('abcd', [Validators.required])
    // });

    this.bookingDetails = this.bookingDetailsQuery.getValue();
    this.payingPrices = this.commonService.calcPayingPrices(this.bookingDetails);
    this.inputAmount = this.configQuery.getValue().inputAmount;

    this.cardDetailsForm.controls.cardNumber.valueChanges.subscribe(val => {
      if (this.cardDetailsForm.controls.cardNumber.valid) {
        try {
          this.cardType = CARD_TYPES[creditCardType(val)[0].niceType];
          this.cardDetailsForm.controls.cardNumber.setErrors(null);
        } catch (e) {
          this.cardDetailsForm.controls.cardNumber.setErrors({ undetectable: true });
        }
      }
    });
    this.cardDetailsForm.controls.expiredDate.valueChanges.subscribe(val => {
      this.monthInput = val.slice(0, 2);
      this.yearInput = ('20' + val.slice(-2));
      const currentYear = (new Date).getFullYear();
      const currentMonth = (new Date).getMonth() + 1;

      if (+(this.monthInput) > 12 || +(this.yearInput) < currentYear || (+(this.monthInput) < currentMonth && +this.yearInput <= currentYear)) {
        this.cardDetailsForm.controls.expiredDate.setErrors({ invalidDateCard: true });
      } else {
        this.cardDetailsForm.controls.expiredDate.setErrors(null);
      }
    });
  }

  ngOnInit() {
    this.paymentService.getPaymentMeta().subscribe((res: any) => {
      this.paymentUrl = res.creditCard.endpointUri;
    });
    // this.clearTheSecondPopup();
  }

  /**
   * Fixing an issue: This component is being rendered twice, this causes the CardDetailsDialog popup to be duplicated
   */
  clearTheSecondPopup() {
    try {
      const list = document.querySelectorAll('.card-detail-dialog');
      list[1].remove();
      const list1 = document.querySelectorAll('#paymentPayloadForm');
      list1[1].remove();
    } catch (e) { }
  }

  openDlg(hasBackdrop: boolean = true) {
    this.paymentService.submitPayment(this.bookingDetailsQuery.getValue().id).subscribe(val => {
      this.currentState.emit('payment-result');
    }, err => {

    })
  }

  pay() {
    if (this.cardDetailsForm.invalid) return;

    const cybersourceSignaturePayload: CybersourceSignaturePayload = this.getCybersourceSignaturePayload();

    if (cybersourceSignaturePayload) {
      this.loading = true;
      this.paymentService.createCybersourceSignature(cybersourceSignaturePayload).subscribe((signature: any) => {
        this.loading = false;
        let values = signature;
        values.card_type = this.cardType;
        values.card_number = this.cardDetailsForm.value.cardNumber.split(' ').join('');
        values.card_expiry_date = `${this.monthInput}-${this.yearInput}`;

        this.createPaymentForm(values.signed_field_names, values);
        this.loading = true;
        const cardPaymentProcess = {
          bookingId: this.bookingDetails.id,
          makingCreditCardPayment: true
        }
        this.configService.setCreditCardPaymentProcess(cardPaymentProcess);
        setTimeout(() => {
          this.submitForm();
        }, 1000);
      }, () => {
        this.loading = false;
      });
    }
  }

  getCybersourceSignaturePayload(): CybersourceSignaturePayload {
    const bookingDetails = this.bookingDetailsQuery.getValue();
    this.bookingDetailsQuery.getValue()
    this.bookingDetailsQuery.getValue()
    const userDetails = bookingDetails.customer;

    try {
      let payload: any = {
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        bookingId: bookingDetails.id,
        amount: +(this.discount ? parseFloat(this.payingPrices.totalWithDiscountAndPaymentFee).toFixed(2) : parseFloat(this.payingPrices.totalAndPaymentFee).toFixed(2)),
        email: this.commonService.getUserContact(bookingDetails.customer.contacts, 'email').contact.value,
        phone: this.commonService.getUserContact(bookingDetails.customer.contacts, 'phone').contact.value,
        address: this.commonService.getUserAddress(bookingDetails.customer.addresses[0]),
        city: bookingDetails.customer.addresses[0].address.city || environment.citiesOfStates[bookingDetails.customer.addresses[0].address.state],
        state: bookingDetails.customer.addresses[0].address.state,
        country: bookingDetails.customer.addresses[0].address.country || 'AU',
        postalCode: bookingDetails.customer.addresses[0].address.postCode,
      };

      if (this.discount) {
        payload.coupon = 'admin-discount-10';
      }

      let invalidFields = [];
      for (let k in payload) {
        if (!payload[k]) {
          invalidFields.push(k);
        }
      }

      if (invalidFields.length > 0) {
        const errMsg = environment.production ? ERROR_MESSAGES.generalError : 'Empty info: ' + invalidFields.join(', ');
        this.toastrService.danger(errMsg, 'Error', { destroyByClick: true, hasIcon: false });

        return null;
      }

      return payload;
    } catch (e) {
      this.toastrService.danger(ERROR_MESSAGES.generalError, 'Error', { destroyByClick: true, hasIcon: false });

      return null;
    }
  }

  private createPaymentForm(signedFieldNames: string, data) {
    let fieldNames = [...signedFieldNames.split(','), 'card_type', 'card_number', 'card_expiry_date', 'signature'];
    const form = document.getElementById('paymentPayloadForm');

    fieldNames.forEach(fieldName => {
      let input = document.createElement('input');
      input.id = fieldName;
      input.value = data[fieldName];
      input.name = fieldName;
      input.className = 'hidden'
      form.appendChild(input);
    });
  }

  private submitForm() {
    this.form.nativeElement.action = this.paymentUrl;
    this.form.nativeElement.submit();
  }

  close() {
    this.ref.close();
  }
}
