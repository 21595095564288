import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class CarretDownIconService {

    render() {
        return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1284 14.5871L8.75103 10.8345C8.46145 10.5127 8.6898 10 9.12268 10H15.8773C16.3102 10 16.5386 10.5127 16.249 10.8345L12.8716 14.5871C12.673 14.8077 12.327 14.8077 12.1284 14.5871Z" fill="black"/>
        </svg>                                
        `;
    }
}
