import { Query } from '@datorama/akita';
import { CustomerState, CustomerStore } from './customer.store';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class CustomerQuery extends Query<CustomerState> {

  currentCustomerDetails$ = this.select(['currentCustomerDetails']);

  constructor(protected store: CustomerStore) {
    super(store);
  }
}