import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class OverTimeIconService {

    render() {
        return `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.5682 8.255L7.57704 1.57009C7.27532 1.06215 6.74243 0.758789 6.15161 0.758789C5.56079 0.758789 5.0279 1.06215 4.72618 1.57009L0.735386 8.255C0.427195 8.77362 0.421366 9.3965 0.719513 9.92095C1.01834 10.4457 1.55671 10.7588 2.16046 10.7588H10.1428C10.7465 10.7588 11.2849 10.4457 11.5837 9.92095C11.8819 9.39652 11.8761 8.77362 11.5682 8.255ZM6.15163 2.80259C6.51782 2.80259 6.81464 3.09941 6.81464 3.4656V6.11768C6.81464 6.48382 6.51782 6.78069 6.15163 6.78069C5.78545 6.78069 5.48862 6.4838 5.48862 6.11768V3.4656C5.4886 3.09941 5.78545 2.80259 6.15163 2.80259ZM6.15163 9.43275C5.60322 9.43275 5.1571 8.98662 5.1571 8.43821C5.1571 7.8898 5.60322 7.44368 6.15163 7.44368C6.70004 7.44368 7.14617 7.8898 7.14617 8.43821C7.14615 8.98664 6.70004 9.43275 6.15163 9.43275Z" fill="#FF3838"/>
        </svg>
        `;
    }
}
