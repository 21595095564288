import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class RadioSelectIconService {

  render(isDefault = true) {
    if (isDefault) {
      return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12Z" fill="white"/>
    </svg>
    `;
    }
    else {
      return `<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.416504 4.99996C0.416504 2.46865 2.46853 0.416626 4.99984 0.416626C7.53114 0.416626 9.58317 2.46865 9.58317 4.99996C9.58317 7.53126 7.53114 9.58329 4.99984 9.58329C2.46853 9.58329 0.416504 7.53126 0.416504 4.99996Z" fill="#0062FF"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.33301 4.99992C3.33301 4.07944 4.0792 3.33325 4.99967 3.33325C5.92015 3.33325 6.66634 4.07944 6.66634 4.99992C6.66634 5.92039 5.92015 6.66659 4.99967 6.66659C4.0792 6.66659 3.33301 5.92039 3.33301 4.99992Z" fill="white"/>
      </svg>      
    `;
    }
  }
  checking() {
    return `<svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="var(--block-colour)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12Z" fill="white"/>
    </svg>
    `;
  }
}
