import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class ThumbsUpIconService {

    render() {
        return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0862 1.59386C10.2467 1.23273 10.6048 1 11 1C12.0609 1 13.0783 1.42143 13.8284 2.17157C14.5786 2.92172 15 3.93913 15 5V8H19.6549C20.0876 7.996 20.5162 8.08567 20.911 8.26289C21.3078 8.44096 21.6611 8.70317 21.9465 9.03134C22.2318 9.35951 22.4424 9.7458 22.5637 10.1634C22.6849 10.5811 22.7139 11.0201 22.6487 11.4501L21.2687 20.4499C21.2687 20.4502 21.2687 20.4497 21.2687 20.4499C21.1601 21.165 20.7966 21.8175 20.2456 22.2859C19.6958 22.7532 18.9961 23.0067 18.2748 23H4C3.20435 23 2.44129 22.6839 1.87868 22.1213C1.31607 21.5587 1 20.7957 1 20V13C1 12.2044 1.31607 11.4413 1.87868 10.8787C2.44129 10.3161 3.20435 10 4 10H6.35013L10.0862 1.59386ZM8 11.2122L11.6078 3.0946C11.9092 3.19075 12.1864 3.35794 12.4142 3.58579C12.7893 3.96086 13 4.46957 13 5V9C13 9.55228 13.4477 10 14 10H19.66L19.6713 9.99994C19.8163 9.99829 19.9599 10.0282 20.0921 10.0875C20.2244 10.1469 20.3421 10.2343 20.4373 10.3437C20.5324 10.4531 20.6026 10.5818 20.643 10.7211C20.6834 10.8601 20.6931 11.0063 20.6714 11.1495C20.6714 11.1494 20.6714 11.1497 20.6714 11.1495L19.2913 20.1501C19.2551 20.3885 19.134 20.6059 18.9503 20.7621C18.7665 20.9183 18.5325 21.0028 18.2913 21.0001L8 21V11.2122ZM6 21V12H4C3.73478 12 3.48043 12.1054 3.29289 12.2929C3.10536 12.4804 3 12.7348 3 13V20C3 20.2652 3.10536 20.5196 3.29289 20.7071C3.48043 20.8946 3.73478 21 4 21H6Z" fill="black"/>
        </svg>                        
        `;
    }
}
