import { Injectable } from '@angular/core';
import { API_PATH } from '../../constants/api-urls';
import { HttpClient } from '@angular/common/http';
import { Share } from '@ngspot/rxjs/decorators';
import { map, tap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { APIService } from './api.service';

export interface CybersourceSignaturePayload {
  bookingId: number,
  amount: number,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  address: string,
  city: string,
  state: string,
  country: string,
  postalCode: string
}

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) { }

  submitPayment(bookingId, amt?) {
    const url = APIService.prepareURI(API_PATH.bookings.bookingPayment, { bookingId });

    return this.http.post(url, { bookingId, amt });
  }

  createCybersourceSignature(payload: CybersourceSignaturePayload) {
    return this.http.post(API_PATH.payment.cyberSourceSignature, payload);
  }

  getPaymentMeta() {
    return this.http.get(API_PATH.payment.meta);
  }

  @Share()
  getPaymentStatus(bookingId) {
    const url = APIService.prepareURI(API_PATH.payment.status, { bookingId });

    return this.http.get(url);
  }
}
