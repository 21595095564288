import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DollarSignIconService {

  render() {
    return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM11.9999 21.8182C6.57748 21.8182 2.18173 17.4224 2.18173 12C2.18173 6.57757 6.57748 2.18182 11.9999 2.18182C17.4223 2.18182 21.8181 6.57757 21.8181 12C21.8181 17.4224 17.4223 21.8182 11.9999 21.8182Z" fill="black"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5453 11.2529V6.92744C12.9114 7.05409 13.2436 7.26309 13.5162 7.53835C13.838 7.86017 13.849 8.04563 13.849 8.78744C13.849 9.23931 14.2153 9.60563 14.6671 9.60563C15.119 9.60563 15.4853 9.23931 15.4853 8.78744C15.5956 7.90526 15.2962 7.02109 14.6726 6.38744C14.0968 5.79882 13.3543 5.40093 12.5453 5.24744V4.92017C12.5453 4.4683 12.179 4.10199 11.7271 4.10199C11.2753 4.10199 10.909 4.4683 10.909 4.92017V5.36199C9.10135 5.77887 7.84322 7.41901 7.90896 9.2729C7.90896 11.3074 9.54532 12.3056 10.909 12.6602V17.6238C9.86673 17.1707 9.21473 16.1188 9.27259 14.9838C9.27259 14.5319 8.90628 14.1656 8.45441 14.1656C8.00254 14.1656 7.63623 14.5319 7.63623 14.9838C7.63623 17.1656 9.05441 19.0038 10.909 19.3093V19.6311C10.909 20.0829 11.2753 20.4493 11.7271 20.4493C12.179 20.4493 12.5453 20.0829 12.5453 19.6311V19.2547C14.1817 18.9438 16.0908 17.8802 16.0908 15.2674C16.0908 12.922 13.909 11.5638 12.5453 11.2529ZM9.54528 9.27259C9.4874 8.32877 10.0352 7.45232 10.9089 7.09077V10.9089C10.1468 10.7183 9.59537 10.0566 9.54528 9.27259ZM12.5454 17.5852V12.9597C13.3036 13.2543 14.4545 14.0506 14.4545 15.2725C14.4884 16.4131 13.6719 17.4023 12.5454 17.5852Z" fill="black"/>
      </svg>
    `;
  }
}
