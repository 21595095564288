import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { MoCalendarComponent } from '../../../../@theme/components/mo-calendar/mo-calendar.component'
import { BookingCalendarQuery, BookingCalendarService } from '../../../../@core/akita-stores/stores/booking-calendar/booking-calendar.store';
import * as _ from 'lodash';
import { getTimezone } from '../../../../@core/akita-stores/storage';
import * as moment from 'moment-timezone';
import { ServiceCentersQuery } from '../../../../@core/akita-stores/entity-stores/sercice-centers/service-centers.query';
import { interval, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ConfigService } from '../../../../@core/akita-stores/stores/config/config.service';
import { BookingListService } from '../../../../@core/akita-stores/entity-stores/bookings/bookings.service';
import { EntitiesStore } from '../../../../@core/akita-stores/entity-stores/entities/entities.store';
import { MatCalendar } from '@angular/material';

const timezone = getTimezone();
moment.tz.setDefault(timezone);

@Component({
  selector: 'booking-calendar',
  templateUrl: './booking-calendar.component.html',
  styleUrls: ['./booking-calendar.component.scss']
})
export class BookingCalendarComponent implements OnInit {
  @ViewChild('calendarRef', { static: false }) calendarRef: MatCalendar<Date>;

  moCalendarComponent = MoCalendarComponent;
  selectedDate = new Date();
  startAt = new Date();
  minDate = new Date(2001, 0, 1);

  viewDate: Date;
  viewDateRange: [Date, Date];
  viewBookingBy = 'bookingDate'

  includeEnquiry = false;
  currentWorkflow = 'lite';
  isViewAllBooking = false;

  currentMonthView = new Date();
  listBookingCurrentMonth = []; 
  isCalenderLoading = false;

  destroy$ = new Subject();

  @Input() type = 'manage-booking';
  @Output() calendarChange: EventEmitter<any> = new EventEmitter();

  bookingCalendar$;
  getBookingList$ = new Subject(); // control trigger get booking list, avoid multiple call
  getBookingListSubscription;
  eventListeners = [];

  constructor(
    public bookingCalendarQuery: BookingCalendarQuery,
    public bookingCalendarService: BookingCalendarService,
    private serviceCentersQuery: ServiceCentersQuery,
    private configService: ConfigService,
    private bookingListService: BookingListService,
    private entitiesStore: EntitiesStore,
  ) { }

  ngOnInit() {
    if (this.type == 'manage-booking') {
      this.bookingCalendarQuery.bookingCalendar$.pipe(
        takeUntil(this.destroy$),
      ).subscribe((data) => {
        this.selectedDate = moment(data.viewDate).toDate();
        this.startAt = moment(data.viewDate).toDate();

        if(this.viewBookingBy !== data.viewBy) {
          this.viewBookingBy = data.viewBy;
          this.getBookingList$.next("viewBy" + data.viewBy);
        } else {
          this.viewBookingBy = data.viewBy;
        }
        
        if(data.viewDateRange && data.viewDateRange.length > 1) {
          this.viewDate = moment(data.viewDate).toDate();
          this.viewDateRange = data.viewDateRange;

          const isRangeSameDay = moment(data.viewDateRange[0]).isSame(data.viewDateRange[1], 'day');

          if (isRangeSameDay) {
            this.selectedDate = moment(data.viewDateRange[0])
            this.startAt = moment(data.viewDateRange[0])
          } else {
            this.selectedDate = null;
            this.startAt = moment(data.viewDateRange[1])
          }
        }
      });

      this.hookCalendarMonthChange();

      this.getBookingListByMonthSetup();
    }
  }

  ngAfterViewInit() {
    this.onSelect(this.selectedDate);
  }

  onSelect(event) {
    if(!event) return;
    this.selectedDate = event;
    this.calendarChange.emit(event);
    if (document.querySelectorAll('.box-calendar-sidebar-booking .mo-calendar__header-label').length > 0) {
      document.querySelectorAll('.box-calendar-sidebar-booking .mo-calendar__header-label')[0].innerHTML = moment(this.selectedDate).format('MMM YYYY');
    }
  }

  hookCalendarMonthChange() {
    setTimeout(() => {
      this.clearEventListeners();
      const btnCalendarPrev = document.querySelectorAll('.box-calendar-sidebar-booking .btn-calendar-prev')[0];
      const btnCalendarNext = document.querySelectorAll('.box-calendar-sidebar-booking .btn-calendar-next')[0];
      if (btnCalendarPrev) {
        const listenEvent = btnCalendarPrev.addEventListener('click', () => {
          console.log('btnCalendarPrev');
          this.handleMonthChange();
        });
        this.eventListeners.push(listenEvent);
      }
      if (btnCalendarNext) {
        const listenEvent = btnCalendarNext.addEventListener('click', () => {
          console.log('btnCalendarNext');
          this.handleMonthChange();
        });
        this.eventListeners.push(listenEvent);
      }
    })
  }

  private monthChangeTimeOut;
  handleMonthChange() {
    if (this.monthChangeTimeOut) clearTimeout(this.monthChangeTimeOut);
    this.monthChangeTimeOut = setTimeout(() => {
      const currentMonthTextShow = document.querySelectorAll('.box-calendar-sidebar-booking .mo-calendar__header-label')[0].innerHTML;
      const currentMonthView = moment('15 ' + currentMonthTextShow, 'DD MMM YYYY').toDate();
      this.currentMonthView = currentMonthView;
      this.getBookingList$.next("handleMonthChange" + currentMonthTextShow);
    }, 200);
  }

  getBookingListByMonthSetup() {
    this.getBookingList$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      takeUntil(this.destroy$),
    ).subscribe(() => {
      this.getBookingList()
    })

    this.serviceCentersQuery.serviceCenterSeleted$.pipe(
      takeUntil(this.destroy$),
    ).subscribe(serviceCenterSelected => {
      if (!serviceCenterSelected.length) {
        return;
      }
      const serviceCenterConfig = _.get(this.serviceCentersQuery.serviceCenterSeleted, '[0]');
      this.includeEnquiry = serviceCenterConfig.displayLiteBooking;
      this.currentWorkflow = _.get(serviceCenterConfig, "bookingServicesConfigs.useEraPower") ? 'heavy' : 'lite';

      this.getBookingList$.next(serviceCenterSelected);
    });

    this.configService.refreshBookings.pipe(takeUntil(this.destroy$)).subscribe(isRefresh => {
      if (isRefresh) this.getBookingList$.next("isRefresh" + isRefresh);
    })

    this.bookingCalendarQuery.isViewAllBooking$.pipe(
      distinctUntilChanged(),
      takeUntil(this.destroy$),
    ).subscribe(isViewAllBooking => {

      this.isViewAllBooking = isViewAllBooking;
      this.getBookingList$.next("isViewAllBooking" + isViewAllBooking);
    });

    interval(1000 * 60).pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.getBookingList$.next("interval" + value);
    });

    setTimeout(() => {
      this.handleMonthChange();
    })
  }

  getBookingList() {
    if (this.getBookingListSubscription) this.getBookingListSubscription.unsubscribe();
    const thisMonthDateRange = [
      moment(this.currentMonthView).startOf('month').toDate(),
      moment(this.currentMonthView).endOf('month').toDate()
    ];

    const allEntityIds = _.get(this.entitiesStore.getValue(), "ids") || [];

    this.getBookingListSubscription = this.bookingListService.getListBookings({
      viewDate: moment(this.viewDate).format('YYYY-MM-DD'),
      viewDateRange: thisMonthDateRange,
      viewBy: this.viewBookingBy as any,
      // filter: this.filterStatus,
      includes: this.includeEnquiry ? ['enquiry'] : null,
      serviceCenterIds: this.isViewAllBooking ? allEntityIds : null,
    }
    ).subscribe((booking) => {
      this.listBookingCurrentMonth = booking;

      // refresh calendar view to update date class
      this.calendarRef.updateTodaysDate();
    })
  }

  dateClassChecker = (date: Date, view) => {
    const dateStr = moment(date).format('YYYY-MM-DD');
    const booking = _.find(this.listBookingCurrentMonth, (booking) => {
      if(this.viewBookingBy === 'createdDate') return moment(booking.createdAt).format('YYYY-MM-DD') === dateStr;
      else return moment(booking.bookedAt).format('YYYY-MM-DD') === dateStr;
    });
    if (booking) {
      return 'has-booking';
    } else {
      return '';
    }
  }

  clearEventListeners() {
    this.eventListeners.forEach(listenEvent => {
      if (listenEvent) listenEvent.remove();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.bookingCalendar$) this.bookingCalendar$.unsubscribe();
    if (this.getBookingListSubscription) this.getBookingListSubscription.unsubscribe();
    this.clearEventListeners();
  }
}
