import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';
import { BookingDetailsSelectedService } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.service';
import { BookingDetailsSelectedQuery } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.query';
import { CommonService } from '../../../@core/utils/common.service';
import { ConfigService } from './../../../@core/akita-stores/stores/config/config.service';
import { DataReceipt } from '../../../@core/data/common';
import { getTimezone } from '../../../@core/akita-stores/storage';
import { STATUS_BEFORE_CHECKED_IN } from '../../../constants/status';
import { ServiceCentersService } from '../../../@core/akita-stores/entity-stores/sercice-centers/service-centers.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FlightDeckService } from '../../../@core/akita-stores/stores/flightdeck/flightdeck.service';

@Component({
  selector: 'booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.scss']
})
export class BookingDetailsComponent implements OnInit, OnDestroy {
  bookingDetails: any;
  phoneContact;
  email;
  address;
  homeLocation;
  suburb;
  dataServiceProcess = [];
  statusObj = {};
  dataReceipt: DataReceipt;
  isExpress: boolean = false;
  moTag = {
    types: 'type',
    code: '',
  }
  timezone: string = getTimezone();
  loaddingStatues: boolean = true;
  isAfterCheckin: boolean;
  isPickedUp: boolean;
  showPickupButton: boolean;
  protected destroy$ = new Subject<void>();

  @Input('bookingId') bookingId;

  constructor(
    protected ref: NbDialogRef<BookingDetailsComponent>,
    private bookingService: BookingDetailsSelectedService,
    private commonService: CommonService,
    private router: Router,
    private bookingDetailsQuery: BookingDetailsSelectedQuery,
    private configService: ConfigService,
    private toastrService: NbToastrService,
    private centersService: ServiceCentersService,
    private flightDeckService: FlightDeckService,
  ) {
  }

  convertToStatusObj(statusArr: any[]) {
    let obj = {}
    statusArr.forEach(item => {
      obj[item.bookingStatus.code] = item;
    });

    return obj;
  }

  handlePhoneNumber(phoneNumberObj) {
    const phoneNumber = phoneNumberObj.contact.value;

    return (phoneNumber.length < 10 && phoneNumber.slice(0, 1) !== '0') ? ('0' + phoneNumber) : phoneNumber;
  }

  ngOnInit() {
    this.bookingService.getBookingDetails(this.bookingId)
    .pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.bookingDetails = this.bookingDetailsQuery.getValue();
      this.showPickupButton = this.bookingDetails && (this.bookingDetails.beacon && this.bookingDetails.bookingStatus.code === 'ready-for-pickup');
      this.isAfterCheckin = STATUS_BEFORE_CHECKED_IN.indexOf(this.bookingDetails.bookingStatus.code) === -1;
      this.isPickedUp = !this.bookingDetails.beacon && this.isAfterCheckin;
      this.dataReceipt = this.commonService.getReceiptValue(this.bookingDetails);
      this.isExpress = this.commonService.isExpress(this.bookingDetails.serviceType.code);
      this.moTag.code = this.dataReceipt.isPaymentCompleted ? 'paid' : 'unpaid';
      this.email = this.commonService.getUserContact(this.bookingDetails.customer.contacts, 'email');
      this.homeLocation = this.commonService.customLocationAddress(this.bookingDetails.customer.addresses[0].address, true);
      this.address = this.commonService.customLocationAddress(this.bookingDetails.customer.addresses[0].address, false);
      this.phoneContact = this.handlePhoneNumber(this.commonService.getUserContact(this.bookingDetails.customer.contacts, 'phone'));
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close() {
    this.ref.close();
  }

  gotoBookingDetails(bookingId) {
    this.ref.close();
    this.configService.updateAction('viewBooking');
    this.router.navigate(['pages/booking/booking-details/' + bookingId]);
  }

  handleServiceProcess(results) {
    this.dataServiceProcess = results;
  }

  async pickUp() {
    const dlgPickup = this.commonService.pickUp();
    const instancePickup = dlgPickup.componentRef.instance;
    instancePickup.submitClicked.pipe(takeUntil(this.destroy$)).subscribe(async () => {
      this.flightDeckService.pickUpRO(this.bookingDetails.id);
      await this.bookingService.pickUp(this.bookingDetails.id).toPromise().catch(err => { throw err });
      this.showPickupButton = false;
      const beaconId = this.bookingDetails.beacon.id;
      await this.centersService.updateBeacon(beaconId, {isActive: true}).toPromise().catch(err => { throw err });
      this.bookingService.getBookingDetails(this.bookingDetails.id)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          instancePickup.loading = false;
          dlgPickup.close();
        })
      ).subscribe(() => {
        this.toastrService.success('Picked Up successfully', 'Success', { destroyByClick: true, hasIcon: false });
      });
    });
  }

  public getTotalBookingTime() {
    return this.bookingService.getTotalBookingTime();
  }
}
