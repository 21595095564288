import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class CreditCardIconService {

    render() {
        return `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.75 2.91667C1.42783 2.91667 1.16667 3.17783 1.16667 3.5V10.5C1.16667 10.8222 1.42783 11.0833 1.75 11.0833H12.25C12.5722 11.0833 12.8333 10.8222 12.8333 10.5V3.5C12.8333 3.17783 12.5722 2.91667 12.25 2.91667H1.75ZM0 3.5C0 2.5335 0.783502 1.75 1.75 1.75H12.25C13.2165 1.75 14 2.5335 14 3.5V10.5C14 11.4665 13.2165 12.25 12.25 12.25H1.75C0.783502 12.25 0 11.4665 0 10.5V3.5Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 5.83333C0 5.51117 0.261167 5.25 0.583333 5.25H13.4167C13.7388 5.25 14 5.51117 14 5.83333C14 6.1555 13.7388 6.41667 13.4167 6.41667H0.583333C0.261167 6.41667 0 6.1555 0 5.83333Z" fill="white"/>
        </svg>
        `;
    }
}
