import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { CommonService } from '../../../@core/utils/common.service';
import { NbToastrService } from '@nebular/theme';
import { getTimezone } from '../../../@core/akita-stores/storage';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'status-progress',
  templateUrl: './status-progress.component.html',
  styleUrls: ['./status-progress.component.scss']
})

export class StatusProgressComponent implements OnInit, OnDestroy {
  @Input() bookingId: number;
  @Input() isShownTitle = true;
  @Input() horizontal = false;
  @Input() isReception = false;
  @Output() loaddingStatuses = new EventEmitter<boolean>();
  @Output() results = new EventEmitter<any>();
  serviceProcess = [];
  timezone = getTimezone();
  lastActiveIcon: string;
  indexLastActiveIcon: number;
  loadding: boolean = true;
  protected destroy$ = new Subject<void>();

  constructor(
    private commonService: CommonService,
    private toastrService: NbToastrService
  ) { }

  ngOnInit() {
    this.commonService.getBookingStatus(this.bookingId).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      if (res.results.length === 0) {
        setTimeout(() => {
          this.toastrService.danger('Error in getting booking status', 'Error', { destroyByClick: true });
        }, 1000);
        return;
      };
      this.handleServiceProcess(res.results);
    }, () => {
      this.loadding = false;
      this.loaddingStatuses.emit(this.loadding);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleServiceProcess(statuses) {
    let activeStatusExist;
    if (this.horizontal) {
      this.serviceProcess = statuses.filter(status => {
        return ['checked-in', 'work-in-progress', 'wheel-alignment', 'test-drive', 'wash-bay', 'ready-for-pickup', 'pickedup'].includes(status.bookingStatus.code);
      })
    } else {
      this.serviceProcess = statuses;
    }
    this.serviceProcess = this.serviceProcess.map((item, index) => {
      const process: any = { ...item };
      const statusCode = item.bookingStatus.code;
      process.time = statusCode === 'cancelled'
        ? (item.createdUtc ? this.convertTimezone(item.createdUtc).format('D MMMM hh:mm A') : '')
        : this.getDateTime(item.createdUtc, item.isLate, item.duration);
      process.color = this.getColor(item.isLate);
      if (process.createdUtc) {
        activeStatusExist = true;
        if (!this.commonService.isStatusBeforeCheckedIn(statusCode) && statusCode !== 'checked-in') {
          this.lastActiveIcon = statusCode;
        }
        this.indexLastActiveIcon = index;
      }
      return process;
    });
    if (this.lastActiveIcon) this.lastActiveIcon = this.commonService.getIconProcessStatus(this.lastActiveIcon, false);
    if (!activeStatusExist) this.serviceProcess = [];
    this.loadding = false;
    this.loaddingStatuses.emit(this.loadding);
    this.results.emit(this.serviceProcess);
  }

  getDateTime(dateTime: string, isLate: boolean, duration: number): string {
    if (!dateTime) return '';
    if (moment.utc(dateTime).tz(this.timezone).isSame(moment(), 'day')) {
      return this.convertTimezone(dateTime).format('[Today] hh:mm A');
    } else {
      return this.convertTimezone(dateTime).format('D MMMM hh:mm A');
    }
  }

  convertTimezone(dateTime: string) {
    return moment.utc(dateTime).tz(this.timezone);
  }

  getColor(isLate: boolean): string {
    return isLate ? 'red' : '';
  }
}
