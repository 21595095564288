import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import * as _ from "lodash";
import { FormControl } from "@angular/forms";

export type MultipleOptions = Array<{
  label: string;
  value: any;
  isGroupOption?: boolean;
  parentValue?: any;
  hide?: boolean;
}>;
@Component({
  selector: "multiple-select",
  templateUrl: "./multiple-select.component.html",
  styleUrls: ["./multiple-select.component.scss"],
})
export class MultipleSelect implements OnInit, OnChanges {
  @Input() isGroupOption: boolean = false;
  @Input() allOptionValue: any = -1;
  @Input() placeholder: string;
  @Input() noSelectCount: boolean = false;
  @Input() selected: any = [];
  selectedControl: FormControl = new FormControl([-1]);
  @Input() options: MultipleOptions;
  @Input() canSelectAll: boolean;
  @Input() canSearch: boolean;

  @Output() selectedChange = new EventEmitter();

  private subjecValueSearch = new Subject<any>();

  formControl = new FormControl(new Date());

  filteredOptions = [];
  allOptions = [];
  isCheckAll = false;
  searchInput = "";

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.options &&
      changes.options.currentValue !== changes.options.previousValue
    ) {
      this.allOptions = !this.isGroupOption
        ? this.options.map((item) => item.value)
        : this.options
          .filter((item) => !item.isGroupOption)
          .map((item) => item.value);

      this.filteredOptions = [...(this.options || [])];

    }
    if(changes.selected && changes.selected.currentValue !== this.selectedControl.value){
      setTimeout(() => { this.selectedControl.setValue([...this.selected]) },0)
    }
  }
  ngOnInit() {
    this.selectedControl.valueChanges.subscribe((value) => {
      const hasAllValue = value.includes(this.allOptionValue);
      const selectWithoutAllValue = value.filter((item) => item !== this.allOptionValue);
      if (!_.isEqual(selectWithoutAllValue, this.selected)) { // Avoid unnecessary trigger
        this.selectedChange.emit(selectWithoutAllValue);
      }
      if (value.length == this.allOptions.length && !hasAllValue) {
        this.selectedControl.setValue([...this.allOptions, this.allOptionValue])
        this.isCheckAll = true
      } else if(this.isCheckAll){
        this.isCheckAll = false;
        this.selectedControl.setValue(selectWithoutAllValue)
      }
    });
  }

  onSelectClick() {
    setTimeout(() => {
      const listContainer = document.getElementsByClassName("options-list");
      listContainer.length && listContainer[0].scroll({ top: 0 });
    }, 50); // TODO Temporarily solve the problem of scrolling incorrectly during the first render, please check
    if (this.canSearch) {
      if (this.searchInput) {
        this.searchInput = "";
        this.subjecValueSearch.next("");
      }
    }
  }

  ngAfterViewInit() {
    this.subjecValueSearch
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((value) => { this.searchOptions(value) });
  }
  searchOptions(searchString: string) {
    if(searchString) searchString = searchString.trim();
    if (!searchString) this.filteredOptions = [...(this.options || [])];
    else {
      if (this.isGroupOption)
        this.filteredOptions = this.options.map((item) => {
          if (item.label.toLowerCase().includes(searchString.toLowerCase()) || item.isGroupOption) return item;
          else {
            return { ...item, hide: true };
          }
        }
        );

      // const allFilteredValue = this.filteredOptions.filter((item) => !item.isGroupOption).map((item) => item.value)
      // this.cloneSelected = this.cloneSelected.filter((el) => !allFilteredValue.includes(el));
    }
    setTimeout(() => this.selectedControl.setValue([...this.selected]), 0);
  }
  handleSearch(searchString: string) {
    setTimeout(() => {
      const listContainer = document.getElementById("search-input")
      listContainer && listContainer.focus()
    }, 200);
    this.subjecValueSearch.next(searchString || null);
  }

  selectCheck(event) { }

  onSelect(value) { }
  handleCheckAll() {
    if (this.isCheckAll) this.selectedControl.setValue([]);
    else this.selectedControl.setValue([...this.allOptions])
  }
  getFlatten(arr) {
    function flatten(arr) {
      return arr
        ? arr.reduce(function (flat, toFlatten) {
          return flat.concat(
            Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
          );
        }, [])
        : [];
    }
    return flatten(arr);
  }
}
