import { Pipe, PipeTransform } from '@angular/core';
import { VehicleBookingDetails } from '../../@core/data/common';
import { CommonService } from '../../@core/utils/common.service';

@Pipe({ name: 'vehicleStr' })
export class VehicleStrPipe implements PipeTransform {
  constructor(
    private commonService: CommonService
  ) { }

  transform(vehicle: VehicleBookingDetails, type?: string | string[]): string {
    if (!vehicle) return '';
    let strInfoVehicle = '';
    if (!type || type === 'name') {
      if (vehicle.make) strInfoVehicle += vehicle.make.toUpperCase();
      if (vehicle.model) strInfoVehicle += ' ' + vehicle.model;
      if (vehicle.year) strInfoVehicle += ' ' + vehicle.year;
    }
    if (!type || type === 'colour-cylinder') {
      if (vehicle.colour) strInfoVehicle += ' · ' + vehicle.colour;
    }

    if (!type || type === 'cylinder' || type === 'colour-cylinder') {
      if (vehicle.cylinder && !this.commonService.isBydVehicle(vehicle)) strInfoVehicle += ' · ' + vehicle.cylinder + ' Cylinders'
    }
    const strFirstThree = strInfoVehicle.substring(0, 3);
    if (strFirstThree === ' · ') return strInfoVehicle.slice(3);
    return strInfoVehicle.trim();
  }
}
