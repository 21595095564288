import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { CommonService } from './../../../@core/utils/common.service';
import * as _ from 'lodash';
import { BookingDetailsSelectedStore } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.store';
import { BookingDetailsSelectedQuery } from './../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.query';
import { ConfigQuery, ConfigStore } from '../../../@core/akita-stores/stores/config/config.store';
import { PermissionsService } from './../../../@core/utils/permissions.service';
import { ERROR_MESSAGES } from '../../../constants/error-messages';
import { NbToastrService } from '@nebular/theme';
import { NgxPermissionsService } from 'ngx-permissions';
import { BOOKING_PERMISSIONS } from './../../../constants/booking-permissions';
import { BookingDetailsSelectedService } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.service';

@Component({
  selector: 'special-offer',
  templateUrl: './special-offer.component.html',
  styleUrls: ['./special-offer.component.scss']
})
export class SpecialOfferComponent implements OnInit {
  public isActive = false;
  slideConfig = {
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    variableWidth: true,
    adaptiveHeight: true,
    prevArrow: false,
    nextArrow: false,
    responsive: [
      {
        breakpoint: 1367,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          variableWidth: true,
        }
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          variableWidth: true,
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        }
      }
    ]
  };

  list = [];
  isUneditableMode: boolean;
  hide: boolean = false;
  chosenIdx: number;
  userHasPermissionToUpdateBooking;
  @Input('bookingDetails') bookingDetails;
  @ViewChild('slickCarousel', { static: false }) slickCarousel: any;
  isBookingPaid: boolean = false;

  constructor(
    private commonService: CommonService,
    private cdr: ChangeDetectorRef,
    private configStore: ConfigStore,
    private configQuery: ConfigQuery,
    private bookingDetailsSelectedStore: BookingDetailsSelectedStore,
    private bookingDetailsQuery: BookingDetailsSelectedQuery,
    private bookingDetailsService: BookingDetailsSelectedService,
    private permissionsService: PermissionsService,
    private ngxPermissions: NgxPermissionsService,
  ) {
    this.bookingDetailsQuery.bookingDetailsSelected$.subscribe((val: any) => {
      this.isUneditableMode = this.commonService.isUneditableMode(val);
      this.isBookingPaid = this.bookingDetailsService.isPaid(val.bookingPayments);
      this.ngxPermissions.hasPermission(BOOKING_PERMISSIONS.updateBooking)
        .then(hasUpdatePermission => {
          if (!hasUpdatePermission) {
            this.isUneditableMode = true;
          }
        });
    });
  }
  ngOnInit() {
    this.commonService.getSpecialOffers().subscribe((res: any) => {
      this.list = res.results;
      this.list.forEach(item => {
        const itemAvaiCheck = this.isItemAvai(item, this.bookingDetails.extraOptions);

        if (itemAvaiCheck.isAvai) {
          item.active = true;
          item.relatedId = this.bookingDetails.extraOptions[itemAvaiCheck.idx].relatedId;
        }
      });
    });
  }

  async chooseItem(e, item, idx) {
    const itemAvaiCheck = this.isItemAvai(item, this.bookingDetails.extraOptions);
    if (itemAvaiCheck.isAvai) {
      const hasPermission = await this.ngxPermissions.hasPermission(BOOKING_PERMISSIONS.removeBookingExtraOption);
      if (!hasPermission) {
        this.permissionsService.showPermissionError();
        return;
      }
    } else {
      const hasPermission = await this.ngxPermissions.hasPermission(BOOKING_PERMISSIONS.addBookingExtraOption);
      if (!hasPermission) {
        this.permissionsService.showPermissionError();
        return;
      }
    }
    this.chosenIdx = idx;
    this.bookingDetails = Object.assign({}, this.bookingDetails);
    /** For some reasons it is not allowed to push to extraOptions array directly, this is a workaround solution */
    this.bookingDetails.extraOptions = Object.assign([], this.bookingDetails.extraOptions);

    const onFail = () => {
      this.hide = true;
      this.list.forEach(option => {
        option = Object.assign({}, option);
        const itemAvaiCheck = this.isItemAvai(option, this.bookingDetails.extraOptions);

        if (itemAvaiCheck.isAvai) {
          option.active = true;
          option.relatedId = this.bookingDetails.extraOptions[itemAvaiCheck.idx].relatedId;
        }
      });
      this.chosenIdx = null;
      this.cdr.detectChanges();
      this.hide = false;
    }

    if (itemAvaiCheck.isAvai) {
      const deleteSpecialOffer = () => {
        e.currentTarget.classList.remove("active");
        this.commonService.deleteSpecialOffer([this.bookingDetails.extraOptions[itemAvaiCheck.idx].relatedId], this.bookingDetails.id).subscribe(val => {
          _.pullAt(this.bookingDetails.extraOptions, itemAvaiCheck.idx);
          this.bookingDetailsSelectedStore.updateStore(this.bookingDetails);
          this.configStore.updateClonnedBookingDetails(this.bookingDetails);
          this.list[this.chosenIdx].active = false;
          this.chosenIdx = null;
        }, err => {
          onFail();
        });
      };
      deleteSpecialOffer();
    } else {

      const addSpecialOffer = () => {
        e.currentTarget.classList.add("active");
        const data = [{
          additionalOptionId: item.id
        }];
        this.commonService.addSpecialOffer(data, this.bookingDetails.id).subscribe((res: any) => {
          this.list[this.chosenIdx].active = true;
          const relatedId = res.results[0].id;
          item = Object.assign({}, item);
          item.relatedId = relatedId;

          this.bookingDetails.extraOptions.push(item);
          this.bookingDetailsSelectedStore.updateStore(this.bookingDetails);
          this.configStore.updateClonnedBookingDetails(this.bookingDetails);
          this.chosenIdx = null;
        }, err => {
          onFail();
        });
      };
      addSpecialOffer();
    }
  }

  isItemAvai(item, arr) {
    const idx = arr.findIndex(ele => ele.id === item.id);
    return {
      isAvai: idx > -1,
      idx
    };
  }
}
