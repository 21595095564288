import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'mo-tag',
  templateUrl: './mo-tag.component.html',
  styleUrls: ['./mo-tag.component.scss']
})
export class MoTagComponent implements OnInit, OnChanges {
  @Input() tag: any;
  @Input('positionLeft') positionLeft: boolean;
  @Input('isTransparent') isTransparent: boolean;
  @Input() size: string = '';

  isCustomerView: boolean = false;

  public tagDetails: {
    text: string,
    class: string,
    icon: string
  };
  constructor(private router: Router) { }

  ngOnInit() {
    this.isCustomerView = this.router.url.includes('reception')
    this.handleTagDetails();
  }

  ngOnChanges(changes: SimpleChanges) {
    const tag: SimpleChange = changes.tag;
    if (tag.currentValue !== tag.previousValue) {
      this.handleTagDetails();
    }
  }

  handleTagDetails() {
    if (this.tag && this.tag.types === "status") {
      let tagStatus = this.tag.code ? this.tag.code : null;
      switch (tagStatus) {
        case "booked":
        case "service-booked":
          this.tagDetails = {
            text: 'Booked In',
            class: 'service-booked',
            icon: 'circle-plus'
          };
          break;
        case "contact-attempted":
          this.tagDetails = {
            text: 'Contact Attempted',
            class: 'contact-attempted',
            icon: 'phone-outgoing'
          };
          break;
        case "confirmed":
        case "service-confirmed":
          this.tagDetails = {
            text: 'Confirmed',
            class: 'service-confirmed',
            icon: 'user-check'
          };
          break;
        case "qualified":
          this.tagDetails = {
            text: 'Qualified',
            class: 'qualified',
            icon: 'circle-check-transparent'
          };
          break;
        case "await":
        case "awaiting-arrival":
          this.tagDetails = {
            text: 'Awaiting Arrival',
            class: 'status-await',
            icon: 'clock'
          };
          break;
        case "checkin":
        case "vehicle-checked-in":
        case "checked-in":
          this.tagDetails = {
            text: 'Checked in',
            class: 'status-checkin',
            icon: 'login'
          };
          break;
        case "vehicle-health-check":
          this.tagDetails = {
            text: 'Vehicle Health Check',
            class: 'vehicle-health-check',
            icon: 'edit-square'
          };
          break;
        case "progress":
        case "work-in-progress":
          this.tagDetails = {
            text: 'Servicing',
            class: 'status-progress',
            icon: 'car'
          };
          break;
        case "special-offer-wip":
          this.tagDetails = {
            text: 'Special Offer WIP',
            class: 'special-offer-wip',
            icon: 'setting'
          };
          break;
        case "quality-assurance":
          this.tagDetails = {
            text: 'Quality Assurance',
            class: 'quality-assurance',
            icon: 'shield'
          };
          break;
        case "wash-bay":
          this.tagDetails = {
            text: 'Washing',
            class: 'wash-bay',
            icon: 'droplet'
          };
          break;
        case "invoiced":
          this.tagDetails = {
            text: 'Booking Invoiced',
            class: 'invoiced',
            icon: 'dollar-sign'
          };
          break;
        case "ready":
        case "ready-for-pickup":
          this.tagDetails = {
            text: 'Ready For Pick Up',
            class: 'status-ready',
            icon: 'circle-check-ready'
          };
          break;
        case "pickedup":
          this.tagDetails = {
            text: 'Picked Up',
            class: 'status-ready',
            icon: 'flag'
          };
          break;
        case "waiting-payment": {
          this.tagDetails = {
            text: 'Awaiting Payment',
            class: 'status-await',
            icon: 'circle'
          };
          break;
        }
        case "cancelled": {
          this.tagDetails = {
            text: 'Cancelled',
            class: 'status-await',
            icon: 'x-circle'
          };
          break;
        }
        case "in-transit": {
          this.tagDetails = {
            text: this.isCustomerView ? 'Servicing' : 'In Transit',
            class: this.isCustomerView ? 'status-progress' : 'in-transit',
            icon: this.isCustomerView ? 'car' : 'odometer'
          };
          break;
        }
        case "wheel-alignment": {
          this.tagDetails = {
            text: 'Wheel Alignment',
            class: 'wheel-alignment',
            icon: 'circle-plus'
          };
          break;
        }
        case "test-drive": {
          this.tagDetails = {
            text: 'Test Drive',
            class: 'test-drive',
            icon: 'check-square'
          };
          break;
        }
        case "parked": {
          this.tagDetails = {
            text: this.isCustomerView ? 'Servicing' : 'Parked',
            class: this.isCustomerView ? 'status-progress' : 'parked',
            icon: this.isCustomerView ? 'car' : 'flag'
          };
          break;
        }
        case "tyres": {
          this.tagDetails = {
            text: this.isCustomerView ? 'Servicing' : 'Tyres',
            class: this.isCustomerView ? 'status-progress' : 'tyres',
            icon: this.isCustomerView ? 'car' : 'arrow-up-circle'
          };
          break;
        }
        default:
          this.tagDetails = {
            text: 'Awaiting Arrival',
            class: 'status-await',
            icon: 'circle'
          };
          break;
      }
    } else if (this.tag && this.tag.types === "type") {
      let tagType = this.tag.code ? this.tag.code : null;
      switch (tagType) {
        case "paid":
          this.tagDetails = {
            text: 'PAID',
            class: 'type-paid',
            icon: null
          };
          break;
        case "unpaid":
          this.tagDetails = {
            text: 'UNPAID',
            class: 'type-unpaid',
            icon: null
          };
          break;
        case "logbook":
          this.tagDetails = {
            text: 'LOGBOOK',
            class: 'type-logbook',
            icon: null
          };
          break;
        case "express":
          this.tagDetails = {
            text: 'EXPRESS',
            class: 'type-express',
            icon: null
          };
          break;
        case "premium":
          this.tagDetails = {
            text: 'PREMIUM',
            class: 'type-logbook',
            icon: null
          };
          break;
        case "premium-plus":
          this.tagDetails = {
            text: 'PREMIUM PLUS',
            class: 'type-logbook',
            icon: null
          };
          break;
        case "tyre-bonus":
          this.tagDetails = {
            text: 'TYRE BONUS',
            class: 'type-logbook',
            icon: null
          };
          break;
        case "oem-a":
          this.tagDetails = {
            text: 'OEM A',
            class: 'type-oem',
            icon: null
          };
          break;
        case "oem-b":
          this.tagDetails = {
            text: 'OEM B',
            class: 'type-oem',
            icon: null
          };
          break;
        case "oem-c":
          this.tagDetails = {
            text: 'OEM C',
            class: 'type-oem',
            icon: null
          };
          break;
        case "oem-d":
          this.tagDetails = {
            text: 'OEM D',
            class: 'type-oem',
            icon: null
          };
          break;
        case "specialOffer":
          this.tagDetails = {
            text: 'Special Offer',
            class: 'type-special-offer',
            icon: null
          };
          break;
        case 'Miscellaneous':
          this.tagDetails = {
            text: 'Miscellaneous',
            class: 'type-miscellaneous',
            icon: null
          };
          break;
        case 'inactive':
          this.tagDetails = {
            text: 'Inactive',
            class: 'type-inactive',
            icon: null
          };
          break;
        case 'active':
          this.tagDetails = {
            text: 'Active',
            class: 'type-active',
            icon: null
          };
          break;
        default:
          this.tagDetails = {
            text: '',
            class: '',
            icon: ''
          };
          break;
      }
    } else {
      this.tagDetails = {
        text: '',
        class: '',
        icon: ''
      };
    }
  }
}
