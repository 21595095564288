import { Component, OnInit, ViewChild, Input, ChangeDetectorRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { BookingDetailsSelectedQuery } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.query';
import { BookingDetails, BookingDetailsSelectedStore } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.store';
import { CommonService } from './../../../@core/utils/common.service';
import { map, mergeMap, share, takeUntil } from 'rxjs/operators';
import { of, forkJoin, Subject } from 'rxjs';
import * as _ from 'lodash';
import { BookingDetailsSelectedService, LogbookPackage } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.service';
import { ConfigQuery } from '../../../@core/akita-stores/stores/config/config.store';
import { VehiclesService } from '../../../@core/akita-stores/entity-stores/vehicles/vehicles.service';
import { ConfigService } from '../../../@core/akita-stores/stores/config/config.service';
import { BOOKING_PERMISSIONS } from '../../../constants/booking-permissions';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'logbook-service',
  templateUrl: './logbook-service.component.html',
  styleUrls: ['./logbook-service.component.scss']
})
export class LogbookServiceComponent implements OnInit, OnDestroy {
  @Input() serviceType: string;
  @Input() isDlg: boolean = false;
  @Input() slideConfig: any;
  @Input() selectedVehicleItem: any;
  @Output() onSelect = new EventEmitter();
  public isActive = false;

  list = [];
  bookingDetails: BookingDetails;
  isUneditableMode: boolean;
  clonnedOdometer;
  show: boolean = true;
  @ViewChild('slickCarousel', { static: false }) slickCarousel: any;
  bookingDetailsSelected$;
  loading: boolean = false;
  suggestedPackageIdx;
  fnTimeout;
  isAfterQualifiedStatus: boolean = false;
  protected destroy$ = new Subject();
  isInit: boolean = true;
  constructor(
    private bookingDetailsQuery: BookingDetailsSelectedQuery,
    private commonService: CommonService,
    private cdr: ChangeDetectorRef,
    private configQuery: ConfigQuery,
    private bookingDetailsStore: BookingDetailsSelectedStore,
    private bookingDetailsService: BookingDetailsSelectedService,
    private vehicleService: VehiclesService,
    private configService: ConfigService,
    private ngxPermissions: NgxPermissionsService
  ) {
    if (this.bookingDetailsSelected$) this.bookingDetailsSelected$.unsubscribe();
    this.bookingDetailsSelected$ = this.bookingDetailsQuery.bookingDetailsSelected$
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        if (JSON.stringify(this.bookingDetails) !== JSON.stringify(val)) {
          this.isUneditableMode = this.commonService.isUneditableMode(val);
          this.isAfterQualifiedStatus = this.commonService.isCheckStatusPosition('qualified', val.bookingStatus.code, 'afterFrom');
          this.bookingDetails = Object.assign({}, val);
        }
      });

    const odometer = this.bookingDetails.vehicle.odometer === null || this.bookingDetails.vehicle.odometer === undefined ? 0 : this.bookingDetails.vehicle.odometer;
    const vehicleReference = this.bookingDetails.npVehicleReference ? this.bookingDetails.npVehicleReference : '';
    const quoteRequestKey = this.bookingDetails.quoteRequestKey ? this.bookingDetails.quoteRequestKey : '';
    this.clonnedOdometer = _.cloneWith(this.bookingDetails.vehicle.odometer);

    if (this.bookingDetails.id) {
      this.commonService.getLogbookPackages(odometer, vehicleReference, quoteRequestKey)
        .pipe(takeUntil(this.destroy$))
        .subscribe(res => {
          this.list = res.results.filter(p => p.serviceType && p.serviceType.code === this.serviceType);
          this.list.forEach(item => item.active = (item.id === this.bookingDetails.jobs[0].id) ? true : false);
        });
    }

    this.configQuery.inputVehicle
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        if (val && val.id) {
          const vehicle = Object.assign({}, this.bookingDetails.vehicle);
          vehicle.odometer = val.odometer || 0;
          vehicle.id = val.id;
          this.bookingDetails.vehicle = Object.assign({}, vehicle);

          if (this.isInit && this.bookingDetails.id) {
            this.isInit = false;
          } else {
            // if ((!this.clonnedOdometer && val.odometer === 0) || !val.odometer) return;
            // if (this.clonnedOdometer === val.odometer) return;
          }

          if (this.slickCarousel) this.removeActiveCarousel(this.slickCarousel);

          this.loading = true;
          const odometer = this.isAfterQualifiedStatus
            ? this.commonService.getLargestOdometer(this.bookingDetails.jobs[0].km.toString())
            : (val.odometer || 0);

          this.getSuggestedLogbookPackage(odometer)
            .pipe(takeUntil(this.destroy$))
            .subscribe(suggestedPackage => {
              if (!suggestedPackage) return;
              this.onSelect.emit(suggestedPackage);
              for (let i = 0; i < this.list.length; i++) {
                if (this.list[i].id === suggestedPackage.id) {
                  this.suggestedPackageIdx = i;
                  this.list[i] = Object.assign({}, this.list[i]);
                  setTimeout(() => { try { this.list[i].active = true } catch (err) { } });
                  this.list[i].active = true

                  if (!this.isAfterQualifiedStatus) {
                    const relatedId = this.bookingDetails.jobs[0].relatedId;
                    this.bookingDetails.jobs = Object.assign([], this.bookingDetails.jobs);
                    this.bookingDetails.jobs[0] = this.list[i];
                    this.bookingDetails.jobs[0].relatedId = relatedId;
                  }

                  try {
                    clearTimeout(this.fnTimeout);
                    this.fnTimeout = setTimeout(() => {
                      const _index = this.suggestedPackageIdx == this.list.length - 1 ? (this.list.length - 3) : this.suggestedPackageIdx;
                      this.slickCarousel.slickGoTo(_index);
                      this.cdr.detectChanges();
                    }, 500);
                  } catch (e) { }
                } else {
                  this.list[i].active = false;
                }
              }

              if (this.bookingDetails.id && !this.isAfterQualifiedStatus) {
                this.updateLogBookPackage(this.bookingDetails.jobs[0]);
              } else {
                this.configService.setInputVehicle(null);
                this.vehicleService.updateAnVehicle({ id: this.bookingDetails.vehicle.id, odometer: this.bookingDetails.vehicle.odometer });
                this.bookingDetailsStore.updateStore(this.bookingDetails);
              }
              this.clonnedOdometer = val.odometer;
              this.loading = false;
            }, () => this.loading = false);
        }

      });
  }

  getSuggestedLogbookPackage(odometer) {
    // if (!odometer) return of(null);

    return this.commonService.getLogbookPackages(odometer).pipe(mergeMap((res => {
      const packages = res.results.filter(p => p.serviceType && p.serviceType.code === this.serviceType);
      this.list = Object.assign([], packages);
      odometer = parseInt(odometer);

      if (odometer <= packages[0].km) return of({
        id: packages[0].id
      });

      if (odometer >= packages[packages.length - 1].km) return of({
        id: packages[packages.length - 1].id
      });

      let resultObj = {
        distance: Math.abs(packages[0].km - odometer),
        id: 0
      }

      for (let i = 0; i < packages.length; i++) {
        const distance = Math.abs(packages[i].km - odometer);
        if (distance === 0) {
          resultObj = {
            distance: distance,
            id: packages[i].id
          }
          break;
        } else if (resultObj.distance > distance) {
          if (packages[i].km - odometer > 0) {
            resultObj = {
              distance: distance,
              id: packages[i].id
            }
          }
        }
      }

      if (resultObj.id !== 0) {
        return of(resultObj);
      }

      return of({ id: packages[1].id })
    })));
  }

  removeActiveCarousel(carousel: any) {
    carousel.slides.map(val => {
      if (val.el.nativeElement.classList.contains('active')) {
        val.el.nativeElement.classList.remove('active');
      }
    });
  }

  ngOnInit() {
    this.ngxPermissions.hasPermission(BOOKING_PERMISSIONS.updateBooking)
      .then(hasUpdatePermission => {
        if (!hasUpdatePermission && this.bookingDetails.id) {
          this.isUneditableMode = true;
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  chooseItem(e, item) {
    if (this.isAfterQualifiedStatus) return;
    const isCreatingBooking = !this.bookingDetails.id;
    if (isCreatingBooking) {
      const bookingDetails = _.cloneDeepWith(this.bookingDetailsQuery.getValue());
      bookingDetails.jobs[0].id = item.id;
      this.bookingDetailsStore.updateStore(bookingDetails);
      this.removeActiveCarousel(this.slickCarousel);
      e.currentTarget.classList.add("active");
    } else {
      if (item.id === this.bookingDetails.jobs[0].id) return;

      this.removeActiveCarousel(this.slickCarousel);
      const relatedId = this.bookingDetails.jobs[0].relatedId;
      this.bookingDetails.jobs = Object.assign([], this.bookingDetails.jobs);
      this.bookingDetails.jobs[0] = item;
      this.bookingDetails.jobs[0] = Object.assign({}, this.bookingDetails.jobs[0]);
      this.bookingDetails.jobs[0].relatedId = relatedId;

      this.updateLogBookPackage(this.bookingDetails.jobs[0], true);
      e.currentTarget.classList.add("active");
    }
    // emit outside
    this.onSelect.emit(item);
  }

  updateLogBookPackage(data, onChoose = false) {
    const logbookPackage: LogbookPackage = {
      bookingJobs: [
        {
          id: data.relatedId,
          jobId: data.id
        }
      ]
    }

    const observables = onChoose ? this.bookingDetailsService.updateLogBookPackage(logbookPackage) : forkJoin([
      this.vehicleService.updateAnVehicle({ id: this.bookingDetails.vehicle.id, odometer: this.bookingDetails.vehicle.odometer }),
      this.bookingDetailsService.updateLogBookPackage(logbookPackage)
    ]);

    this.loading = true;
    observables.subscribe(val => {
      this.bookingDetailsStore.updateStore(this.bookingDetails);
      this.configService.setInputVehicle(null);
      this.loading = false;
    }, err => {
      this.bookingDetailsStore.updateStore(this.configQuery.getValue().clonnedBookingDetailsSelected);
      this.configService.setInputVehicle(null);

      const data = Object.assign([], this.list);
      this.list = [];
      this.show = false;
      this.list = data;

      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].id === this.configQuery.getValue().clonnedBookingDetailsSelected.jobs[0].id) {
          this.list[i].active = true;
        } else {
          this.list[i].active = false;
        }
      }
      try {
        this.cdr.detectChanges();
      } catch (e) { }
      this.show = true;
      this.loading = false;
    });
  }
}
