import { ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as fileType from 'file-type';

@Component({
  selector: 'upload-control',
  templateUrl: './upload-control.component.html',
  styleUrls: ['./upload-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => UploadControlComponent),
    }
  ]
})
export class UploadControlComponent implements OnInit, ControlValueAccessor {
  @Input() label: string;
  @Input() hint: string;
  @Input() accept: string;
  @Input() width: number;
  @Input() height: number;
  private onChange;
  private onTouch;
  isDisabled: boolean;
  fileName = "";
  file: File = null;
  isTypeError = false;
  fileType = '';
  isWrongDimension = false;
  h: number;
  w: number;
  constructor(private cdr: ChangeDetectorRef) { }

  writeValue(obj: File | string): void {
    if (obj instanceof File) {
      this.fileName = obj.name;
      return;
    }
    this.fileName = obj;
    this.file = null;
    this.isTypeError = false;
    this.isWrongDimension = false;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  ngOnInit() {
  }

  onOpen() {
    if (this.file instanceof File) {
      this.openFile(this.file);
      return;
    }
    window.open(this.fileName, '_blank');
  }

  onFileChange(files: FileList) {

    if (files && files.length > 0) {
      this.isWrongDimension = false;
      this.isTypeError = false;
      this.file = files[0];
      this.fileName = this.file.name;
      this.checkFileType(this.file, (type: string) => {
        this.isTypeError = !this.isAllowed(type);
        if (this.isTypeError) {
          this.fileType = type;
          this.emitNullValue();
          this.cdr.detectChanges();
          return;
        }

        if (this.height && this.width) {
          this.checkDimension(this.file, ([h, w]) => {

            this.isWrongDimension = this.height !== h || this.width !== w;
            if (this.isWrongDimension) {
              this.h = h;
              this.w = w;
              this.emitNullValue();
              this.cdr.detectChanges();
              return;
            }

            this.emitValue()
            this.cdr.detectChanges();
          })
          return;
        }

        this.emitValue()
        this.cdr.detectChanges();
      })
    }
  }

  onClear(fileRef: HTMLInputElement) {
    this.isWrongDimension = false;
    this.isTypeError = false;
    this.file = null;
    this.fileName = "";
    fileRef.value = null;
    this.emitNullValue();
    this.cdr.detectChanges();
  }

  private emitNullValue() {
    this.onChange(null);
    this.onTouch(null);
  }

  private emitValue() {
    this.onChange(this.file);
    this.onTouch(this.file);
  }
  private checkFileType(bob: File, callback: (type: string) => void) {
    var fileReader = new FileReader();
    fileReader.onloadend = (e: any) => {
      try {
        const { mime } = fileType(new Uint8Array(e.target.result));
        callback(mime);
      } catch (ex) {
        callback('unknown');
      }

    };
    fileReader.readAsArrayBuffer(bob);
  }

  private checkDimension(file: File, callback: ([h, w]: [number, number]) => void) {
    var reader = new FileReader();

    //Read the contents of Image File.
    reader.readAsDataURL(file);
    reader.onload = (e: any) => {

      //Initiate the JavaScript Image object.
      var image = new Image();

      //Set the Base64 string return from FileReader as source.
      image.src = e.target.result;

      //Validate the File Height and Width.
      image.onload = () => {
        var height = image.height;
        var width = image.width;
        callback([height, width]);
      };
    };
  }

  private openFile(file: File) {
    var reader = new FileReader();
    reader.onload = function (event: any) {
      let url = event.target.result;
      window.open(url, '_blank');

      // var dataURL = reader.result as string;
      // window.open(dataURL, '_blank');
    };
    reader.readAsDataURL(file);
  };

  private isAllowed(type: string) {
    if (!this.accept) {
      return true;
    }
    const arr = this.accept.split(',');
    return arr.includes(type);
  }
}
