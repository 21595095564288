import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment-timezone';

import { CustomersStore } from '../../../@core/akita-stores/entity-stores/customers/customers.store';
import { CustomerQuery } from '../../../@core/akita-stores/stores/customer/customer.query';
import { CustomerService } from '../../../@core/akita-stores/stores/customer/customer.service';
import { CustomersQuery } from '../../../@core/akita-stores/entity-stores/customers/customers.query';
import { getTimezone } from '../../../@core/akita-stores/storage';
import { CommonService } from '../../../@core/utils/common.service';

@Component({
  selector: 'sidebar-customer',
  templateUrl: './sidebar-customer.component.html',
  styleUrls: ['./sidebar-customer.component.scss']
})
export class SidebarCustomerComponent implements OnInit, OnDestroy {
  customerDetails: any = null;
  nextAppointment: string;
  hasContacts: boolean = false;
  timezone: string = getTimezone();
  subscriptions: Subscription = new Subscription();
  customerId: number;
  addressCustomer: string = '';

  constructor(
    private router: Router,
    private customersStore: CustomersStore,
    private customersQuery: CustomersQuery,
    private customerQuery: CustomerQuery,
    private customerService: CustomerService,
    private commonService: CommonService,
  ) {
    this.customerId = this.customersQuery.getActiveId();
  }

  ngOnInit() {
    this.getCustomerDetails();
    this.getNextAppointment();
  }

  ngOnDestroy() {
    this.subscriptions && this.subscriptions.unsubscribe();
  }

  getCustomerDetails() {
    this.subscriptions.add(this.customerQuery.currentCustomerDetails$
      .subscribe((data: any) => {
        this.customerDetails = data.currentCustomerDetails;
        if (this.customerDetails.phone || this.customerDetails.email) this.hasContacts = true;
        this.addressCustomer = this.commonService.customStrAddress(this.customerDetails);
      })
    );
  }

  getNextAppointment() {
    this.subscriptions.add(this.customerService.getBookings(this.customersQuery.getActiveId(), 'upcoming', 'BookedAt')
      .pipe(map(bookings => bookings[0]))
      .subscribe(booking => {
        this.nextAppointment = booking && booking.bookedAtUtc
          ? moment.tz(booking.bookedAtUtc, "UTC").tz(this.timezone).format('MMM DD, YYYY - hh:mm A')
          : '--'
      })
    );
  }

  goBack() {
    this.customersStore.update(state => ({ backToCustomers: true }));
    // this.router.navigate(['/pages/customers']);
    this.router.navigateByUrl('/reload', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/pages/customers']));
  }
}
