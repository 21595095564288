import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class EllipseIconService {

    render() {
        return `<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="5" cy="5" r="4" stroke="#231F20" stroke-width="2"/>
        </svg>                             
        `;
    }
}
