import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface FlightDeckState {
  listView: any[];
  flightDecks: any[];
  isOnFullScreen: boolean;
  flightdeckCode: string;
  isShownBookingTimeline: boolean;
}

export function createInitialFlightDeckState(): FlightDeckState {
  return {
    flightDecks: [],
    listView: [],
    isOnFullScreen: false,
    flightdeckCode: null,
    isShownBookingTimeline: false
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'flightdeck', resettable: true })
export class FlightDeckStore extends Store<FlightDeckState> {
  constructor() {
    super(createInitialFlightDeckState());
  }

  updateFlightDeck(flightDecks) {
    this.update({ flightDecks });
  }

  setIsOnFullScreen(isOnFullScreen){
    this.update({
      isOnFullScreen: isOnFullScreen
    })
  }

  getIsOnFullScreen() {
    return this.getValue().isOnFullScreen;
  }

  setflightdeckCode(flightdeckCode: string){
    this.update({
      flightdeckCode: flightdeckCode
    })
  }

  getflightdeckCode(){
    return this.getValue().flightdeckCode;
  }

  setIsShownBookingTimeline(isShown: boolean){
    this.update({
      isShownBookingTimeline: isShown
    })
  }

  isShownBookingTimeline() {
    return this.getValue().isShownBookingTimeline;
  }
}
