import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class ListIconService {

  render() {
      return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 7.00002H8C7.4 7.00002 7 6.60002 7 6.00002C7 5.40002 7.4 5.00002 8 5.00002H21C21.6 5.00002 22 5.40002 22 6.00002C22 6.60002 21.6 7.00002 21 7.00002Z" fill="black"/>
      <path d="M21 13H8C7.4 13 7 12.6 7 12C7 11.4 7.4 11 8 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13Z" fill="black"/>
      <path d="M21 19H8C7.4 19 7 18.6 7 18C7 17.4 7.4 17 8 17H21C21.6 17 22 17.4 22 18C22 18.6 21.6 19 21 19Z" fill="black"/>
      <path d="M3 7.00002C2.9 7.00002 2.9 7.00002 2.8 7.00002C2.7 7.00002 2.7 7.00002 2.6 6.90002C2.5 6.90002 2.5 6.80002 2.4 6.80002C2.3 6.80002 2.3 6.70002 2.3 6.70002C2.2 6.60002 2.1 6.50002 2.1 6.40002C2 6.30002 2 6.10002 2 6.00002C2 5.90002 2 5.70002 2.1 5.60002C2.2 5.50002 2.2 5.40002 2.3 5.30002C2.6 5.00002 3 4.90002 3.4 5.10002C3.5 5.20002 3.6 5.20002 3.7 5.30002C3.9 5.50002 4 5.70002 4 6.00002C4 6.30002 3.9 6.50002 3.7 6.70002C3.5 6.90002 3.3 7.00002 3 7.00002Z" fill="black"/>
      <path d="M3 13C2.7 13 2.5 12.9 2.3 12.7C2.1 12.5 2 12.3 2 12C2 11.9 2 11.9 2 11.8C2 11.7 2 11.7 2.1 11.6C2.1 11.5 2.2 11.5 2.2 11.4C2.2 11.3 2.3 11.3 2.3 11.3C2.4 11.2 2.5 11.1 2.6 11.1C3 10.9 3.4 11 3.7 11.3L3.8 11.4C3.8 11.5 3.9 11.5 3.9 11.6C3.9 11.7 3.9 11.7 4 11.8C4 11.9 4 11.9 4 12C4 12.3 3.9 12.5 3.7 12.7C3.5 12.9 3.3 13 3 13Z" fill="black"/>
      <path d="M3 19C2.7 19 2.5 18.9 2.3 18.7C2.1 18.5 2 18.3 2 18C2 17.9 2 17.7 2.1 17.6C2.2 17.5 2.2 17.4 2.3 17.3C2.7 16.9 3.3 16.9 3.7 17.3C3.9 17.5 4 17.7 4 18C4 18.3 3.9 18.5 3.7 18.7C3.5 18.9 3.3 19 3 19Z" fill="black"/>
      </svg>      
      `;
  }
}
