import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class ExpressLogbookCarIconService {

  render(service = null, direction = null) {
    if (service == 'express' && direction == 'up') {
      return `<svg width="40" height="72" viewBox="0 0 40 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.3" filter="url(#filter0_f)">
        <path d="M7.96859 16.3029C7.94934 13.8003 8.95003 11.394 10.759 9.66141C12.183 8.29461 14.0112 7.44758 15.9549 7.21657C18.4181 6.92781 20.9198 6.92781 23.383 7.21657C25.3267 7.46683 27.1549 8.31386 28.5789 9.68065C30.3686 11.4132 31.3886 13.8003 31.3693 16.3029L31.3308 20.5188L30.715 27.0255L30.8112 29.8938L31.2346 29.9901C31.7927 30.1249 32.2161 30.5676 32.3315 31.1451L32.4085 31.5879L32.4277 31.7804L30.869 31.5109L31.0999 38.0369L31.3116 60.3676C31.3308 61.7729 30.5033 63.0435 29.214 63.6017C27.9054 64.1793 26.5006 64.4873 25.0765 64.545L20.5157 64.7375C19.9384 64.7568 19.3803 64.7568 18.803 64.7375L14.2421 64.545C12.8181 64.4873 11.4133 64.16 10.1047 63.6017C8.83457 63.0435 8.00708 61.7729 8.00708 60.3676L8.21876 38.0369L8.60364 27.0448L7.98783 20.538L7.96859 16.3029Z" fill="#020947"/>
        </g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M28.6501 9.92801C27.2261 8.56121 25.3979 7.71418 23.4542 7.48317C20.991 7.19441 18.5085 7.19441 16.0453 7.48317C14.1017 7.71418 12.2735 8.56121 10.8494 9.92801C9.04048 11.6606 8.03979 14.0476 8.05904 16.5695L8.09752 20.7854L8.71333 27.2921L8.60201 30.4715L7.21237 30.7954C6.6543 30.9302 6.23093 31.373 6.11546 31.9505L6.03849 32.3932L6 32.5857L8.54401 32.128L8.32845 38.2842L8.11677 60.615C8.09752 62.0203 8.92502 63.2908 10.2144 63.8491C11.523 64.4266 12.9278 64.7346 14.3518 64.7924L18.9127 64.9849C19.49 65.0041 20.0481 65.0041 20.6254 64.9849L25.1862 64.7924C26.6103 64.7346 28.0151 64.4074 29.3237 63.8491C30.5938 63.2908 31.4213 62.0203 31.4213 60.615L31.2096 38.2842L30.9841 31.8448C31.0515 31.9763 31.1769 32.0745 31.3251 32.1042L34.0001 32.5855L33.9616 32.393L33.8846 31.9502C33.7691 31.3727 33.3458 30.9299 32.7877 30.7951L30.9322 30.3627L30.8247 27.2921L31.4405 20.7854L31.479 16.5695C31.479 14.0476 30.4591 11.6413 28.6501 9.92801Z" fill="#FF5200"/>
        <g style="mix-blend-mode:overlay" opacity="0.15">
        <path d="M28.8829 24.7332L28.9021 24.6562C28.9021 24.6562 28.6712 16.9559 28.1901 14.9346C27.7667 13.1635 25.7846 10.0642 19.9729 10.0449C12.6024 10.0449 11.6017 13.664 11.1784 15.4351C10.6973 17.4564 11.1399 24.6562 11.1399 24.6562L11.1591 24.7332H28.8829Z" fill="white"/>
        </g>
        <g style="mix-blend-mode:overlay" opacity="0.15">
        <path d="M27.3624 46.4863C25.5342 46.6211 23.706 46.7366 21.8778 46.8713C18.818 47.0831 15.7582 47.2756 12.6792 47.4104L12.6407 49.0274C12.6407 49.0274 11.1396 52.2615 11.1396 52.4155C11.1396 52.5695 12.6792 61.5596 12.6792 61.5596C14.2379 62.7531 18.0675 63.1766 20.0304 63.3114C21.9933 63.1766 25.8421 62.7724 27.3816 61.5596C27.3816 61.5596 28.9211 52.5695 28.9211 52.4155C28.9211 52.2615 27.4201 49.0274 27.4201 49.0274L27.3624 46.4863Z" fill="white"/>
        </g>
        <path d="M28.9577 23.9431C28.9577 23.9431 25.8209 20.4202 19.8937 20.5742C13.9473 20.4202 10.8105 23.9431 10.8105 23.9431L12.6002 31.8744C14.7363 31.0466 19.5473 30.8733 19.8937 30.8733C20.2209 30.8733 25.0319 31.0466 27.1872 31.8744L28.9577 23.9431Z" fill="#231F20"/>
        <path opacity="0.11" d="M28.3802 24.1161L28.3609 24.2124L26.7829 31.2003C26.0324 30.9886 25.1279 30.8153 24.2235 30.6998C22.0681 30.4111 19.9128 30.3533 19.8743 30.3533C19.8358 30.3533 17.9884 30.3918 16.0255 30.6421C15.3135 30.7191 14.6014 30.8346 13.9472 30.9693C13.62 31.0463 13.2929 31.1233 12.9849 31.2003L12.2729 28.0625L11.3877 24.1161C11.9265 23.5963 13.6585 22.1333 16.4681 21.4595C17.3726 21.2478 18.3925 21.0938 19.5087 21.0938C19.6241 21.0938 19.7396 21.0938 19.8551 21.0938H19.8743C19.9898 21.0938 20.086 21.0938 20.1822 21.0938H20.2015C20.8365 21.0938 21.4138 21.1323 21.9719 21.2093C25.5898 21.6905 27.7644 23.5193 28.3802 24.1161Z" fill="white"/>
        <path d="M28.1888 49.0839C28.7468 49.9117 29.3049 50.7395 29.863 51.5672L29.863 24.1543L27.6499 32.8363L27.5537 48.1599C27.7654 48.4679 27.9771 48.7759 28.1888 49.0839Z" fill="#231F20"/>
        <path opacity="0.11" d="M29.2661 48.9873L29.2661 29.0244L28.2847 32.8938L28.1885 47.3703C28.1885 47.3895 28.9198 48.4676 29.2661 48.9873Z" fill="white"/>
        <path d="M27.9385 39.9021L29.5935 40.5952L29.3818 39.1514L28.1694 39.2476L27.9385 39.9021Z" fill="#231F20"/>
        <path d="M11.7162 49.0839C11.1581 49.9117 10.6001 50.7394 10.042 51.5672L10.042 24.1543L12.2551 32.8363L12.3513 48.1599C12.1396 48.4679 11.9279 48.7759 11.7162 49.0839Z" fill="#231F20"/>
        <path opacity="0.11" d="M10.6582 48.9873L10.6582 29.0244L11.6396 32.8938L11.7359 47.3703C11.7359 47.3895 11.0046 48.4676 10.6582 48.9873Z" fill="white"/>
        <path d="M11.9665 39.9021L10.3115 40.5952L10.5425 39.1514L11.7356 39.2476L11.9665 39.9021Z" fill="#231F20"/>
        <path d="M29.5352 52.5107L27.3991 49.0264C25.6287 50.3547 14.2555 50.3547 12.5043 49.0264L10.3682 52.5107L10.984 55.0326C11.5998 57.6122 13.9091 59.4217 16.5455 59.4217H23.3386C25.9751 59.4217 28.2843 57.6122 28.9002 55.0326L29.5352 52.5107Z" fill="#231F20"/>
        <path opacity="0.11" d="M28.957 52.6076L28.399 54.9177C27.8794 57.093 26.0319 58.6523 23.8574 58.8641C23.6842 58.8833 23.5302 58.8833 23.357 58.8833H16.5639C16.256 58.8833 15.9481 58.8641 15.6594 58.8063C15.1206 58.7101 14.601 58.5368 14.1199 58.2673C13.2154 57.786 12.4649 57.0545 11.9838 56.1305C11.7914 55.7455 11.6182 55.3412 11.5219 54.9177L10.9639 52.6076L12.7151 49.7393C13.4463 50.028 14.5433 50.2205 15.6787 50.3553C16.9103 50.49 18.1804 50.5478 19.0464 50.5478C19.4505 50.5478 19.7776 50.567 19.9701 50.567C20.1625 50.567 20.4897 50.567 20.9323 50.5478C22.6257 50.5093 25.7433 50.3553 27.2251 49.7393L28.4182 51.7028L28.957 52.6076Z" fill="white"/>
        <g style="mix-blend-mode:overlay" opacity="0.2">
        <path d="M6 32.5874L8.67492 32.1061C8.86736 32.0676 9.02131 31.9136 9.0598 31.7211C9.11753 31.4131 9.3677 31.2014 9.67561 31.2014H10.0412L10.0412 30.1426L9.19451 30.3351L8.27079 31.7404L6.03849 32.4142L6 32.5874Z" fill="black"/>
        </g>
        <g style="mix-blend-mode:overlay" opacity="0.2">
        <path d="M34.0002 32.5874L31.3253 32.1062C31.1329 32.0677 30.9789 31.9136 30.9404 31.7211C30.8827 31.4131 30.6325 31.2014 30.3246 31.2014H29.959L29.959 30.1426L30.8057 30.3351L31.7294 31.7404L33.9617 32.4142L34.0002 32.5874Z" fill="black"/>
        </g>
        <path d="M29.612 12.7966L29.5735 13.2201L29.4965 14.1634C29.458 14.6447 28.8422 14.7987 28.5728 14.3944L26.8601 11.7763C26.7831 11.6608 26.7254 11.5646 26.6676 11.4491C26.3982 10.9486 26.2443 10.3903 26.1865 9.83203C26.3212 9.83203 26.4752 9.85128 26.6099 9.88978C27.5913 10.1593 28.4958 11.1796 29.0539 11.9304C29.4003 12.4116 29.612 12.7966 29.612 12.7966Z" fill="#F6F6F6"/>
        <path d="M29.5735 13.2201L29.4965 14.1634C29.458 14.6447 28.8422 14.7987 28.5728 14.3944L26.8601 11.7763C26.4752 11.1988 26.2443 10.5251 26.1865 9.83203C26.3212 9.83203 26.4752 9.85128 26.6099 9.88978C26.4175 10.0438 26.6291 10.9486 26.9563 11.3721C27.3219 11.8534 28.1109 12.8544 28.6305 13.5282C28.8614 13.8169 29.3041 13.7784 29.4773 13.4319L29.5735 13.2201Z" fill="#8C8984"/>
        <path d="M29.6123 12.7968L29.5738 13.2203L29.4968 14.1636C29.4583 14.6448 28.8425 14.7988 28.5731 14.3946L26.8604 11.7765C26.7834 11.661 26.7257 11.5647 26.668 11.4492C27.457 11.6225 28.2652 11.7765 29.0542 11.9497C29.4006 12.4118 29.6123 12.7968 29.6123 12.7968Z" fill="#DFDBE2"/>
        <path d="M10.0049 12.7966L10.0434 13.2201L10.1203 14.1634C10.1588 14.6447 10.7746 14.7987 11.0441 14.3944L12.7568 11.7763C12.8338 11.6608 12.8915 11.5646 12.9492 11.4491C13.2186 10.9486 13.3726 10.3903 13.4303 9.83203C13.2956 9.83203 13.1417 9.85128 13.0069 9.88978C12.0255 10.1593 11.121 11.1796 10.563 11.9304C10.2166 12.4116 10.0049 12.7966 10.0049 12.7966Z" fill="#F6F6F6"/>
        <path d="M10.042 13.2201L10.119 14.1634C10.1575 14.6447 10.7733 14.7987 11.0427 14.3944L12.7554 11.7763C13.1403 11.1988 13.3712 10.5251 13.4289 9.83203C13.2942 9.83203 13.1403 9.85128 13.0056 9.88978C13.198 10.0438 12.9863 10.9486 12.6592 11.3721C12.2935 11.8534 11.5045 12.8544 10.9849 13.5282C10.754 13.8169 10.3114 13.7784 10.1382 13.4319L10.042 13.2201Z" fill="#8C8984"/>
        <path d="M10.0039 12.7968L10.0424 13.2203L10.1194 14.1636C10.1579 14.6448 10.7737 14.7988 11.0431 14.3946L12.7558 11.7765C12.8328 11.661 12.8905 11.5647 12.9482 11.4492C12.1592 11.6225 11.351 11.7765 10.562 11.9497C10.2156 12.4118 10.0039 12.7968 10.0039 12.7968Z" fill="#DFDBE2"/>
        <g style="mix-blend-mode:overlay" opacity="0.3">
        <path d="M14.1592 32.5096C14.1592 32.5096 20.9716 30.8733 26.0712 32.5866L26.1482 46.3845L24.8781 45.3835L23.1846 35.5513C23.1461 35.5705 18.0465 32.3749 14.1592 32.5096Z" fill="white"/>
        </g>
        <path d="M28.2847 63.4259C27.6881 63.6376 26.8991 63.7916 25.8984 63.8109L26.8029 62.6366C26.8029 62.6366 27.5727 62.4248 28.4002 62.0976C29.1507 61.7895 29.9782 61.366 30.3631 60.8848C30.3438 60.8848 30.4785 62.6173 28.2847 63.4259Z" fill="#FF3838"/>
        <path d="M28.2847 63.4259C27.6881 63.6377 26.8991 63.7917 25.8984 63.811L26.8029 62.6367C26.8029 62.6367 27.5727 62.4249 28.4002 62.0977C28.3617 62.1362 28.3232 62.1939 28.2655 62.2324C28.15 62.3672 28.0153 62.5019 27.9575 62.6752C27.8613 62.9639 28.0345 63.2719 28.2847 63.4259Z" fill="#DCDCDC"/>
        <path d="M11.0213 63.4259C11.6179 63.6376 12.4069 63.7916 13.4076 63.8109L12.5031 62.6366C12.5031 62.6366 11.7334 62.4248 10.9059 62.0976C10.1554 61.7896 9.32787 61.366 8.94299 60.8848C8.94299 60.8848 8.80828 62.6173 11.0213 63.4259Z" fill="#FF3838"/>
        <path d="M11.0227 63.426C11.6193 63.6377 12.4083 63.7917 13.4089 63.811L12.5045 62.6367C12.5045 62.6367 11.7347 62.4249 10.9072 62.0977C10.9457 62.1362 10.9842 62.1939 11.0419 62.2324C11.1574 62.3672 11.2921 62.5019 11.3498 62.6752C11.4268 62.9639 11.2536 63.272 11.0227 63.426Z" fill="#DCDCDC"/>
        <path opacity="0.11" d="M20.2212 21.0935L13.9477 30.9306C13.6205 31.0076 13.2934 31.0846 12.9855 31.1616L12.2734 28.0237L16.4686 21.44C17.3731 21.2282 18.393 21.0742 19.5092 21.0742C19.6247 21.0742 19.7401 21.0742 19.8556 21.0742H19.8748C20.0095 21.0935 20.1058 21.0935 20.2212 21.0935Z" fill="white"/>
        <path opacity="0.11" d="M28.3791 24.1171L28.3598 24.2133L24.2224 30.6816C22.067 30.3928 19.9117 30.3351 19.8732 30.3351C19.8347 30.3351 17.9873 30.3736 16.0244 30.6238L22.0093 21.2295C25.5887 21.6915 27.7633 23.5203 28.3791 24.1171Z" fill="white"/>
        <path opacity="0.11" d="M19.0294 50.5477L14.1029 58.2672C13.1984 57.7859 12.4479 57.0544 11.9668 56.1304L15.6617 50.3359C16.8933 50.4899 18.1634 50.5284 19.0294 50.5477Z" fill="white"/>
        <path opacity="0.11" d="M28.4189 51.7028L23.8581 58.8641C23.6849 58.8833 23.531 58.8833 23.3578 58.8833H16.5646C16.2567 58.8833 15.9488 58.8641 15.6602 58.8063L20.9138 50.5478C22.6072 50.5093 25.7248 50.3553 27.2066 49.7393L28.4189 51.7028Z" fill="white"/>
        <g style="mix-blend-mode:overlay" opacity="0.3">
        <path d="M23.2812 11.623C23.2812 11.623 26.7452 16.9362 27.8613 22.4227C27.8613 22.4227 27.284 14.7609 26.5335 13.5481C25.783 12.3161 23.2812 11.623 23.2812 11.623Z" fill="white"/>
        </g>
        <g style="mix-blend-mode:overlay" opacity="0.3">
        <path d="M16.623 11.623C16.623 11.623 13.1591 16.9362 12.043 22.4227C12.043 22.4227 12.6203 14.7609 13.3708 13.5481C14.1213 12.3353 16.623 11.623 16.623 11.623Z" fill="white"/>
        </g>
        <path d="M25.8217 10.9876C25.4945 10.3523 25.3213 9.38978 25.3213 9.38978C24.6286 8.33099 20.5296 8.15773 19.8176 8.19624C19.1055 8.15773 14.8718 8.33099 14.1791 9.38978C14.1791 9.38978 14.0059 10.3523 13.6787 10.9876C13.6787 10.9876 15.276 9.44753 19.7406 9.46678C24.2052 9.44753 25.8217 10.9876 25.8217 10.9876Z" fill="#231F20"/>
        <path opacity="0.11" d="M14.6406 9.81283C14.6599 9.73582 14.6791 9.65882 14.6791 9.60107C15.1795 9.0813 17.7004 8.69629 19.6633 8.69629C19.721 8.69629 19.7595 8.69629 19.798 8.69629H19.8365C19.875 8.69629 19.9135 8.69629 19.9712 8.69629C21.7032 8.69629 24.3011 9.0428 24.8207 9.60107C24.84 9.65882 24.84 9.71657 24.8592 9.81283C23.8585 9.37006 22.2228 8.9273 19.798 8.9273H19.7018C17.2771 8.9273 15.6413 9.38931 14.6406 9.81283Z" fill="white"/>
        <g style="mix-blend-mode:overlay" opacity="0.15">
        <path d="M15.3147 63.6762C15.3147 63.6762 20.068 64.3885 24.6288 63.6377L24.7058 64.1767C24.7058 64.1767 19.4521 65.2355 15.1992 64.1767L15.3147 63.6762Z" fill="white"/>
        </g>
        <defs>
        <filter id="filter0_f" x="0.96875" y="0" width="38.4594" height="71.752" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="3.5" result="effect1_foregroundBlur"/>
        </filter>
        </defs>
        </svg>      
      `;
    }

    if (service == 'logbook' && direction == 'up') {
      return `<svg width="39" height="72" viewBox="0 0 39 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.3" filter="url(#filter0_f)">
        <path d="M7.51974 16.3028C7.5009 13.8003 8.4804 11.3939 10.251 9.66139C11.6449 8.2946 13.4344 7.44758 15.3369 7.21657C17.748 6.92781 20.1967 6.92781 22.6078 7.21657C24.5103 7.46683 26.2997 8.31385 27.6936 9.68064C29.4454 11.4132 30.4438 13.8002 30.4249 16.3028L30.3873 20.5187L29.7845 27.0254L29.8787 29.8937L30.2931 29.9899C30.8393 30.1247 31.2537 30.5675 31.3668 31.145L31.4421 31.5877L31.4609 31.7802L29.9352 31.5107L30.1612 38.0367L30.3684 60.3673C30.3873 61.7726 29.5773 63.0431 28.3152 63.6014C27.0344 64.1789 25.6593 64.4869 24.2654 64.5446L19.8011 64.7371C19.2361 64.7564 18.6898 64.7564 18.1247 64.7371L13.6604 64.5446C12.2665 64.4869 10.8915 64.1596 9.6106 63.6014C8.36739 63.0431 7.55742 61.7726 7.55742 60.3673L7.76462 38.0367L8.14135 27.0446L7.53858 20.5379L7.51974 16.3028Z" fill="#020947"/>
        </g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M27.7639 9.92896C26.37 8.56218 24.5806 7.71515 22.6781 7.48415C20.267 7.19539 17.8371 7.19539 15.426 7.48415C13.5235 7.71515 11.7341 8.56218 10.3402 9.92896C8.56953 11.6615 7.59003 14.0486 7.60887 16.5704L7.64654 20.7863L8.24931 27.2929L8.14033 30.4728L6.78045 30.7966C6.23419 30.9314 5.81979 31.3742 5.70677 31.9517L5.63142 32.3944L5.59375 32.5869L8.08355 32.1293L7.87258 38.285L7.66538 60.6156C7.64654 62.0209 8.45651 63.2914 9.71856 63.8497C10.9994 64.4272 12.3745 64.7352 13.7684 64.793L18.2327 64.9855C18.7978 65.0047 19.344 65.0047 19.9091 64.9855L24.3734 64.793C25.7673 64.7352 27.1423 64.408 28.4232 63.8497C29.6664 63.2914 30.4764 62.0209 30.4764 60.6156L30.2692 38.285L30.0485 31.8473C30.1146 31.9783 30.2371 32.0761 30.3818 32.1057L33.0001 32.5869L32.9624 32.3944L32.8871 31.9517C32.7741 31.3742 32.3597 30.9314 31.8134 30.7966L29.9977 30.3643L29.8925 27.2929L30.4952 20.7863L30.5329 16.5704C30.5329 14.0486 29.5346 11.6423 27.7639 9.92896Z" fill="#231F20"/>
        <g style="mix-blend-mode:overlay" opacity="0.15">
        <path d="M27.3968 24.7321L27.4156 24.6551C27.4156 24.6551 27.1896 16.9549 26.7187 14.9336C26.3042 13.1625 24.3641 10.0632 18.6755 10.0439C11.4611 10.0439 10.4816 13.663 10.0672 15.4341C9.59627 17.4554 10.0295 24.6551 10.0295 24.6551L10.0483 24.7321H27.3968Z" fill="white"/>
        </g>
        <g style="mix-blend-mode:overlay" opacity="0.15">
        <path d="M25.9085 46.4844C24.119 46.6191 22.3295 46.7346 20.5401 46.8694C17.5451 47.0811 14.5501 47.2736 11.5362 47.4084L11.4985 49.0254C11.4985 49.0254 10.0293 52.2595 10.0293 52.4135C10.0293 52.5675 11.5362 61.5575 11.5362 61.5575C13.062 62.7511 16.8104 63.1746 18.7318 63.3093C20.6531 63.1746 24.4204 62.7703 25.9273 61.5575C25.9273 61.5575 27.4342 52.5675 27.4342 52.4135C27.4342 52.2595 25.965 49.0254 25.965 49.0254L25.9085 46.4844Z" fill="white"/>
        </g>
        <path d="M27.4708 23.9431C27.4708 23.9431 24.4005 20.4202 18.5988 20.5742C12.7784 20.4202 9.70801 23.9431 9.70801 23.9431L11.4598 31.8743C13.5507 31.0465 18.2598 30.8733 18.5988 30.8733C18.9191 30.8733 23.6282 31.0465 25.7379 31.8743L27.4708 23.9431Z" fill="#918F8F"/>
        <path opacity="0.11" d="M26.9061 24.1171L26.8872 24.2133L25.3426 31.2013C24.608 30.9895 23.7227 30.8162 22.8374 30.7007C20.7277 30.412 18.618 30.3542 18.5803 30.3542C18.5427 30.3542 16.7344 30.3927 14.813 30.643C14.1161 30.72 13.4191 30.8355 12.7787 30.9702C12.4585 31.0472 12.1383 31.1243 11.8369 31.2013L11.1399 28.0634L10.2734 24.1171C10.8009 23.5973 12.4961 22.1343 15.2463 21.4605C16.1316 21.2487 17.1299 21.0947 18.2224 21.0947C18.3355 21.0947 18.4485 21.0947 18.5615 21.0947H18.5803C18.6934 21.0947 18.7875 21.0947 18.8817 21.0947H18.9006C19.5222 21.0947 20.0873 21.1332 20.6335 21.2102C24.1748 21.6915 26.3033 23.5203 26.9061 24.1171Z" fill="white"/>
        <path d="M26.7183 49.0837C27.2645 49.9115 27.8108 50.7393 28.3571 51.5671L28.3571 24.1543L26.1909 32.8363L26.0967 48.1597C26.3039 48.4677 26.5111 48.7757 26.7183 49.0837Z" fill="#918F8F"/>
        <path opacity="0.11" d="M27.7716 48.9882L27.7716 29.0254L26.811 32.8947L26.7168 47.3711C26.7168 47.3904 27.4326 48.4684 27.7716 48.9882Z" fill="white"/>
        <path d="M26.4727 39.9012L28.0926 40.5942L27.8854 39.1504L26.6987 39.2466L26.4727 39.9012Z" fill="#020947"/>
        <path d="M10.5948 49.0837C10.0486 49.9115 9.50231 50.7393 8.95605 51.5671L8.95605 24.1543L11.1223 32.8363L11.2164 48.1597C11.0092 48.4677 10.802 48.7757 10.5948 49.0837Z" fill="#918F8F"/>
        <path opacity="0.11" d="M9.55957 48.9882L9.55957 29.0254L10.5202 32.8947L10.6144 47.3711C10.6144 47.3904 9.89863 48.4684 9.55957 48.9882Z" fill="white"/>
        <path d="M10.8397 39.9012L9.21973 40.5942L9.44576 39.1504L10.6136 39.2466L10.8397 39.9012Z" fill="#020947"/>
        <path d="M28.0375 52.5107L25.9467 49.0264C24.2137 50.3547 13.0813 50.3547 11.3672 49.0264L9.27637 52.5107L9.87914 55.0325C10.4819 57.6121 12.7423 59.4217 15.3229 59.4217H21.9722C24.5528 59.4217 26.8132 57.6121 27.4159 55.0325L28.0375 52.5107Z" fill="#918F8F"/>
        <path opacity="0.11" d="M27.4725 52.6066L26.9262 54.9167C26.4176 57.092 24.6093 58.6513 22.4808 58.863C22.3113 58.8823 22.1606 58.8823 21.9911 58.8823H15.3418C15.0404 58.8823 14.739 58.863 14.4565 58.8053C13.929 58.709 13.4204 58.5358 12.9495 58.2663C12.0642 57.785 11.3296 57.0535 10.8587 56.1295C10.6703 55.7444 10.5008 55.3402 10.4066 54.9167L9.86035 52.6066L11.5745 49.7383C12.2903 50.027 13.3639 50.2195 14.4753 50.3543C15.6808 50.4891 16.924 50.5468 17.7717 50.5468C18.1672 50.5468 18.4875 50.5661 18.6758 50.5661C18.8642 50.5661 19.1844 50.5661 19.6177 50.5468C21.2753 50.5083 24.3268 50.3543 25.7772 49.7383L26.9451 51.7018L27.4725 52.6066Z" fill="white"/>
        <g style="mix-blend-mode:overlay" opacity="0.2">
        <path d="M5 32.5874L7.61827 32.1061C7.80664 32.0676 7.95733 31.9136 7.995 31.7211C8.05151 31.4131 8.29639 31.2014 8.59777 31.2014H8.95567L8.95567 30.1426L8.12686 30.3351L7.22271 31.7404L5.03767 32.4141L5 32.5874Z" fill="black"/>
        </g>
        <g style="mix-blend-mode:overlay" opacity="0.2">
        <path d="M32.4068 32.5874L29.7886 32.1061C29.6002 32.0676 29.4495 31.9136 29.4118 31.7211C29.3553 31.4131 29.1104 31.2014 28.8091 31.2014H28.4512L28.4512 30.1426L29.28 30.3351L30.1841 31.7404L32.3692 32.4141L32.4068 32.5874Z" fill="black"/>
        </g>
        <path d="M28.1127 12.7966L28.075 13.2201L27.9996 14.1634C27.962 14.6447 27.3592 14.7987 27.0955 14.3944L25.419 11.7763C25.3437 11.6608 25.2872 11.5646 25.2307 11.4491C24.967 10.9486 24.8163 10.3903 24.7598 9.83203C24.8916 9.83203 25.0423 9.85128 25.1742 9.88978C26.1348 10.1593 27.0201 11.1796 27.5664 11.9303C27.9055 12.4116 28.1127 12.7966 28.1127 12.7966Z" fill="#F6F6F6"/>
        <path d="M28.075 13.2201L27.9996 14.1634C27.962 14.6447 27.3592 14.7987 27.0955 14.3944L25.419 11.7763C25.0423 11.1988 24.8163 10.5251 24.7598 9.83203C24.8916 9.83203 25.0423 9.85128 25.1742 9.88978C24.9858 10.0438 25.193 10.9486 25.5132 11.3721C25.8711 11.8533 26.6434 12.8544 27.152 13.5281C27.378 13.8169 27.8113 13.7784 27.9808 13.4319L28.075 13.2201Z" fill="#8C8984"/>
        <path d="M28.1134 12.7968L28.0758 13.2203L28.0004 14.1635C27.9627 14.6448 27.36 14.7988 27.0963 14.3946L25.4198 11.7765C25.3445 11.661 25.288 11.5647 25.2314 11.4492C26.0037 11.6225 26.7949 11.7765 27.5672 11.9497C27.9062 12.4117 28.1134 12.7968 28.1134 12.7968Z" fill="#DFDBE2"/>
        <path d="M8.91992 12.7976L8.95759 13.2211L9.03294 14.1644C9.07061 14.6456 9.67338 14.7996 9.93709 14.3954L11.6135 11.7773C11.6889 11.6618 11.7454 11.5656 11.8019 11.4501C12.0656 10.9495 12.2163 10.3913 12.2728 9.83301C12.141 9.83301 11.9903 9.85226 11.8584 9.89076C10.8978 10.1603 10.0124 11.1805 9.46618 11.9313C9.12712 12.4126 8.91992 12.7976 8.91992 12.7976Z" fill="#F6F6F6"/>
        <path d="M8.95605 13.2211L9.0314 14.1644C9.06907 14.6456 9.67184 14.7996 9.93555 14.3954L11.612 11.7773C11.9887 11.1998 12.2148 10.526 12.2713 9.83301C12.1394 9.83301 11.9887 9.85226 11.8569 9.89076C12.0452 10.0448 11.838 10.9495 11.5178 11.3731C11.1599 11.8543 10.3876 12.8553 9.87904 13.5291C9.65301 13.8179 9.21977 13.7794 9.05024 13.4329L8.95605 13.2211Z" fill="#8C8984"/>
        <path d="M8.91895 12.7977L8.95662 13.2212L9.03196 14.1645C9.06964 14.6458 9.6724 14.7998 9.93612 14.3955L11.6126 11.7775C11.6879 11.662 11.7444 11.5657 11.8009 11.4502C11.0286 11.6235 10.2375 11.7775 9.4652 11.9507C9.12615 12.4127 8.91895 12.7977 8.91895 12.7977Z" fill="#DFDBE2"/>
        <g style="mix-blend-mode:overlay" opacity="0.3">
        <path d="M12.9873 32.5096C12.9873 32.5096 19.6554 30.8733 24.6471 32.5866L24.7225 46.3844L23.4792 45.3834L21.8216 35.5512C21.784 35.5705 16.7923 32.3749 12.9873 32.5096Z" fill="white"/>
        </g>
        <path d="M26.8123 63.4258C26.2284 63.6376 25.4561 63.7916 24.4766 63.8109L25.3619 62.6366C25.3619 62.6366 26.1153 62.4248 26.9253 62.0976C27.6599 61.7895 28.4699 61.366 28.8466 60.8848C28.8278 60.8848 28.9596 62.6173 26.8123 63.4258Z" fill="#FD515F"/>
        <path d="M26.8123 63.4269C26.2284 63.6387 25.4561 63.7927 24.4766 63.8119L25.3619 62.6376C25.3619 62.6376 26.1153 62.4259 26.9253 62.0986C26.8876 62.1371 26.85 62.1949 26.7935 62.2334C26.6804 62.3681 26.5486 62.5029 26.4921 62.6761C26.3979 62.9649 26.5674 63.2729 26.8123 63.4269Z" fill="#DCDCDC"/>
        <path d="M10.7693 63.4258C11.3532 63.6376 12.1255 63.7916 13.105 63.8109L12.2197 62.6366C12.2197 62.6366 11.4662 62.4248 10.6563 62.0976C9.92165 61.7895 9.11168 61.366 8.73495 60.8848C8.73495 60.8848 8.6031 62.6173 10.7693 63.4258Z" fill="#FD515F"/>
        <path d="M10.7702 63.4269C11.3542 63.6387 12.1265 63.7927 13.106 63.8119L12.2207 62.6376C12.2207 62.6376 11.4672 62.4259 10.6572 62.0986C10.6949 62.1371 10.7326 62.1949 10.7891 62.2334C10.9021 62.3681 11.034 62.5029 11.0905 62.6761C11.1658 62.9649 10.9963 63.2729 10.7702 63.4269Z" fill="#DCDCDC"/>
        <path opacity="0.11" d="M18.9191 21.0935L12.7784 30.9305C12.4582 31.0075 12.138 31.0845 11.8366 31.1615L11.1396 28.0237L15.246 21.44C16.1313 21.2282 17.1297 21.0742 18.2222 21.0742C18.3352 21.0742 18.4482 21.0742 18.5612 21.0742H18.5801C18.7119 21.0935 18.8061 21.0935 18.9191 21.0935Z" fill="white"/>
        <path opacity="0.11" d="M26.9065 24.1161L26.8877 24.2123L22.8378 30.6805C20.7281 30.3918 18.6184 30.334 18.5808 30.334C18.5431 30.334 16.7348 30.3725 14.8135 30.6228L20.6716 21.2285C24.1752 21.6905 26.3037 23.5193 26.9065 24.1161Z" fill="white"/>
        <path opacity="0.11" d="M17.7519 50.5467L12.9297 58.2662C12.0444 57.7849 11.3098 57.0534 10.8389 56.1294L14.4555 50.335C15.661 50.489 16.9042 50.5275 17.7519 50.5467Z" fill="white"/>
        <path opacity="0.11" d="M26.9447 51.7018L22.4804 58.863C22.3109 58.8823 22.1602 58.8823 21.9907 58.8823H15.3414C15.04 58.8823 14.7386 58.863 14.4561 58.8053L19.5984 50.5468C21.256 50.5083 24.3075 50.3543 25.758 49.7383L26.9447 51.7018Z" fill="white"/>
        <g style="mix-blend-mode:overlay" opacity="0.3">
        <path d="M21.9141 11.623C21.9141 11.623 25.3046 16.9362 26.3971 22.4226C26.3971 22.4226 25.8321 14.7609 25.0974 13.5481C24.3628 12.3161 21.9141 11.623 21.9141 11.623Z" fill="white"/>
        </g>
        <g style="mix-blend-mode:overlay" opacity="0.3">
        <path d="M15.3972 11.623C15.3972 11.623 12.0066 16.9362 10.9141 22.4226C10.9141 22.4226 11.4792 14.7609 12.2138 13.5481C12.9484 12.3353 15.3972 11.623 15.3972 11.623Z" fill="white"/>
        </g>
        <path d="M24.4024 10.9876C24.0822 10.3523 23.9127 9.38977 23.9127 9.38977C23.2346 8.33099 19.2224 8.15774 18.5254 8.19624C17.8285 8.15774 13.6845 8.33099 13.0064 9.38977C13.0064 9.38977 12.8368 10.3523 12.5166 10.9876C12.5166 10.9876 14.08 9.44752 18.4501 9.46677C22.8202 9.44752 24.4024 10.9876 24.4024 10.9876Z" fill="#918F8F"/>
        <path opacity="0.11" d="M13.458 9.81282C13.4768 9.73582 13.4957 9.65882 13.4957 9.60106C13.9854 9.0813 16.453 8.69629 18.3743 8.69629C18.4308 8.69629 18.4685 8.69629 18.5062 8.69629H18.5439C18.5815 8.69629 18.6192 8.69629 18.6757 8.69629C20.371 8.69629 22.9139 9.0428 23.4225 9.60106C23.4414 9.65882 23.4414 9.71657 23.4602 9.81282C22.4807 9.37006 20.8796 8.9273 18.5062 8.9273H18.412C16.0386 8.9273 14.4375 9.38931 13.458 9.81282Z" fill="white"/>
        <g style="mix-blend-mode:overlay" opacity="0.15">
        <path d="M14.1169 63.6762C14.1169 63.6762 18.7695 64.3885 23.2338 63.6377L23.3091 64.1767C23.3091 64.1767 18.1668 65.2355 14.0039 64.1767L14.1169 63.6762Z" fill="white"/>
        </g>
        <defs>
        <filter id="filter0_f" x="0.519531" y="0" width="37.9415" height="71.7516" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="3.5" result="effect1_foregroundBlur"/>
        </filter>
        </defs>
        </svg>
      `;
    }

    if (service == 'express' && direction == 'down') {
      return `<svg width="40" height="72" viewBox="0 0 40 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.3" filter="url(#filter0_f)">
        <path d="M32.0314 55.6971C32.0507 58.1997 31.05 60.606 29.241 62.3386C27.817 63.7054 25.9888 64.5524 24.0451 64.7834C21.5819 65.0722 19.0802 65.0722 16.617 64.7834C14.6733 64.5332 12.8451 63.6861 11.4211 62.3193C9.63138 60.5868 8.61144 58.1997 8.63069 55.6971L8.66917 51.4812L9.28498 44.9745L9.18876 42.1062L8.76539 42.0099C8.20732 41.8751 7.78395 41.4324 7.66849 40.8549L7.59151 40.4121L7.57227 40.2196L9.13103 40.4891L8.9001 33.9631L8.68842 11.6324C8.66917 10.2271 9.49667 8.95652 10.786 8.39825C12.0946 7.82073 13.4994 7.51273 14.9235 7.45497L19.4843 7.26247C20.0616 7.24321 20.6197 7.24321 21.197 7.26247L25.7579 7.45497C27.1819 7.51273 28.5867 7.83999 29.8953 8.39825C31.1654 8.95652 31.9929 10.2271 31.9929 11.6324L31.7812 33.9631L31.3964 44.9553L32.0122 51.462L32.0314 55.6971Z" fill="#020947"/>
        </g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3499 62.072C12.7739 63.4388 14.6021 64.2858 16.5458 64.5168C19.009 64.8056 21.4915 64.8056 23.9547 64.5168C25.8983 64.2858 27.7265 63.4388 29.1506 62.072C30.9595 60.3394 31.9602 57.9524 31.941 55.4305L31.9025 51.2146L31.2867 44.7079L31.398 41.5285L32.7876 41.2046C33.3457 41.0698 33.7691 40.627 33.8845 40.0495L33.9615 39.6068L34 39.4143L31.456 39.872L31.6716 33.7158L31.8832 11.385C31.9025 9.97971 31.075 8.70917 29.7856 8.15091C28.477 7.57338 27.0722 7.26537 25.6482 7.20762L21.0873 7.01511C20.51 6.99586 19.9519 6.99586 19.3746 7.01511L14.8138 7.20762C13.3897 7.26537 11.9849 7.59263 10.6763 8.1509C9.40623 8.70917 8.57874 9.97971 8.57874 11.385L8.79042 33.7158L9.01589 40.1552C8.94851 40.0237 8.82311 39.9255 8.67487 39.8958L5.99995 39.4145L6.03844 39.607L6.11541 40.0498C6.23088 40.6273 6.65425 41.0701 7.21232 41.2049L9.06778 41.6373L9.17529 44.7079L8.55949 51.2146L8.521 55.4305C8.521 57.9524 9.54093 60.3587 11.3499 62.072Z" fill="#FF5200"/>
        <g style="mix-blend-mode:overlay" opacity="0.15">
        <path d="M11.1171 47.2668L11.0979 47.3438C11.0979 47.3438 11.3288 55.0441 11.8099 57.0654C12.2333 58.8365 14.2154 61.9358 20.0271 61.9551C27.3976 61.9551 28.3983 58.336 28.8216 56.5649C29.3027 54.5436 28.8601 47.3438 28.8601 47.3438L28.8409 47.2668L11.1171 47.2668Z" fill="white"/>
        </g>
        <g style="mix-blend-mode:overlay" opacity="0.15">
        <path d="M12.6376 25.5137C14.4658 25.3789 16.294 25.2634 18.1222 25.1287C21.182 24.9169 24.2418 24.7244 27.3208 24.5896L27.3593 22.9726C27.3593 22.9726 28.8604 19.7385 28.8604 19.5845C28.8604 19.4305 27.3208 10.4404 27.3208 10.4404C25.7621 9.24687 21.9325 8.82335 19.9696 8.6886C18.0067 8.82335 14.1579 9.22761 12.6184 10.4404C12.6184 10.4404 11.0789 19.4305 11.0789 19.5845C11.0789 19.7385 12.5799 22.9726 12.5799 22.9726L12.6376 25.5137Z" fill="white"/>
        </g>
        <path d="M11.0423 48.0569C11.0423 48.0569 14.1791 51.5798 20.1063 51.4258C26.0527 51.5798 29.1895 48.0569 29.1895 48.0569L27.3998 40.1256C25.2637 40.9534 20.4527 41.1267 20.1063 41.1267C19.7791 41.1267 14.9681 40.9534 12.8128 40.1256L11.0423 48.0569Z" fill="#231F20"/>
        <path opacity="0.11" d="M11.6198 47.8839L11.6391 47.7876L13.2171 40.7997C13.9676 41.0114 14.8721 41.1847 15.7765 41.3002C17.9319 41.5889 20.0872 41.6467 20.1257 41.6467C20.1642 41.6467 22.0116 41.6082 23.9745 41.3579C24.6865 41.2809 25.3986 41.1654 26.0529 41.0307C26.38 40.9537 26.7071 40.8767 27.0151 40.7997L27.7271 43.9375L28.6123 47.8839C28.0735 48.4037 26.3415 49.8667 23.5319 50.5405C22.6274 50.7522 21.6075 50.9062 20.4913 50.9062C20.3759 50.9062 20.2604 50.9062 20.1449 50.9062L20.1257 50.9062C20.0102 50.9062 19.914 50.9062 19.8178 50.9062L19.7985 50.9062C19.1635 50.9062 18.5862 50.8677 18.0281 50.7907C14.4102 50.3095 12.2356 48.4807 11.6198 47.8839Z" fill="white"/>
        <path d="M11.8112 22.9161C11.2532 22.0883 10.6951 21.2605 10.137 20.4328L10.137 47.8457L12.3501 39.1637L12.4463 23.8401C12.2346 23.5321 12.0229 23.2241 11.8112 22.9161Z" fill="#231F20"/>
        <path opacity="0.11" d="M10.7339 23.0127L10.7339 42.9756L11.7153 39.1062L11.8115 24.6297C11.8115 24.6105 11.0802 23.5324 10.7339 23.0127Z" fill="white"/>
        <path d="M12.0615 32.0979L10.4065 31.4048L10.6182 32.8486L11.8306 32.7524L12.0615 32.0979Z" fill="#231F20"/>
        <path d="M28.2838 22.9161C28.8419 22.0883 29.3999 21.2606 29.958 20.4328L29.958 47.8457L27.7449 39.1637L27.6487 23.8401C27.8604 23.5321 28.0721 23.2241 28.2838 22.9161Z" fill="#231F20"/>
        <path opacity="0.11" d="M29.3418 23.0127L29.3418 42.9756L28.3604 39.1062L28.2641 24.6297C28.2641 24.6105 28.9954 23.5324 29.3418 23.0127Z" fill="white"/>
        <path d="M28.0335 32.0979L29.6885 31.4048L29.4575 32.8486L28.2644 32.7524L28.0335 32.0979Z" fill="#231F20"/>
        <path d="M10.4648 19.4893L12.6009 22.9736C14.3713 21.6453 25.7445 21.6453 27.4957 22.9736L29.6318 19.4893L29.016 16.9674C28.4002 14.3878 26.0909 12.5783 23.4545 12.5783L16.6614 12.5783C14.0249 12.5783 11.7157 14.3878 11.0998 16.9674L10.4648 19.4893Z" fill="#231F20"/>
        <path opacity="0.11" d="M11.043 19.3924L11.601 17.0823C12.1206 14.907 13.9681 13.3477 16.1426 13.1359C16.3158 13.1167 16.4698 13.1167 16.643 13.1167L23.4361 13.1167C23.744 13.1167 24.0519 13.1359 24.3406 13.1937C24.8794 13.2899 25.399 13.4632 25.8801 13.7327C26.7846 14.214 27.5351 14.9455 28.0162 15.8695C28.2086 16.2545 28.3818 16.6588 28.4781 17.0823L29.0361 19.3924L27.2849 22.2607C26.5537 21.972 25.4567 21.7795 24.3213 21.6447C23.0897 21.51 21.8196 21.4522 20.9536 21.4522C20.5495 21.4522 20.2224 21.433 20.0299 21.433C19.8375 21.433 19.5103 21.433 19.0677 21.4522C17.3743 21.4907 14.2567 21.6447 12.7749 22.2607L11.5818 20.2972L11.043 19.3924Z" fill="white"/>
        <g style="mix-blend-mode:overlay" opacity="0.2">
        <path d="M34 39.4126L31.3251 39.8939C31.1326 39.9324 30.9787 40.0864 30.9402 40.2789C30.8825 40.5869 30.6323 40.7986 30.3244 40.7986L29.9588 40.7986L29.9588 41.8574L30.8055 41.6649L31.7292 40.2596L33.9615 39.5858L34 39.4126Z" fill="black"/>
        </g>
        <g style="mix-blend-mode:overlay" opacity="0.2">
        <path d="M5.99977 39.4126L8.67469 39.8938C8.86713 39.9323 9.02108 40.0864 9.05957 40.2789C9.1173 40.5869 9.36748 40.7986 9.67538 40.7986L10.041 40.7986L10.041 41.8574L9.19428 41.6649L8.27057 40.2596L6.03826 39.5858L5.99977 39.4126Z" fill="black"/>
        </g>
        <path d="M10.388 59.2034L10.4265 58.7799L10.5035 57.8366C10.542 57.3553 11.1578 57.2013 11.4272 57.6056L13.1399 60.2237C13.2169 60.3392 13.2746 60.4354 13.3324 60.5509C13.6018 61.0514 13.7557 61.6097 13.8135 62.168C13.6788 62.168 13.5248 62.1487 13.3901 62.1102C12.4087 61.8407 11.5042 60.8204 10.9461 60.0696C10.5997 59.5884 10.388 59.2034 10.388 59.2034Z" fill="#F6F6F6"/>
        <path d="M10.4265 58.7799L10.5035 57.8366C10.542 57.3553 11.1578 57.2013 11.4272 57.6056L13.1399 60.2237C13.5248 60.8012 13.7557 61.4749 13.8135 62.168C13.6788 62.168 13.5248 62.1487 13.3901 62.1102C13.5825 61.9562 13.3709 61.0514 13.0437 60.6279C12.6781 60.1466 11.8891 59.1456 11.3695 58.4718C11.1386 58.1831 10.6959 58.2216 10.5227 58.5681L10.4265 58.7799Z" fill="#8C8984"/>
        <path d="M10.3877 59.2032L10.4262 58.7797L10.5032 57.8364C10.5417 57.3552 11.1575 57.2012 11.4269 57.6054L13.1396 60.2235C13.2166 60.339 13.2743 60.4353 13.332 60.5508C12.543 60.3775 11.7348 60.2235 10.9458 60.0503C10.5994 59.5882 10.3877 59.2032 10.3877 59.2032Z" fill="#DFDBE2"/>
        <path d="M29.9951 59.2034L29.9566 58.7799L29.8797 57.8366C29.8412 57.3553 29.2254 57.2013 28.9559 57.6056L27.2432 60.2237C27.1662 60.3392 27.1085 60.4354 27.0508 60.5509C26.7814 61.0514 26.6274 61.6097 26.5697 62.168C26.7044 62.168 26.8583 62.1487 26.9931 62.1102C27.9745 61.8407 28.879 60.8204 29.437 60.0696C29.7834 59.5884 29.9951 59.2034 29.9951 59.2034Z" fill="#F6F6F6"/>
        <path d="M29.958 58.7799L29.881 57.8366C29.8425 57.3553 29.2267 57.2013 28.9573 57.6056L27.2446 60.2237C26.8597 60.8012 26.6288 61.4749 26.5711 62.168C26.7058 62.168 26.8597 62.1487 26.9944 62.1102C26.802 61.9562 27.0137 61.0514 27.3408 60.6279C27.7065 60.1466 28.4955 59.1456 29.0151 58.4718C29.246 58.1831 29.6886 58.2216 29.8618 58.5681L29.958 58.7799Z" fill="#8C8984"/>
        <path d="M29.9961 59.2032L29.9576 58.7797L29.8806 57.8364C29.8421 57.3552 29.2263 57.2012 28.9569 57.6054L27.2442 60.2235C27.1672 60.339 27.1095 60.4353 27.0518 60.5508C27.8408 60.3775 28.649 60.2235 29.438 60.0503C29.7844 59.5882 29.9961 59.2032 29.9961 59.2032Z" fill="#DFDBE2"/>
        <g style="mix-blend-mode:overlay" opacity="0.3">
        <path d="M25.8408 39.4904C25.8408 39.4904 19.0284 41.1267 13.9288 39.4134L13.8518 25.6155L15.1219 26.6165L16.8154 36.4487C16.8539 36.4295 21.9535 39.6251 25.8408 39.4904Z" fill="white"/>
        </g>
        <path d="M11.7153 8.57415C12.3119 8.36239 13.1009 8.20839 14.1016 8.18914L13.1971 9.36343C13.1971 9.36343 12.4273 9.57518 11.5998 9.90244C10.8493 10.2105 10.0218 10.634 9.63695 11.1152C9.65619 11.1152 9.52149 9.38267 11.7153 8.57415Z" fill="#FF3838"/>
        <path d="M11.7153 8.57405C12.3119 8.3623 13.1009 8.20829 14.1016 8.18904L13.1971 9.36333C13.1971 9.36333 12.4273 9.57508 11.5998 9.90234C11.6383 9.86384 11.6768 9.80609 11.7345 9.76759C11.85 9.63284 11.9847 9.49808 12.0425 9.32483C12.1387 9.03607 11.9655 8.72806 11.7153 8.57405Z" fill="#DCDCDC"/>
        <path d="M28.9787 8.57414C28.3821 8.36238 27.5931 8.20838 26.5924 8.18913L27.4969 9.36342C27.4969 9.36342 28.2666 9.57518 29.0941 9.90244C29.8446 10.2104 30.6721 10.634 31.057 11.1152C31.057 11.1152 31.1917 9.38267 28.9787 8.57414Z" fill="#FF3838"/>
        <path d="M28.9773 8.57404C28.3807 8.36228 27.5917 8.20827 26.5911 8.18902L27.4955 9.36332C27.4955 9.36332 28.2653 9.57508 29.0928 9.90234C29.0543 9.86384 29.0158 9.80609 28.9581 9.76759C28.8426 9.63284 28.7079 9.49808 28.6502 9.32482C28.5732 9.03606 28.7464 8.72804 28.9773 8.57404Z" fill="#DCDCDC"/>
        <path opacity="0.11" d="M19.7788 50.9065L26.0523 41.0694C26.3795 40.9924 26.7066 40.9154 27.0145 40.8384L27.7266 43.9763L23.5314 50.56C22.6269 50.7718 21.607 50.9258 20.4908 50.9258C20.3753 50.9258 20.2599 50.9258 20.1444 50.9258L20.1252 50.9258C19.9905 50.9065 19.8942 50.9065 19.7788 50.9065Z" fill="white"/>
        <path opacity="0.11" d="M11.6209 47.8829L11.6402 47.7867L15.7776 41.3184C17.933 41.6072 20.0883 41.6649 20.1268 41.6649C20.1653 41.6649 22.0127 41.6264 23.9756 41.3762L17.9907 50.7705C14.4113 50.3085 12.2367 48.4797 11.6209 47.8829Z" fill="white"/>
        <path opacity="0.11" d="M20.9706 21.4523L25.8971 13.7328C26.8016 14.2141 27.5521 14.9456 28.0332 15.8696L24.3383 21.6641C23.1067 21.5101 21.8366 21.4716 20.9706 21.4523Z" fill="white"/>
        <path opacity="0.11" d="M11.5811 20.2972L16.1419 13.1359C16.3151 13.1167 16.469 13.1167 16.6422 13.1167L23.4354 13.1167C23.7433 13.1167 24.0512 13.1359 24.3398 13.1937L19.0862 21.4522C17.3928 21.4907 14.2752 21.6447 12.7934 22.2607L11.5811 20.2972Z" fill="white"/>
        <g style="mix-blend-mode:overlay" opacity="0.3">
        <path d="M16.7188 60.377C16.7188 60.377 13.2548 55.0638 12.1387 49.5773C12.1387 49.5773 12.716 57.2391 13.4665 58.4519C14.217 59.6839 16.7188 60.377 16.7188 60.377Z" fill="white"/>
        </g>
        <g style="mix-blend-mode:overlay" opacity="0.3">
        <path d="M23.377 60.377C23.377 60.377 26.8409 55.0638 27.957 49.5773C27.957 49.5773 27.3797 57.2391 26.6292 58.4519C25.8787 59.6647 23.377 60.377 23.377 60.377Z" fill="white"/>
        </g>
        <path d="M14.1783 61.0124C14.5055 61.6477 14.6787 62.6102 14.6787 62.6102C15.3714 63.669 19.4704 63.8423 20.1824 63.8038C20.8945 63.8423 25.1282 63.669 25.8209 62.6102C25.8209 62.6102 25.9941 61.6477 26.3213 61.0124C26.3213 61.0124 24.724 62.5525 20.2594 62.5332C15.7948 62.5525 14.1783 61.0124 14.1783 61.0124Z" fill="#231F20"/>
        <path opacity="0.11" d="M25.3594 62.1872C25.3401 62.2642 25.3209 62.3412 25.3209 62.3989C24.8205 62.9187 22.2996 63.3037 20.3367 63.3037C20.279 63.3037 20.2405 63.3037 20.202 63.3037L20.1635 63.3037C20.125 63.3037 20.0865 63.3037 20.0288 63.3037C18.2968 63.3037 15.6989 62.9572 15.1793 62.3989C15.16 62.3412 15.16 62.2834 15.1408 62.1872C16.1415 62.6299 17.7772 63.0727 20.202 63.0727L20.2982 63.0727C22.7229 63.0727 24.3587 62.6107 25.3594 62.1872Z" fill="white"/>
        <g style="mix-blend-mode:overlay" opacity="0.15">
        <path d="M24.6853 8.32381C24.6853 8.32381 19.932 7.61153 15.3712 8.3623L15.2942 7.82329C15.2942 7.82329 20.5479 6.7645 24.8008 7.82329L24.6853 8.32381Z" fill="white"/>
        </g>
        <defs>
        <filter id="filter0_f" x="0.572266" y="0.248047" width="38.4594" height="71.752" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="3.5" result="effect1_foregroundBlur"/>
        </filter>
        </defs>
        </svg>
      `;
    }

    if (service == 'logbook' && direction == 'down') {
      return `<svg width="39" height="72" viewBox="0 0 39 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.3" filter="url(#filter0_f)">
        <path d="M31.4803 55.6972C31.4991 58.1998 30.5196 60.6061 28.749 62.3386C27.3551 63.7054 25.5656 64.5524 23.6631 64.7834C21.252 65.0722 18.8033 65.0722 16.3922 64.7834C14.4897 64.5332 12.7003 63.6862 11.3064 62.3194C9.55457 60.5868 8.55623 58.1998 8.57507 55.6972L8.61274 51.4813L9.21551 44.9746L9.12133 42.1063L8.70693 42.0101C8.16067 41.8753 7.74626 41.4325 7.63325 40.855L7.5579 40.4123L7.53906 40.2198L9.06482 40.4893L8.83878 33.9633L8.63158 11.6327C8.61274 10.2274 9.42271 8.95691 10.6848 8.39864C11.9656 7.82113 13.3407 7.51312 14.7346 7.45537L19.1989 7.26286C19.764 7.24361 20.3102 7.24361 20.8753 7.26286L25.3396 7.45537C26.7335 7.51312 28.1085 7.84038 29.3894 8.39864C30.6326 8.95691 31.4426 10.2274 31.4426 11.6327L31.2354 33.9633L30.8587 44.9554L31.4614 51.4621L31.4803 55.6972Z" fill="#020947"/>
        </g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2361 62.071C12.63 63.4378 14.4194 64.2848 16.3219 64.5159C18.733 64.8046 21.1629 64.8046 23.574 64.5159C25.4765 64.2848 27.2659 63.4378 28.6598 62.071C30.4305 60.3385 31.41 57.9514 31.3911 55.4296L31.3535 51.2137L30.7507 44.7071L30.8597 41.5272L32.2196 41.2034C32.7658 41.0686 33.1802 40.6258 33.2932 40.0483L33.3686 39.6056L33.4063 39.4131L30.9164 39.8707L31.1274 33.715L31.3346 11.3844C31.3535 9.97911 30.5435 8.70858 29.2814 8.15031C28.0006 7.5728 26.6255 7.26478 25.2316 7.20703L20.7673 7.01453C20.2023 6.99528 19.656 6.99528 19.0909 7.01453L14.6266 7.20703C13.2327 7.26478 11.8577 7.59205 10.5768 8.15031C9.33359 8.70857 8.52362 9.97911 8.52362 11.3844L8.73082 33.715L8.95146 40.1527C8.88538 40.0217 8.7629 39.9239 8.61818 39.8943L5.9999 39.4131L6.03758 39.6056L6.11292 40.0483C6.22594 40.6258 6.64034 41.0686 7.1866 41.2034L9.00229 41.6357L9.10755 44.7071L8.50478 51.2137L8.46711 55.4296C8.46711 57.9514 9.46544 60.3577 11.2361 62.071Z" fill="#231F20"/>
        <g style="mix-blend-mode:overlay" opacity="0.15">
        <path d="M11.6032 47.2679L11.5844 47.3449C11.5844 47.3449 11.8104 55.0451 12.2813 57.0664C12.6958 58.8375 14.6359 61.9368 20.3245 61.9561C27.5389 61.9561 28.5184 58.337 28.9328 56.5659C29.4037 54.5446 28.9705 47.3449 28.9705 47.3449L28.9517 47.2679L11.6032 47.2679Z" fill="white"/>
        </g>
        <g style="mix-blend-mode:overlay" opacity="0.15">
        <path d="M13.0915 25.5156C14.881 25.3809 16.6705 25.2654 18.4599 25.1306C21.4549 24.9189 24.4499 24.7264 27.4638 24.5916L27.5015 22.9746C27.5015 22.9746 28.9707 19.7405 28.9707 19.5865C28.9707 19.4325 27.4638 10.4425 27.4638 10.4425C25.938 9.24893 22.1896 8.82542 20.2682 8.69066C18.3469 8.82542 14.5796 9.22968 13.0727 10.4425C13.0727 10.4425 11.5658 19.4325 11.5658 19.5865C11.5658 19.7405 13.035 22.9746 13.035 22.9746L13.0915 25.5156Z" fill="white"/>
        </g>
        <path d="M11.5292 48.0569C11.5292 48.0569 14.5995 51.5798 20.4012 51.4258C26.2216 51.5798 29.292 48.0569 29.292 48.0569L27.5402 40.1257C25.4493 40.9535 20.7402 41.1267 20.4012 41.1267C20.0809 41.1267 15.3718 40.9535 13.2621 40.1257L11.5292 48.0569Z" fill="#918F8F"/>
        <path opacity="0.11" d="M12.0939 47.8829L12.1128 47.7867L13.6574 40.7987C14.392 41.0105 15.2773 41.1838 16.1626 41.2993C18.2723 41.588 20.382 41.6458 20.4197 41.6458C20.4573 41.6458 22.2656 41.6073 24.187 41.357C24.8839 41.28 25.5809 41.1645 26.2213 41.0298C26.5415 40.9528 26.8617 40.8757 27.1631 40.7987L27.8601 43.9366L28.7266 47.8829C28.1991 48.4027 26.5039 49.8657 23.7537 50.5395C22.8684 50.7513 21.8701 50.9053 20.7776 50.9053C20.6645 50.9053 20.5515 50.9053 20.4385 50.9053L20.4197 50.9053C20.3066 50.9053 20.2125 50.9053 20.1183 50.9053L20.0994 50.9053C19.4778 50.9053 18.9127 50.8668 18.3665 50.7898C14.8252 50.3085 12.6967 48.4797 12.0939 47.8829Z" fill="white"/>
        <path d="M12.2817 22.9163C11.7355 22.0885 11.1892 21.2607 10.6429 20.4329L10.6429 47.8457L12.8091 39.1637L12.9033 23.8403C12.6961 23.5323 12.4889 23.2243 12.2817 22.9163Z" fill="#918F8F"/>
        <path opacity="0.11" d="M11.2284 23.0118L11.2284 42.9746L12.189 39.1053L12.2832 24.6289C12.2832 24.6096 11.5674 23.5316 11.2284 23.0118Z" fill="white"/>
        <path d="M12.5273 32.0988L10.9074 31.4058L11.1146 32.8496L12.3013 32.7534L12.5273 32.0988Z" fill="#020947"/>
        <path d="M28.4052 22.9163C28.9514 22.0885 29.4977 21.2607 30.0439 20.4329L30.0439 47.8457L27.8777 39.1637L27.7836 23.8403C27.9908 23.5323 28.198 23.2243 28.4052 22.9163Z" fill="#918F8F"/>
        <path opacity="0.11" d="M29.4404 23.0118L29.4404 42.9746L28.4798 39.1053L28.3856 24.6289C28.3856 24.6096 29.1014 23.5316 29.4404 23.0118Z" fill="white"/>
        <path d="M28.1603 32.0988L29.7803 31.4058L29.5542 32.8496L28.3864 32.7534L28.1603 32.0988Z" fill="#020947"/>
        <path d="M10.9625 19.4893L13.0533 22.9736C14.7863 21.6453 25.9187 21.6453 27.6328 22.9736L29.7236 19.4893L29.1209 16.9675C28.5181 14.3879 26.2577 12.5783 23.6771 12.5783L17.0278 12.5783C14.4472 12.5783 12.1868 14.3879 11.5841 16.9675L10.9625 19.4893Z" fill="#918F8F"/>
        <path opacity="0.11" d="M11.5275 19.3934L12.0738 17.0833C12.5824 14.908 14.3907 13.3487 16.5192 13.137C16.6887 13.1177 16.8394 13.1177 17.0089 13.1177L23.6582 13.1177C23.9596 13.1177 24.261 13.137 24.5435 13.1947C25.071 13.291 25.5796 13.4642 26.0505 13.7337C26.9358 14.215 27.6704 14.9465 28.1413 15.8705C28.3297 16.2556 28.4992 16.6598 28.5934 17.0833L29.1396 19.3934L27.4255 22.2617C26.7097 21.973 25.6361 21.7805 24.5247 21.6457C23.3192 21.5109 22.076 21.4532 21.2283 21.4532C20.8328 21.4532 20.5125 21.4339 20.3242 21.4339C20.1358 21.4339 19.8156 21.4339 19.3823 21.4532C17.7247 21.4917 14.6732 21.6457 13.2228 22.2617L12.0549 20.2982L11.5275 19.3934Z" fill="white"/>
        <g style="mix-blend-mode:overlay" opacity="0.2">
        <path d="M34 39.4126L31.3817 39.8939C31.1934 39.9324 31.0427 40.0864 31.005 40.2789C30.9485 40.5869 30.7036 40.7986 30.4022 40.7986L30.0443 40.7986L30.0443 41.8574L30.8731 41.6649L31.7773 40.2596L33.9623 39.5859L34 39.4126Z" fill="black"/>
        </g>
        <g style="mix-blend-mode:overlay" opacity="0.2">
        <path d="M6.59316 39.4126L9.21144 39.8939C9.3998 39.9324 9.55049 40.0864 9.58817 40.2789C9.64468 40.5869 9.88955 40.7986 10.1909 40.7986L10.5488 40.7986L10.5488 41.8574L9.72002 41.6649L8.81587 40.2596L6.63084 39.5859L6.59316 39.4126Z" fill="black"/>
        </g>
        <path d="M10.8873 59.2034L10.925 58.7799L11.0004 57.8366C11.038 57.3553 11.6408 57.2013 11.9045 57.6056L13.581 60.2237C13.6563 60.3392 13.7128 60.4354 13.7693 60.5509C14.033 61.0514 14.1837 61.6097 14.2402 62.168C14.1084 62.168 13.9577 62.1487 13.8258 62.1102C12.8652 61.8407 11.9799 60.8204 11.4336 60.0697C11.0945 59.5884 10.8873 59.2034 10.8873 59.2034Z" fill="#F6F6F6"/>
        <path d="M10.925 58.7799L11.0004 57.8366C11.038 57.3553 11.6408 57.2013 11.9045 57.6056L13.581 60.2237C13.9577 60.8012 14.1837 61.4749 14.2402 62.168C14.1084 62.168 13.9577 62.1487 13.8258 62.1102C14.0142 61.9562 13.807 61.0514 13.4868 60.6279C13.1289 60.1467 12.3566 59.1456 11.848 58.4719C11.622 58.1831 11.1887 58.2216 11.0192 58.5681L10.925 58.7799Z" fill="#8C8984"/>
        <path d="M10.8866 59.2032L10.9242 58.7797L10.9996 57.8365C11.0373 57.3552 11.64 57.2012 11.9037 57.6054L13.5802 60.2235C13.6555 60.339 13.712 60.4353 13.7686 60.5508C12.9963 60.3775 12.2051 60.2235 11.4328 60.0503C11.0938 59.5883 10.8866 59.2032 10.8866 59.2032Z" fill="#DFDBE2"/>
        <path d="M30.0801 59.2024L30.0424 58.7789L29.9671 57.8356C29.9294 57.3544 29.3266 57.2004 29.0629 57.6046L27.3865 60.2227C27.3111 60.3382 27.2546 60.4344 27.1981 60.5499C26.9344 61.0505 26.7837 61.6087 26.7272 62.167C26.859 62.167 27.0097 62.1477 27.1416 62.1092C28.1022 61.8397 28.9876 60.8195 29.5338 60.0687C29.8729 59.5874 30.0801 59.2024 30.0801 59.2024Z" fill="#F6F6F6"/>
        <path d="M30.0439 58.7789L29.9686 57.8356C29.9309 57.3544 29.3282 57.2004 29.0644 57.6046L27.388 60.2227C27.0113 60.8002 26.7852 61.474 26.7287 62.167C26.8606 62.167 27.0113 62.1477 27.1431 62.1092C26.9548 61.9552 27.162 61.0505 27.4822 60.6269C27.8401 60.1457 28.6124 59.1447 29.121 58.4709C29.347 58.1821 29.7802 58.2206 29.9498 58.5671L30.0439 58.7789Z" fill="#8C8984"/>
        <path d="M30.0811 59.2023L30.0434 58.7788L29.968 57.8355C29.9304 57.3542 29.3276 57.2002 29.0639 57.6045L27.3874 60.2225C27.3121 60.338 27.2556 60.4343 27.1991 60.5498C27.9714 60.3765 28.7625 60.2225 29.5348 60.0493C29.8739 59.5873 30.0811 59.2023 30.0811 59.2023Z" fill="#DFDBE2"/>
        <g style="mix-blend-mode:overlay" opacity="0.3">
        <path d="M26.0127 39.4904C26.0127 39.4904 19.3446 41.1267 14.3529 39.4134L14.2776 25.6156L15.5208 26.6166L17.1784 36.4488C17.216 36.4295 22.2077 39.6251 26.0127 39.4904Z" fill="white"/>
        </g>
        <path d="M12.1877 8.57416C12.7716 8.3624 13.5439 8.2084 14.5234 8.18915L13.6381 9.36343C13.6381 9.36343 12.8847 9.57519 12.0747 9.90244C11.3401 10.2105 10.5301 10.634 10.1534 11.1152C10.1722 11.1152 10.0404 9.38268 12.1877 8.57416Z" fill="#FD515F"/>
        <path d="M12.1877 8.57309C12.7716 8.36133 13.5439 8.20733 14.5234 8.18808L13.6381 9.36236C13.6381 9.36236 12.8847 9.57411 12.0747 9.90137C12.1124 9.86287 12.15 9.80512 12.2065 9.76662C12.3196 9.63186 12.4514 9.49711 12.5079 9.32385C12.6021 9.0351 12.4326 8.72709 12.1877 8.57309Z" fill="#DCDCDC"/>
        <path d="M28.2307 8.57416C27.6468 8.3624 26.8745 8.2084 25.895 8.18915L26.7803 9.36343C26.7803 9.36343 27.5338 9.57519 28.3437 9.90245C29.0783 10.2105 29.8883 10.634 30.265 11.1152C30.265 11.1152 30.3969 9.38268 28.2307 8.57416Z" fill="#FD515F"/>
        <path d="M28.2298 8.57308C27.6458 8.36133 26.8735 8.20732 25.894 8.18807L26.7793 9.36236C26.7793 9.36236 27.5328 9.57411 28.3428 9.90137C28.3051 9.86287 28.2674 9.80512 28.2109 9.76662C28.0979 9.63186 27.966 9.49711 27.9095 9.32385C27.8342 9.03509 28.0037 8.72709 28.2298 8.57308Z" fill="#DCDCDC"/>
        <path opacity="0.11" d="M20.0809 50.9065L26.2216 41.0695C26.5418 40.9925 26.862 40.9155 27.1634 40.8385L27.8604 43.9763L23.754 50.56C22.8687 50.7718 21.8703 50.9258 20.7778 50.9258C20.6648 50.9258 20.5518 50.9258 20.4388 50.9258L20.4199 50.9258C20.2881 50.9065 20.1939 50.9065 20.0809 50.9065Z" fill="white"/>
        <path opacity="0.11" d="M12.0935 47.8839L12.1123 47.7877L16.1622 41.3195C18.2719 41.6082 20.3816 41.666 20.4192 41.666C20.4569 41.666 22.2652 41.6275 24.1865 41.3772L18.3284 50.7715C14.8248 50.3095 12.6963 48.4807 12.0935 47.8839Z" fill="white"/>
        <path opacity="0.11" d="M21.2481 21.4533L26.0703 13.7338C26.9556 14.2151 27.6902 14.9466 28.1611 15.8706L24.5445 21.665C23.339 21.511 22.0958 21.4725 21.2481 21.4533Z" fill="white"/>
        <path opacity="0.11" d="M12.0553 20.2982L16.5196 13.137C16.6891 13.1177 16.8398 13.1177 17.0093 13.1177L23.6586 13.1177C23.96 13.1177 24.2614 13.137 24.5439 13.1947L19.4016 21.4532C17.744 21.4917 14.6925 21.6457 13.242 22.2617L12.0553 20.2982Z" fill="white"/>
        <g style="mix-blend-mode:overlay" opacity="0.3">
        <path d="M17.0859 60.377C17.0859 60.377 13.6954 55.0638 12.6029 49.5774C12.6029 49.5774 13.1679 57.2391 13.9026 58.4519C14.6372 59.6839 17.0859 60.377 17.0859 60.377Z" fill="white"/>
        </g>
        <g style="mix-blend-mode:overlay" opacity="0.3">
        <path d="M23.6028 60.377C23.6028 60.377 26.9934 55.0638 28.0859 49.5774C28.0859 49.5774 27.5208 57.2391 26.7862 58.4519C26.0516 59.6647 23.6028 60.377 23.6028 60.377Z" fill="white"/>
        </g>
        <path d="M14.5976 61.0124C14.9178 61.6477 15.0873 62.6102 15.0873 62.6102C15.7654 63.669 19.7776 63.8423 20.4746 63.8038C21.1715 63.8423 25.3155 63.669 25.9936 62.6102C25.9936 62.6102 26.1632 61.6477 26.4834 61.0124C26.4834 61.0124 24.92 62.5525 20.5499 62.5332C16.1798 62.5525 14.5976 61.0124 14.5976 61.0124Z" fill="#918F8F"/>
        <path opacity="0.11" d="M25.542 62.1872C25.5232 62.2642 25.5043 62.3412 25.5043 62.3989C25.0146 62.9187 22.547 63.3037 20.6257 63.3037C20.5692 63.3037 20.5315 63.3037 20.4938 63.3037L20.4561 63.3037C20.4185 63.3037 20.3808 63.3037 20.3243 63.3037C18.629 63.3037 16.0861 62.9572 15.5775 62.3989C15.5586 62.3412 15.5586 62.2834 15.5398 62.1872C16.5193 62.6299 18.1204 63.0727 20.4938 63.0727L20.588 63.0727C22.9614 63.0727 24.5625 62.6107 25.542 62.1872Z" fill="white"/>
        <g style="mix-blend-mode:overlay" opacity="0.15">
        <path d="M24.8831 8.32381C24.8831 8.32381 20.2305 7.61153 15.7662 8.3623L15.6909 7.82329C15.6909 7.82329 20.8332 6.7645 24.9961 7.82329L24.8831 8.32381Z" fill="white"/>
        </g>
        <defs>
        <filter id="filter0_f" x="0.539062" y="0.248047" width="37.9415" height="71.7516" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="3.5" result="effect1_foregroundBlur"/>
        </filter>
        </defs>
        </svg>      
      `;
    }

    if (service == 'express' && direction == 'left') {
      return `<svg width="72" height="40" viewBox="0 0 72 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.3" filter="url(#filter0_f)">
        <path d="M55.6971 7.96859C58.1997 7.94934 60.606 8.95003 62.3386 10.759C63.7054 12.183 64.5524 14.0112 64.7834 15.9549C65.0722 18.4181 65.0722 20.9198 64.7834 23.383C64.5332 25.3267 63.6861 27.1549 62.3193 28.5789C60.5868 30.3686 58.1997 31.3886 55.6971 31.3693L51.4812 31.3308L44.9745 30.715L42.1062 30.8112L42.0099 31.2346C41.8751 31.7927 41.4324 32.2161 40.8549 32.3315L40.4121 32.4085L40.2196 32.4277L40.4891 30.869L33.9631 31.0999L11.6324 31.3116C10.2271 31.3308 8.95652 30.5033 8.39825 29.214C7.82073 27.9054 7.51273 26.5006 7.45497 25.0765L7.26247 20.5157C7.24321 19.9384 7.24321 19.3803 7.26247 18.803L7.45497 14.2421C7.51273 12.8181 7.83998 11.4133 8.39825 10.1047C8.95652 8.83457 10.2271 8.00708 11.6324 8.00708L33.9631 8.21876L44.9553 8.60364L51.462 7.98783L55.6971 7.96859Z" fill="#020947"/>
        </g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M62.072 28.6501C63.4388 27.2261 64.2858 25.3979 64.5168 23.4542C64.8056 20.991 64.8056 18.5085 64.5168 16.0453C64.2858 14.1017 63.4388 12.2735 62.072 10.8494C60.3394 9.04048 57.9524 8.03979 55.4305 8.05903L51.2146 8.09752L44.7079 8.71333L41.5285 8.60201L41.2046 7.21237C41.0698 6.6543 40.627 6.23093 40.0495 6.11546L39.6068 6.03849L39.4143 6L39.872 8.544L33.7158 8.32845L11.385 8.11676C9.97971 8.09752 8.70917 8.92501 8.15091 10.2144C7.57338 11.523 7.26537 12.9278 7.20762 14.3518L7.01511 18.9127C6.99586 19.49 6.99586 20.0481 7.01511 20.6254L7.20762 25.1862C7.26537 26.6103 7.59264 28.0151 8.1509 29.3237C8.70917 30.5938 9.97971 31.4213 11.385 31.4213L33.7158 31.2096L40.1552 30.9841C40.0237 31.0515 39.9255 31.1769 39.8958 31.3251L39.4145 34.0001L39.607 33.9616L40.0498 33.8846C40.6273 33.7691 41.0701 33.3458 41.2049 32.7877L41.6373 30.9322L44.7079 30.8247L51.2146 31.4405L55.4305 31.479C57.9524 31.479 60.3587 30.4591 62.072 28.6501Z" fill="#FF5200"/>
        <g style="mix-blend-mode:overlay" opacity="0.15">
        <path d="M47.2668 28.8829L47.3438 28.9021C47.3438 28.9021 55.0441 28.6712 57.0654 28.1901C58.8365 27.7667 61.9358 25.7846 61.9551 19.9729C61.9551 12.6024 58.336 11.6017 56.5649 11.1784C54.5436 10.6973 47.3438 11.1399 47.3438 11.1399L47.2668 11.1591L47.2668 28.8829Z" fill="white"/>
        </g>
        <g style="mix-blend-mode:overlay" opacity="0.15">
        <path d="M25.5137 27.3624C25.3789 25.5342 25.2634 23.706 25.1287 21.8778C24.9169 18.818 24.7244 15.7582 24.5896 12.6792L22.9726 12.6407C22.9726 12.6407 19.7385 11.1396 19.5845 11.1396C19.4305 11.1396 10.4404 12.6792 10.4404 12.6792C9.24687 14.2379 8.82335 18.0675 8.6886 20.0304C8.82335 21.9933 9.22761 25.8421 10.4404 27.3816C10.4404 27.3816 19.4305 28.9211 19.5845 28.9211C19.7385 28.9211 22.9726 27.4201 22.9726 27.4201L25.5137 27.3624Z" fill="white"/>
        </g>
        <path d="M48.0569 28.9577C48.0569 28.9577 51.5798 25.8209 51.4258 19.8937C51.5798 13.9473 48.0569 10.8105 48.0569 10.8105L40.1256 12.6002C40.9534 14.7363 41.1267 19.5473 41.1267 19.8937C41.1267 20.2209 40.9534 25.0319 40.1256 27.1872L48.0569 28.9577Z" fill="#231F20"/>
        <path opacity="0.11" d="M47.8839 28.3802L47.7876 28.3609L40.7997 26.7829C41.0114 26.0324 41.1847 25.1279 41.3002 24.2235C41.5889 22.0681 41.6467 19.9128 41.6467 19.8743C41.6467 19.8358 41.6082 17.9884 41.3579 16.0255C41.2809 15.3135 41.1654 14.6014 41.0307 13.9471C40.9537 13.62 40.8767 13.2929 40.7997 12.9849L43.9375 12.2729L47.8839 11.3877C48.4037 11.9265 49.8667 13.6585 50.5405 16.4681C50.7522 17.3726 50.9062 18.3925 50.9062 19.5087C50.9062 19.6241 50.9062 19.7396 50.9062 19.8551L50.9062 19.8743C50.9062 19.9898 50.9062 20.086 50.9062 20.1822L50.9062 20.2015C50.9062 20.8365 50.8677 21.4138 50.7907 21.9719C50.3095 25.5898 48.4807 27.7644 47.8839 28.3802Z" fill="white"/>
        <path d="M22.9161 28.1888C22.0883 28.7468 21.2605 29.3049 20.4328 29.863L47.8457 29.863L39.1637 27.6499L23.8401 27.5537C23.5321 27.7654 23.2241 27.9771 22.9161 28.1888Z" fill="#231F20"/>
        <path opacity="0.11" d="M23.0127 29.2661L42.9756 29.2661L39.1062 28.2847L24.6297 28.1885C24.6105 28.1885 23.5324 28.9198 23.0127 29.2661Z" fill="white"/>
        <path d="M32.0979 27.9385L31.4048 29.5935L32.8486 29.3818L32.7524 28.1694L32.0979 27.9385Z" fill="#231F20"/>
        <path d="M22.9161 11.7162C22.0883 11.1581 21.2606 10.6001 20.4328 10.042L47.8457 10.042L39.1637 12.2551L23.8401 12.3513C23.5321 12.1396 23.2241 11.9279 22.9161 11.7162Z" fill="#231F20"/>
        <path opacity="0.11" d="M23.0127 10.6582L42.9756 10.6582L39.1062 11.6396L24.6297 11.7359C24.6105 11.7359 23.5324 11.0046 23.0127 10.6582Z" fill="white"/>
        <path d="M32.0979 11.9665L31.4048 10.3115L32.8486 10.5425L32.7524 11.7356L32.0979 11.9665Z" fill="#231F20"/>
        <path d="M19.4893 29.5352L22.9736 27.3991C21.6453 25.6287 21.6453 14.2555 22.9736 12.5043L19.4893 10.3682L16.9674 10.984C14.3878 11.5998 12.5783 13.9091 12.5783 16.5455L12.5783 23.3386C12.5783 25.9751 14.3878 28.2843 16.9674 28.9002L19.4893 29.5352Z" fill="#231F20"/>
        <path opacity="0.11" d="M19.3924 28.957L17.0823 28.399C14.907 27.8794 13.3477 26.0319 13.1359 23.8574C13.1167 23.6842 13.1167 23.5302 13.1167 23.357L13.1167 16.5639C13.1167 16.256 13.1359 15.9481 13.1937 15.6594C13.2899 15.1206 13.4632 14.601 13.7327 14.1199C14.214 13.2154 14.9455 12.4649 15.8695 11.9838C16.2545 11.7914 16.6588 11.6182 17.0823 11.5219L19.3924 10.9639L22.2607 12.7151C21.972 13.4463 21.7795 14.5433 21.6447 15.6787C21.51 16.9103 21.4522 18.1804 21.4522 19.0464C21.4522 19.4505 21.433 19.7776 21.433 19.9701C21.433 20.1625 21.433 20.4897 21.4522 20.9323C21.4907 22.6257 21.6447 25.7433 22.2607 27.2251L20.2972 28.4182L19.3924 28.957Z" fill="white"/>
        <g style="mix-blend-mode:overlay" opacity="0.2">
        <path d="M39.4126 6L39.8939 8.67492C39.9324 8.86736 40.0864 9.02131 40.2789 9.0598C40.5869 9.11753 40.7986 9.3677 40.7986 9.67561L40.7986 10.0412L41.8574 10.0412L41.6649 9.19451L40.2596 8.27079L39.5858 6.03849L39.4126 6Z" fill="black"/>
        </g>
        <g style="mix-blend-mode:overlay" opacity="0.2">
        <path d="M39.4126 34.0002L39.8938 31.3253C39.9323 31.1329 40.0864 30.9789 40.2789 30.9404C40.5869 30.8827 40.7986 30.6325 40.7986 30.3246L40.7986 29.959L41.8574 29.959L41.6649 30.8057L40.2596 31.7294L39.5858 33.9617L39.4126 34.0002Z" fill="black"/>
        </g>
        <path d="M59.2034 29.612L58.7799 29.5735L57.8366 29.4965C57.3553 29.458 57.2013 28.8422 57.6056 28.5728L60.2237 26.8601C60.3392 26.7831 60.4354 26.7254 60.5509 26.6676C61.0514 26.3982 61.6097 26.2443 62.168 26.1865C62.168 26.3212 62.1487 26.4752 62.1102 26.6099C61.8407 27.5913 60.8204 28.4958 60.0696 29.0539C59.5884 29.4003 59.2034 29.612 59.2034 29.612Z" fill="#F6F6F6"/>
        <path d="M58.7799 29.5735L57.8366 29.4965C57.3553 29.458 57.2013 28.8422 57.6056 28.5728L60.2237 26.8601C60.8012 26.4752 61.4749 26.2443 62.168 26.1865C62.168 26.3212 62.1487 26.4752 62.1102 26.6099C61.9562 26.4175 61.0514 26.6291 60.6279 26.9563C60.1466 27.3219 59.1456 28.1109 58.4718 28.6305C58.1831 28.8614 58.2216 29.3041 58.5681 29.4773L58.7799 29.5735Z" fill="#8C8984"/>
        <path d="M59.2032 29.6123L58.7797 29.5738L57.8364 29.4968C57.3552 29.4583 57.2012 28.8425 57.6054 28.5731L60.2235 26.8604C60.339 26.7834 60.4353 26.7257 60.5508 26.668C60.3775 27.457 60.2235 28.2652 60.0503 29.0542C59.5882 29.4006 59.2032 29.6123 59.2032 29.6123Z" fill="#DFDBE2"/>
        <path d="M59.2034 10.0049L58.7799 10.0434L57.8366 10.1203C57.3553 10.1588 57.2013 10.7746 57.6056 11.0441L60.2237 12.7568C60.3392 12.8338 60.4354 12.8915 60.5509 12.9492C61.0514 13.2186 61.6097 13.3726 62.168 13.4303C62.168 13.2956 62.1487 13.1417 62.1102 13.0069C61.8407 12.0255 60.8204 11.121 60.0696 10.563C59.5884 10.2166 59.2034 10.0049 59.2034 10.0049Z" fill="#F6F6F6"/>
        <path d="M58.7799 10.042L57.8366 10.119C57.3553 10.1575 57.2013 10.7733 57.6056 11.0427L60.2237 12.7554C60.8012 13.1403 61.4749 13.3712 62.168 13.4289C62.168 13.2942 62.1487 13.1403 62.1102 13.0056C61.9562 13.198 61.0514 12.9863 60.6279 12.6592C60.1466 12.2935 59.1456 11.5045 58.4718 10.9849C58.1831 10.754 58.2216 10.3114 58.5681 10.1382L58.7799 10.042Z" fill="#8C8984"/>
        <path d="M59.2032 10.0039L58.7797 10.0424L57.8364 10.1194C57.3552 10.1579 57.2012 10.7737 57.6054 11.0431L60.2235 12.7558C60.339 12.8328 60.4353 12.8905 60.5508 12.9482C60.3775 12.1592 60.2235 11.351 60.0503 10.562C59.5882 10.2156 59.2032 10.0039 59.2032 10.0039Z" fill="#DFDBE2"/>
        <g style="mix-blend-mode:overlay" opacity="0.3">
        <path d="M39.4904 14.1592C39.4904 14.1592 41.1267 20.9716 39.4134 26.0712L25.6155 26.1482L26.6165 24.8781L36.4487 23.1846C36.4295 23.1461 39.6251 18.0465 39.4904 14.1592Z" fill="white"/>
        </g>
        <path d="M8.57415 28.2847C8.36239 27.6881 8.20839 26.8991 8.18914 25.8984L9.36343 26.8029C9.36343 26.8029 9.57518 27.5727 9.90244 28.4002C10.2105 29.1507 10.634 29.9782 11.1152 30.3631C11.1152 30.3438 9.38267 30.4785 8.57415 28.2847Z" fill="#FF3838"/>
        <path d="M8.57405 28.2847C8.3623 27.6881 8.20829 26.8991 8.18904 25.8984L9.36333 26.8029C9.36333 26.8029 9.57508 27.5727 9.90234 28.4002C9.86384 28.3617 9.80609 28.3232 9.76759 28.2655C9.63284 28.15 9.49808 28.0153 9.32483 27.9575C9.03607 27.8613 8.72806 28.0345 8.57405 28.2847Z" fill="#DCDCDC"/>
        <path d="M8.57414 11.0213C8.36238 11.6179 8.20838 12.4069 8.18913 13.4076L9.36342 12.5031C9.36342 12.5031 9.57518 11.7334 9.90244 10.9059C10.2104 10.1554 10.634 9.32787 11.1152 8.94299C11.1152 8.94299 9.38267 8.80828 8.57414 11.0213Z" fill="#FF3838"/>
        <path d="M8.57404 11.0227C8.36228 11.6193 8.20827 12.4083 8.18902 13.4089L9.36332 12.5045C9.36332 12.5045 9.57508 11.7347 9.90234 10.9072C9.86384 10.9457 9.80609 10.9842 9.76759 11.0419C9.63284 11.1574 9.49808 11.2921 9.32482 11.3498C9.03606 11.4268 8.72804 11.2536 8.57404 11.0227Z" fill="#DCDCDC"/>
        <path opacity="0.11" d="M50.9065 20.2212L41.0694 13.9477C40.9924 13.6205 40.9154 13.2934 40.8384 12.9855L43.9763 12.2734L50.56 16.4686C50.7718 17.3731 50.9258 18.393 50.9258 19.5092C50.9258 19.6247 50.9258 19.7401 50.9258 19.8556L50.9258 19.8748C50.9065 20.0095 50.9065 20.1058 50.9065 20.2212Z" fill="white"/>
        <path opacity="0.11" d="M47.8829 28.3791L47.7867 28.3598L41.3184 24.2224C41.6072 22.067 41.6649 19.9117 41.6649 19.8732C41.6649 19.8347 41.6264 17.9873 41.3762 16.0244L50.7705 22.0093C50.3085 25.5887 48.4797 27.7633 47.8829 28.3791Z" fill="white"/>
        <path opacity="0.11" d="M21.4523 19.0294L13.7328 14.1029C14.2141 13.1984 14.9456 12.4479 15.8696 11.9668L21.6641 15.6617C21.5101 16.8933 21.4716 18.1634 21.4523 19.0294Z" fill="white"/>
        <path opacity="0.11" d="M20.2972 28.4189L13.1359 23.8581C13.1167 23.6849 13.1167 23.531 13.1167 23.3578L13.1167 16.5646C13.1167 16.2567 13.1359 15.9488 13.1937 15.6602L21.4522 20.9138C21.4907 22.6072 21.6447 25.7248 22.2607 27.2066L20.2972 28.4189Z" fill="white"/>
        <g style="mix-blend-mode:overlay" opacity="0.3">
        <path d="M60.377 23.2812C60.377 23.2812 55.0638 26.7452 49.5773 27.8613C49.5773 27.8613 57.2391 27.284 58.4519 26.5335C59.6839 25.783 60.377 23.2812 60.377 23.2812Z" fill="white"/>
        </g>
        <g style="mix-blend-mode:overlay" opacity="0.3">
        <path d="M60.377 16.623C60.377 16.623 55.0638 13.1591 49.5773 12.043C49.5773 12.043 57.2391 12.6203 58.4519 13.3708C59.6647 14.1213 60.377 16.623 60.377 16.623Z" fill="white"/>
        </g>
        <path d="M61.0124 25.8217C61.6477 25.4945 62.6102 25.3213 62.6102 25.3213C63.669 24.6286 63.8423 20.5296 63.8038 19.8176C63.8423 19.1055 63.669 14.8718 62.6102 14.1791C62.6102 14.1791 61.6477 14.0059 61.0124 13.6787C61.0124 13.6787 62.5525 15.276 62.5332 19.7406C62.5525 24.2052 61.0124 25.8217 61.0124 25.8217Z" fill="#231F20"/>
        <path opacity="0.11" d="M62.1872 14.6406C62.2642 14.6599 62.3412 14.6791 62.3989 14.6791C62.9187 15.1795 63.3037 17.7004 63.3037 19.6633C63.3037 19.721 63.3037 19.7595 63.3037 19.798L63.3037 19.8365C63.3037 19.875 63.3037 19.9135 63.3037 19.9712C63.3037 21.7032 62.9572 24.3011 62.3989 24.8207C62.3412 24.84 62.2834 24.84 62.1872 24.8592C62.6299 23.8585 63.0727 22.2228 63.0727 19.798L63.0727 19.7018C63.0727 17.2771 62.6107 15.6413 62.1872 14.6406Z" fill="white"/>
        <g style="mix-blend-mode:overlay" opacity="0.15">
        <path d="M8.32381 15.3147C8.32381 15.3147 7.61153 20.068 8.3623 24.6288L7.82329 24.7058C7.82329 24.7058 6.7645 19.4521 7.82329 15.1992L8.32381 15.3147Z" fill="white"/>
        </g>
        <defs>
        <filter id="filter0_f" x="0.248047" y="0.96875" width="71.752" height="38.4594" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="3.5" result="effect1_foregroundBlur"/>
        </filter>
        </defs>
        </svg>         
      `;
    }

    if (service == 'logbook' && direction == 'left') {
      return `<svg width="72" height="39" viewBox="0 0 72 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.3" filter="url(#filter0_f)">
        <path d="M55.6972 7.51974C58.1998 7.5009 60.6061 8.4804 62.3386 10.251C63.7054 11.6449 64.5524 13.4344 64.7834 15.3369C65.0722 17.748 65.0722 20.1967 64.7834 22.6078C64.5332 24.5103 63.6862 26.2997 62.3194 27.6936C60.5868 29.4454 58.1998 30.4438 55.6972 30.4249L51.4813 30.3873L44.9746 29.7845L42.1063 29.8787L42.0101 30.2931C41.8753 30.8393 41.4325 31.2537 40.855 31.3668L40.4123 31.4421L40.2198 31.4609L40.4893 29.9352L33.9633 30.1612L11.6327 30.3684C10.2274 30.3873 8.95691 29.5773 8.39864 28.3152C7.82113 27.0344 7.51312 25.6593 7.45537 24.2654L7.26286 19.8011C7.24361 19.236 7.24361 18.6898 7.26286 18.1247L7.45537 13.6604C7.51312 12.2665 7.84038 10.8915 8.39864 9.61059C8.95691 8.36739 10.2274 7.55742 11.6327 7.55742L33.9633 7.76462L44.9554 8.14134L51.4621 7.53858L55.6972 7.51974Z" fill="#020947"/>
        </g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M62.071 27.7639C63.4378 26.37 64.2848 24.5806 64.5159 22.6781C64.8046 20.267 64.8046 17.8371 64.5159 15.426C64.2848 13.5235 63.4378 11.7341 62.071 10.3402C60.3385 8.56953 57.9514 7.59003 55.4296 7.60887L51.2137 7.64654L44.7071 8.24931L41.5272 8.14032L41.2034 6.78045C41.0686 6.23419 40.6258 5.81979 40.0483 5.70677L39.6056 5.63142L39.4131 5.59375L39.8707 8.08355L33.715 7.87258L11.3844 7.66538C9.97911 7.64654 8.70858 8.45651 8.15031 9.71855C7.5728 10.9994 7.26478 12.3745 7.20703 13.7684L7.01453 18.2327C6.99528 18.7977 6.99528 19.344 7.01453 19.9091L7.20703 24.3734C7.26478 25.7673 7.59205 27.1423 8.15031 28.4232C8.70858 29.6664 9.97911 30.4764 11.3844 30.4764L33.715 30.2692L40.1527 30.0485C40.0217 30.1146 39.9239 30.2371 39.8943 30.3818L39.4131 33.0001L39.6056 32.9624L40.0483 32.8871C40.6258 32.7741 41.0686 32.3597 41.2034 31.8134L41.6357 29.9977L44.7071 29.8925L51.2137 30.4952L55.4296 30.5329C57.9514 30.5329 60.3577 29.5346 62.071 27.7639Z" fill="#231F20"/>
        <g style="mix-blend-mode:overlay" opacity="0.15">
        <path d="M47.2679 27.3968L47.3449 27.4156C47.3449 27.4156 55.0451 27.1896 57.0664 26.7187C58.8375 26.3042 61.9368 24.3641 61.9561 18.6755C61.9561 11.4611 58.337 10.4816 56.5659 10.0672C54.5446 9.59627 47.3449 10.0295 47.3449 10.0295L47.2679 10.0483L47.2679 27.3968Z" fill="white"/>
        </g>
        <g style="mix-blend-mode:overlay" opacity="0.15">
        <path d="M25.5156 25.9085C25.3809 24.119 25.2654 22.3295 25.1306 20.5401C24.9189 17.5451 24.7264 14.5501 24.5916 11.5362L22.9746 11.4985C22.9746 11.4985 19.7405 10.0293 19.5865 10.0293C19.4325 10.0293 10.4425 11.5362 10.4425 11.5362C9.24893 13.062 8.82542 16.8104 8.69066 18.7318C8.82542 20.6531 9.22968 24.4204 10.4425 25.9273C10.4425 25.9273 19.4325 27.4342 19.5865 27.4342C19.7405 27.4342 22.9746 25.965 22.9746 25.965L25.5156 25.9085Z" fill="white"/>
        </g>
        <path d="M48.0569 27.4708C48.0569 27.4708 51.5798 24.4005 51.4258 18.5988C51.5798 12.7784 48.0569 9.70801 48.0569 9.70801L40.1257 11.4598C40.9535 13.5507 41.1267 18.2598 41.1267 18.5988C41.1267 18.9191 40.9535 23.6282 40.1257 25.7379L48.0569 27.4708Z" fill="#918F8F"/>
        <path opacity="0.11" d="M47.8829 26.9061L47.7867 26.8872L40.7987 25.3426C41.0105 24.608 41.1838 23.7227 41.2993 22.8374C41.588 20.7277 41.6458 18.618 41.6458 18.5803C41.6458 18.5427 41.6073 16.7344 41.357 14.813C41.28 14.1161 41.1645 13.4191 41.0298 12.7787C40.9528 12.4585 40.8757 12.1383 40.7987 11.8369L43.9366 11.1399L47.8829 10.2734C48.4027 10.8009 49.8657 12.4961 50.5395 15.2463C50.7513 16.1316 50.9053 17.1299 50.9053 18.2224C50.9053 18.3355 50.9053 18.4485 50.9053 18.5615L50.9053 18.5803C50.9053 18.6934 50.9053 18.7875 50.9053 18.8817L50.9053 18.9006C50.9053 19.5222 50.8668 20.0873 50.7898 20.6335C50.3085 24.1748 48.4797 26.3033 47.8829 26.9061Z" fill="white"/>
        <path d="M22.9163 26.7183C22.0885 27.2645 21.2607 27.8108 20.4329 28.3571L47.8457 28.3571L39.1637 26.1909L23.8403 26.0967C23.5323 26.3039 23.2243 26.5111 22.9163 26.7183Z" fill="#918F8F"/>
        <path opacity="0.11" d="M23.0118 27.7716L42.9746 27.7716L39.1053 26.811L24.6289 26.7168C24.6096 26.7168 23.5316 27.4326 23.0118 27.7716Z" fill="white"/>
        <path d="M32.0988 26.4727L31.4058 28.0926L32.8496 27.8854L32.7534 26.6987L32.0988 26.4727Z" fill="#020947"/>
        <path d="M22.9163 10.5948C22.0885 10.0486 21.2607 9.50231 20.4329 8.95605L47.8457 8.95605L39.1637 11.1223L23.8403 11.2164C23.5323 11.0092 23.2243 10.802 22.9163 10.5948Z" fill="#918F8F"/>
        <path opacity="0.11" d="M23.0118 9.55957L42.9746 9.55957L39.1053 10.5202L24.6289 10.6144C24.6096 10.6144 23.5316 9.89863 23.0118 9.55957Z" fill="white"/>
        <path d="M32.0988 10.8397L31.4058 9.21973L32.8496 9.44576L32.7534 10.6136L32.0988 10.8397Z" fill="#020947"/>
        <path d="M19.4893 28.0375L22.9736 25.9467C21.6453 24.2137 21.6453 13.0813 22.9736 11.3672L19.4893 9.27637L16.9675 9.87914C14.3879 10.4819 12.5783 12.7423 12.5783 15.3229L12.5783 21.9722C12.5783 24.5528 14.3879 26.8132 16.9675 27.4159L19.4893 28.0375Z" fill="#918F8F"/>
        <path opacity="0.11" d="M19.3934 27.4725L17.0833 26.9262C14.908 26.4176 13.3487 24.6093 13.137 22.4808C13.1177 22.3113 13.1177 22.1606 13.1177 21.9911L13.1177 15.3418C13.1177 15.0404 13.137 14.739 13.1947 14.4565C13.291 13.929 13.4642 13.4204 13.7337 12.9495C14.215 12.0642 14.9465 11.3296 15.8705 10.8587C16.2556 10.6703 16.6598 10.5008 17.0833 10.4066L19.3934 9.86035L22.2617 11.5745C21.973 12.2903 21.7805 13.3639 21.6457 14.4753C21.5109 15.6808 21.4532 16.924 21.4532 17.7717C21.4532 18.1672 21.4339 18.4875 21.4339 18.6758C21.4339 18.8642 21.4339 19.1844 21.4532 19.6177C21.4917 21.2753 21.6457 24.3268 22.2617 25.7772L20.2982 26.9451L19.3934 27.4725Z" fill="white"/>
        <g style="mix-blend-mode:overlay" opacity="0.2">
        <path d="M39.4126 5L39.8939 7.61827C39.9324 7.80664 40.0864 7.95733 40.2789 7.995C40.5869 8.05151 40.7986 8.29639 40.7986 8.59777L40.7986 8.95567L41.8574 8.95567L41.6649 8.12686L40.2596 7.22271L39.5859 5.03767L39.4126 5Z" fill="black"/>
        </g>
        <g style="mix-blend-mode:overlay" opacity="0.2">
        <path d="M39.4126 32.4068L39.8939 29.7886C39.9324 29.6002 40.0864 29.4495 40.2789 29.4118C40.5869 29.3553 40.7986 29.1104 40.7986 28.8091L40.7986 28.4512L41.8574 28.4512L41.6649 29.28L40.2596 30.1841L39.5859 32.3692L39.4126 32.4068Z" fill="black"/>
        </g>
        <path d="M59.2034 28.1127L58.7799 28.075L57.8366 27.9996C57.3553 27.962 57.2013 27.3592 57.6056 27.0955L60.2237 25.419C60.3392 25.3437 60.4354 25.2872 60.5509 25.2307C61.0514 24.967 61.6097 24.8163 62.168 24.7598C62.168 24.8916 62.1487 25.0423 62.1102 25.1742C61.8407 26.1348 60.8204 27.0201 60.0697 27.5664C59.5884 27.9055 59.2034 28.1127 59.2034 28.1127Z" fill="#F6F6F6"/>
        <path d="M58.7799 28.075L57.8366 27.9996C57.3553 27.962 57.2013 27.3592 57.6056 27.0955L60.2237 25.419C60.8012 25.0423 61.4749 24.8163 62.168 24.7598C62.168 24.8916 62.1487 25.0423 62.1102 25.1742C61.9562 24.9858 61.0514 25.193 60.6279 25.5132C60.1467 25.8711 59.1456 26.6434 58.4719 27.152C58.1831 27.378 58.2216 27.8113 58.5681 27.9808L58.7799 28.075Z" fill="#8C8984"/>
        <path d="M59.2032 28.1134L58.7797 28.0758L57.8365 28.0004C57.3552 27.9627 57.2012 27.36 57.6054 27.0963L60.2235 25.4198C60.339 25.3445 60.4353 25.288 60.5508 25.2314C60.3775 26.0037 60.2235 26.7949 60.0503 27.5672C59.5883 27.9062 59.2032 28.1134 59.2032 28.1134Z" fill="#DFDBE2"/>
        <path d="M59.2024 8.91992L58.7789 8.95759L57.8356 9.03294C57.3544 9.07061 57.2004 9.67338 57.6046 9.93709L60.2227 11.6135C60.3382 11.6889 60.4344 11.7454 60.5499 11.8019C61.0505 12.0656 61.6087 12.2163 62.167 12.2728C62.167 12.141 62.1477 11.9903 62.1092 11.8584C61.8397 10.8978 60.8195 10.0124 60.0687 9.46618C59.5874 9.12712 59.2024 8.91992 59.2024 8.91992Z" fill="#F6F6F6"/>
        <path d="M58.7789 8.95605L57.8356 9.0314C57.3544 9.06907 57.2004 9.67184 57.6046 9.93555L60.2227 11.612C60.8002 11.9887 61.474 12.2148 62.167 12.2713C62.167 12.1394 62.1477 11.9887 62.1092 11.8569C61.9552 12.0452 61.0505 11.838 60.6269 11.5178C60.1457 11.1599 59.1447 10.3876 58.4709 9.87904C58.1821 9.65301 58.2206 9.21977 58.5671 9.05024L58.7789 8.95605Z" fill="#8C8984"/>
        <path d="M59.2023 8.91895L58.7788 8.95662L57.8355 9.03196C57.3542 9.06964 57.2002 9.6724 57.6045 9.93612L60.2225 11.6126C60.338 11.6879 60.4343 11.7444 60.5498 11.8009C60.3765 11.0286 60.2225 10.2375 60.0493 9.4652C59.5873 9.12615 59.2023 8.91895 59.2023 8.91895Z" fill="#DFDBE2"/>
        <g style="mix-blend-mode:overlay" opacity="0.3">
        <path d="M39.4904 12.9873C39.4904 12.9873 41.1267 19.6554 39.4134 24.6471L25.6156 24.7224L26.6166 23.4792L36.4488 21.8216C36.4295 21.784 39.6251 16.7923 39.4904 12.9873Z" fill="white"/>
        </g>
        <path d="M8.57416 26.8123C8.3624 26.2284 8.2084 25.4561 8.18915 24.4766L9.36343 25.3619C9.36343 25.3619 9.57519 26.1153 9.90244 26.9253C10.2105 27.6599 10.634 28.4699 11.1152 28.8466C11.1152 28.8278 9.38268 28.9596 8.57416 26.8123Z" fill="#FD515F"/>
        <path d="M8.57309 26.8123C8.36133 26.2284 8.20733 25.4561 8.18808 24.4766L9.36236 25.3619C9.36236 25.3619 9.57411 26.1153 9.90137 26.9253C9.86287 26.8876 9.80512 26.85 9.76662 26.7935C9.63186 26.6804 9.49711 26.5486 9.32385 26.4921C9.0351 26.3979 8.72709 26.5674 8.57309 26.8123Z" fill="#DCDCDC"/>
        <path d="M8.57416 10.7693C8.3624 11.3532 8.2084 12.1255 8.18915 13.105L9.36343 12.2197C9.36343 12.2197 9.57519 11.4662 9.90245 10.6563C10.2105 9.92165 10.634 9.11168 11.1152 8.73495C11.1152 8.73495 9.38268 8.6031 8.57416 10.7693Z" fill="#FD515F"/>
        <path d="M8.57308 10.7702C8.36133 11.3542 8.20732 12.1265 8.18807 13.106L9.36236 12.2207C9.36236 12.2207 9.57411 11.4672 9.90137 10.6572C9.86287 10.6949 9.80512 10.7326 9.76662 10.7891C9.63186 10.9021 9.49711 11.034 9.32385 11.0905C9.03509 11.1658 8.72709 10.9963 8.57308 10.7702Z" fill="#DCDCDC"/>
        <path opacity="0.11" d="M50.9065 18.9191L41.0695 12.7784C40.9925 12.4582 40.9155 12.138 40.8385 11.8366L43.9763 11.1396L50.56 15.246C50.7718 16.1313 50.9258 17.1297 50.9258 18.2222C50.9258 18.3352 50.9258 18.4482 50.9258 18.5612L50.9258 18.5801C50.9065 18.7119 50.9065 18.8061 50.9065 18.9191Z" fill="white"/>
        <path opacity="0.11" d="M47.8839 26.9065L47.7877 26.8877L41.3195 22.8378C41.6082 20.7281 41.666 18.6184 41.666 18.5808C41.666 18.5431 41.6275 16.7348 41.3772 14.8135L50.7715 20.6716C50.3095 24.1752 48.4807 26.3037 47.8839 26.9065Z" fill="white"/>
        <path opacity="0.11" d="M21.4533 17.7519L13.7338 12.9297C14.2151 12.0444 14.9466 11.3098 15.8706 10.8389L21.665 14.4555C21.511 15.661 21.4725 16.9042 21.4533 17.7519Z" fill="white"/>
        <path opacity="0.11" d="M20.2982 26.9447L13.137 22.4804C13.1177 22.3109 13.1177 22.1602 13.1177 21.9907L13.1177 15.3414C13.1177 15.04 13.137 14.7386 13.1947 14.4561L21.4532 19.5984C21.4917 21.256 21.6457 24.3075 22.2617 25.758L20.2982 26.9447Z" fill="white"/>
        <g style="mix-blend-mode:overlay" opacity="0.3">
        <path d="M60.377 21.9141C60.377 21.9141 55.0638 25.3046 49.5774 26.3971C49.5774 26.3971 57.2391 25.8321 58.4519 25.0974C59.6839 24.3628 60.377 21.9141 60.377 21.9141Z" fill="white"/>
        </g>
        <g style="mix-blend-mode:overlay" opacity="0.3">
        <path d="M60.377 15.3972C60.377 15.3972 55.0638 12.0066 49.5774 10.9141C49.5774 10.9141 57.2391 11.4792 58.4519 12.2138C59.6647 12.9484 60.377 15.3972 60.377 15.3972Z" fill="white"/>
        </g>
        <path d="M61.0124 24.4024C61.6477 24.0822 62.6102 23.9127 62.6102 23.9127C63.669 23.2346 63.8423 19.2224 63.8038 18.5254C63.8423 17.8285 63.669 13.6845 62.6102 13.0064C62.6102 13.0064 61.6477 12.8368 61.0124 12.5166C61.0124 12.5166 62.5525 14.08 62.5332 18.4501C62.5525 22.8202 61.0124 24.4024 61.0124 24.4024Z" fill="#918F8F"/>
        <path opacity="0.11" d="M62.1872 13.458C62.2642 13.4768 62.3412 13.4957 62.3989 13.4957C62.9187 13.9854 63.3037 16.453 63.3037 18.3743C63.3037 18.4308 63.3037 18.4685 63.3037 18.5062L63.3037 18.5439C63.3037 18.5815 63.3037 18.6192 63.3037 18.6757C63.3037 20.371 62.9572 22.9139 62.3989 23.4225C62.3412 23.4414 62.2834 23.4414 62.1872 23.4602C62.6299 22.4807 63.0727 20.8796 63.0727 18.5062L63.0727 18.412C63.0727 16.0386 62.6107 14.4375 62.1872 13.458Z" fill="white"/>
        <g style="mix-blend-mode:overlay" opacity="0.15">
        <path d="M8.32381 14.1169C8.32381 14.1169 7.61153 18.7695 8.3623 23.2338L7.82329 23.3091C7.82329 23.3091 6.7645 18.1668 7.82329 14.0039L8.32381 14.1169Z" fill="white"/>
        </g>
        <defs>
        <filter id="filter0_f" x="0.248047" y="0.519531" width="71.7516" height="37.9415" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="3.5" result="effect1_foregroundBlur"/>
        </filter>
        </defs>
        </svg>
      `;
    }

    if (service == 'express' && direction == 'right') {
      return `<svg width="72" height="40" viewBox="0 0 72 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.3" filter="url(#filter0_f)">
        <path d="M16.3029 32.0314C13.8003 32.0507 11.394 31.05 9.66141 29.241C8.29461 27.817 7.44758 25.9888 7.21657 24.0451C6.92781 21.5819 6.92781 19.0802 7.21657 16.617C7.46683 14.6733 8.31386 12.8451 9.68065 11.4211C11.4132 9.63137 13.8003 8.61144 16.3029 8.63069L20.5188 8.66917L27.0255 9.28498L29.8938 9.18876L29.9901 8.76539C30.1249 8.20732 30.5676 7.78395 31.1451 7.66848L31.5879 7.59151L31.7804 7.57226L31.5109 9.13103L38.0369 8.9001L60.3676 8.68841C61.7729 8.66917 63.0435 9.49666 63.6017 10.786C64.1793 12.0946 64.4873 13.4994 64.545 14.9235L64.7375 19.4843C64.7568 20.0616 64.7568 20.6197 64.7375 21.197L64.545 25.7579C64.4873 27.1819 64.16 28.5867 63.6017 29.8953C63.0435 31.1654 61.7729 31.9929 60.3676 31.9929L38.0369 31.7812L27.0448 31.3964L20.538 32.0122L16.3029 32.0314Z" fill="#020947"/>
        </g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.928 11.3499C8.56121 12.7739 7.71418 14.6021 7.48317 16.5458C7.19441 19.009 7.19441 21.4915 7.48317 23.9547C7.71418 25.8983 8.56121 27.7265 9.92801 29.1506C11.6606 30.9595 14.0476 31.9602 16.5695 31.941L20.7854 31.9025L27.2921 31.2867L30.4715 31.398L30.7954 32.7876C30.9302 33.3457 31.373 33.7691 31.9505 33.8845L32.3932 33.9615L32.5857 34L32.128 31.456L38.2842 31.6715L60.615 31.8832C62.0203 31.9025 63.2908 31.075 63.8491 29.7856C64.4266 28.477 64.7346 27.0722 64.7924 25.6482L64.9849 21.0873C65.0041 20.51 65.0041 19.9519 64.9849 19.3746L64.7924 14.8138C64.7346 13.3897 64.4074 11.9849 63.8491 10.6763C63.2908 9.40622 62.0203 8.57873 60.615 8.57873L38.2842 8.79041L31.8448 9.01588C31.9763 8.94851 32.0745 8.8231 32.1042 8.67486L32.5855 5.99995L32.3929 6.03844L31.9502 6.11541C31.3727 6.23087 30.9299 6.65424 30.7951 7.21232L30.3627 9.06778L27.2921 9.17529L20.7854 8.55949L16.5695 8.521C14.0476 8.521 11.6413 9.54093 9.928 11.3499Z" fill="#FF5200"/>
        <g style="mix-blend-mode:overlay" opacity="0.15">
        <path d="M24.7332 11.1171L24.6562 11.0979C24.6562 11.0979 16.9559 11.3288 14.9346 11.8099C13.1635 12.2333 10.0642 14.2154 10.0449 20.0271C10.0449 27.3976 13.664 28.3983 15.4351 28.8216C17.4564 29.3027 24.6562 28.8601 24.6562 28.8601L24.7332 28.8409L24.7332 11.1171Z" fill="white"/>
        </g>
        <g style="mix-blend-mode:overlay" opacity="0.15">
        <path d="M46.4863 12.6376C46.6211 14.4658 46.7366 16.294 46.8713 18.1222C47.0831 21.182 47.2756 24.2418 47.4104 27.3208L49.0274 27.3593C49.0274 27.3593 52.2615 28.8604 52.4155 28.8604C52.5695 28.8604 61.5596 27.3208 61.5596 27.3208C62.7531 25.7621 63.1766 21.9325 63.3114 19.9696C63.1766 18.0067 62.7724 14.1579 61.5596 12.6184C61.5596 12.6184 52.5695 11.0789 52.4155 11.0789C52.2615 11.0789 49.0274 12.5799 49.0274 12.5799L46.4863 12.6376Z" fill="white"/>
        </g>
        <path d="M23.9431 11.0423C23.9431 11.0423 20.4202 14.1791 20.5742 20.1063C20.4202 26.0527 23.9431 29.1895 23.9431 29.1895L31.8744 27.3998C31.0466 25.2637 30.8733 20.4527 30.8733 20.1063C30.8733 19.7791 31.0466 14.9681 31.8744 12.8128L23.9431 11.0423Z" fill="#231F20"/>
        <path opacity="0.11" d="M24.1161 11.6198L24.2124 11.6391L31.2003 13.2171C30.9886 13.9676 30.8153 14.8721 30.6998 15.7765C30.4111 17.9319 30.3533 20.0872 30.3533 20.1257C30.3533 20.1642 30.3918 22.0116 30.6421 23.9745C30.7191 24.6865 30.8346 25.3986 30.9693 26.0528C31.0463 26.38 31.1233 26.7071 31.2003 27.0151L28.0625 27.7271L24.1161 28.6123C23.5963 28.0735 22.1333 26.3415 21.4595 23.5319C21.2478 22.6274 21.0937 21.6075 21.0937 20.4913C21.0937 20.3759 21.0937 20.2604 21.0937 20.1449L21.0937 20.1257C21.0937 20.0102 21.0937 19.914 21.0937 19.8178L21.0937 19.7985C21.0937 19.1635 21.1323 18.5862 21.2093 18.0281C21.6905 14.4102 23.5193 12.2356 24.1161 11.6198Z" fill="white"/>
        <path d="M49.0839 11.8112C49.9117 11.2532 50.7395 10.6951 51.5672 10.137L24.1543 10.137L32.8363 12.3501L48.1599 12.4463C48.4679 12.2346 48.7759 12.0229 49.0839 11.8112Z" fill="#231F20"/>
        <path opacity="0.11" d="M48.9873 10.7339L29.0244 10.7339L32.8938 11.7153L47.3703 11.8115C47.3895 11.8115 48.4676 11.0802 48.9873 10.7339Z" fill="white"/>
        <path d="M39.9021 12.0615L40.5952 10.4065L39.1514 10.6182L39.2476 11.8306L39.9021 12.0615Z" fill="#231F20"/>
        <path d="M49.0839 28.2838C49.9117 28.8419 50.7394 29.3999 51.5672 29.958L24.1543 29.958L32.8363 27.7449L48.1599 27.6487C48.4679 27.8604 48.7759 28.0721 49.0839 28.2838Z" fill="#231F20"/>
        <path opacity="0.11" d="M48.9873 29.3418L29.0244 29.3418L32.8938 28.3604L47.3703 28.2641C47.3895 28.2641 48.4676 28.9954 48.9873 29.3418Z" fill="white"/>
        <path d="M39.9021 28.0335L40.5952 29.6885L39.1514 29.4575L39.2476 28.2644L39.9021 28.0335Z" fill="#231F20"/>
        <path d="M52.5107 10.4648L49.0264 12.6009C50.3547 14.3713 50.3547 25.7445 49.0264 27.4957L52.5107 29.6318L55.0326 29.016C57.6122 28.4002 59.4217 26.0909 59.4217 23.4545L59.4217 16.6614C59.4217 14.0249 57.6122 11.7157 55.0326 11.0998L52.5107 10.4648Z" fill="#231F20"/>
        <path opacity="0.11" d="M52.6076 11.043L54.9177 11.601C57.093 12.1206 58.6523 13.9681 58.8641 16.1426C58.8833 16.3158 58.8833 16.4698 58.8833 16.643L58.8833 23.4361C58.8833 23.744 58.8641 24.0519 58.8063 24.3406C58.7101 24.8794 58.5368 25.399 58.2673 25.8801C57.786 26.7846 57.0545 27.5351 56.1305 28.0162C55.7455 28.2086 55.3412 28.3818 54.9177 28.4781L52.6076 29.0361L49.7393 27.2849C50.028 26.5537 50.2205 25.4567 50.3553 24.3213C50.49 23.0897 50.5478 21.8196 50.5478 20.9536C50.5478 20.5495 50.567 20.2224 50.567 20.0299C50.567 19.8375 50.567 19.5103 50.5478 19.0677C50.5093 17.3743 50.3553 14.2567 49.7393 12.7749L51.7028 11.5818L52.6076 11.043Z" fill="white"/>
        <g style="mix-blend-mode:overlay" opacity="0.2">
        <path d="M32.5874 34L32.1061 31.3251C32.0676 31.1326 31.9136 30.9787 31.7211 30.9402C31.4131 30.8825 31.2014 30.6323 31.2014 30.3244L31.2014 29.9588L30.1426 29.9588L30.3351 30.8055L31.7404 31.7292L32.4142 33.9615L32.5874 34Z" fill="black"/>
        </g>
        <g style="mix-blend-mode:overlay" opacity="0.2">
        <path d="M32.5874 5.99977L32.1062 8.67469C32.0677 8.86713 31.9136 9.02108 31.7211 9.05957C31.4131 9.1173 31.2014 9.36748 31.2014 9.67538L31.2014 10.041L30.1426 10.041L30.3351 9.19428L31.7404 8.27057L32.4142 6.03826L32.5874 5.99977Z" fill="black"/>
        </g>
        <path d="M12.7966 10.388L13.2201 10.4265L14.1634 10.5035C14.6447 10.542 14.7987 11.1578 14.3944 11.4272L11.7763 13.1399C11.6608 13.2169 11.5646 13.2746 11.4491 13.3324C10.9486 13.6018 10.3903 13.7557 9.83203 13.8135C9.83203 13.6788 9.85128 13.5248 9.88978 13.3901C10.1593 12.4087 11.1796 11.5042 11.9304 10.9461C12.4116 10.5997 12.7966 10.388 12.7966 10.388Z" fill="#F6F6F6"/>
        <path d="M13.2201 10.4265L14.1634 10.5035C14.6447 10.542 14.7987 11.1578 14.3944 11.4272L11.7763 13.1399C11.1988 13.5248 10.5251 13.7557 9.83203 13.8135C9.83203 13.6788 9.85128 13.5248 9.88978 13.3901C10.0438 13.5825 10.9486 13.3709 11.3721 13.0437C11.8534 12.6781 12.8544 11.8891 13.5282 11.3695C13.8169 11.1386 13.7784 10.6959 13.4319 10.5227L13.2201 10.4265Z" fill="#8C8984"/>
        <path d="M12.7968 10.3877L13.2203 10.4262L14.1636 10.5032C14.6448 10.5417 14.7988 11.1575 14.3946 11.4269L11.7765 13.1396C11.661 13.2166 11.5647 13.2743 11.4492 13.332C11.6225 12.543 11.7765 11.7348 11.9497 10.9458C12.4118 10.5994 12.7968 10.3877 12.7968 10.3877Z" fill="#DFDBE2"/>
        <path d="M12.7966 29.9951L13.2201 29.9566L14.1634 29.8797C14.6447 29.8412 14.7987 29.2254 14.3944 28.9559L11.7763 27.2432C11.6608 27.1662 11.5646 27.1085 11.4491 27.0508C10.9486 26.7814 10.3903 26.6274 9.83203 26.5697C9.83203 26.7044 9.85128 26.8583 9.88978 26.9931C10.1593 27.9745 11.1796 28.879 11.9304 29.437C12.4116 29.7834 12.7966 29.9951 12.7966 29.9951Z" fill="#F6F6F6"/>
        <path d="M13.2201 29.958L14.1634 29.881C14.6447 29.8425 14.7987 29.2267 14.3944 28.9573L11.7763 27.2446C11.1988 26.8597 10.5251 26.6288 9.83203 26.5711C9.83203 26.7058 9.85128 26.8597 9.88978 26.9944C10.0438 26.802 10.9486 27.0137 11.3721 27.3408C11.8534 27.7065 12.8544 28.4955 13.5282 29.0151C13.8169 29.246 13.7784 29.6886 13.4319 29.8618L13.2201 29.958Z" fill="#8C8984"/>
        <path d="M12.7968 29.9961L13.2203 29.9576L14.1636 29.8806C14.6448 29.8421 14.7988 29.2263 14.3946 28.9569L11.7765 27.2442C11.661 27.1672 11.5647 27.1095 11.4492 27.0518C11.6225 27.8408 11.7765 28.649 11.9497 29.438C12.4118 29.7844 12.7968 29.9961 12.7968 29.9961Z" fill="#DFDBE2"/>
        <g style="mix-blend-mode:overlay" opacity="0.3">
        <path d="M32.5096 25.8408C32.5096 25.8408 30.8733 19.0284 32.5866 13.9288L46.3845 13.8518L45.3835 15.1219L35.5513 16.8154C35.5705 16.8539 32.3749 21.9535 32.5096 25.8408Z" fill="white"/>
        </g>
        <path d="M63.4259 11.7153C63.6376 12.3119 63.7916 13.1009 63.8109 14.1016L62.6366 13.1971C62.6366 13.1971 62.4248 12.4273 62.0976 11.5998C61.7895 10.8493 61.366 10.0218 60.8848 9.63695C60.8848 9.65619 62.6173 9.52148 63.4259 11.7153Z" fill="#FF3838"/>
        <path d="M63.4259 11.7153C63.6377 12.3119 63.7917 13.1009 63.811 14.1016L62.6367 13.1971C62.6367 13.1971 62.4249 12.4273 62.0977 11.5998C62.1362 11.6383 62.1939 11.6768 62.2324 11.7345C62.3672 11.85 62.5019 11.9847 62.6752 12.0425C62.9639 12.1387 63.2719 11.9655 63.4259 11.7153Z" fill="#DCDCDC"/>
        <path d="M63.4259 28.9787C63.6376 28.3821 63.7916 27.5931 63.8109 26.5924L62.6366 27.4969C62.6366 27.4969 62.4248 28.2666 62.0976 29.0941C61.7896 29.8446 61.366 30.6721 60.8848 31.057C60.8848 31.057 62.6173 31.1917 63.4259 28.9787Z" fill="#FF3838"/>
        <path d="M63.426 28.9773C63.6377 28.3807 63.7917 27.5917 63.811 26.5911L62.6367 27.4955C62.6367 27.4955 62.4249 28.2653 62.0977 29.0928C62.1362 29.0543 62.1939 29.0158 62.2324 28.9581C62.3672 28.8426 62.5019 28.7079 62.6752 28.6502C62.9639 28.5732 63.272 28.7464 63.426 28.9773Z" fill="#DCDCDC"/>
        <path opacity="0.11" d="M21.0935 19.7788L30.9306 26.0523C31.0076 26.3795 31.0846 26.7066 31.1616 27.0145L28.0237 27.7266L21.44 23.5314C21.2282 22.6269 21.0742 21.607 21.0742 20.4908C21.0742 20.3753 21.0742 20.2599 21.0742 20.1444L21.0742 20.1252C21.0935 19.9905 21.0935 19.8942 21.0935 19.7788Z" fill="white"/>
        <path opacity="0.11" d="M24.1171 11.6209L24.2133 11.6402L30.6816 15.7776C30.3928 17.933 30.3351 20.0883 30.3351 20.1268C30.3351 20.1653 30.3736 22.0127 30.6238 23.9756L21.2295 17.9907C21.6915 14.4113 23.5203 12.2367 24.1171 11.6209Z" fill="white"/>
        <path opacity="0.11" d="M50.5477 20.9706L58.2672 25.8971C57.7859 26.8016 57.0544 27.5521 56.1304 28.0332L50.3359 24.3383C50.4899 23.1067 50.5284 21.8366 50.5477 20.9706Z" fill="white"/>
        <path opacity="0.11" d="M51.7028 11.5811L58.8641 16.1419C58.8833 16.3151 58.8833 16.469 58.8833 16.6422L58.8833 23.4354C58.8833 23.7433 58.8641 24.0512 58.8063 24.3398L50.5478 19.0862C50.5093 17.3928 50.3553 14.2752 49.7393 12.7934L51.7028 11.5811Z" fill="white"/>
        <g style="mix-blend-mode:overlay" opacity="0.3">
        <path d="M11.623 16.7188C11.623 16.7188 16.9362 13.2548 22.4227 12.1387C22.4227 12.1387 14.7609 12.716 13.5481 13.4665C12.3161 14.217 11.623 16.7188 11.623 16.7188Z" fill="white"/>
        </g>
        <g style="mix-blend-mode:overlay" opacity="0.3">
        <path d="M11.623 23.377C11.623 23.377 16.9362 26.8409 22.4227 27.957C22.4227 27.957 14.7609 27.3797 13.5481 26.6292C12.3353 25.8787 11.623 23.377 11.623 23.377Z" fill="white"/>
        </g>
        <path d="M10.9876 14.1783C10.3523 14.5055 9.38978 14.6787 9.38978 14.6787C8.33099 15.3714 8.15773 19.4704 8.19624 20.1824C8.15773 20.8945 8.33099 25.1282 9.38978 25.8209C9.38978 25.8209 10.3523 25.9941 10.9876 26.3213C10.9876 26.3213 9.44753 24.724 9.46678 20.2594C9.44753 15.7948 10.9876 14.1783 10.9876 14.1783Z" fill="#231F20"/>
        <path opacity="0.11" d="M9.81283 25.3594C9.73582 25.3401 9.65882 25.3209 9.60107 25.3209C9.0813 24.8205 8.69629 22.2996 8.69629 20.3367C8.69629 20.279 8.69629 20.2405 8.69629 20.202L8.69629 20.1635C8.69629 20.125 8.69629 20.0865 8.69629 20.0288C8.69629 18.2968 9.0428 15.6989 9.60107 15.1793C9.65882 15.16 9.71657 15.16 9.81283 15.1408C9.37006 16.1415 8.9273 17.7772 8.9273 20.202L8.9273 20.2982C8.9273 22.7229 9.38931 24.3587 9.81283 25.3594Z" fill="white"/>
        <g style="mix-blend-mode:overlay" opacity="0.15">
        <path d="M63.6762 24.6853C63.6762 24.6853 64.3885 19.932 63.6377 15.3712L64.1767 15.2942C64.1767 15.2942 65.2355 20.5479 64.1767 24.8008L63.6762 24.6853Z" fill="white"/>
        </g>
        <defs>
        <filter id="filter0_f" x="0" y="0.572266" width="71.752" height="38.4594" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="3.5" result="effect1_foregroundBlur"/>
        </filter>
        </defs>
        </svg>      
      `;
    }

    if (service == 'logbook' && direction == 'right') {
      return `<svg width="72" height="39" viewBox="0 0 72 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.3" filter="url(#filter0_f)">
        <path d="M16.3028 31.4803C13.8003 31.4991 11.3939 30.5196 9.66139 28.749C8.2946 27.3551 7.44758 25.5656 7.21657 23.6631C6.92781 21.252 6.92781 18.8033 7.21657 16.3922C7.46683 14.4897 8.31385 12.7003 9.68064 11.3064C11.4132 9.55457 13.8002 8.55623 16.3028 8.57507L20.5187 8.61274L27.0254 9.21551L29.8937 9.12133L29.9899 8.70692C30.1247 8.16066 30.5675 7.74626 31.145 7.63324L31.5877 7.5579L31.7802 7.53906L31.5107 9.06482L38.0367 8.83878L60.3673 8.63157C61.7726 8.61274 63.0431 9.42271 63.6014 10.6848C64.1789 11.9656 64.4869 13.3407 64.5446 14.7346L64.7371 19.1989C64.7564 19.7639 64.7564 20.3102 64.7371 20.8753L64.5446 25.3396C64.4869 26.7335 64.1596 28.1085 63.6014 29.3894C63.0431 30.6326 61.7726 31.4426 60.3673 31.4426L38.0367 31.2354L27.0446 30.8587L20.5379 31.4614L16.3028 31.4803Z" fill="#020947"/>
        </g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.92896 11.2361C8.56218 12.63 7.71515 14.4194 7.48415 16.3219C7.19539 18.733 7.19539 21.1629 7.48415 23.574C7.71515 25.4765 8.56218 27.2659 9.92896 28.6598C11.6615 30.4305 14.0486 31.41 16.5704 31.3911L20.7863 31.3535L27.2929 30.7507L30.4728 30.8597L30.7966 32.2195C30.9314 32.7658 31.3742 33.1802 31.9517 33.2932L32.3944 33.3686L32.5869 33.4062L32.1293 30.9164L38.285 31.1274L60.6156 31.3346C62.0209 31.3535 63.2914 30.5435 63.8497 29.2814C64.4272 28.0006 64.7352 26.6255 64.793 25.2316L64.9855 20.7673C65.0047 20.2022 65.0047 19.656 64.9855 19.0909L64.793 14.6266C64.7352 13.2327 64.408 11.8577 63.8497 10.5768C63.2914 9.33358 62.0209 8.52362 60.6156 8.52362L38.285 8.73082L31.8473 8.95146C31.9783 8.88538 32.0761 8.7629 32.1057 8.61817L32.5869 5.9999L32.3944 6.03757L31.9517 6.11292C31.3742 6.22594 30.9314 6.64034 30.7966 7.1866L30.3643 9.00228L27.2929 9.10755L20.7863 8.50478L16.5704 8.46711C14.0486 8.46711 11.6423 9.46544 9.92896 11.2361Z" fill="#231F20"/>
        <g style="mix-blend-mode:overlay" opacity="0.15">
        <path d="M24.7321 11.6032L24.6551 11.5844C24.6551 11.5844 16.9549 11.8104 14.9336 12.2813C13.1625 12.6958 10.0632 14.6359 10.0439 20.3245C10.0439 27.5389 13.663 28.5184 15.4341 28.9328C17.4554 29.4037 24.6551 28.9705 24.6551 28.9705L24.7321 28.9517L24.7321 11.6032Z" fill="white"/>
        </g>
        <g style="mix-blend-mode:overlay" opacity="0.15">
        <path d="M46.4844 13.0915C46.6191 14.881 46.7346 16.6705 46.8694 18.4599C47.0811 21.4549 47.2736 24.4499 47.4084 27.4638L49.0254 27.5015C49.0254 27.5015 52.2595 28.9707 52.4135 28.9707C52.5675 28.9707 61.5575 27.4638 61.5575 27.4638C62.7511 25.938 63.1746 22.1896 63.3093 20.2682C63.1746 18.3469 62.7703 14.5796 61.5575 13.0727C61.5575 13.0727 52.5675 11.5658 52.4135 11.5658C52.2595 11.5658 49.0254 13.035 49.0254 13.035L46.4844 13.0915Z" fill="white"/>
        </g>
        <path d="M23.9431 11.5282C23.9431 11.5282 20.4202 14.5985 20.5742 20.4002C20.4202 26.2207 23.9431 29.291 23.9431 29.291L31.8743 27.5392C31.0465 25.4484 30.8733 20.7392 30.8733 20.4002C30.8733 20.08 31.0465 15.3708 31.8743 13.2612L23.9431 11.5282Z" fill="#918F8F"/>
        <path opacity="0.11" d="M24.1171 12.0939L24.2133 12.1128L31.2013 13.6574C30.9895 14.392 30.8162 15.2773 30.7007 16.1626C30.412 18.2723 30.3542 20.382 30.3542 20.4197C30.3542 20.4573 30.3927 22.2656 30.643 24.187C30.72 24.8839 30.8355 25.5809 30.9702 26.2213C31.0472 26.5415 31.1243 26.8617 31.2013 27.1631L28.0634 27.8601L24.1171 28.7266C23.5973 28.1991 22.1343 26.5039 21.4605 23.7537C21.2487 22.8684 21.0947 21.8701 21.0947 20.7776C21.0947 20.6645 21.0947 20.5515 21.0947 20.4385L21.0947 20.4197C21.0947 20.3066 21.0947 20.2125 21.0947 20.1183L21.0947 20.0994C21.0947 19.4778 21.1332 18.9127 21.2102 18.3665C21.6915 14.8252 23.5203 12.6967 24.1171 12.0939Z" fill="white"/>
        <path d="M49.0837 12.2817C49.9115 11.7355 50.7393 11.1892 51.5671 10.6429L24.1543 10.6429L32.8363 12.8091L48.1597 12.9033C48.4677 12.6961 48.7757 12.4889 49.0837 12.2817Z" fill="#918F8F"/>
        <path opacity="0.11" d="M48.9882 11.2274L29.0254 11.2274L32.8947 12.188L47.3711 12.2822C47.3904 12.2822 48.4684 11.5664 48.9882 11.2274Z" fill="white"/>
        <path d="M39.9012 12.5273L40.5942 10.9074L39.1504 11.1146L39.2466 12.3013L39.9012 12.5273Z" fill="#020947"/>
        <path d="M49.0837 28.4052C49.9115 28.9514 50.7393 29.4977 51.5671 30.0439L24.1543 30.0439L32.8363 27.8777L48.1597 27.7836C48.4677 27.9908 48.7757 28.198 49.0837 28.4052Z" fill="#918F8F"/>
        <path opacity="0.11" d="M48.9882 29.4404L29.0254 29.4404L32.8947 28.4798L47.3711 28.3856C47.3904 28.3856 48.4684 29.1014 48.9882 29.4404Z" fill="white"/>
        <path d="M39.9012 28.1594L40.5942 29.7793L39.1504 29.5533L39.2466 28.3854L39.9012 28.1594Z" fill="#020947"/>
        <path d="M52.5107 10.9625L49.0264 13.0533C50.3547 14.7863 50.3547 25.9187 49.0264 27.6328L52.5107 29.7236L55.0325 29.1209C57.6121 28.5181 59.4217 26.2577 59.4217 23.6771L59.4217 17.0278C59.4217 14.4472 57.6121 12.1868 55.0325 11.5841L52.5107 10.9625Z" fill="#918F8F"/>
        <path opacity="0.11" d="M52.6066 11.5275L54.9167 12.0738C57.092 12.5824 58.6513 14.3907 58.863 16.5192C58.8823 16.6887 58.8823 16.8394 58.8823 17.0089L58.8823 23.6582C58.8823 23.9596 58.863 24.261 58.8053 24.5435C58.709 25.071 58.5358 25.5795 58.2663 26.0505C57.785 26.9358 57.0535 27.6704 56.1295 28.1413C55.7444 28.3297 55.3402 28.4992 54.9167 28.5934L52.6066 29.1396L49.7383 27.4255C50.027 26.7097 50.2195 25.6361 50.3543 24.5247C50.4891 23.3192 50.5468 22.076 50.5468 21.2283C50.5468 20.8328 50.5661 20.5125 50.5661 20.3242C50.5661 20.1358 50.5661 19.8156 50.5468 19.3823C50.5083 17.7247 50.3543 14.6732 49.7383 13.2228L51.7018 12.0549L52.6066 11.5275Z" fill="white"/>
        <g style="mix-blend-mode:overlay" opacity="0.2">
        <path d="M32.5874 34L32.1061 31.3817C32.0676 31.1934 31.9136 31.0427 31.7211 31.005C31.4131 30.9485 31.2014 30.7036 31.2014 30.4022L31.2014 30.0443L30.1426 30.0443L30.3351 30.8731L31.7404 31.7773L32.4141 33.9623L32.5874 34Z" fill="black"/>
        </g>
        <g style="mix-blend-mode:overlay" opacity="0.2">
        <path d="M32.5874 6.59316L32.1061 9.21144C32.0676 9.3998 31.9136 9.55049 31.7211 9.58817C31.4131 9.64468 31.2014 9.88955 31.2014 10.1909L31.2014 10.5488L30.1426 10.5488L30.3351 9.72002L31.7404 8.81587L32.4141 6.63084L32.5874 6.59316Z" fill="black"/>
        </g>
        <path d="M12.7966 10.8873L13.2201 10.925L14.1634 11.0004C14.6447 11.038 14.7987 11.6408 14.3944 11.9045L11.7763 13.581C11.6608 13.6563 11.5646 13.7128 11.4491 13.7693C10.9486 14.033 10.3903 14.1837 9.83203 14.2402C9.83203 14.1084 9.85128 13.9577 9.88978 13.8258C10.1593 12.8652 11.1796 11.9799 11.9303 11.4336C12.4116 11.0945 12.7966 10.8873 12.7966 10.8873Z" fill="#F6F6F6"/>
        <path d="M13.2201 10.925L14.1634 11.0004C14.6447 11.038 14.7987 11.6408 14.3944 11.9045L11.7763 13.581C11.1988 13.9577 10.5251 14.1837 9.83203 14.2402C9.83203 14.1084 9.85128 13.9577 9.88978 13.8258C10.0438 14.0142 10.9486 13.807 11.3721 13.4868C11.8533 13.1289 12.8544 12.3566 13.5281 11.848C13.8169 11.622 13.7784 11.1887 13.4319 11.0192L13.2201 10.925Z" fill="#8C8984"/>
        <path d="M12.7968 10.8866L13.2203 10.9242L14.1635 10.9996C14.6448 11.0373 14.7988 11.64 14.3946 11.9037L11.7765 13.5802C11.661 13.6555 11.5647 13.712 11.4492 13.7686C11.6225 12.9963 11.7765 12.2051 11.9497 11.4328C12.4117 11.0938 12.7968 10.8866 12.7968 10.8866Z" fill="#DFDBE2"/>
        <path d="M12.7976 30.0801L13.2211 30.0424L14.1644 29.9671C14.6456 29.9294 14.7996 29.3266 14.3954 29.0629L11.7773 27.3865C11.6618 27.3111 11.5656 27.2546 11.4501 27.1981C10.9495 26.9344 10.3913 26.7837 9.83301 26.7272C9.83301 26.859 9.85226 27.0097 9.89076 27.1416C10.1603 28.1022 11.1805 28.9876 11.9313 29.5338C12.4126 29.8729 12.7976 30.0801 12.7976 30.0801Z" fill="#F6F6F6"/>
        <path d="M13.2211 30.0439L14.1644 29.9686C14.6456 29.9309 14.7996 29.3282 14.3954 29.0644L11.7773 27.388C11.1998 27.0113 10.526 26.7852 9.83301 26.7287C9.83301 26.8606 9.85226 27.0113 9.89076 27.1431C10.0448 26.9548 10.9495 27.162 11.3731 27.4822C11.8543 27.8401 12.8553 28.6124 13.5291 29.121C13.8179 29.347 13.7794 29.7802 13.4329 29.9498L13.2211 30.0439Z" fill="#8C8984"/>
        <path d="M12.7977 30.0811L13.2212 30.0434L14.1645 29.968C14.6458 29.9304 14.7998 29.3276 14.3955 29.0639L11.7775 27.3874C11.662 27.3121 11.5657 27.2556 11.4502 27.1991C11.6234 27.9714 11.7775 28.7625 11.9507 29.5348C12.4127 29.8739 12.7977 30.0811 12.7977 30.0811Z" fill="#DFDBE2"/>
        <g style="mix-blend-mode:overlay" opacity="0.3">
        <path d="M32.5096 26.0127C32.5096 26.0127 30.8733 19.3446 32.5866 14.3529L46.3844 14.2775L45.3834 15.5208L35.5512 17.1784C35.5705 17.216 32.3749 22.2077 32.5096 26.0127Z" fill="white"/>
        </g>
        <path d="M63.4258 12.1877C63.6376 12.7716 63.7916 13.5439 63.8109 14.5234L62.6366 13.6381C62.6366 13.6381 62.4248 12.8847 62.0976 12.0747C61.7895 11.3401 61.366 10.5301 60.8848 10.1534C60.8848 10.1722 62.6173 10.0404 63.4258 12.1877Z" fill="#FD515F"/>
        <path d="M63.4269 12.1877C63.6387 12.7716 63.7927 13.5439 63.8119 14.5234L62.6376 13.6381C62.6376 13.6381 62.4259 12.8847 62.0986 12.0747C62.1371 12.1124 62.1949 12.15 62.2334 12.2065C62.3681 12.3196 62.5029 12.4514 62.6761 12.5079C62.9649 12.6021 63.2729 12.4326 63.4269 12.1877Z" fill="#DCDCDC"/>
        <path d="M63.4258 28.2307C63.6376 27.6468 63.7916 26.8745 63.8109 25.895L62.6366 26.7803C62.6366 26.7803 62.4248 27.5338 62.0976 28.3437C61.7895 29.0783 61.366 29.8883 60.8848 30.265C60.8848 30.265 62.6173 30.3969 63.4258 28.2307Z" fill="#FD515F"/>
        <path d="M63.4269 28.2298C63.6387 27.6458 63.7927 26.8735 63.8119 25.894L62.6376 26.7793C62.6376 26.7793 62.4259 27.5328 62.0986 28.3428C62.1371 28.3051 62.1949 28.2674 62.2334 28.2109C62.3681 28.0979 62.5029 27.966 62.6761 27.9095C62.9649 27.8342 63.2729 28.0037 63.4269 28.2298Z" fill="#DCDCDC"/>
        <path opacity="0.11" d="M21.0935 20.0809L30.9305 26.2216C31.0075 26.5418 31.0845 26.862 31.1615 27.1634L28.0237 27.8604L21.44 23.754C21.2282 22.8687 21.0742 21.8703 21.0742 20.7778C21.0742 20.6648 21.0742 20.5518 21.0742 20.4388L21.0742 20.4199C21.0935 20.2881 21.0935 20.1939 21.0935 20.0809Z" fill="white"/>
        <path opacity="0.11" d="M24.1161 12.0935L24.2123 12.1123L30.6805 16.1622C30.3918 18.2719 30.334 20.3816 30.334 20.4192C30.334 20.4569 30.3725 22.2652 30.6228 24.1865L21.2285 18.3284C21.6905 14.8248 23.5193 12.6963 24.1161 12.0935Z" fill="white"/>
        <path opacity="0.11" d="M50.5467 21.2481L58.2662 26.0703C57.7849 26.9556 57.0534 27.6902 56.1294 28.1611L50.335 24.5445C50.489 23.339 50.5275 22.0958 50.5467 21.2481Z" fill="white"/>
        <path opacity="0.11" d="M51.7018 12.0553L58.863 16.5196C58.8823 16.6891 58.8823 16.8398 58.8823 17.0093L58.8823 23.6586C58.8823 23.96 58.863 24.2614 58.8053 24.5439L50.5468 19.4016C50.5083 17.744 50.3543 14.6925 49.7383 13.242L51.7018 12.0553Z" fill="white"/>
        <g style="mix-blend-mode:overlay" opacity="0.3">
        <path d="M11.623 17.0859C11.623 17.0859 16.9362 13.6954 22.4226 12.6029C22.4226 12.6029 14.7609 13.1679 13.5481 13.9026C12.3161 14.6372 11.623 17.0859 11.623 17.0859Z" fill="white"/>
        </g>
        <g style="mix-blend-mode:overlay" opacity="0.3">
        <path d="M11.623 23.6028C11.623 23.6028 16.9362 26.9934 22.4226 28.0859C22.4226 28.0859 14.7609 27.5208 13.5481 26.7862C12.3353 26.0516 11.623 23.6028 11.623 23.6028Z" fill="white"/>
        </g>
        <path d="M10.9876 14.5976C10.3523 14.9178 9.38977 15.0873 9.38977 15.0873C8.33099 15.7654 8.15773 19.7776 8.19624 20.4746C8.15773 21.1715 8.33099 25.3155 9.38977 25.9936C9.38977 25.9936 10.3523 26.1632 10.9876 26.4834C10.9876 26.4834 9.44752 24.92 9.46677 20.5499C9.44752 16.1798 10.9876 14.5976 10.9876 14.5976Z" fill="#918F8F"/>
        <path opacity="0.11" d="M9.81282 25.542C9.73582 25.5232 9.65882 25.5043 9.60106 25.5043C9.0813 25.0146 8.69629 22.547 8.69629 20.6257C8.69629 20.5692 8.69629 20.5315 8.69629 20.4938L8.69629 20.4561C8.69629 20.4185 8.69629 20.3808 8.69629 20.3243C8.69629 18.629 9.0428 16.0861 9.60106 15.5775C9.65882 15.5586 9.71657 15.5586 9.81282 15.5398C9.37006 16.5193 8.9273 18.1204 8.9273 20.4938L8.9273 20.588C8.9273 22.9614 9.38931 24.5625 9.81282 25.542Z" fill="white"/>
        <g style="mix-blend-mode:overlay" opacity="0.15">
        <path d="M63.6762 24.8831C63.6762 24.8831 64.3885 20.2305 63.6377 15.7662L64.1767 15.6909C64.1767 15.6909 65.2355 20.8332 64.1767 24.9961L63.6762 24.8831Z" fill="white"/>
        </g>
        <defs>
        <filter id="filter0_f" x="0" y="0.539062" width="71.7516" height="37.9415" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="3.5" result="effect1_foregroundBlur"/>
        </filter>
        </defs>
        </svg>
      `;
    }

    if (service == 'oem' && direction == 'up') {
        return `<svg width="40" height="72" viewBox="0 0 40 72" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.3" filter="url(#filter0_f)">
          <path d="M7.96859 16.3029C7.94934 13.8003 8.95003 11.394 10.759 9.66141C12.183 8.29461 14.0112 7.44758 15.9549 7.21657C18.4181 6.92781 20.9198 6.92781 23.383 7.21657C25.3267 7.46683 27.1549 8.31386 28.5789 9.68065C30.3686 11.4132 31.3886 13.8003 31.3693 16.3029L31.3308 20.5188L30.715 27.0255L30.8112 29.8938L31.2346 29.9901C31.7927 30.1249 32.2161 30.5676 32.3315 31.1451L32.4085 31.5879L32.4277 31.7804L30.869 31.5109L31.0999 38.0369L31.3116 60.3676C31.3308 61.7729 30.5033 63.0435 29.214 63.6017C27.9054 64.1793 26.5006 64.4873 25.0765 64.545L20.5157 64.7375C19.9384 64.7568 19.3803 64.7568 18.803 64.7375L14.2421 64.545C12.8181 64.4873 11.4133 64.16 10.1047 63.6017C8.83457 63.0435 8.00708 61.7729 8.00708 60.3676L8.21876 38.0369L8.60364 27.0448L7.98783 20.538L7.96859 16.3029Z" fill="#020947"/>
          </g>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M28.6501 9.92801C27.2261 8.56121 25.3979 7.71418 23.4542 7.48317C20.991 7.19441 18.5085 7.19441 16.0453 7.48317C14.1017 7.71418 12.2735 8.56121 10.8494 9.92801C9.04048 11.6606 8.03979 14.0476 8.05904 16.5695L8.09752 20.7854L8.71333 27.2921L8.60201 30.4715L7.21237 30.7954C6.6543 30.9302 6.23093 31.373 6.11546 31.9505L6.03849 32.3932L6 32.5857L8.54401 32.128L8.32845 38.2842L8.11677 60.615C8.09752 62.0203 8.92502 63.2908 10.2144 63.8491C11.523 64.4266 12.9278 64.7346 14.3518 64.7924L18.9127 64.9849C19.49 65.0041 20.0481 65.0041 20.6254 64.9849L25.1862 64.7924C26.6103 64.7346 28.0151 64.4074 29.3237 63.8491C30.5938 63.2908 31.4213 62.0203 31.4213 60.615L31.2096 38.2842L30.9841 31.8448C31.0515 31.9763 31.1769 32.0745 31.3251 32.1042L34.0001 32.5855L33.9616 32.393L33.8846 31.9502C33.7691 31.3727 33.3458 30.9299 32.7877 30.7951L30.9322 30.3627L30.8247 27.2921L31.4405 20.7854L31.479 16.5695C31.479 14.0476 30.4591 11.6413 28.6501 9.92801Z" fill="#3366ff"/>
          <g style="mix-blend-mode:overlay" opacity="0.15">
          <path d="M28.8829 24.7332L28.9021 24.6562C28.9021 24.6562 28.6712 16.9559 28.1901 14.9346C27.7667 13.1635 25.7846 10.0642 19.9729 10.0449C12.6024 10.0449 11.6017 13.664 11.1784 15.4351C10.6973 17.4564 11.1399 24.6562 11.1399 24.6562L11.1591 24.7332H28.8829Z" fill="white"/>
          </g>
          <g style="mix-blend-mode:overlay" opacity="0.15">
          <path d="M27.3624 46.4863C25.5342 46.6211 23.706 46.7366 21.8778 46.8713C18.818 47.0831 15.7582 47.2756 12.6792 47.4104L12.6407 49.0274C12.6407 49.0274 11.1396 52.2615 11.1396 52.4155C11.1396 52.5695 12.6792 61.5596 12.6792 61.5596C14.2379 62.7531 18.0675 63.1766 20.0304 63.3114C21.9933 63.1766 25.8421 62.7724 27.3816 61.5596C27.3816 61.5596 28.9211 52.5695 28.9211 52.4155C28.9211 52.2615 27.4201 49.0274 27.4201 49.0274L27.3624 46.4863Z" fill="white"/>
          </g>
          <path d="M28.9577 23.9431C28.9577 23.9431 25.8209 20.4202 19.8937 20.5742C13.9473 20.4202 10.8105 23.9431 10.8105 23.9431L12.6002 31.8744C14.7363 31.0466 19.5473 30.8733 19.8937 30.8733C20.2209 30.8733 25.0319 31.0466 27.1872 31.8744L28.9577 23.9431Z" fill="#231F20"/>
          <path opacity="0.11" d="M28.3802 24.1161L28.3609 24.2124L26.7829 31.2003C26.0324 30.9886 25.1279 30.8153 24.2235 30.6998C22.0681 30.4111 19.9128 30.3533 19.8743 30.3533C19.8358 30.3533 17.9884 30.3918 16.0255 30.6421C15.3135 30.7191 14.6014 30.8346 13.9472 30.9693C13.62 31.0463 13.2929 31.1233 12.9849 31.2003L12.2729 28.0625L11.3877 24.1161C11.9265 23.5963 13.6585 22.1333 16.4681 21.4595C17.3726 21.2478 18.3925 21.0938 19.5087 21.0938C19.6241 21.0938 19.7396 21.0938 19.8551 21.0938H19.8743C19.9898 21.0938 20.086 21.0938 20.1822 21.0938H20.2015C20.8365 21.0938 21.4138 21.1323 21.9719 21.2093C25.5898 21.6905 27.7644 23.5193 28.3802 24.1161Z" fill="white"/>
          <path d="M28.1888 49.0839C28.7468 49.9117 29.3049 50.7395 29.863 51.5672L29.863 24.1543L27.6499 32.8363L27.5537 48.1599C27.7654 48.4679 27.9771 48.7759 28.1888 49.0839Z" fill="#231F20"/>
          <path opacity="0.11" d="M29.2661 48.9873L29.2661 29.0244L28.2847 32.8938L28.1885 47.3703C28.1885 47.3895 28.9198 48.4676 29.2661 48.9873Z" fill="white"/>
          <path d="M27.9385 39.9021L29.5935 40.5952L29.3818 39.1514L28.1694 39.2476L27.9385 39.9021Z" fill="#231F20"/>
          <path d="M11.7162 49.0839C11.1581 49.9117 10.6001 50.7394 10.042 51.5672L10.042 24.1543L12.2551 32.8363L12.3513 48.1599C12.1396 48.4679 11.9279 48.7759 11.7162 49.0839Z" fill="#231F20"/>
          <path opacity="0.11" d="M10.6582 48.9873L10.6582 29.0244L11.6396 32.8938L11.7359 47.3703C11.7359 47.3895 11.0046 48.4676 10.6582 48.9873Z" fill="white"/>
          <path d="M11.9665 39.9021L10.3115 40.5952L10.5425 39.1514L11.7356 39.2476L11.9665 39.9021Z" fill="#231F20"/>
          <path d="M29.5352 52.5107L27.3991 49.0264C25.6287 50.3547 14.2555 50.3547 12.5043 49.0264L10.3682 52.5107L10.984 55.0326C11.5998 57.6122 13.9091 59.4217 16.5455 59.4217H23.3386C25.9751 59.4217 28.2843 57.6122 28.9002 55.0326L29.5352 52.5107Z" fill="#231F20"/>
          <path opacity="0.11" d="M28.957 52.6076L28.399 54.9177C27.8794 57.093 26.0319 58.6523 23.8574 58.8641C23.6842 58.8833 23.5302 58.8833 23.357 58.8833H16.5639C16.256 58.8833 15.9481 58.8641 15.6594 58.8063C15.1206 58.7101 14.601 58.5368 14.1199 58.2673C13.2154 57.786 12.4649 57.0545 11.9838 56.1305C11.7914 55.7455 11.6182 55.3412 11.5219 54.9177L10.9639 52.6076L12.7151 49.7393C13.4463 50.028 14.5433 50.2205 15.6787 50.3553C16.9103 50.49 18.1804 50.5478 19.0464 50.5478C19.4505 50.5478 19.7776 50.567 19.9701 50.567C20.1625 50.567 20.4897 50.567 20.9323 50.5478C22.6257 50.5093 25.7433 50.3553 27.2251 49.7393L28.4182 51.7028L28.957 52.6076Z" fill="white"/>
          <g style="mix-blend-mode:overlay" opacity="0.2">
          <path d="M6 32.5874L8.67492 32.1061C8.86736 32.0676 9.02131 31.9136 9.0598 31.7211C9.11753 31.4131 9.3677 31.2014 9.67561 31.2014H10.0412L10.0412 30.1426L9.19451 30.3351L8.27079 31.7404L6.03849 32.4142L6 32.5874Z" fill="black"/>
          </g>
          <g style="mix-blend-mode:overlay" opacity="0.2">
          <path d="M34.0002 32.5874L31.3253 32.1062C31.1329 32.0677 30.9789 31.9136 30.9404 31.7211C30.8827 31.4131 30.6325 31.2014 30.3246 31.2014H29.959L29.959 30.1426L30.8057 30.3351L31.7294 31.7404L33.9617 32.4142L34.0002 32.5874Z" fill="black"/>
          </g>
          <path d="M29.612 12.7966L29.5735 13.2201L29.4965 14.1634C29.458 14.6447 28.8422 14.7987 28.5728 14.3944L26.8601 11.7763C26.7831 11.6608 26.7254 11.5646 26.6676 11.4491C26.3982 10.9486 26.2443 10.3903 26.1865 9.83203C26.3212 9.83203 26.4752 9.85128 26.6099 9.88978C27.5913 10.1593 28.4958 11.1796 29.0539 11.9304C29.4003 12.4116 29.612 12.7966 29.612 12.7966Z" fill="#F6F6F6"/>
          <path d="M29.5735 13.2201L29.4965 14.1634C29.458 14.6447 28.8422 14.7987 28.5728 14.3944L26.8601 11.7763C26.4752 11.1988 26.2443 10.5251 26.1865 9.83203C26.3212 9.83203 26.4752 9.85128 26.6099 9.88978C26.4175 10.0438 26.6291 10.9486 26.9563 11.3721C27.3219 11.8534 28.1109 12.8544 28.6305 13.5282C28.8614 13.8169 29.3041 13.7784 29.4773 13.4319L29.5735 13.2201Z" fill="#8C8984"/>
          <path d="M29.6123 12.7968L29.5738 13.2203L29.4968 14.1636C29.4583 14.6448 28.8425 14.7988 28.5731 14.3946L26.8604 11.7765C26.7834 11.661 26.7257 11.5647 26.668 11.4492C27.457 11.6225 28.2652 11.7765 29.0542 11.9497C29.4006 12.4118 29.6123 12.7968 29.6123 12.7968Z" fill="#DFDBE2"/>
          <path d="M10.0049 12.7966L10.0434 13.2201L10.1203 14.1634C10.1588 14.6447 10.7746 14.7987 11.0441 14.3944L12.7568 11.7763C12.8338 11.6608 12.8915 11.5646 12.9492 11.4491C13.2186 10.9486 13.3726 10.3903 13.4303 9.83203C13.2956 9.83203 13.1417 9.85128 13.0069 9.88978C12.0255 10.1593 11.121 11.1796 10.563 11.9304C10.2166 12.4116 10.0049 12.7966 10.0049 12.7966Z" fill="#F6F6F6"/>
          <path d="M10.042 13.2201L10.119 14.1634C10.1575 14.6447 10.7733 14.7987 11.0427 14.3944L12.7554 11.7763C13.1403 11.1988 13.3712 10.5251 13.4289 9.83203C13.2942 9.83203 13.1403 9.85128 13.0056 9.88978C13.198 10.0438 12.9863 10.9486 12.6592 11.3721C12.2935 11.8534 11.5045 12.8544 10.9849 13.5282C10.754 13.8169 10.3114 13.7784 10.1382 13.4319L10.042 13.2201Z" fill="#8C8984"/>
          <path d="M10.0039 12.7968L10.0424 13.2203L10.1194 14.1636C10.1579 14.6448 10.7737 14.7988 11.0431 14.3946L12.7558 11.7765C12.8328 11.661 12.8905 11.5647 12.9482 11.4492C12.1592 11.6225 11.351 11.7765 10.562 11.9497C10.2156 12.4118 10.0039 12.7968 10.0039 12.7968Z" fill="#DFDBE2"/>
          <g style="mix-blend-mode:overlay" opacity="0.3">
          <path d="M14.1592 32.5096C14.1592 32.5096 20.9716 30.8733 26.0712 32.5866L26.1482 46.3845L24.8781 45.3835L23.1846 35.5513C23.1461 35.5705 18.0465 32.3749 14.1592 32.5096Z" fill="white"/>
          </g>
          <path d="M28.2847 63.4259C27.6881 63.6376 26.8991 63.7916 25.8984 63.8109L26.8029 62.6366C26.8029 62.6366 27.5727 62.4248 28.4002 62.0976C29.1507 61.7895 29.9782 61.366 30.3631 60.8848C30.3438 60.8848 30.4785 62.6173 28.2847 63.4259Z" fill="#FF3838"/>
          <path d="M28.2847 63.4259C27.6881 63.6377 26.8991 63.7917 25.8984 63.811L26.8029 62.6367C26.8029 62.6367 27.5727 62.4249 28.4002 62.0977C28.3617 62.1362 28.3232 62.1939 28.2655 62.2324C28.15 62.3672 28.0153 62.5019 27.9575 62.6752C27.8613 62.9639 28.0345 63.2719 28.2847 63.4259Z" fill="#DCDCDC"/>
          <path d="M11.0213 63.4259C11.6179 63.6376 12.4069 63.7916 13.4076 63.8109L12.5031 62.6366C12.5031 62.6366 11.7334 62.4248 10.9059 62.0976C10.1554 61.7896 9.32787 61.366 8.94299 60.8848C8.94299 60.8848 8.80828 62.6173 11.0213 63.4259Z" fill="#FF3838"/>
          <path d="M11.0227 63.426C11.6193 63.6377 12.4083 63.7917 13.4089 63.811L12.5045 62.6367C12.5045 62.6367 11.7347 62.4249 10.9072 62.0977C10.9457 62.1362 10.9842 62.1939 11.0419 62.2324C11.1574 62.3672 11.2921 62.5019 11.3498 62.6752C11.4268 62.9639 11.2536 63.272 11.0227 63.426Z" fill="#DCDCDC"/>
          <path opacity="0.11" d="M20.2212 21.0935L13.9477 30.9306C13.6205 31.0076 13.2934 31.0846 12.9855 31.1616L12.2734 28.0237L16.4686 21.44C17.3731 21.2282 18.393 21.0742 19.5092 21.0742C19.6247 21.0742 19.7401 21.0742 19.8556 21.0742H19.8748C20.0095 21.0935 20.1058 21.0935 20.2212 21.0935Z" fill="white"/>
          <path opacity="0.11" d="M28.3791 24.1171L28.3598 24.2133L24.2224 30.6816C22.067 30.3928 19.9117 30.3351 19.8732 30.3351C19.8347 30.3351 17.9873 30.3736 16.0244 30.6238L22.0093 21.2295C25.5887 21.6915 27.7633 23.5203 28.3791 24.1171Z" fill="white"/>
          <path opacity="0.11" d="M19.0294 50.5477L14.1029 58.2672C13.1984 57.7859 12.4479 57.0544 11.9668 56.1304L15.6617 50.3359C16.8933 50.4899 18.1634 50.5284 19.0294 50.5477Z" fill="white"/>
          <path opacity="0.11" d="M28.4189 51.7028L23.8581 58.8641C23.6849 58.8833 23.531 58.8833 23.3578 58.8833H16.5646C16.2567 58.8833 15.9488 58.8641 15.6602 58.8063L20.9138 50.5478C22.6072 50.5093 25.7248 50.3553 27.2066 49.7393L28.4189 51.7028Z" fill="white"/>
          <g style="mix-blend-mode:overlay" opacity="0.3">
          <path d="M23.2812 11.623C23.2812 11.623 26.7452 16.9362 27.8613 22.4227C27.8613 22.4227 27.284 14.7609 26.5335 13.5481C25.783 12.3161 23.2812 11.623 23.2812 11.623Z" fill="white"/>
          </g>
          <g style="mix-blend-mode:overlay" opacity="0.3">
          <path d="M16.623 11.623C16.623 11.623 13.1591 16.9362 12.043 22.4227C12.043 22.4227 12.6203 14.7609 13.3708 13.5481C14.1213 12.3353 16.623 11.623 16.623 11.623Z" fill="white"/>
          </g>
          <path d="M25.8217 10.9876C25.4945 10.3523 25.3213 9.38978 25.3213 9.38978C24.6286 8.33099 20.5296 8.15773 19.8176 8.19624C19.1055 8.15773 14.8718 8.33099 14.1791 9.38978C14.1791 9.38978 14.0059 10.3523 13.6787 10.9876C13.6787 10.9876 15.276 9.44753 19.7406 9.46678C24.2052 9.44753 25.8217 10.9876 25.8217 10.9876Z" fill="#231F20"/>
          <path opacity="0.11" d="M14.6406 9.81283C14.6599 9.73582 14.6791 9.65882 14.6791 9.60107C15.1795 9.0813 17.7004 8.69629 19.6633 8.69629C19.721 8.69629 19.7595 8.69629 19.798 8.69629H19.8365C19.875 8.69629 19.9135 8.69629 19.9712 8.69629C21.7032 8.69629 24.3011 9.0428 24.8207 9.60107C24.84 9.65882 24.84 9.71657 24.8592 9.81283C23.8585 9.37006 22.2228 8.9273 19.798 8.9273H19.7018C17.2771 8.9273 15.6413 9.38931 14.6406 9.81283Z" fill="white"/>
          <g style="mix-blend-mode:overlay" opacity="0.15">
          <path d="M15.3147 63.6762C15.3147 63.6762 20.068 64.3885 24.6288 63.6377L24.7058 64.1767C24.7058 64.1767 19.4521 65.2355 15.1992 64.1767L15.3147 63.6762Z" fill="white"/>
          </g>
          <defs>
          <filter id="filter0_f" x="0.96875" y="0" width="38.4594" height="71.752" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="3.5" result="effect1_foregroundBlur"/>
          </filter>
          </defs>
          </svg>      
        `;
    }

    if (service == 'oem' && direction == 'down') {
        return `<svg width="40" height="72" viewBox="0 0 40 72" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.3" filter="url(#filter0_f)">
          <path d="M32.0314 55.6971C32.0507 58.1997 31.05 60.606 29.241 62.3386C27.817 63.7054 25.9888 64.5524 24.0451 64.7834C21.5819 65.0722 19.0802 65.0722 16.617 64.7834C14.6733 64.5332 12.8451 63.6861 11.4211 62.3193C9.63138 60.5868 8.61144 58.1997 8.63069 55.6971L8.66917 51.4812L9.28498 44.9745L9.18876 42.1062L8.76539 42.0099C8.20732 41.8751 7.78395 41.4324 7.66849 40.8549L7.59151 40.4121L7.57227 40.2196L9.13103 40.4891L8.9001 33.9631L8.68842 11.6324C8.66917 10.2271 9.49667 8.95652 10.786 8.39825C12.0946 7.82073 13.4994 7.51273 14.9235 7.45497L19.4843 7.26247C20.0616 7.24321 20.6197 7.24321 21.197 7.26247L25.7579 7.45497C27.1819 7.51273 28.5867 7.83999 29.8953 8.39825C31.1654 8.95652 31.9929 10.2271 31.9929 11.6324L31.7812 33.9631L31.3964 44.9553L32.0122 51.462L32.0314 55.6971Z" fill="#020947"/>
          </g>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3499 62.072C12.7739 63.4388 14.6021 64.2858 16.5458 64.5168C19.009 64.8056 21.4915 64.8056 23.9547 64.5168C25.8983 64.2858 27.7265 63.4388 29.1506 62.072C30.9595 60.3394 31.9602 57.9524 31.941 55.4305L31.9025 51.2146L31.2867 44.7079L31.398 41.5285L32.7876 41.2046C33.3457 41.0698 33.7691 40.627 33.8845 40.0495L33.9615 39.6068L34 39.4143L31.456 39.872L31.6716 33.7158L31.8832 11.385C31.9025 9.97971 31.075 8.70917 29.7856 8.15091C28.477 7.57338 27.0722 7.26537 25.6482 7.20762L21.0873 7.01511C20.51 6.99586 19.9519 6.99586 19.3746 7.01511L14.8138 7.20762C13.3897 7.26537 11.9849 7.59263 10.6763 8.1509C9.40623 8.70917 8.57874 9.97971 8.57874 11.385L8.79042 33.7158L9.01589 40.1552C8.94851 40.0237 8.82311 39.9255 8.67487 39.8958L5.99995 39.4145L6.03844 39.607L6.11541 40.0498C6.23088 40.6273 6.65425 41.0701 7.21232 41.2049L9.06778 41.6373L9.17529 44.7079L8.55949 51.2146L8.521 55.4305C8.521 57.9524 9.54093 60.3587 11.3499 62.072Z" fill="#3366ff"/>
          <g style="mix-blend-mode:overlay" opacity="0.15">
          <path d="M11.1171 47.2668L11.0979 47.3438C11.0979 47.3438 11.3288 55.0441 11.8099 57.0654C12.2333 58.8365 14.2154 61.9358 20.0271 61.9551C27.3976 61.9551 28.3983 58.336 28.8216 56.5649C29.3027 54.5436 28.8601 47.3438 28.8601 47.3438L28.8409 47.2668L11.1171 47.2668Z" fill="white"/>
          </g>
          <g style="mix-blend-mode:overlay" opacity="0.15">
          <path d="M12.6376 25.5137C14.4658 25.3789 16.294 25.2634 18.1222 25.1287C21.182 24.9169 24.2418 24.7244 27.3208 24.5896L27.3593 22.9726C27.3593 22.9726 28.8604 19.7385 28.8604 19.5845C28.8604 19.4305 27.3208 10.4404 27.3208 10.4404C25.7621 9.24687 21.9325 8.82335 19.9696 8.6886C18.0067 8.82335 14.1579 9.22761 12.6184 10.4404C12.6184 10.4404 11.0789 19.4305 11.0789 19.5845C11.0789 19.7385 12.5799 22.9726 12.5799 22.9726L12.6376 25.5137Z" fill="white"/>
          </g>
          <path d="M11.0423 48.0569C11.0423 48.0569 14.1791 51.5798 20.1063 51.4258C26.0527 51.5798 29.1895 48.0569 29.1895 48.0569L27.3998 40.1256C25.2637 40.9534 20.4527 41.1267 20.1063 41.1267C19.7791 41.1267 14.9681 40.9534 12.8128 40.1256L11.0423 48.0569Z" fill="#231F20"/>
          <path opacity="0.11" d="M11.6198 47.8839L11.6391 47.7876L13.2171 40.7997C13.9676 41.0114 14.8721 41.1847 15.7765 41.3002C17.9319 41.5889 20.0872 41.6467 20.1257 41.6467C20.1642 41.6467 22.0116 41.6082 23.9745 41.3579C24.6865 41.2809 25.3986 41.1654 26.0529 41.0307C26.38 40.9537 26.7071 40.8767 27.0151 40.7997L27.7271 43.9375L28.6123 47.8839C28.0735 48.4037 26.3415 49.8667 23.5319 50.5405C22.6274 50.7522 21.6075 50.9062 20.4913 50.9062C20.3759 50.9062 20.2604 50.9062 20.1449 50.9062L20.1257 50.9062C20.0102 50.9062 19.914 50.9062 19.8178 50.9062L19.7985 50.9062C19.1635 50.9062 18.5862 50.8677 18.0281 50.7907C14.4102 50.3095 12.2356 48.4807 11.6198 47.8839Z" fill="white"/>
          <path d="M11.8112 22.9161C11.2532 22.0883 10.6951 21.2605 10.137 20.4328L10.137 47.8457L12.3501 39.1637L12.4463 23.8401C12.2346 23.5321 12.0229 23.2241 11.8112 22.9161Z" fill="#231F20"/>
          <path opacity="0.11" d="M10.7339 23.0127L10.7339 42.9756L11.7153 39.1062L11.8115 24.6297C11.8115 24.6105 11.0802 23.5324 10.7339 23.0127Z" fill="white"/>
          <path d="M12.0615 32.0979L10.4065 31.4048L10.6182 32.8486L11.8306 32.7524L12.0615 32.0979Z" fill="#231F20"/>
          <path d="M28.2838 22.9161C28.8419 22.0883 29.3999 21.2606 29.958 20.4328L29.958 47.8457L27.7449 39.1637L27.6487 23.8401C27.8604 23.5321 28.0721 23.2241 28.2838 22.9161Z" fill="#231F20"/>
          <path opacity="0.11" d="M29.3418 23.0127L29.3418 42.9756L28.3604 39.1062L28.2641 24.6297C28.2641 24.6105 28.9954 23.5324 29.3418 23.0127Z" fill="white"/>
          <path d="M28.0335 32.0979L29.6885 31.4048L29.4575 32.8486L28.2644 32.7524L28.0335 32.0979Z" fill="#231F20"/>
          <path d="M10.4648 19.4893L12.6009 22.9736C14.3713 21.6453 25.7445 21.6453 27.4957 22.9736L29.6318 19.4893L29.016 16.9674C28.4002 14.3878 26.0909 12.5783 23.4545 12.5783L16.6614 12.5783C14.0249 12.5783 11.7157 14.3878 11.0998 16.9674L10.4648 19.4893Z" fill="#231F20"/>
          <path opacity="0.11" d="M11.043 19.3924L11.601 17.0823C12.1206 14.907 13.9681 13.3477 16.1426 13.1359C16.3158 13.1167 16.4698 13.1167 16.643 13.1167L23.4361 13.1167C23.744 13.1167 24.0519 13.1359 24.3406 13.1937C24.8794 13.2899 25.399 13.4632 25.8801 13.7327C26.7846 14.214 27.5351 14.9455 28.0162 15.8695C28.2086 16.2545 28.3818 16.6588 28.4781 17.0823L29.0361 19.3924L27.2849 22.2607C26.5537 21.972 25.4567 21.7795 24.3213 21.6447C23.0897 21.51 21.8196 21.4522 20.9536 21.4522C20.5495 21.4522 20.2224 21.433 20.0299 21.433C19.8375 21.433 19.5103 21.433 19.0677 21.4522C17.3743 21.4907 14.2567 21.6447 12.7749 22.2607L11.5818 20.2972L11.043 19.3924Z" fill="white"/>
          <g style="mix-blend-mode:overlay" opacity="0.2">
          <path d="M34 39.4126L31.3251 39.8939C31.1326 39.9324 30.9787 40.0864 30.9402 40.2789C30.8825 40.5869 30.6323 40.7986 30.3244 40.7986L29.9588 40.7986L29.9588 41.8574L30.8055 41.6649L31.7292 40.2596L33.9615 39.5858L34 39.4126Z" fill="black"/>
          </g>
          <g style="mix-blend-mode:overlay" opacity="0.2">
          <path d="M5.99977 39.4126L8.67469 39.8938C8.86713 39.9323 9.02108 40.0864 9.05957 40.2789C9.1173 40.5869 9.36748 40.7986 9.67538 40.7986L10.041 40.7986L10.041 41.8574L9.19428 41.6649L8.27057 40.2596L6.03826 39.5858L5.99977 39.4126Z" fill="black"/>
          </g>
          <path d="M10.388 59.2034L10.4265 58.7799L10.5035 57.8366C10.542 57.3553 11.1578 57.2013 11.4272 57.6056L13.1399 60.2237C13.2169 60.3392 13.2746 60.4354 13.3324 60.5509C13.6018 61.0514 13.7557 61.6097 13.8135 62.168C13.6788 62.168 13.5248 62.1487 13.3901 62.1102C12.4087 61.8407 11.5042 60.8204 10.9461 60.0696C10.5997 59.5884 10.388 59.2034 10.388 59.2034Z" fill="#F6F6F6"/>
          <path d="M10.4265 58.7799L10.5035 57.8366C10.542 57.3553 11.1578 57.2013 11.4272 57.6056L13.1399 60.2237C13.5248 60.8012 13.7557 61.4749 13.8135 62.168C13.6788 62.168 13.5248 62.1487 13.3901 62.1102C13.5825 61.9562 13.3709 61.0514 13.0437 60.6279C12.6781 60.1466 11.8891 59.1456 11.3695 58.4718C11.1386 58.1831 10.6959 58.2216 10.5227 58.5681L10.4265 58.7799Z" fill="#8C8984"/>
          <path d="M10.3877 59.2032L10.4262 58.7797L10.5032 57.8364C10.5417 57.3552 11.1575 57.2012 11.4269 57.6054L13.1396 60.2235C13.2166 60.339 13.2743 60.4353 13.332 60.5508C12.543 60.3775 11.7348 60.2235 10.9458 60.0503C10.5994 59.5882 10.3877 59.2032 10.3877 59.2032Z" fill="#DFDBE2"/>
          <path d="M29.9951 59.2034L29.9566 58.7799L29.8797 57.8366C29.8412 57.3553 29.2254 57.2013 28.9559 57.6056L27.2432 60.2237C27.1662 60.3392 27.1085 60.4354 27.0508 60.5509C26.7814 61.0514 26.6274 61.6097 26.5697 62.168C26.7044 62.168 26.8583 62.1487 26.9931 62.1102C27.9745 61.8407 28.879 60.8204 29.437 60.0696C29.7834 59.5884 29.9951 59.2034 29.9951 59.2034Z" fill="#F6F6F6"/>
          <path d="M29.958 58.7799L29.881 57.8366C29.8425 57.3553 29.2267 57.2013 28.9573 57.6056L27.2446 60.2237C26.8597 60.8012 26.6288 61.4749 26.5711 62.168C26.7058 62.168 26.8597 62.1487 26.9944 62.1102C26.802 61.9562 27.0137 61.0514 27.3408 60.6279C27.7065 60.1466 28.4955 59.1456 29.0151 58.4718C29.246 58.1831 29.6886 58.2216 29.8618 58.5681L29.958 58.7799Z" fill="#8C8984"/>
          <path d="M29.9961 59.2032L29.9576 58.7797L29.8806 57.8364C29.8421 57.3552 29.2263 57.2012 28.9569 57.6054L27.2442 60.2235C27.1672 60.339 27.1095 60.4353 27.0518 60.5508C27.8408 60.3775 28.649 60.2235 29.438 60.0503C29.7844 59.5882 29.9961 59.2032 29.9961 59.2032Z" fill="#DFDBE2"/>
          <g style="mix-blend-mode:overlay" opacity="0.3">
          <path d="M25.8408 39.4904C25.8408 39.4904 19.0284 41.1267 13.9288 39.4134L13.8518 25.6155L15.1219 26.6165L16.8154 36.4487C16.8539 36.4295 21.9535 39.6251 25.8408 39.4904Z" fill="white"/>
          </g>
          <path d="M11.7153 8.57415C12.3119 8.36239 13.1009 8.20839 14.1016 8.18914L13.1971 9.36343C13.1971 9.36343 12.4273 9.57518 11.5998 9.90244C10.8493 10.2105 10.0218 10.634 9.63695 11.1152C9.65619 11.1152 9.52149 9.38267 11.7153 8.57415Z" fill="#FF3838"/>
          <path d="M11.7153 8.57405C12.3119 8.3623 13.1009 8.20829 14.1016 8.18904L13.1971 9.36333C13.1971 9.36333 12.4273 9.57508 11.5998 9.90234C11.6383 9.86384 11.6768 9.80609 11.7345 9.76759C11.85 9.63284 11.9847 9.49808 12.0425 9.32483C12.1387 9.03607 11.9655 8.72806 11.7153 8.57405Z" fill="#DCDCDC"/>
          <path d="M28.9787 8.57414C28.3821 8.36238 27.5931 8.20838 26.5924 8.18913L27.4969 9.36342C27.4969 9.36342 28.2666 9.57518 29.0941 9.90244C29.8446 10.2104 30.6721 10.634 31.057 11.1152C31.057 11.1152 31.1917 9.38267 28.9787 8.57414Z" fill="#FF3838"/>
          <path d="M28.9773 8.57404C28.3807 8.36228 27.5917 8.20827 26.5911 8.18902L27.4955 9.36332C27.4955 9.36332 28.2653 9.57508 29.0928 9.90234C29.0543 9.86384 29.0158 9.80609 28.9581 9.76759C28.8426 9.63284 28.7079 9.49808 28.6502 9.32482C28.5732 9.03606 28.7464 8.72804 28.9773 8.57404Z" fill="#DCDCDC"/>
          <path opacity="0.11" d="M19.7788 50.9065L26.0523 41.0694C26.3795 40.9924 26.7066 40.9154 27.0145 40.8384L27.7266 43.9763L23.5314 50.56C22.6269 50.7718 21.607 50.9258 20.4908 50.9258C20.3753 50.9258 20.2599 50.9258 20.1444 50.9258L20.1252 50.9258C19.9905 50.9065 19.8942 50.9065 19.7788 50.9065Z" fill="white"/>
          <path opacity="0.11" d="M11.6209 47.8829L11.6402 47.7867L15.7776 41.3184C17.933 41.6072 20.0883 41.6649 20.1268 41.6649C20.1653 41.6649 22.0127 41.6264 23.9756 41.3762L17.9907 50.7705C14.4113 50.3085 12.2367 48.4797 11.6209 47.8829Z" fill="white"/>
          <path opacity="0.11" d="M20.9706 21.4523L25.8971 13.7328C26.8016 14.2141 27.5521 14.9456 28.0332 15.8696L24.3383 21.6641C23.1067 21.5101 21.8366 21.4716 20.9706 21.4523Z" fill="white"/>
          <path opacity="0.11" d="M11.5811 20.2972L16.1419 13.1359C16.3151 13.1167 16.469 13.1167 16.6422 13.1167L23.4354 13.1167C23.7433 13.1167 24.0512 13.1359 24.3398 13.1937L19.0862 21.4522C17.3928 21.4907 14.2752 21.6447 12.7934 22.2607L11.5811 20.2972Z" fill="white"/>
          <g style="mix-blend-mode:overlay" opacity="0.3">
          <path d="M16.7188 60.377C16.7188 60.377 13.2548 55.0638 12.1387 49.5773C12.1387 49.5773 12.716 57.2391 13.4665 58.4519C14.217 59.6839 16.7188 60.377 16.7188 60.377Z" fill="white"/>
          </g>
          <g style="mix-blend-mode:overlay" opacity="0.3">
          <path d="M23.377 60.377C23.377 60.377 26.8409 55.0638 27.957 49.5773C27.957 49.5773 27.3797 57.2391 26.6292 58.4519C25.8787 59.6647 23.377 60.377 23.377 60.377Z" fill="white"/>
          </g>
          <path d="M14.1783 61.0124C14.5055 61.6477 14.6787 62.6102 14.6787 62.6102C15.3714 63.669 19.4704 63.8423 20.1824 63.8038C20.8945 63.8423 25.1282 63.669 25.8209 62.6102C25.8209 62.6102 25.9941 61.6477 26.3213 61.0124C26.3213 61.0124 24.724 62.5525 20.2594 62.5332C15.7948 62.5525 14.1783 61.0124 14.1783 61.0124Z" fill="#231F20"/>
          <path opacity="0.11" d="M25.3594 62.1872C25.3401 62.2642 25.3209 62.3412 25.3209 62.3989C24.8205 62.9187 22.2996 63.3037 20.3367 63.3037C20.279 63.3037 20.2405 63.3037 20.202 63.3037L20.1635 63.3037C20.125 63.3037 20.0865 63.3037 20.0288 63.3037C18.2968 63.3037 15.6989 62.9572 15.1793 62.3989C15.16 62.3412 15.16 62.2834 15.1408 62.1872C16.1415 62.6299 17.7772 63.0727 20.202 63.0727L20.2982 63.0727C22.7229 63.0727 24.3587 62.6107 25.3594 62.1872Z" fill="white"/>
          <g style="mix-blend-mode:overlay" opacity="0.15">
          <path d="M24.6853 8.32381C24.6853 8.32381 19.932 7.61153 15.3712 8.3623L15.2942 7.82329C15.2942 7.82329 20.5479 6.7645 24.8008 7.82329L24.6853 8.32381Z" fill="white"/>
          </g>
          <defs>
          <filter id="filter0_f" x="0.572266" y="0.248047" width="38.4594" height="71.752" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="3.5" result="effect1_foregroundBlur"/>
          </filter>
          </defs>
          </svg>
        `;
    }

    if (service == 'oem' && direction == 'left') {
        return `<svg width="72" height="40" viewBox="0 0 72 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.3" filter="url(#filter0_f)">
          <path d="M55.6971 7.96859C58.1997 7.94934 60.606 8.95003 62.3386 10.759C63.7054 12.183 64.5524 14.0112 64.7834 15.9549C65.0722 18.4181 65.0722 20.9198 64.7834 23.383C64.5332 25.3267 63.6861 27.1549 62.3193 28.5789C60.5868 30.3686 58.1997 31.3886 55.6971 31.3693L51.4812 31.3308L44.9745 30.715L42.1062 30.8112L42.0099 31.2346C41.8751 31.7927 41.4324 32.2161 40.8549 32.3315L40.4121 32.4085L40.2196 32.4277L40.4891 30.869L33.9631 31.0999L11.6324 31.3116C10.2271 31.3308 8.95652 30.5033 8.39825 29.214C7.82073 27.9054 7.51273 26.5006 7.45497 25.0765L7.26247 20.5157C7.24321 19.9384 7.24321 19.3803 7.26247 18.803L7.45497 14.2421C7.51273 12.8181 7.83998 11.4133 8.39825 10.1047C8.95652 8.83457 10.2271 8.00708 11.6324 8.00708L33.9631 8.21876L44.9553 8.60364L51.462 7.98783L55.6971 7.96859Z" fill="#020947"/>
          </g>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M62.072 28.6501C63.4388 27.2261 64.2858 25.3979 64.5168 23.4542C64.8056 20.991 64.8056 18.5085 64.5168 16.0453C64.2858 14.1017 63.4388 12.2735 62.072 10.8494C60.3394 9.04048 57.9524 8.03979 55.4305 8.05903L51.2146 8.09752L44.7079 8.71333L41.5285 8.60201L41.2046 7.21237C41.0698 6.6543 40.627 6.23093 40.0495 6.11546L39.6068 6.03849L39.4143 6L39.872 8.544L33.7158 8.32845L11.385 8.11676C9.97971 8.09752 8.70917 8.92501 8.15091 10.2144C7.57338 11.523 7.26537 12.9278 7.20762 14.3518L7.01511 18.9127C6.99586 19.49 6.99586 20.0481 7.01511 20.6254L7.20762 25.1862C7.26537 26.6103 7.59264 28.0151 8.1509 29.3237C8.70917 30.5938 9.97971 31.4213 11.385 31.4213L33.7158 31.2096L40.1552 30.9841C40.0237 31.0515 39.9255 31.1769 39.8958 31.3251L39.4145 34.0001L39.607 33.9616L40.0498 33.8846C40.6273 33.7691 41.0701 33.3458 41.2049 32.7877L41.6373 30.9322L44.7079 30.8247L51.2146 31.4405L55.4305 31.479C57.9524 31.479 60.3587 30.4591 62.072 28.6501Z" fill="#3366ff"/>
          <g style="mix-blend-mode:overlay" opacity="0.15">
          <path d="M47.2668 28.8829L47.3438 28.9021C47.3438 28.9021 55.0441 28.6712 57.0654 28.1901C58.8365 27.7667 61.9358 25.7846 61.9551 19.9729C61.9551 12.6024 58.336 11.6017 56.5649 11.1784C54.5436 10.6973 47.3438 11.1399 47.3438 11.1399L47.2668 11.1591L47.2668 28.8829Z" fill="white"/>
          </g>
          <g style="mix-blend-mode:overlay" opacity="0.15">
          <path d="M25.5137 27.3624C25.3789 25.5342 25.2634 23.706 25.1287 21.8778C24.9169 18.818 24.7244 15.7582 24.5896 12.6792L22.9726 12.6407C22.9726 12.6407 19.7385 11.1396 19.5845 11.1396C19.4305 11.1396 10.4404 12.6792 10.4404 12.6792C9.24687 14.2379 8.82335 18.0675 8.6886 20.0304C8.82335 21.9933 9.22761 25.8421 10.4404 27.3816C10.4404 27.3816 19.4305 28.9211 19.5845 28.9211C19.7385 28.9211 22.9726 27.4201 22.9726 27.4201L25.5137 27.3624Z" fill="white"/>
          </g>
          <path d="M48.0569 28.9577C48.0569 28.9577 51.5798 25.8209 51.4258 19.8937C51.5798 13.9473 48.0569 10.8105 48.0569 10.8105L40.1256 12.6002C40.9534 14.7363 41.1267 19.5473 41.1267 19.8937C41.1267 20.2209 40.9534 25.0319 40.1256 27.1872L48.0569 28.9577Z" fill="#231F20"/>
          <path opacity="0.11" d="M47.8839 28.3802L47.7876 28.3609L40.7997 26.7829C41.0114 26.0324 41.1847 25.1279 41.3002 24.2235C41.5889 22.0681 41.6467 19.9128 41.6467 19.8743C41.6467 19.8358 41.6082 17.9884 41.3579 16.0255C41.2809 15.3135 41.1654 14.6014 41.0307 13.9471C40.9537 13.62 40.8767 13.2929 40.7997 12.9849L43.9375 12.2729L47.8839 11.3877C48.4037 11.9265 49.8667 13.6585 50.5405 16.4681C50.7522 17.3726 50.9062 18.3925 50.9062 19.5087C50.9062 19.6241 50.9062 19.7396 50.9062 19.8551L50.9062 19.8743C50.9062 19.9898 50.9062 20.086 50.9062 20.1822L50.9062 20.2015C50.9062 20.8365 50.8677 21.4138 50.7907 21.9719C50.3095 25.5898 48.4807 27.7644 47.8839 28.3802Z" fill="white"/>
          <path d="M22.9161 28.1888C22.0883 28.7468 21.2605 29.3049 20.4328 29.863L47.8457 29.863L39.1637 27.6499L23.8401 27.5537C23.5321 27.7654 23.2241 27.9771 22.9161 28.1888Z" fill="#231F20"/>
          <path opacity="0.11" d="M23.0127 29.2661L42.9756 29.2661L39.1062 28.2847L24.6297 28.1885C24.6105 28.1885 23.5324 28.9198 23.0127 29.2661Z" fill="white"/>
          <path d="M32.0979 27.9385L31.4048 29.5935L32.8486 29.3818L32.7524 28.1694L32.0979 27.9385Z" fill="#231F20"/>
          <path d="M22.9161 11.7162C22.0883 11.1581 21.2606 10.6001 20.4328 10.042L47.8457 10.042L39.1637 12.2551L23.8401 12.3513C23.5321 12.1396 23.2241 11.9279 22.9161 11.7162Z" fill="#231F20"/>
          <path opacity="0.11" d="M23.0127 10.6582L42.9756 10.6582L39.1062 11.6396L24.6297 11.7359C24.6105 11.7359 23.5324 11.0046 23.0127 10.6582Z" fill="white"/>
          <path d="M32.0979 11.9665L31.4048 10.3115L32.8486 10.5425L32.7524 11.7356L32.0979 11.9665Z" fill="#231F20"/>
          <path d="M19.4893 29.5352L22.9736 27.3991C21.6453 25.6287 21.6453 14.2555 22.9736 12.5043L19.4893 10.3682L16.9674 10.984C14.3878 11.5998 12.5783 13.9091 12.5783 16.5455L12.5783 23.3386C12.5783 25.9751 14.3878 28.2843 16.9674 28.9002L19.4893 29.5352Z" fill="#231F20"/>
          <path opacity="0.11" d="M19.3924 28.957L17.0823 28.399C14.907 27.8794 13.3477 26.0319 13.1359 23.8574C13.1167 23.6842 13.1167 23.5302 13.1167 23.357L13.1167 16.5639C13.1167 16.256 13.1359 15.9481 13.1937 15.6594C13.2899 15.1206 13.4632 14.601 13.7327 14.1199C14.214 13.2154 14.9455 12.4649 15.8695 11.9838C16.2545 11.7914 16.6588 11.6182 17.0823 11.5219L19.3924 10.9639L22.2607 12.7151C21.972 13.4463 21.7795 14.5433 21.6447 15.6787C21.51 16.9103 21.4522 18.1804 21.4522 19.0464C21.4522 19.4505 21.433 19.7776 21.433 19.9701C21.433 20.1625 21.433 20.4897 21.4522 20.9323C21.4907 22.6257 21.6447 25.7433 22.2607 27.2251L20.2972 28.4182L19.3924 28.957Z" fill="white"/>
          <g style="mix-blend-mode:overlay" opacity="0.2">
          <path d="M39.4126 6L39.8939 8.67492C39.9324 8.86736 40.0864 9.02131 40.2789 9.0598C40.5869 9.11753 40.7986 9.3677 40.7986 9.67561L40.7986 10.0412L41.8574 10.0412L41.6649 9.19451L40.2596 8.27079L39.5858 6.03849L39.4126 6Z" fill="black"/>
          </g>
          <g style="mix-blend-mode:overlay" opacity="0.2">
          <path d="M39.4126 34.0002L39.8938 31.3253C39.9323 31.1329 40.0864 30.9789 40.2789 30.9404C40.5869 30.8827 40.7986 30.6325 40.7986 30.3246L40.7986 29.959L41.8574 29.959L41.6649 30.8057L40.2596 31.7294L39.5858 33.9617L39.4126 34.0002Z" fill="black"/>
          </g>
          <path d="M59.2034 29.612L58.7799 29.5735L57.8366 29.4965C57.3553 29.458 57.2013 28.8422 57.6056 28.5728L60.2237 26.8601C60.3392 26.7831 60.4354 26.7254 60.5509 26.6676C61.0514 26.3982 61.6097 26.2443 62.168 26.1865C62.168 26.3212 62.1487 26.4752 62.1102 26.6099C61.8407 27.5913 60.8204 28.4958 60.0696 29.0539C59.5884 29.4003 59.2034 29.612 59.2034 29.612Z" fill="#F6F6F6"/>
          <path d="M58.7799 29.5735L57.8366 29.4965C57.3553 29.458 57.2013 28.8422 57.6056 28.5728L60.2237 26.8601C60.8012 26.4752 61.4749 26.2443 62.168 26.1865C62.168 26.3212 62.1487 26.4752 62.1102 26.6099C61.9562 26.4175 61.0514 26.6291 60.6279 26.9563C60.1466 27.3219 59.1456 28.1109 58.4718 28.6305C58.1831 28.8614 58.2216 29.3041 58.5681 29.4773L58.7799 29.5735Z" fill="#8C8984"/>
          <path d="M59.2032 29.6123L58.7797 29.5738L57.8364 29.4968C57.3552 29.4583 57.2012 28.8425 57.6054 28.5731L60.2235 26.8604C60.339 26.7834 60.4353 26.7257 60.5508 26.668C60.3775 27.457 60.2235 28.2652 60.0503 29.0542C59.5882 29.4006 59.2032 29.6123 59.2032 29.6123Z" fill="#DFDBE2"/>
          <path d="M59.2034 10.0049L58.7799 10.0434L57.8366 10.1203C57.3553 10.1588 57.2013 10.7746 57.6056 11.0441L60.2237 12.7568C60.3392 12.8338 60.4354 12.8915 60.5509 12.9492C61.0514 13.2186 61.6097 13.3726 62.168 13.4303C62.168 13.2956 62.1487 13.1417 62.1102 13.0069C61.8407 12.0255 60.8204 11.121 60.0696 10.563C59.5884 10.2166 59.2034 10.0049 59.2034 10.0049Z" fill="#F6F6F6"/>
          <path d="M58.7799 10.042L57.8366 10.119C57.3553 10.1575 57.2013 10.7733 57.6056 11.0427L60.2237 12.7554C60.8012 13.1403 61.4749 13.3712 62.168 13.4289C62.168 13.2942 62.1487 13.1403 62.1102 13.0056C61.9562 13.198 61.0514 12.9863 60.6279 12.6592C60.1466 12.2935 59.1456 11.5045 58.4718 10.9849C58.1831 10.754 58.2216 10.3114 58.5681 10.1382L58.7799 10.042Z" fill="#8C8984"/>
          <path d="M59.2032 10.0039L58.7797 10.0424L57.8364 10.1194C57.3552 10.1579 57.2012 10.7737 57.6054 11.0431L60.2235 12.7558C60.339 12.8328 60.4353 12.8905 60.5508 12.9482C60.3775 12.1592 60.2235 11.351 60.0503 10.562C59.5882 10.2156 59.2032 10.0039 59.2032 10.0039Z" fill="#DFDBE2"/>
          <g style="mix-blend-mode:overlay" opacity="0.3">
          <path d="M39.4904 14.1592C39.4904 14.1592 41.1267 20.9716 39.4134 26.0712L25.6155 26.1482L26.6165 24.8781L36.4487 23.1846C36.4295 23.1461 39.6251 18.0465 39.4904 14.1592Z" fill="white"/>
          </g>
          <path d="M8.57415 28.2847C8.36239 27.6881 8.20839 26.8991 8.18914 25.8984L9.36343 26.8029C9.36343 26.8029 9.57518 27.5727 9.90244 28.4002C10.2105 29.1507 10.634 29.9782 11.1152 30.3631C11.1152 30.3438 9.38267 30.4785 8.57415 28.2847Z" fill="#FF3838"/>
          <path d="M8.57405 28.2847C8.3623 27.6881 8.20829 26.8991 8.18904 25.8984L9.36333 26.8029C9.36333 26.8029 9.57508 27.5727 9.90234 28.4002C9.86384 28.3617 9.80609 28.3232 9.76759 28.2655C9.63284 28.15 9.49808 28.0153 9.32483 27.9575C9.03607 27.8613 8.72806 28.0345 8.57405 28.2847Z" fill="#DCDCDC"/>
          <path d="M8.57414 11.0213C8.36238 11.6179 8.20838 12.4069 8.18913 13.4076L9.36342 12.5031C9.36342 12.5031 9.57518 11.7334 9.90244 10.9059C10.2104 10.1554 10.634 9.32787 11.1152 8.94299C11.1152 8.94299 9.38267 8.80828 8.57414 11.0213Z" fill="#FF3838"/>
          <path d="M8.57404 11.0227C8.36228 11.6193 8.20827 12.4083 8.18902 13.4089L9.36332 12.5045C9.36332 12.5045 9.57508 11.7347 9.90234 10.9072C9.86384 10.9457 9.80609 10.9842 9.76759 11.0419C9.63284 11.1574 9.49808 11.2921 9.32482 11.3498C9.03606 11.4268 8.72804 11.2536 8.57404 11.0227Z" fill="#DCDCDC"/>
          <path opacity="0.11" d="M50.9065 20.2212L41.0694 13.9477C40.9924 13.6205 40.9154 13.2934 40.8384 12.9855L43.9763 12.2734L50.56 16.4686C50.7718 17.3731 50.9258 18.393 50.9258 19.5092C50.9258 19.6247 50.9258 19.7401 50.9258 19.8556L50.9258 19.8748C50.9065 20.0095 50.9065 20.1058 50.9065 20.2212Z" fill="white"/>
          <path opacity="0.11" d="M47.8829 28.3791L47.7867 28.3598L41.3184 24.2224C41.6072 22.067 41.6649 19.9117 41.6649 19.8732C41.6649 19.8347 41.6264 17.9873 41.3762 16.0244L50.7705 22.0093C50.3085 25.5887 48.4797 27.7633 47.8829 28.3791Z" fill="white"/>
          <path opacity="0.11" d="M21.4523 19.0294L13.7328 14.1029C14.2141 13.1984 14.9456 12.4479 15.8696 11.9668L21.6641 15.6617C21.5101 16.8933 21.4716 18.1634 21.4523 19.0294Z" fill="white"/>
          <path opacity="0.11" d="M20.2972 28.4189L13.1359 23.8581C13.1167 23.6849 13.1167 23.531 13.1167 23.3578L13.1167 16.5646C13.1167 16.2567 13.1359 15.9488 13.1937 15.6602L21.4522 20.9138C21.4907 22.6072 21.6447 25.7248 22.2607 27.2066L20.2972 28.4189Z" fill="white"/>
          <g style="mix-blend-mode:overlay" opacity="0.3">
          <path d="M60.377 23.2812C60.377 23.2812 55.0638 26.7452 49.5773 27.8613C49.5773 27.8613 57.2391 27.284 58.4519 26.5335C59.6839 25.783 60.377 23.2812 60.377 23.2812Z" fill="white"/>
          </g>
          <g style="mix-blend-mode:overlay" opacity="0.3">
          <path d="M60.377 16.623C60.377 16.623 55.0638 13.1591 49.5773 12.043C49.5773 12.043 57.2391 12.6203 58.4519 13.3708C59.6647 14.1213 60.377 16.623 60.377 16.623Z" fill="white"/>
          </g>
          <path d="M61.0124 25.8217C61.6477 25.4945 62.6102 25.3213 62.6102 25.3213C63.669 24.6286 63.8423 20.5296 63.8038 19.8176C63.8423 19.1055 63.669 14.8718 62.6102 14.1791C62.6102 14.1791 61.6477 14.0059 61.0124 13.6787C61.0124 13.6787 62.5525 15.276 62.5332 19.7406C62.5525 24.2052 61.0124 25.8217 61.0124 25.8217Z" fill="#231F20"/>
          <path opacity="0.11" d="M62.1872 14.6406C62.2642 14.6599 62.3412 14.6791 62.3989 14.6791C62.9187 15.1795 63.3037 17.7004 63.3037 19.6633C63.3037 19.721 63.3037 19.7595 63.3037 19.798L63.3037 19.8365C63.3037 19.875 63.3037 19.9135 63.3037 19.9712C63.3037 21.7032 62.9572 24.3011 62.3989 24.8207C62.3412 24.84 62.2834 24.84 62.1872 24.8592C62.6299 23.8585 63.0727 22.2228 63.0727 19.798L63.0727 19.7018C63.0727 17.2771 62.6107 15.6413 62.1872 14.6406Z" fill="white"/>
          <g style="mix-blend-mode:overlay" opacity="0.15">
          <path d="M8.32381 15.3147C8.32381 15.3147 7.61153 20.068 8.3623 24.6288L7.82329 24.7058C7.82329 24.7058 6.7645 19.4521 7.82329 15.1992L8.32381 15.3147Z" fill="white"/>
          </g>
          <defs>
          <filter id="filter0_f" x="0.248047" y="0.96875" width="71.752" height="38.4594" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="3.5" result="effect1_foregroundBlur"/>
          </filter>
          </defs>
          </svg>         
        `;
    }

    if (service == 'oem' && direction == 'right') {
        return `<svg width="72" height="40" viewBox="0 0 72 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.3" filter="url(#filter0_f)">
          <path d="M16.3029 32.0314C13.8003 32.0507 11.394 31.05 9.66141 29.241C8.29461 27.817 7.44758 25.9888 7.21657 24.0451C6.92781 21.5819 6.92781 19.0802 7.21657 16.617C7.46683 14.6733 8.31386 12.8451 9.68065 11.4211C11.4132 9.63137 13.8003 8.61144 16.3029 8.63069L20.5188 8.66917L27.0255 9.28498L29.8938 9.18876L29.9901 8.76539C30.1249 8.20732 30.5676 7.78395 31.1451 7.66848L31.5879 7.59151L31.7804 7.57226L31.5109 9.13103L38.0369 8.9001L60.3676 8.68841C61.7729 8.66917 63.0435 9.49666 63.6017 10.786C64.1793 12.0946 64.4873 13.4994 64.545 14.9235L64.7375 19.4843C64.7568 20.0616 64.7568 20.6197 64.7375 21.197L64.545 25.7579C64.4873 27.1819 64.16 28.5867 63.6017 29.8953C63.0435 31.1654 61.7729 31.9929 60.3676 31.9929L38.0369 31.7812L27.0448 31.3964L20.538 32.0122L16.3029 32.0314Z" fill="#020947"/>
          </g>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.928 11.3499C8.56121 12.7739 7.71418 14.6021 7.48317 16.5458C7.19441 19.009 7.19441 21.4915 7.48317 23.9547C7.71418 25.8983 8.56121 27.7265 9.92801 29.1506C11.6606 30.9595 14.0476 31.9602 16.5695 31.941L20.7854 31.9025L27.2921 31.2867L30.4715 31.398L30.7954 32.7876C30.9302 33.3457 31.373 33.7691 31.9505 33.8845L32.3932 33.9615L32.5857 34L32.128 31.456L38.2842 31.6715L60.615 31.8832C62.0203 31.9025 63.2908 31.075 63.8491 29.7856C64.4266 28.477 64.7346 27.0722 64.7924 25.6482L64.9849 21.0873C65.0041 20.51 65.0041 19.9519 64.9849 19.3746L64.7924 14.8138C64.7346 13.3897 64.4074 11.9849 63.8491 10.6763C63.2908 9.40622 62.0203 8.57873 60.615 8.57873L38.2842 8.79041L31.8448 9.01588C31.9763 8.94851 32.0745 8.8231 32.1042 8.67486L32.5855 5.99995L32.3929 6.03844L31.9502 6.11541C31.3727 6.23087 30.9299 6.65424 30.7951 7.21232L30.3627 9.06778L27.2921 9.17529L20.7854 8.55949L16.5695 8.521C14.0476 8.521 11.6413 9.54093 9.928 11.3499Z" fill="#3366ff"/>
          <g style="mix-blend-mode:overlay" opacity="0.15">
          <path d="M24.7332 11.1171L24.6562 11.0979C24.6562 11.0979 16.9559 11.3288 14.9346 11.8099C13.1635 12.2333 10.0642 14.2154 10.0449 20.0271C10.0449 27.3976 13.664 28.3983 15.4351 28.8216C17.4564 29.3027 24.6562 28.8601 24.6562 28.8601L24.7332 28.8409L24.7332 11.1171Z" fill="white"/>
          </g>
          <g style="mix-blend-mode:overlay" opacity="0.15">
          <path d="M46.4863 12.6376C46.6211 14.4658 46.7366 16.294 46.8713 18.1222C47.0831 21.182 47.2756 24.2418 47.4104 27.3208L49.0274 27.3593C49.0274 27.3593 52.2615 28.8604 52.4155 28.8604C52.5695 28.8604 61.5596 27.3208 61.5596 27.3208C62.7531 25.7621 63.1766 21.9325 63.3114 19.9696C63.1766 18.0067 62.7724 14.1579 61.5596 12.6184C61.5596 12.6184 52.5695 11.0789 52.4155 11.0789C52.2615 11.0789 49.0274 12.5799 49.0274 12.5799L46.4863 12.6376Z" fill="white"/>
          </g>
          <path d="M23.9431 11.0423C23.9431 11.0423 20.4202 14.1791 20.5742 20.1063C20.4202 26.0527 23.9431 29.1895 23.9431 29.1895L31.8744 27.3998C31.0466 25.2637 30.8733 20.4527 30.8733 20.1063C30.8733 19.7791 31.0466 14.9681 31.8744 12.8128L23.9431 11.0423Z" fill="#231F20"/>
          <path opacity="0.11" d="M24.1161 11.6198L24.2124 11.6391L31.2003 13.2171C30.9886 13.9676 30.8153 14.8721 30.6998 15.7765C30.4111 17.9319 30.3533 20.0872 30.3533 20.1257C30.3533 20.1642 30.3918 22.0116 30.6421 23.9745C30.7191 24.6865 30.8346 25.3986 30.9693 26.0528C31.0463 26.38 31.1233 26.7071 31.2003 27.0151L28.0625 27.7271L24.1161 28.6123C23.5963 28.0735 22.1333 26.3415 21.4595 23.5319C21.2478 22.6274 21.0937 21.6075 21.0937 20.4913C21.0937 20.3759 21.0937 20.2604 21.0937 20.1449L21.0937 20.1257C21.0937 20.0102 21.0937 19.914 21.0937 19.8178L21.0937 19.7985C21.0937 19.1635 21.1323 18.5862 21.2093 18.0281C21.6905 14.4102 23.5193 12.2356 24.1161 11.6198Z" fill="white"/>
          <path d="M49.0839 11.8112C49.9117 11.2532 50.7395 10.6951 51.5672 10.137L24.1543 10.137L32.8363 12.3501L48.1599 12.4463C48.4679 12.2346 48.7759 12.0229 49.0839 11.8112Z" fill="#231F20"/>
          <path opacity="0.11" d="M48.9873 10.7339L29.0244 10.7339L32.8938 11.7153L47.3703 11.8115C47.3895 11.8115 48.4676 11.0802 48.9873 10.7339Z" fill="white"/>
          <path d="M39.9021 12.0615L40.5952 10.4065L39.1514 10.6182L39.2476 11.8306L39.9021 12.0615Z" fill="#231F20"/>
          <path d="M49.0839 28.2838C49.9117 28.8419 50.7394 29.3999 51.5672 29.958L24.1543 29.958L32.8363 27.7449L48.1599 27.6487C48.4679 27.8604 48.7759 28.0721 49.0839 28.2838Z" fill="#231F20"/>
          <path opacity="0.11" d="M48.9873 29.3418L29.0244 29.3418L32.8938 28.3604L47.3703 28.2641C47.3895 28.2641 48.4676 28.9954 48.9873 29.3418Z" fill="white"/>
          <path d="M39.9021 28.0335L40.5952 29.6885L39.1514 29.4575L39.2476 28.2644L39.9021 28.0335Z" fill="#231F20"/>
          <path d="M52.5107 10.4648L49.0264 12.6009C50.3547 14.3713 50.3547 25.7445 49.0264 27.4957L52.5107 29.6318L55.0326 29.016C57.6122 28.4002 59.4217 26.0909 59.4217 23.4545L59.4217 16.6614C59.4217 14.0249 57.6122 11.7157 55.0326 11.0998L52.5107 10.4648Z" fill="#231F20"/>
          <path opacity="0.11" d="M52.6076 11.043L54.9177 11.601C57.093 12.1206 58.6523 13.9681 58.8641 16.1426C58.8833 16.3158 58.8833 16.4698 58.8833 16.643L58.8833 23.4361C58.8833 23.744 58.8641 24.0519 58.8063 24.3406C58.7101 24.8794 58.5368 25.399 58.2673 25.8801C57.786 26.7846 57.0545 27.5351 56.1305 28.0162C55.7455 28.2086 55.3412 28.3818 54.9177 28.4781L52.6076 29.0361L49.7393 27.2849C50.028 26.5537 50.2205 25.4567 50.3553 24.3213C50.49 23.0897 50.5478 21.8196 50.5478 20.9536C50.5478 20.5495 50.567 20.2224 50.567 20.0299C50.567 19.8375 50.567 19.5103 50.5478 19.0677C50.5093 17.3743 50.3553 14.2567 49.7393 12.7749L51.7028 11.5818L52.6076 11.043Z" fill="white"/>
          <g style="mix-blend-mode:overlay" opacity="0.2">
          <path d="M32.5874 34L32.1061 31.3251C32.0676 31.1326 31.9136 30.9787 31.7211 30.9402C31.4131 30.8825 31.2014 30.6323 31.2014 30.3244L31.2014 29.9588L30.1426 29.9588L30.3351 30.8055L31.7404 31.7292L32.4142 33.9615L32.5874 34Z" fill="black"/>
          </g>
          <g style="mix-blend-mode:overlay" opacity="0.2">
          <path d="M32.5874 5.99977L32.1062 8.67469C32.0677 8.86713 31.9136 9.02108 31.7211 9.05957C31.4131 9.1173 31.2014 9.36748 31.2014 9.67538L31.2014 10.041L30.1426 10.041L30.3351 9.19428L31.7404 8.27057L32.4142 6.03826L32.5874 5.99977Z" fill="black"/>
          </g>
          <path d="M12.7966 10.388L13.2201 10.4265L14.1634 10.5035C14.6447 10.542 14.7987 11.1578 14.3944 11.4272L11.7763 13.1399C11.6608 13.2169 11.5646 13.2746 11.4491 13.3324C10.9486 13.6018 10.3903 13.7557 9.83203 13.8135C9.83203 13.6788 9.85128 13.5248 9.88978 13.3901C10.1593 12.4087 11.1796 11.5042 11.9304 10.9461C12.4116 10.5997 12.7966 10.388 12.7966 10.388Z" fill="#F6F6F6"/>
          <path d="M13.2201 10.4265L14.1634 10.5035C14.6447 10.542 14.7987 11.1578 14.3944 11.4272L11.7763 13.1399C11.1988 13.5248 10.5251 13.7557 9.83203 13.8135C9.83203 13.6788 9.85128 13.5248 9.88978 13.3901C10.0438 13.5825 10.9486 13.3709 11.3721 13.0437C11.8534 12.6781 12.8544 11.8891 13.5282 11.3695C13.8169 11.1386 13.7784 10.6959 13.4319 10.5227L13.2201 10.4265Z" fill="#8C8984"/>
          <path d="M12.7968 10.3877L13.2203 10.4262L14.1636 10.5032C14.6448 10.5417 14.7988 11.1575 14.3946 11.4269L11.7765 13.1396C11.661 13.2166 11.5647 13.2743 11.4492 13.332C11.6225 12.543 11.7765 11.7348 11.9497 10.9458C12.4118 10.5994 12.7968 10.3877 12.7968 10.3877Z" fill="#DFDBE2"/>
          <path d="M12.7966 29.9951L13.2201 29.9566L14.1634 29.8797C14.6447 29.8412 14.7987 29.2254 14.3944 28.9559L11.7763 27.2432C11.6608 27.1662 11.5646 27.1085 11.4491 27.0508C10.9486 26.7814 10.3903 26.6274 9.83203 26.5697C9.83203 26.7044 9.85128 26.8583 9.88978 26.9931C10.1593 27.9745 11.1796 28.879 11.9304 29.437C12.4116 29.7834 12.7966 29.9951 12.7966 29.9951Z" fill="#F6F6F6"/>
          <path d="M13.2201 29.958L14.1634 29.881C14.6447 29.8425 14.7987 29.2267 14.3944 28.9573L11.7763 27.2446C11.1988 26.8597 10.5251 26.6288 9.83203 26.5711C9.83203 26.7058 9.85128 26.8597 9.88978 26.9944C10.0438 26.802 10.9486 27.0137 11.3721 27.3408C11.8534 27.7065 12.8544 28.4955 13.5282 29.0151C13.8169 29.246 13.7784 29.6886 13.4319 29.8618L13.2201 29.958Z" fill="#8C8984"/>
          <path d="M12.7968 29.9961L13.2203 29.9576L14.1636 29.8806C14.6448 29.8421 14.7988 29.2263 14.3946 28.9569L11.7765 27.2442C11.661 27.1672 11.5647 27.1095 11.4492 27.0518C11.6225 27.8408 11.7765 28.649 11.9497 29.438C12.4118 29.7844 12.7968 29.9961 12.7968 29.9961Z" fill="#DFDBE2"/>
          <g style="mix-blend-mode:overlay" opacity="0.3">
          <path d="M32.5096 25.8408C32.5096 25.8408 30.8733 19.0284 32.5866 13.9288L46.3845 13.8518L45.3835 15.1219L35.5513 16.8154C35.5705 16.8539 32.3749 21.9535 32.5096 25.8408Z" fill="white"/>
          </g>
          <path d="M63.4259 11.7153C63.6376 12.3119 63.7916 13.1009 63.8109 14.1016L62.6366 13.1971C62.6366 13.1971 62.4248 12.4273 62.0976 11.5998C61.7895 10.8493 61.366 10.0218 60.8848 9.63695C60.8848 9.65619 62.6173 9.52148 63.4259 11.7153Z" fill="#FF3838"/>
          <path d="M63.4259 11.7153C63.6377 12.3119 63.7917 13.1009 63.811 14.1016L62.6367 13.1971C62.6367 13.1971 62.4249 12.4273 62.0977 11.5998C62.1362 11.6383 62.1939 11.6768 62.2324 11.7345C62.3672 11.85 62.5019 11.9847 62.6752 12.0425C62.9639 12.1387 63.2719 11.9655 63.4259 11.7153Z" fill="#DCDCDC"/>
          <path d="M63.4259 28.9787C63.6376 28.3821 63.7916 27.5931 63.8109 26.5924L62.6366 27.4969C62.6366 27.4969 62.4248 28.2666 62.0976 29.0941C61.7896 29.8446 61.366 30.6721 60.8848 31.057C60.8848 31.057 62.6173 31.1917 63.4259 28.9787Z" fill="#FF3838"/>
          <path d="M63.426 28.9773C63.6377 28.3807 63.7917 27.5917 63.811 26.5911L62.6367 27.4955C62.6367 27.4955 62.4249 28.2653 62.0977 29.0928C62.1362 29.0543 62.1939 29.0158 62.2324 28.9581C62.3672 28.8426 62.5019 28.7079 62.6752 28.6502C62.9639 28.5732 63.272 28.7464 63.426 28.9773Z" fill="#DCDCDC"/>
          <path opacity="0.11" d="M21.0935 19.7788L30.9306 26.0523C31.0076 26.3795 31.0846 26.7066 31.1616 27.0145L28.0237 27.7266L21.44 23.5314C21.2282 22.6269 21.0742 21.607 21.0742 20.4908C21.0742 20.3753 21.0742 20.2599 21.0742 20.1444L21.0742 20.1252C21.0935 19.9905 21.0935 19.8942 21.0935 19.7788Z" fill="white"/>
          <path opacity="0.11" d="M24.1171 11.6209L24.2133 11.6402L30.6816 15.7776C30.3928 17.933 30.3351 20.0883 30.3351 20.1268C30.3351 20.1653 30.3736 22.0127 30.6238 23.9756L21.2295 17.9907C21.6915 14.4113 23.5203 12.2367 24.1171 11.6209Z" fill="white"/>
          <path opacity="0.11" d="M50.5477 20.9706L58.2672 25.8971C57.7859 26.8016 57.0544 27.5521 56.1304 28.0332L50.3359 24.3383C50.4899 23.1067 50.5284 21.8366 50.5477 20.9706Z" fill="white"/>
          <path opacity="0.11" d="M51.7028 11.5811L58.8641 16.1419C58.8833 16.3151 58.8833 16.469 58.8833 16.6422L58.8833 23.4354C58.8833 23.7433 58.8641 24.0512 58.8063 24.3398L50.5478 19.0862C50.5093 17.3928 50.3553 14.2752 49.7393 12.7934L51.7028 11.5811Z" fill="white"/>
          <g style="mix-blend-mode:overlay" opacity="0.3">
          <path d="M11.623 16.7188C11.623 16.7188 16.9362 13.2548 22.4227 12.1387C22.4227 12.1387 14.7609 12.716 13.5481 13.4665C12.3161 14.217 11.623 16.7188 11.623 16.7188Z" fill="white"/>
          </g>
          <g style="mix-blend-mode:overlay" opacity="0.3">
          <path d="M11.623 23.377C11.623 23.377 16.9362 26.8409 22.4227 27.957C22.4227 27.957 14.7609 27.3797 13.5481 26.6292C12.3353 25.8787 11.623 23.377 11.623 23.377Z" fill="white"/>
          </g>
          <path d="M10.9876 14.1783C10.3523 14.5055 9.38978 14.6787 9.38978 14.6787C8.33099 15.3714 8.15773 19.4704 8.19624 20.1824C8.15773 20.8945 8.33099 25.1282 9.38978 25.8209C9.38978 25.8209 10.3523 25.9941 10.9876 26.3213C10.9876 26.3213 9.44753 24.724 9.46678 20.2594C9.44753 15.7948 10.9876 14.1783 10.9876 14.1783Z" fill="#231F20"/>
          <path opacity="0.11" d="M9.81283 25.3594C9.73582 25.3401 9.65882 25.3209 9.60107 25.3209C9.0813 24.8205 8.69629 22.2996 8.69629 20.3367C8.69629 20.279 8.69629 20.2405 8.69629 20.202L8.69629 20.1635C8.69629 20.125 8.69629 20.0865 8.69629 20.0288C8.69629 18.2968 9.0428 15.6989 9.60107 15.1793C9.65882 15.16 9.71657 15.16 9.81283 15.1408C9.37006 16.1415 8.9273 17.7772 8.9273 20.202L8.9273 20.2982C8.9273 22.7229 9.38931 24.3587 9.81283 25.3594Z" fill="white"/>
          <g style="mix-blend-mode:overlay" opacity="0.15">
          <path d="M63.6762 24.6853C63.6762 24.6853 64.3885 19.932 63.6377 15.3712L64.1767 15.2942C64.1767 15.2942 65.2355 20.5479 64.1767 24.8008L63.6762 24.6853Z" fill="white"/>
          </g>
          <defs>
          <filter id="filter0_f" x="0" y="0.572266" width="71.752" height="38.4594" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="3.5" result="effect1_foregroundBlur"/>
          </filter>
          </defs>
          </svg>      
        `;
    }
  }
}
