export interface Role {
    name: string;
    code: string;
    id: number;
    entityId: number;
    isCustom: boolean; // false is 'system' role
    isActive: boolean;
}


export type PermissionAction = "create" | "view" | "update" | "delete";

export const PERMISSION_ACTIONS: Array<PermissionAction> = ["create", "view", "update", "delete"];

export interface SubPermission {
    code: string;
    name: string;
    actions: {
        [key in PermissionAction]?: boolean
    };
}

export interface PermissionModule {
    code: string;
    name: string;
    actions?: {
        [key in PermissionAction]?: boolean
    };
    permissions?: Array<SubPermission>
}

export type Permissions = PermissionModule[];