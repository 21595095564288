import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { NbDialogRef } from '@nebular/theme';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { BookingListService } from '../../../@core/akita-stores/entity-stores/bookings/bookings.service';

@Component({
  selector: 'add-signature-dialog',
  templateUrl: './add-signature-dialog.component.html',
  styleUrls: ['./add-signature-dialog.component.scss']
})
export class AddSignatureDialogComponent implements OnInit {
  @ViewChild(SignaturePad, { static: false }) public signaturePad: SignaturePad;
  @ViewChild('wrapSignaturePadRef', { static: false }) wrapSignaturePadRef: ElementRef;
  bookingId: any;
  checkinBooking;
  isDisabled: boolean = true;
  public signaturePadOptions: Object = {
    'minWidth': 5,
    'canvasWidth': 500,
    'canvasHeight': 300
  };
  bookingDetail;
  isUneditableMode: boolean;
  constructor(protected ref: NbDialogRef<AddSignatureDialogComponent>, 
    private bookingListService: BookingListService, ) { }

  ngOnInit() {
    if(this.checkinBooking) this.checkinBooking.unsubscribe();
  }

  ngAfterViewInit() {
    this.signaturePad.set('minWidth', 1);
    this.signaturePad.set('canvasWidth', this.wrapSignaturePadRef.nativeElement.offsetWidth - 2);
    this.clear();
  }

  clear() {
    this.signaturePad.clear();
    this.isDisabled = true;
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    this.isDisabled = this.signaturePad.toDataURL() ? false : true;
  }

  checkIn() {
    // get base64
    if(!this.signaturePad.toDataURL()){
      return;
    }
    let data = {
      "customerSignature": this.signaturePad.toDataURL(),
      "isForceCheckInEarly": true,
      "isForceCheckInLate": true
    }
   this.checkinBooking = this.bookingListService.checkinBooking(this.bookingId, data).subscribe(res => {
    this.ref.close("done");
    });
   
  }

  close() {
    this.ref.close();
  }
}
