import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
// import { BookingCalendarService, BookingCalendarQuery } from '../../../@core/akita-stores/stores/booking-calendar/booking-calendar.store';

@Component({
  selector: 'sidebar-beverages',
  templateUrl: './sidebar-beverages.component.html',
  styleUrls: ['./sidebar-beverages.component.scss']
})
export class SidebarBeveragesComponent implements OnInit, OnDestroy {

  @Output() calendarChange: EventEmitter<any> = new EventEmitter();
  public viewCalendar: boolean = true;
  viewCalendar$;
  constructor(private router: Router,
    // private bookingCalendarService: BookingCalendarService,
    // public query: BookingCalendarQuery, 
    ) { }

  ngOnInit() {
    // if (this.viewCalendar$) this.viewCalendar$.unsubscribe();
    // this.viewCalendar$ = this.query.viewCalendar$.subscribe(() => {
    //   this.viewCalendar = this.query.getValue().viewCalendar;
    // })
  }

  onCalendarChange(event) {
    this.calendarChange.emit(event);
  }

  redirectTo(view) {
    // if (view === 'pages/booking') {
    //   this.bookingCalendarService.updateViewCalendar(true);
    // }
    // else {
    //   this.bookingCalendarService.updateViewCalendar(false);
    // }
    // this.router.navigateByUrl(view);
  }

  ngOnDestroy() {
    if (this.viewCalendar$) this.viewCalendar$.unsubscribe();
  }
}
