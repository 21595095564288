import { Component, OnInit, Input } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { BookingDetailsSelectedQuery } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.query';
import * as moment from 'moment-timezone';
import { BookingDetailsSelectedService } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.service';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { getTimezone } from '../../../@core/akita-stores/storage';
import * as _ from 'lodash';
import { ConfigService } from '../../../@core/akita-stores/stores/config/config.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss']
})
export class ActivityLogComponent implements OnInit {
  bookingActivities;
  statusRefundedBookingActivities;
  statusCompletedBookingActivities;
  arrStatus = [];
  bookingId;
  bookingStatus = [];
  activityLog$;
  bookingDetails;
  isBookingDetailsLoading = false;
  bookingUserId;
  bookingCustomerName;
  bookingPaymentId;
  activityLogLoading = true;
  timezone = getTimezone();
  @Input() types: string;

  private destroy$ = new Subject<void>();
  constructor(
    private bookingsSelectedQuery: BookingDetailsSelectedQuery,
    private bookingsService: BookingDetailsSelectedService,
    private route: ActivatedRoute,
    private toastrService: NbToastrService,
    private configService: ConfigService,
  ) {
    this.bookingId = this.route.snapshot.params.bookingId;
  }

  ngOnInit() {
    this.activityLog();

    this.configService.refreshBookings.pipe(takeUntil(this.destroy$)).subscribe(isRefresh => {
      console.log("refreshBookings -> ActivityLogComponent", isRefresh);
      if (!isRefresh) return;
      this.activityLog();
    });
  }

  activityLog() {
    if (this.types === 'booking') {
      this.isBookingDetailsLoading = true;
      this.activityLog$ = this.bookingsService.getStatusList().pipe(
        takeUntil(this.destroy$),
        tap(async (res: any) => {
          this.bookingStatus = res.results
          await this.getBookingDetails();

        this.bookingsService.getBookingActivities(this.bookingId).subscribe((val: any) => {
          this.bookingActivities = val.results.map((item, itemIndex) => {
            const tags = (item.tags || '').split(',');
            let icon = 'file';
            let name = "Admin";
            let time = null;
            let event = null;
            let oldValue = null;
            let newValue = item.data && item.data.newBookingStatusId ? this.getStatusCode(item.data.newBookingStatusId, this.bookingStatus) : null;
            let attention = null;
            let total = item;
            let status = item.data && item.data.status ? item.data.status : '';

            if (item.createdBy) {
              // Customer action (heavy)
              if (item.createdBy === this.bookingUserId) {
                name = `Customer - ${this.bookingCustomerName}`;
              }
              else if (item.createdBy === 'guest') {
                // Customer create booking action (lite)
                if (
                  tags.includes('create') &&
                  tags.includes('booking') &&
                  itemIndex === val.results.length - 1
                ) {
                  name = `Customer - ${this.bookingCustomerName}`;
                }
              }
              // User action
              else if (_.get(item, "createdUser.userId")) {
                name = `${_.get(item, "createdUser.role")} - ${_.get(item, "createdUser.firstName")} ${_.get(item, "createdUser.lastName")}`;
              }
              // Default (no expected)
              else {
                name = "Admin";
              }
            }

            // Create action
            if (tags.includes('create')) {
              icon = 'file-plus';
              time = moment.utc(item.createdAt).tz(this.timezone).format('DD/MM/YYYY - hh:mm A');
              // Create Booking
              if (tags.includes('booking')) {
                event = 'Booking Created';
              }
              // Create Booking Payment
              if (tags.includes('booking') && tags.includes('bookingpayment')) {
                event = 'Booking payment';
              }
            }

            // Update action
            else if (tags.includes('update')) {
              icon = 'file';
              time = moment.utc(item.updatedAt).tz(this.timezone).format('DD/MM/YYYY - hh:mm A');

              // Update Booking Status
              if (tags.includes('status')) {
                oldValue = this.getStatusCode(item.data.oldBookingStatusId, this.bookingStatus);
                newValue = this.getStatusCode(item.data.newBookingStatusId, this.bookingStatus);
                event = oldValue ? 'Status has been changed from' : 'Status change to';
              }
              // Update Appointment
              else if (tags.includes('appointment')) {
                icon = 'calendar';
                event = 'Appointment change to';
                attention = moment(item.data.newBookedAt).format('DD/MM/YYYY - hh:mm A');
              }
              // Update Appointment Ref
              else if (tags.includes('appointmentRef')) {
                oldValue = _.get(item, "data.oldAppointmentRef") || '-';
                newValue = _.get(item, "data.newAppointmentRef") || '-';
                event = oldValue ? 'Appointment Ref has been changed from' : 'Appointment Ref change to';
              }
              // Update Booking Admin Note
              else if (tags.includes('admin-booking-note')) {
                oldValue = _.get(item, "data.oldAdminBookingNote") || '-';
                newValue = _.get(item, "data.newAdminBookingNote") || '-';
                event = 'Booking Note has been changed from';
              }
            }
            return {
              icon,
              name,
              time,
              tags,
              event,
              oldValue,
              newValue,
              attention,
              total,
              status,
            }
          });

          this.activityLogLoading = false;

          this.statusRefundedBookingActivities = val.results.find(element => element.data.status == "refunded");
          this.statusCompletedBookingActivities = val.results.find(element => element.data.status == "completed");
          if (this.statusRefundedBookingActivities) {
            this.arrStatus.push(this.statusRefundedBookingActivities)
          }
          if (this.statusCompletedBookingActivities) {
            this.arrStatus.push(this.statusCompletedBookingActivities)
          }
        });
      }),
      catchError((err) => {
        this.activityLogLoading = false;
        this.isBookingDetailsLoading = false;
        return err;
      }),
    ).subscribe();
    }
  }

  getStatusCode(statusId: number, statusList: any) {
    let statusCode;
    for (let i = 0; i < statusList.length; i++) {
      if (statusId === statusList[i].id) {
        statusCode = statusList[i].description || statusList[i].code.split('-').join(' ');
      }
    }
    return statusCode;
  };

  async getBookingDetails() {
    await this.bookingsService.getBookingDetails(this.bookingId).toPromise();
    this.isBookingDetailsLoading = false;
    this.bookingDetails = this.bookingsSelectedQuery.getValue();
    this.bookingPaymentId = _.get(this.bookingDetails, 'bookingPayments[0].id');
    this.bookingUserId = _.get(this.bookingDetails, "userId") || _.get(this.bookingDetails, 'customer.userId');
    this.bookingCustomerName = this.bookingDetails.customer.firstName + ' ' + this.bookingDetails.customer.lastName;
  };
  deleteLogActivities() {
    this.bookingsService.deleteBookingActivities(this.bookingId, this.bookingPaymentId).subscribe(() => {
      this.toastrService.success("Booking refunded successfully");
      this.activityLog()
    })
  }

  ngOnDestroy() {
    if (this.activityLog$) this.activityLog$.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
