import * as _ from 'lodash';

export class APIService {
    constructor() { }

    static prepareURI(URI: string, replacements: any = {}) {
        _.each(replacements, (value: string, key: string) => {
            let find = new RegExp('{' + key + '}');
            URI = URI.replace(find, value);
        });
        return URI;
    }
}