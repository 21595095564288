import { Component, Input, Output, OnInit, Renderer2, EventEmitter, OnDestroy } from '@angular/core';
import { NbSidebarService } from '@nebular/theme';
import { UserQuery } from '../../../@core/akita-stores/stores/user/user.query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ngx-manage-bookings-layout',
  styleUrls: ['./manage-bookings.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-column class="small sidebar-bookings" id="box-sidebar-bookings">
        <nb-sidebar tag="sidebar-bookings" id="sidebar-bookings" containerFixed="false" state="expanded">
          <sidebar-bookings (calendarChange)="onCalendarChange($event)" id="sidebar-bookings__content"></sidebar-bookings>
        </nb-sidebar>
        <nb-icon pack="mo-icons" icon="chevron-left-alt" id="icon-toggle-sidebar" class="icon-toggle-sidebar" (click)="toggle()"></nb-icon>
      </nb-layout-column>
      <nb-layout-column id="box-main-bookings" class="box-main-bookings__expanded" [nbSpinner]="(isLogin$ | async) == false">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>
    </nb-layout>
  `,
})
export class ManageBookingsLayoutComponent implements OnInit, OnDestroy {

  @Output() calendarChange:EventEmitter<any> = new EventEmitter();

  isLogin$ = this.userQuery.isLogin$;
  protected destroy$ = new Subject<void>();

  constructor(
    private sidebarService: NbSidebarService,
    private renderer: Renderer2,
    private userQuery: UserQuery,
  ) { }

  ngOnInit() {
    this.renderer.addClass(document.getElementById('icon-toggle-sidebar'), 'd-flex');
    this.renderer.removeClass(document.getElementById('icon-toggle-sidebar'), 'd-none');
    this.sidebarService.onToggle().pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (data.tag == 'sidebar-bookings') {
        const _class = document.getElementById('sidebar-bookings').getAttribute('class');
        if (_class.includes('expanded')) {
          // this.renderer.setStyle(document.getElementById('icon-toggle-sidebar'), 'right', '294px');
          // this.renderer.addClass(document.getElementById('icon-toggle-sidebar'), 'd-none');
          // this.renderer.removeClass(document.getElementById('icon-toggle-sidebar'), 'd-none');
          
          this.renderer.setStyle(document.getElementById('box-sidebar-bookings'), 'max-width', '0px');
          this.renderer.addClass(document.getElementById('sidebar-bookings__content'), 'd-none');
          this.renderer.removeClass(document.getElementById('box-main-bookings'), 'box-main-bookings__expanded');
        } else if (_class.includes('compacted')) {
          // this.renderer.addClass(document.getElementById('icon-toggle-sidebar'), 'd-flex');
          // this.renderer.removeClass(document.getElementById('icon-toggle-sidebar'), 'd-none');

          this.renderer.setStyle(document.getElementById('box-sidebar-bookings'), 'max-width', '360px');
          this.renderer.removeClass(document.getElementById('sidebar-bookings__content'), 'd-none');
          this.renderer.addClass(document.getElementById('box-main-bookings'), 'box-main-bookings__expanded');
        }
      }
    });
  }

  ngOnDestroy() {

  }

  toggle() {
    this.sidebarService.toggle(true, 'sidebar-bookings');
  }

  onCalendarChange(event) {
    this.calendarChange.emit(event);
  }
}
