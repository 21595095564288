import { Injectable } from "@angular/core";
import { StoreConfig, Store, Query } from '@datorama/akita';
import { BookingDetails } from '../booking-details-selected/booking-details-selected.store';

export enum BookingEnum {
  EXPRESS = "EXPRESS",
  LOGBOOK = "LOGBOOK"
}

export interface Config {
  clonnedBookingDetailsSelected: BookingDetails,
  action: '',
  inputAmount: 0.00,
  inputVehicle: any,
  creditCardPaymentProcess: CreditCardPaymentProcess[],
  loading: boolean,
  companies: []
}

export interface CreditCardPaymentProcess {
  bookingId: number,
  makingCreditCardPayment: boolean
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'config', resettable: true })
export class ConfigStore extends Store<Config> {
  constructor() {
    super({
      clonnedBookingDetailsSelected: {
        "id": 0,
        "bookedAt": "",
        "bookedAtUtc": "",
        "userId": "",
        "serviceTimeInMinutes": 0,
        "serviceCenter": {
          "openingHours": [
            {
              "date": "",
              "open": "",
              "close": ""
            }
          ],
          "id": 0,
          "name": "",
          "entityId": 0,
          "contacts": [
            {
              "id": 0,
              "contact": {
                "id": 0,
                "type": "",
                "value": ""
              },
              "isPreferred": true,
              "isVerified": true
            }
          ],
          "addresses": [
            {
              "id": 0,
              "address": {
                "id": 0,
                "type": "",
                "streetLine1": "",
                "streetLine2": "",
                "city": "",
                "state": "",
                "postCode": "",
                "suburb": "",
                "country": "",
                "longitude": 0,
                "latitude": 0,
                "others": {
                  "timezone": ""
                }
              }
            }
          ]
        },
        "customerContact": {
          "id": 0,
          "contact": {
            "id": 0,
            "type": "",
            "value": ""
          },
          "isPreferred": true,
          "isVerified": true
        },
        "bookingStatus": {
          "id": 0,
          "description": "",
          "code": ""
        },
        "serviceType": {
          "id": 0,
          "description": "",
          "code": ""
        },
        "bay": {
          "id": 0,
          "bayNo": "",
          "description": "",
          "pod": {
            "id": 0,
            "name": ""
          }
        },
        "pod": {
          "id": 0,
          "name": ""
        },
        "customer": {
          "id": 0,
          "firstName": "",
          "lastName": "",
          "title": "",
          "gender": "",
          "dob": "",
          "contacts": [
            {
              "id": 0,
              "contact": {
                "id": 0,
                "type": "",
                "value": ""
              },
              "isPreferred": true,
              "isVerified": true
            }
          ],
          "addresses": [
            {
              "id": 0,
              "address": {
                "id": 0,
                "type": "",
                "streetLine1": "",
                "streetLine2": "",
                "city": "",
                "state": "",
                "postCode": "",
                "suburb": "",
                "country": "",
                "longitude": 0,
                "latitude": 0,
                "others": {
                  "timezone": ""
                }
              }
            }
          ]
        },
        "vehicle": {
          "customerId": 0,
          "id": 0,
          "year": 0,
          "month": 0,
          "make": "",
          "model": "",
          "variant": "",
          "description": "",
          "colour": "",
          "rego": "",
          "cylinder": 0,
          "odometer": 0
        },
        "jobs": [
          {
            "id": 0,
            "name": "",
            "description": "",
            "code": "",
            "serviceNote": "",
            "price": 0,
            "isFixedPrice": false,
            "priceDiscount": 0,
            "npPrice": 0,
            "npPriceDiscount": 0,
            "adjustedPrice": 0,
            "adjustedPriceDiscount": 0,
            "duration": 0,
            "km": 0,
            "serviceType": {
              "id": 0,
              "description": "",
              "code": ""
            },
            "relatedId": 0,
            "parts": [
              {
                "relatedId": 0,
                "id": 0,
                "description": "",
                "sku": "",
                "partNumber": "",
                "partType": "",
                "qty": 0,
                "unitPrice": 0,
                "price": 0,
                "priceDiscount": 0,
                "currency": "",
                "unit": ""
              }
            ],
            "provider": "",
            "npQuoteId": "",
            "qualifiedAtUtc": "2020-04-07T05:50:17.347Z",
            "qualifiedBy": "",
            "paymentId": 0
          }
        ],
        "extraOptions": [
          {
            "id": 0,
            "code": "",
            "name": "",
            "description": "",
            "serviceTime": 0,
            "priceExclTax": 0,
            "priceDiscount": 0,
            "sortOrder": 0,
            "isBeverage": true,
            "relatedId": 0,
            "npQuoteId": "",
            "qualifiedAtUtc": "2020-04-07T05:50:17.347Z",
            "qualifiedBy": "",
            "parts": [
              {
                "relatedId": 0,
                "id": 0,
                "description": "",
                "sku": "",
                "partNumber": "",
                "partType": "",
                "qty": 0,
                "unitPrice": 0,
                "price": 0,
                "priceDiscount": 0,
                "currency": "",
                "unit": ""
              }
            ]
          }
        ],
        "bookingPayments": [
          {
            "id": 0,
            "type": "",
            "amt": 0,
            "status": "",
            "statusDetails": "",
            "reference": "",
            "amtExclDiscount": 0,
            "paymentFeeAmt": 0,
            "paymentFeeAmtExclDiscount": 0,
            "amtExclPaymentFee": 0,
            "subtotal": 0,
            "subtotalExclDiscount": 0,
            "gst": 0,
            "gstExclDiscount": 0,
            "saved": 0,
            "createdAt": "",
            "coupon": {
              "id": 0,
              "code": "",
              "name": "",
              "description": "",
              "discountPercent": 0,
              "isExpired": true
            }
          }
        ],
        "customerNote": "",
        "amount": 0,
        "repairOrder": {
          "id": 0,
          "customerSignature": ""
        },
        timeSlots: [],
        "quoteRequestKey": "",
        "npVehicleReference": ""
      },
      creditCardPaymentProcess: [],
      loading: false
    });
  }

  setCompanies(companies){
    this.update({
      companies: companies
    })
  }

  updateClonnedBookingDetails(bookingDetails) {
    this.update({
      clonnedBookingDetailsSelected: bookingDetails
    });
  }

  setInputAmount(amount) {
    this.update({
      inputAmount: amount
    });
  }

  setInputVehicle(val) {
    this.update({
      inputVehicle: val
    });
  }

  /**
   * 
   *
   * @param {*} action - 'viewBooking' or 'manageBooking'
   * @memberof ConfigStore
   */
  setAction(action) {
    this.update({
      action: action
    });
  }

  setCreditCardPaymentProcess(arrValue: CreditCardPaymentProcess[]) {
    this.update({
      creditCardPaymentProcess: arrValue
    });
  }

  setLoading(value: boolean) {
    this.update({
      loading: value
    });
  }
}


@Injectable({ providedIn: 'root' })
export class ConfigQuery extends Query<Config> {
  clonnedBookingDetailsSelected$ = this.select('clonnedBookingDetailsSelected');
  action$ = this.select('action');
  inputVehicle = this.select('inputVehicle');
  $loading = this.select('loading');

  constructor(protected store: ConfigStore) {
    super(store);
  }

  get getCompanies(){
    return this.getValue().companies;
  }
}