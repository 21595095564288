import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { CommonService } from './../../../@core/utils/common.service';
import * as _ from 'lodash';
import { BookingDetailsSelectedStore } from './../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.store';
import { BookingDetailsSelectedQuery } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.query';
import { ConfigStore } from '../../../@core/akita-stores/stores/config/config.store';
import { Observable, of, throwError } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { NbToastrService } from '@nebular/theme';
import { PermissionsService } from './../../../@core/utils/permissions.service';
import { BOOKING_PERMISSIONS } from '../../../constants/booking-permissions';
import { NgxPermissionsService } from 'ngx-permissions';
import { BookingDetailsSelectedService } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.service';

@Component({
  selector: 'free-beverage',
  templateUrl: './free-beverage.component.html',
  styleUrls: ['./free-beverage.component.scss']
})
export class FreeBeverageComponent implements OnInit {
  @Input('bookingDetails') bookingDetails;

  public isActive = false;
  slideConfig = {
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 6,
    variableWidth: true,
    adaptiveHeight: true,
    prevArrow: false,
    nextArrow: false,
    responsive: [
      {
        breakpoint: 1441,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          variableWidth: true,
        }
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          variableWidth: true,
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          variableWidth: true,
        }
      }
    ]
  };

  list = [];
  isUneditableMode: boolean;
  isBookingPaid: boolean = false;
  @ViewChild('slickCarousel', { static: false }) slickCarousel: any;
  chosenIdx: number;
  hide: boolean = false;

  constructor(
    private bookingDetailsQuery: BookingDetailsSelectedQuery,
    private cdr: ChangeDetectorRef,
    private commonService: CommonService,
    private bookingDetailsSelectedStore: BookingDetailsSelectedStore,
    private bookingDetailsService: BookingDetailsSelectedService,
    private configStore: ConfigStore,
    private toastrService: NbToastrService,
    private ngxPermissions: NgxPermissionsService,
    private permissionsService: PermissionsService
  ) {
    this.bookingDetailsQuery.bookingDetailsSelected$.subscribe((val: any) => {
      this.isUneditableMode = this.commonService.isUneditableMode(val);
      this.isBookingPaid = this.bookingDetailsService.isPaid(val.bookingPayments);
      this.ngxPermissions.hasPermission(BOOKING_PERMISSIONS.updateBooking)
        .then(hasUpdatePermission => {
          if (!hasUpdatePermission) {
            this.isUneditableMode = true;
          }
        });
    });
  }

  ngOnInit() {
    this.commonService.getBeverages().subscribe((res: any) => {
      this.list = res.results;
      this.list.forEach(item => {
        item.url = item.name !== 'Flat White' ? `/assets/images/service/beverages/${item.code}.svg` : `/assets/images/service/Flat-White.svg`;
        if (this.isItemAvai(item, this.bookingDetails.extraOptions).isAvai) {
          item.active = true;
        }
      });
    })
  }

  async chooseItem(e, item, idx) {
    const itemAvaiCheck = this.isItemAvai(item, this.bookingDetails.extraOptions);
    if (itemAvaiCheck.isAvai) {
      const hasPermission = await this.ngxPermissions.hasPermission(BOOKING_PERMISSIONS.removeBookingExtraOption);
      if (!hasPermission) {
        this.permissionsService.showPermissionError();
        return;
      }
    } else {
      const hasPermission = await this.ngxPermissions.hasPermission(BOOKING_PERMISSIONS.addBookingExtraOption)
      if (!hasPermission) {
        this.permissionsService.showPermissionError();
        return;
      }
    }
    this.chosenIdx = idx;
    item = Object.assign({}, item);
    item.isBeverage = true;
    this.bookingDetails = Object.assign({}, this.bookingDetails);
    /** For some reasons it is not allowed to push to extraOptions array directly, this is a workaround solution */
    this.bookingDetails.extraOptions = Object.assign([], this.bookingDetails.extraOptions);
    const onFail = () => {
      this.hide = true;
      this.list.forEach(option => {
        option = Object.assign({}, option);
        const itemAvaiCheck = this.isItemAvai(option, this.bookingDetails.extraOptions);

        if (itemAvaiCheck.isAvai) {
          option.active = true;
          option.relatedId = this.bookingDetails.extraOptions[itemAvaiCheck.idx].relatedId;
        }
      });
      this.chosenIdx = null;
      this.cdr.detectChanges();
      this.hide = false;
    }

    if (itemAvaiCheck.isAvai) {

      const deleteSpecialOffer = () => {
        e.currentTarget.classList.remove("active");
        this.commonService.deleteSpecialOffer([this.bookingDetails.extraOptions[itemAvaiCheck.idx].relatedId], this.bookingDetails.id).subscribe(val => {
          _.pullAt(this.bookingDetails.extraOptions, itemAvaiCheck.idx);
          this.bookingDetailsSelectedStore.updateStore(this.bookingDetails);
          this.configStore.updateClonnedBookingDetails(this.bookingDetails);
          this.list[this.chosenIdx].active = false;
          this.chosenIdx = null;
        }, err => {
          onFail();
        });
      };
      deleteSpecialOffer();
    } else {

      const addSpecialOffer = () => {
        this.tryToDeleteOtherChosenBeverage().subscribe(success => {
          const data = [{
            additionalOptionId: item.id
          }];

          this.list.forEach(element => {
            element.active = false;
          });

          this.commonService.addSpecialOffer(data, this.bookingDetails.id).subscribe((res: any) => {
            this.hide = true;
            this.list[this.chosenIdx].active = true;
            const relatedId = res.results[0].id;
            item = Object.assign({}, item);
            item.relatedId = relatedId;

            this.bookingDetails.extraOptions.push(item);
            this.bookingDetailsSelectedStore.updateStore(this.bookingDetails);
            this.configStore.updateClonnedBookingDetails(this.bookingDetails);
            this.chosenIdx = null;

            this.cdr.detectChanges();
            this.hide = false;
          }, err => {
            onFail();
          });
        }, fail => {
          onFail();
          this.toastrService.danger('Something went wrong! Please try again.', 'Error', { destroyByClick: true, hasIcon: false });
        });
      };
      addSpecialOffer();
    }

  }

  isItemAvai(item, arr) {
    const idx = arr.findIndex(ele => ele.isBeverage && ele.id === item.id);
    return {
      isAvai: idx > -1,
      idx
    };
  }

  tryToDeleteOtherChosenBeverage(): Observable<string> {
    if (this.bookingDetails && this.bookingDetails.extraOptions.length > 0) {
      for (let i = 0; i < this.bookingDetails.extraOptions.length; i++) {
        if (this.bookingDetails.extraOptions[i].isBeverage) {

          return this.commonService.deleteSpecialOffer([this.bookingDetails.extraOptions[i].relatedId], this.bookingDetails.id).pipe(map(success => {
            _.pullAt(this.bookingDetails.extraOptions, i);
            return 'done';
          }, err => {
            return throwError('failed');
          }))
        }

        if (i === this.bookingDetails.extraOptions.length - 1) {
          return of('done');
        }
      }
    } else {
      return of('done');
    }
  }
}
