import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class BagIconService {
    render() {
        return `<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.03366 0.816341C2.14382 0.669454 2.31672 0.583008 2.50033 0.583008H9.50033C9.68393 0.583008 9.85683 0.669454 9.96699 0.816341L11.717 3.14967C11.7927 3.25065 11.8337 3.37346 11.8337 3.49967V11.6663C11.8337 12.1305 11.6493 12.5756 11.3211 12.9038C10.9929 13.232 10.5478 13.4163 10.0837 13.4163H1.91699C1.45286 13.4163 1.00774 13.232 0.679555 12.9038C0.351367 12.5756 0.166992 12.1305 0.166992 11.6663V3.49967C0.166992 3.37346 0.207929 3.25065 0.283659 3.14967L2.03366 0.816341ZM2.79199 1.74967L1.33366 3.69412V11.6663C1.33366 11.8211 1.39512 11.9694 1.50451 12.0788C1.61391 12.1882 1.76228 12.2497 1.91699 12.2497H10.0837C10.2384 12.2497 10.3867 12.1882 10.4961 12.0788C10.6055 11.9694 10.667 11.821 10.667 11.6663V3.69412L9.20866 1.74967H2.79199Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.166992 3.49967C0.166992 3.17751 0.428159 2.91634 0.750326 2.91634H11.2503C11.5725 2.91634 11.8337 3.17751 11.8337 3.49967C11.8337 3.82184 11.5725 4.08301 11.2503 4.08301H0.750326C0.428159 4.08301 0.166992 3.82184 0.166992 3.49967Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.66699 5.24967C3.98916 5.24967 4.25033 5.51084 4.25033 5.83301C4.25033 6.29714 4.4347 6.74226 4.76289 7.07044C5.09108 7.39863 5.5362 7.58301 6.00033 7.58301C6.46445 7.58301 6.90957 7.39863 7.23776 7.07044C7.56595 6.74226 7.75033 6.29714 7.75033 5.83301C7.75033 5.51084 8.01149 5.24967 8.33366 5.24967C8.65583 5.24967 8.91699 5.51084 8.91699 5.83301C8.91699 6.60656 8.6097 7.34842 8.06272 7.8954C7.51574 8.44238 6.77387 8.74967 6.00033 8.74967C5.22678 8.74967 4.48491 8.44238 3.93793 7.8954C3.39095 7.34842 3.08366 6.60656 3.08366 5.83301C3.08366 5.51084 3.34483 5.24967 3.66699 5.24967Z" fill="white"/>
        </svg>
        `;
    }
}
