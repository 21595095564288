import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { from, of } from 'rxjs';
import * as _ from 'lodash';
import { VehiclesService } from '../../../../@core/akita-stores/entity-stores/vehicles/vehicles.service';
import { CommonService } from '../../../../@core/utils/common.service';

@Component({
  selector: 'search-vehicle-manual',
  templateUrl: './search-vehicle-manual.component.html',
  styleUrls: ['./search-vehicle-manual.component.scss']
})
export class SearchVehicleManualComponent implements OnInit {

  vehicleMakes = [];

  model = {
    make: null,
    model: null,
    year: null,
    variant: null,
    cylinder: null,
    transmission: null,
    slid: null,
    rego: null
  };

  vehicleSearch = {
    makes: null,
    models: null,
    variants: null,
    years: null,
    transmissions: null,
    cylinder: null
  };

  vehicleSearchSubscribe = {
    vehicle$: null,
    makes$: null,
    models$: null,
    variants$: null,
    years$: null,
    transmissions$: null,
    cylinder$: null,
  };
  modelsByMakeId$ = from([]);
  yearsGenerated = [];
  loading: boolean = false;
  @Output() onSearchManual: EventEmitter<any> = new EventEmitter();
  constructor(
    private vehicleService: VehiclesService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.vehicleSearchSubscribe.makes$ = this.vehicleService.getVehiclesMakes().subscribe(makesArr => {
      this.vehicleSearch.makes = makesArr;
      this.loading = false;
    });
  }

  onSubmit() {
    this.model.cylinder = this.model.cylinder ? this.model.cylinder : 6;
    this.onSearchManual.emit({ vehicles: [this.model] });
  }

  onMakeChanged(e) {
    this.model.model = null; this.vehicleSearch.models = null;
    this.model.variant = null; this.vehicleSearch.variants = null;
    this.model.year = null; this.vehicleSearch.years = null;
    this.model.cylinder = null; this.vehicleSearch.cylinder = null;
    this.model.transmission = null; this.vehicleSearch.transmissions = null;
    this.model.slid = null;
    this.vehicleSearchSubscribe.models$ = this.vehicleService.getVehiclesModels(e).subscribe(modelArray => this.vehicleSearch.models = modelArray);
  }

  onModelChanged() {
    this.model.variant = null; this.vehicleSearch.variants = null;
    this.model.year = null; this.vehicleSearch.years = null;
    this.model.cylinder = null; this.vehicleSearch.cylinder = null;
    this.model.transmission = null; this.vehicleSearch.transmissions = null;
    this.model.slid = null;
    this.vehicleSearchSubscribe.variants$ = this.vehicleService.getVehiclesVariants(this.model.make, this.model.model).subscribe(variantArray => {
      if (variantArray.length === 0) {
        this.model.variant = "invalid";
        return;
      }
      this.vehicleSearch.variants = variantArray;
    });
  }

  onVariantChanged() {
    this.model.year = null; this.vehicleSearch.years = null;
    this.model.cylinder = null; this.vehicleSearch.cylinder = null;
    this.model.transmission = null; this.vehicleSearch.transmissions = null;
    this.model.slid = null;
    this.vehicleSearchSubscribe.years$ = this.vehicleService.getVehiclesYears(this.model.make, this.model.model, this.model.variant).subscribe(yearArray => {
      if (yearArray.length === 0) {
        this.model.year = "invalid";
        return;
      }
      this.vehicleSearch.years = yearArray
    });
  }

  onYearChanged() {
    this.model.cylinder = null; this.vehicleSearch.cylinder = null;
    this.model.transmission = null; this.vehicleSearch.transmissions = null;
    this.model.slid = null;
    this.vehicleSearchSubscribe.transmissions$ = this.vehicleService.getVehicleTransmissions(this.model.make, this.model.model, this.model.variant, this.model.year)
      .subscribe(transmissions => { this.vehicleSearch.transmissions = transmissions; });
  }

  onTransmissionChanged() {
    this.model.cylinder = null; this.vehicleSearch.cylinder = null;
    let temp = this.vehicleSearch.transmissions.filter(m => m.transmission === this.model.transmission);
    this.model.slid = temp[0].slid;
    this.vehicleSearchSubscribe.cylinder$ = this.vehicleService.getVehicleCylinder(this.model.slid).subscribe(cylinder => this.vehicleSearch.cylinder = cylinder);
  }

  disabledSelect() {
    return ((this.model.rego === null || this.model.rego == '')
      || this.model.make === null
      || this.model.model === null
      || this.model.variant === null
      || this.model.year === null)
  }

  ngOnDestroy() {
    let vehicleSub = this.vehicleSearchSubscribe;
    if (vehicleSub.makes$) vehicleSub.makes$.unsubscribe();
    if (vehicleSub.models$) vehicleSub.models$.unsubscribe();
    if (vehicleSub.variants$) vehicleSub.variants$.unsubscribe();
    if (vehicleSub.years$) vehicleSub.years$.unsubscribe();
    if (vehicleSub.transmissions$) vehicleSub.transmissions$.unsubscribe();
    if (vehicleSub.cylinder$) vehicleSub.cylinder$.unsubscribe();
  }

  removeWhiteSpacesRego($rego) {
    this.model.rego = this.commonService.removeWhiteSpacesStr($rego);
  }
}
