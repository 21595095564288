import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class LogoutIconService {

    render() {
        return `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.1665 3.33317C3.94549 3.33317 3.73353 3.42097 3.57725 3.57725C3.42097 3.73353 3.33317 3.94549 3.33317 4.1665V15.8332C3.33317 16.0542 3.42097 16.2661 3.57725 16.4224C3.73353 16.5787 3.94549 16.6665 4.1665 16.6665H7.49984C7.96007 16.6665 8.33317 17.0396 8.33317 17.4998C8.33317 17.9601 7.96007 18.3332 7.49984 18.3332H4.1665C3.50346 18.3332 2.86758 18.0698 2.39874 17.6009C1.9299 17.1321 1.6665 16.4962 1.6665 15.8332V4.1665C1.6665 3.50346 1.9299 2.86758 2.39874 2.39874C2.86758 1.9299 3.50346 1.6665 4.1665 1.6665H7.49984C7.96007 1.6665 8.33317 2.0396 8.33317 2.49984C8.33317 2.96007 7.96007 3.33317 7.49984 3.33317H4.1665Z" fill="#A9A9A9"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7441 5.24408C13.0695 4.91864 13.5972 4.91864 13.9226 5.24408L18.0893 9.41074C18.4147 9.73618 18.4147 10.2638 18.0893 10.5893L13.9226 14.7559C13.5972 15.0814 13.0695 15.0814 12.7441 14.7559C12.4186 14.4305 12.4186 13.9028 12.7441 13.5774L16.3215 10L12.7441 6.42259C12.4186 6.09715 12.4186 5.56951 12.7441 5.24408Z" fill="#A9A9A9"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.6665 9.99984C6.6665 9.5396 7.0396 9.1665 7.49984 9.1665H17.4998C17.9601 9.1665 18.3332 9.5396 18.3332 9.99984C18.3332 10.4601 17.9601 10.8332 17.4998 10.8332H7.49984C7.0396 10.8332 6.6665 10.4601 6.6665 9.99984Z" fill="#A9A9A9"/>
        </svg>
        `;
    }
}
