import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SessionQuery } from '../@core/akita-stores/stores/session/session.query';
import { ServiceCentersQuery } from '../@core/akita-stores/entity-stores/sercice-centers/service-centers.query';

import * as _ from 'lodash';
import { environment } from '../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private sessionQuery: SessionQuery, private serviceCentersQuery: ServiceCentersQuery) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.sessionQuery.getValue().accessToken;

    let httpHeader = new HttpHeaders();
    if (request.body instanceof FormData) {
      // httpHeader = httpHeader.set('Content-Type', 'multipart/form-data');
      httpHeader = httpHeader.set("Accept", "application/json");
    }
    else httpHeader = httpHeader.set('Content-Type', 'application/json');

    if (!_.isEmpty(accessToken)) {
      httpHeader = httpHeader.set('Authorization', `Bearer ${this.sessionQuery.getValue().accessToken}`);
    }

    const { url, method } = request;
    if (!this.noAttachingEnityInHeader(url, method)) {
      if (this.sessionQuery.getValue().entityId) {
        httpHeader = httpHeader.set('Entity-Context', request.headers.get('Entity-Context') || this.sessionQuery.getValue().entityId.toString());
      } 
      else if (request.headers.get('Entity-Context')) {
        httpHeader = httpHeader.set('Entity-Context', request.headers.get('Entity-Context'));
      }
    }

    if(request.headers.has('Download-Receipt')) {
      httpHeader = httpHeader.set('Accept', 'application/pdf');
    }
    
    request = request.clone({
      headers: httpHeader
    });
        
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        //401 UNAUTHORIZED
        if (error && error.status === 401) {
          console.log("ERROR 401 UNAUTHORIZED")
        }

        return throwError(error);
      })
    );
  }

  noAttachingEnityInHeader(url, method) {
    const list = [{ uri: 'companies', method: 'PUT' }];

    return list.find(item => url.indexOf(item.uri) > -1 && item.method.toLowerCase() === method.toLowerCase());
  }
}
