<div class="mo-calendar mt-2 mb-2">
  <!-- <button mat-icon-button class="example-double-arrow" (click)="previousClicked('year')">
    <mat-icon>keyboard_arrow_left</mat-icon>
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button> -->
  <button mat-icon-button (click)="previousClicked('month')" class="btn-calendar-prev">
    <nb-icon icon="carret-left" pack="mo-icons"></nb-icon>
  </button>
  <span *ngIf="show" class="mo-calendar__header-label">{{periodLabel}}</span>
  <button mat-icon-button (click)="nextClicked('month')" class="btn-calendar-next">
    <nb-icon icon="carret-right" pack="mo-icons"></nb-icon>
  </button>
  <!-- <button mat-icon-button class="example-double-arrow" (click)="nextClicked('year')">
    <mat-icon>keyboard_arrow_right</mat-icon>
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button> -->
</div>