import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { API_PATH } from './../../../../constants/api-urls';
import { UserStore, UserState } from './user.store';
import { VehiclesService } from '../../entity-stores/vehicles/vehicles.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { PermissionsService } from '../../../utils/permissions.service';
import { UserQuery } from './user.query';
import { APIService } from "../../../utils/api.service";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(
    private http: HttpClient,
    private userStore: UserStore,
    private vehiclesService: VehiclesService,
    private ngxpermissionsService: NgxPermissionsService,
    private permissionsService: PermissionsService,
    private userQuery: UserQuery
  ) {

  }

  getUserPermissions() {
    return this.permissionsService.getPermissions().pipe(map((res: any) => {
      this.ngxpermissionsService.loadPermissions(res.operations);
      this.userStore.savePermissions(res.operations);
    }));
  }

  clear() {
    this.userStore.clearUser();
  }

  updateUserLogin(flg = false) {
    this.userStore.update({
      isLogin: flg
    })
  }

  getUserData() {
    return this.http.get(API_PATH.self.userInfo).pipe();
  }

  resetPassword(data, email) {
    const url = APIService.prepareURI(API_PATH.user.resetPassword, { email });

    return this.http.put(url, data);
  }

  resetPasswordRequest(email): Observable<any> {
    let url = API_PATH.user.resetPasswordRequest.replace('$email', email);
    return this.http.post(url, {});
  }
}