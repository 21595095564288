import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { PERMISSION_ACTIONS, PermissionAction, Permissions, Role } from '../../../@core/data/roles-permissions';
import * as _ from 'lodash';

@Component({
  selector: 'permission-list-management',
  templateUrl: './permission-list-management.component.html',
  styleUrls: ['./permission-list-management.component.scss']
})
export class PermissionListManagementComponent implements OnInit, OnChanges {

  @Input()
  role: Role;

  @Input()
  permissionList: Permissions = [];
  
  @Input()
  createMode: boolean = false;

  @Input()
  loading: boolean = false;

  @Input()
  disabled: boolean = false;

  @Input()
  readonly: boolean = false;

  @Output()
  onPermissionChange = new EventEmitter<any>();

  // specific state manage how to root permission checkbox show
  rootPermissionStatus: {
    [key in PermissionAction]: {
      [key: number]: {
        isIndeterminate: boolean,
        isDisabled: boolean,
        isChecked: boolean,
        readonly?: boolean
      }
    }
  }

  permissionCollapse: Array<boolean> = []

  constructor() {
  }

  ngOnInit() {

  }

  ngOnChanges() {
    this.rootPermissionStatusCheck()
  }

  rootPermissionStatusCheck(rootPermissionId = null) {
    if (rootPermissionId) {
      this.rootPermissionStatus = {
        create: {},
        delete: {},
        update: {},
        view: {}
      }
    } else {
      if (this.permissionList && this.permissionList.length > 0) {

        this.rootPermissionStatus = this.permissionList.reduce((res, permissionModule, index) => {
          const actionTracking: {
            [key in PermissionAction]?: {
              isIndeterminate: boolean,
              isDisabled: boolean,
              isChecked: boolean,
              readonly?: boolean
            }
          } = {}

          let checkedCount = {};
          let checkable = {};

          if (permissionModule.permissions && permissionModule.permissions.length > 0) {
            permissionModule.permissions.forEach((per, index, arr) => {
              const actions = per.actions;
              PERMISSION_ACTIONS.forEach((action) => {
                if (!checkedCount[action]) checkedCount[action] = 0;
                if (actions) {
                  if (typeof actions[action] === "boolean") { // checkable
                    checkable[action] = true; // mark as checkable
                  }
                  checkedCount[action] += actions && actions[action] ? 1 : 0;
                } else {
                  per.actions = {};
                }
              });
            });
          } else if (permissionModule.actions) {
            const actions = permissionModule.actions;
            PERMISSION_ACTIONS.forEach((action) => {
              if (actions && typeof actions[action] === "boolean") { // checkable
                checkable[action] = true;
              }
              if (!checkedCount[action]) checkedCount[action] = 0;
              checkedCount[action] += actions && actions[action] ? 1 : 0;
            });
          }
          PERMISSION_ACTIONS.forEach((action) => {
            let checkableCount = 0;
            if ((!permissionModule.permissions || permissionModule.permissions && permissionModule.permissions.length === 0) && permissionModule.actions) {
              checkableCount = permissionModule.actions[action] !== undefined && permissionModule.actions[action] !== null ? 1 : 0;
            } else {
              checkableCount = (permissionModule.permissions || []).reduce((count, permissionModule) => {
                return permissionModule.actions[action] !== undefined && permissionModule.actions[action] !== null ? count + 1 : count;
              }, 0);
            }

          actionTracking[action] = {
            isIndeterminate: permissionModule.permissions ? checkedCount[action] > 0 && checkedCount[action] < checkableCount : false,
            isDisabled: !checkedCount[action] && !checkable[action],
            isChecked: checkedCount[action] == checkableCount,
            readonly: !this.createMode && this.role && this.role.code === "sysadmin" && permissionModule.code === "manageAPIAccess" // sysadmin can't change manageAPIAccess
          };

            res[action][permissionModule.code] = actionTracking[action];
          });

          return res
        }, {
          create: {},
          delete: {},
          update: {},
          view: {}
        })
      }
    }
  }


  permissionChange(e, type: PermissionAction, permissionCode, subPermissionCode = null) {
    // e is MatCheckboxChange when called form root permission checkbox, 
    // e is boolean when called from sub permission checkbox

    if (this.readonly) return;
    if (permissionCode) {
      const permissionModule = this.permissionList.find(v => v.code === permissionCode);

      // sub permission change
      if (subPermissionCode) {
        const isAllChecked = permissionModule.permissions.every(v => v.actions[type] !== false);
        const isNoneChecked = permissionModule.permissions.every(v => !v.actions[type]);
        if (isAllChecked) {
          this.rootPermissionStatus[type][permissionCode].isChecked = true;
          this.rootPermissionStatus[type][permissionCode].isIndeterminate = false;
        } else if (isNoneChecked) {
          this.rootPermissionStatus[type][permissionCode].isChecked = false;
          this.rootPermissionStatus[type][permissionCode].isIndeterminate = false;
        } else {
          this.rootPermissionStatus[type][permissionCode].isChecked = false;
          this.rootPermissionStatus[type][permissionCode].isIndeterminate = true;
        }

        // if create sub checkbox checked, view and update should be checked too
        if(type === 'create' && e) { 
          const viewSubPermission = permissionModule.permissions.find(v => v.code === subPermissionCode);
          if (viewSubPermission && _.isBoolean(_.get(viewSubPermission, 'actions.view'))) {
            viewSubPermission.actions['view'] = true;
            this.permissionChange(true, 'view', permissionCode, subPermissionCode);
          }

          const updateSubPermission = permissionModule.permissions.find(v => v.code === subPermissionCode);
          if (updateSubPermission && _.isBoolean(_.get(updateSubPermission, 'actions.update'))) {
            updateSubPermission.actions['update'] = true;
            this.permissionChange(true, 'update', permissionCode, subPermissionCode);
          }
        }

      } else {
        const isIndeterminate = this.rootPermissionStatus[type][permissionCode].isIndeterminate;
        let rootCheck = false;
        if (isIndeterminate) {
          rootCheck = true;
        } else {
          rootCheck = !this.rootPermissionStatus[type][permissionCode].isChecked;
        }
        this.rootPermissionStatus[type][permissionCode].isIndeterminate = false;
        this.rootPermissionStatus[type][permissionCode].isChecked = rootCheck;
        // update permissionList
        if (permissionModule.permissions && permissionModule.permissions.length > 0) {
          
          // if create checkbox checked, view and update should be checked too
          if (type === 'create' && rootCheck) {

            // Has sub permission so we need check through this.rootPermissionStatus (not permissionModule)
            const canView = !_.get(this.rootPermissionStatus, `[view][${permissionCode}].readonly`) && !_.get(this.rootPermissionStatus, `[view][${permissionCode}].isDisabled`);
            const canUpdate = !_.get(this.rootPermissionStatus, `[update][${permissionCode}].readonly`) && !_.get(this.rootPermissionStatus, `[update][${permissionCode}].isDisabled`);

            if (canView) {
              this.rootPermissionStatus['view'][permissionCode].isChecked = !rootCheck; // Reverse the value to proceed recursively
              this.permissionChange({ checked: true }, 'view', permissionCode);
            }
            if (canUpdate) {
              this.rootPermissionStatus['update'][permissionCode].isChecked = !rootCheck;
              this.permissionChange({ checked: true }, 'update', permissionCode);
            }
          }

          permissionModule.permissions.forEach(v => {
            if (_.isBoolean(v.actions[type])) {
              v.actions[type] = rootCheck;
            }
          })
        } else {
          permissionModule.actions[type] = rootCheck;

          // if create checked, view and update should be checked too
          if (type === 'create' && rootCheck) {
            if (_.isBoolean(permissionModule.actions['view'])) {
              permissionModule.actions['view'] = rootCheck;
              this.rootPermissionStatus['view'][permissionCode].isChecked = !rootCheck;
              this.permissionChange({ checked: true }, 'view', permissionCode);
            }
            if (_.isBoolean(permissionModule.actions['update'])) {
              permissionModule.actions['update'] = rootCheck;
              this.rootPermissionStatus['update'][permissionCode].isChecked = !rootCheck;
              this.permissionChange({ checked: true }, 'update', permissionCode);
            }
          }

        }
      }
    }
    this.onPermissionChange.emit();
  }

  isIndeterminate(type: PermissionAction, permissionCode,) {
    return this.rootPermissionStatus[type][permissionCode].isIndeterminate;
  }

  isChecked(type: PermissionAction, permissionCode,) {
    return this.rootPermissionStatus[type][permissionCode].isChecked;
  }

  collapsePermission(permissionIndex) {
    this.permissionCollapse[permissionIndex] = !this.permissionCollapse[permissionIndex];
  }
}
