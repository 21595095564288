import { Injectable } from '@angular/core';
import { Store, StoreConfig, Query } from '@datorama/akita';
import { HttpClient } from '@angular/common/http';
import { forkJoin, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { API_PATH } from './../../../../constants/api-urls';
import { ServiceCentersQuery } from '../../entity-stores/sercice-centers/service-centers.query';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';

import { getTimezone, getCurrentCentre } from '../../storage';
import { Share } from '@ngspot/rxjs/decorators';
import { APIService } from '../../../utils/api.service'

const timezone = getTimezone();
moment.tz.setDefault(timezone);

const colors: any = {
  red: {
    primary: '#FF5200',
    secondary: '#FF5200'
  },
  black: {
    primary: '#231F20',
    secondary: '#231F20'
  },
};

export interface BayState {
  id: number;
  name: string;
  podName: string;
  serviceType: string;
  serviceTypeDescription: string;
  interval: number;
  color: any;
}

export interface BookingCalendarState {
  viewDate: any;
  viewDateRange: any;
  viewBy: any;
  bays: BayState[];
  serviceType: string;
  logbookDuration: any;
  expressDuration: any;
  page: number;
  totalPages: number;
  totalBays: number;
  viewCalendar: boolean;
  isViewAllBooking: boolean;
}

export function createInitialState(): BookingCalendarState {
  return {
    viewDate: moment().toDate(),
    viewDateRange: null,
    viewBy: 'bookingDate',
    bays: [],
    serviceType: '',
    logbookDuration: 0,
    expressDuration: 0,
    page: 0,
    totalPages: 1,
    totalBays: 0,
    viewCalendar: false, // this view only for flight deck service
    isViewAllBooking: false,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'booking-calendar', resettable: true })
export class BookingCalendarStore extends Store<BookingCalendarState> {
  constructor() {
    super(createInitialState());
  }
}

@Injectable({ providedIn: 'root' })
export class BookingCalendarQuery extends Query<BookingCalendarState> {
  bookingCalendar$ = this.select(['viewDate', 'viewDateRange', 'viewBy']);
  bays$ = this.select(['bays']);
  serviceType$ = this.select(['serviceType']);
  viewCalendar$ = this.select('viewCalendar');
  isViewAllBooking$ = this.select('isViewAllBooking');

  constructor(protected store: BookingCalendarStore) {
    super(store);
  }
}

@Injectable({ providedIn: 'root' })
export class BookingCalendarService {
  
  pageSize = 6;

  constructor(
    private store: BookingCalendarStore,
    private serviceCentersQuery: ServiceCentersQuery,
    public query: BookingCalendarQuery,
    private http: HttpClient,
  ) {}

  updateViewDate(date, viewDateRange = null, viewBy = 'bookingDate') {
    this.store.update({
      viewDate: moment(date).toDate(),
      viewDateRange: viewDateRange,
      viewBy: viewBy,
    })
  }

  resetViewDate() {
    this.store.update({
      viewDate: moment().toDate(),
      viewDateRange: null,
      viewBy: 'bookingDate',
    })
  }

  updateServiceType(service) {
    this.store.update({
      serviceType: service
    })
  }

  updateViewCalendar(val) {
    this.store.update({
      viewCalendar: val
    })
  }

  updatePage(val) {
    this.store.update({
      page: val
    })
  }

  updateIsViewAllBooking(val: boolean) {
    this.store.update({
      isViewAllBooking: val
    });
  }

  @Share()
  getBays(isForceStart = false, isPrev = false, isNext = false, numberBays = null) {
    let logbookJobs = of(null);
    let expressJobs = of(null);
    if (this.query.getValue().logbookDuration == 0) {
      logbookJobs = this.http.get(API_PATH.bookings.logbookJobs);
    }
    if (this.query.getValue().expressDuration == 0) {
      expressJobs = this.http.get(API_PATH.bookings.expressJobs + '?cylinder=6');
    }
    let url =API_PATH.entities.getBays;
    if (this.query.getValue().serviceType && this.query.getValue().serviceType != '') {
      url += `?serviceTypes=${this.query.getValue().serviceType}`;
    }
    else {
      url += '?serviceTypes=express,logbook,premium,premium-plus,tyre-bonus';
    }

    let pageSize = this.pageSize;

    if (numberBays != null) {
      pageSize = numberBays;
    }

    url += `&pageSize=${pageSize}`;

    let page = this.query.getValue().page || 1;

    if (isNext && this.query.getValue().page < Math.ceil(this.query.getValue().totalBays / pageSize)) {
      page = this.query.getValue().page ? this.query.getValue().page + 1 : 1;
    }
    if (isPrev) {
      page = this.query.getValue().page && this.query.getValue().page > 1 ? this.query.getValue().page - 1 : 1;
    }
    if (isForceStart) {
      page = 1;
    }
    url += `&page=${page}`;

    return forkJoin([
      logbookJobs,
      expressJobs,
      this.http.get(url)
    ])
    .pipe(
      tap((res: any) => {
        const logbookJobs = res[0] ? res[0].results : null;
        const expressJobs = res[1] ? res[1].results : null;
        const baysData = res[2].results;
        if (logbookJobs && logbookJobs.length > 0) {
          this.store.update({
            logbookDuration: logbookJobs[0].duration * 60
          });
        }

        if (expressJobs && expressJobs.length > 0) {
          this.store.update({
            expressDuration: expressJobs[0].duration * 60
          });
        }
  
        let bays = [];
        const expressDuration = this.query.getValue().expressDuration;
        const logbookDuration = this.query.getValue().logbookDuration;
        _.forEach(baysData, (result) => {
          let bay: BayState = {
            id: result.id,
            name: result.bayNo,
            podName: (result.pod && result.pod.id) ? result.pod.name : null,
            // serviceType: result.serviceType.code,
            // interval: result.serviceType.code == 'express' ? expressDuration : logbookDuration,
            // color: result.serviceType.code == 'express' ? colors.red : colors.black,
            serviceType: result.serviceTypes && result.serviceTypes.length ? result.serviceTypes.map(s => s.code).join() : '',
            serviceTypeDescription: result.serviceTypeDescription ? result.serviceTypeDescription : '',
            interval: result.serviceTypeDescription && result.serviceTypeDescription.includes('express') ? expressDuration : logbookDuration,
            color: result.serviceTypeDescription && result.serviceTypeDescription.includes('express') ? colors.red : colors.black,
          };
          bays.push(bay);
        });

        this.store.update(state => ({
          bays: bays,
          page: page,
          totalPages: Math.ceil(res[2].count / pageSize),
          totalBays: res[2].count,
        }));
      })
    );
  }
}