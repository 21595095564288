import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class OdometerIconService {

    render() {
        return `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5 13.5002H5.5C5.224 13.5002 5 13.2762 5 13.0002C5 12.7242 5.224 12.5002 5.5 12.5002H7.5C7.776 12.5002 8 12.7242 8 13.0002C8 13.2762 7.776 13.5002 7.5 13.5002Z" fill="var(--block-colour)" stroke="var(--block-colour)" stroke-width="0.5"/>
        <path d="M20.5 13.5002H18.5C18.224 13.5002 18 13.2762 18 13.0002C18 12.7242 18.224 12.5002 18.5 12.5002H20.5C20.776 12.5002 21 12.7242 21 13.0002C21 13.2762 20.776 13.5002 20.5 13.5002Z" fill="var(--block-colour)" stroke="var(--block-colour)" stroke-width="0.5"/>
        <path d="M13 25C6.3835 25 1 19.6165 1 13C1 6.3835 6.3835 1 13 1C19.6165 1 25 6.3835 25 13C25 19.6165 19.6165 25 13 25ZM13 2C6.9345 2 2 6.9345 2 13C2 19.0655 6.9345 24 13 24C19.0655 24 24 19.0655 24 13C24 6.9345 19.0655 2 13 2Z" fill="var(--block-colour)" stroke="var(--block-colour)" stroke-width="0.5"/>
        <path d="M17.8535 8.1462C17.658 7.9507 17.342 7.9507 17.1465 8.1462L14.7355 10.5572C14.245 10.2077 13.647 9.9997 13 9.9997C11.3455 9.9997 10 11.3452 10 12.9997C10 14.6542 11.3455 15.9997 13 15.9997C14.6545 15.9997 16 14.6542 16 12.9997C16 12.3527 15.792 11.7547 15.4425 11.2642L17.8535 8.8532C18.049 8.6577 18.049 8.3417 17.8535 8.1462ZM13 14.9997C11.897 14.9997 11 14.1027 11 12.9997C11 11.8967 11.897 10.9997 13 10.9997C14.103 10.9997 15 11.8967 15 12.9997C15 14.1027 14.103 14.9997 13 14.9997Z" fill="var(--block-colour)" stroke="var(--block-colour)" stroke-width="0.5"/>
        <path d="M13 8.00021C12.724 8.00021 12.5 7.77621 12.5 7.50021V5.50021C12.5 5.22421 12.724 5.00021 13 5.00021C13.276 5.00021 13.5 5.22421 13.5 5.50021V7.50021C13.5 7.77621 13.276 8.00021 13 8.00021Z" fill="var(--block-colour)" stroke="var(--block-colour)" stroke-width="0.5"/>
        <path d="M13 20.9998C12.724 20.9998 12.5 20.7758 12.5 20.4998V18.4998C12.5 18.2238 12.724 17.9998 13 17.9998C13.276 17.9998 13.5 18.2238 13.5 18.4998V20.4998C13.5 20.7758 13.276 20.9998 13 20.9998Z" fill="var(--block-colour)" stroke="var(--block-colour)" stroke-width="0.5"/>
        </svg>                               
        `;
    }
}
