<nb-card class="box-select-vehicle wrapper-popup">
  <nb-card-header class="d-flex align-items-center px-3">
    <h4 class="d-flex flex-row justify-content-start align-items-center flex-fill">
      Create Vehicle cc
    </h4>
    <nb-icon class="d-flex align-items-center justify-content-center cursor-pointer" icon="x-icon" pack="mo-icons"
      (click)="closeDlg()"></nb-icon>
  </nb-card-header>
  <nb-card-body class="p-0">
    <div *ngIf="!isResult && isSearchRego">
      <search-by-rego [customerId]="customerId" (onSearchRego)="onSearchRego($event)"></search-by-rego>
      <div class="switchSearch py-4 text-center">
        <a (click)="switchSearch()">Select Vehicle Manually</a>
      </div>
    </div>
    <div *ngIf="!isResult && !isSearchRego">
      <search-vehicle-manual *ngIf="!isResult && !isSearchRego" (onSearchManual)="onSearchManual($event)">
      </search-vehicle-manual>
      <div class="switchSearch py-4 text-center">
        <a (click)="switchSearch()">Select Vehicle REGO</a>
      </div>
    </div>

    <div *ngIf="isResult" class="mo-container pt-4 pb-4">
      <div class="d-flex flex-column">
        <h4 class="mb-3 text-center">Search Vehicle by Rego</h4>
        <p class="mb-3 text-center">Please select your vehicles:</p>
        <nb-list id="list-vehicle" *ngIf="searchVehicles.length > 0">
          <nb-list-item class="px-0 py-0 border-bottom-0" *ngFor="let vehicle of searchVehicles; let i = index"
            (click)="selectVehicle(vehicle)">
            <nb-card [ngClass]="{ 'selected': vehicle.isSelected }" class="mb-3 full-width selected"
              id="search-item-vehicle-{{i}}">
              <nb-card-body class="py-2 px-3 pb-0">
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <h5 class="mb-0"><span class="text-uppercase">{{ vehicle.rego }}</span> ·
                      {{ vehicle.make }} {{ vehicle.model }} {{ vehicle.year }}</h5>
                  </div>
                  <div class="align-self-center">
                    <mat-checkbox checked="true" disableRipple disabled></mat-checkbox>
                  </div>
                </div>
                <div class="d-flex">
                  <p class="mb-0">{{ vehicle | vehicleStr: 'colour-cylinder' }}</p>
                </div>
              </nb-card-body>
            </nb-card>
          </nb-list-item>
        </nb-list>
        <div class="d-flex mt-4">
          <button nbButton fullWidth class="box-select-vehicle__submit" status="primary" (click)="onSelectFromResults()"
            [disabled]="!isSelected">Select</button>
        </div>
        <div class="line my-4"></div>
        <p class="m-0 body-3 font-weight-500 text-center">That’s not your car?<span
            class="font-weight-600 text-decoration ml-2 cursor-pointer" (click)="isResult = false">Search Again</span>
        </p>
      </div>
    </div>
  </nb-card-body>
</nb-card>