<ng-container *ngIf="!showCardDetailsForm">
  <nb-card [nbSpinner]="loading" class="payment-result-dialog">
    <nb-card-header>Payment<nb-icon icon="x-icon" pack="mo-icons" (click)="close()"></nb-icon>
    </nb-card-header>
    <nb-card-body>
      <span class="btn-back-to" (click)="gotoManageBooking()">
        <nb-icon icon="arrow-left" pack="mo-icons"></nb-icon>Back to manage booking
      </span>
      <div class="wrap-content" *ngIf="!onError && !threeTimesFailing" [ngClass]="{'no-show': loading || onError}">
        <div class="ico-check">
          <nb-icon icon="check-circle-payment" pack="mo-icons"></nb-icon>
        </div>
        <div class="title text-uppercase">payment successful!</div>
        <div class="description">Booking <span class="primary-color">#{{bookingDetails.id}}</span> has been processed.
        </div>
      </div>
      <br>
      <br>
      <h5 *ngIf="onError && !threeTimesFailing" class="text-center err">
        <nb-icon icon="info" pack="mo-icons"></nb-icon> &nbsp;Internal issues encountered!
      </h5>

      <h5 *ngIf="threeTimesFailing" class="text-center err">
        <nb-icon icon="info" pack="mo-icons"></nb-icon> &nbsp;Booking was cancelled due to 3 failing attempts of
        payment.
      </h5>
    </nb-card-body>
  </nb-card>
</ng-container>
<card-details-dialog [failedPayment]="showCardDetailsForm"
  *ngIf="showCardDetailsForm && !loading && !threeTimesFailing">
</card-details-dialog>