import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../../../utils/common.service';
import { API_PATH } from '../../../../constants';
import { catchError, delay, map } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

export interface BookingSummaryParams {
  serviceCenterId: string;
  startDate: string;
  endDate: string;
  serviceType: string;
  state: string;
}
type SummaryCard = {
  label?: string;
  value: number;
  growth?: number | string;
};

export type BookingSummaryData = {
  totalBooking: SummaryCard,
  brandBookingSummary: SummaryCard[],
  stateSummaryChart: {
    labels: string[],
    datasets: {
      distributionRate: number[],
      numberOfBookings: number[],
    },
  },
};

export type BookingTypesSummaryData = {
  totalBookingServiceRevenue: SummaryCard,
  totalASR: SummaryCard,
  totalASRRevenue: SummaryCard,
  totalASRPenetrationRate: SummaryCard,
  servicesSummaryChart: {
    labels: string[],
    datasets: {
      penetrationRate: number[],
      numberOfBookings: number[],
      revenue: number[],
    },
  },
  specialOffersSummaryChart: {
    labels: string[],
    datasets: {
      penetrationRate: number[],
      numberOfBookings: number[],
      revenue: number[],
    },
  },
};

export type RevenueSummaryData = {
  totalRevenue: SummaryCard,
  totalRevenuePerYear: SummaryCard,
};

export type TrendLeadsSummaryData = {
  totalLeadsLastYear: SummaryCard
  leadsSummaryChart: {
    labels: string[],
    totalLeadDatesets: number[],
    brandLeadDatesets: {
      label: string;
      data: number[];
    }[],
  },
};

@Injectable({ providedIn: 'root' })
export class KpiReportsService {
  constructor(private http: HttpClient, private commonService: CommonService) { }

  getBookingSummary(params: any) {
    const httpParams = this.commonService.convertObjToHttpParams(params);
    return this.http.get<BookingSummaryData>(API_PATH.kpiReports.bookingSummary, { params: httpParams }).pipe(catchError(err => {
      return of({}).pipe()
    }));
  }

  getBookingTypesSummary(params: any) {
    const httpParams = this.commonService.convertObjToHttpParams(params);
    return this.http.get<BookingTypesSummaryData>(API_PATH.kpiReports.bookingTypesSummary, { params: httpParams }).pipe(catchError(err => {
      return of({}).pipe()
    }));
  }
  getRevenueSummary(params: any) {
    const httpParams = this.commonService.convertObjToHttpParams(params);
    return this.http.get<RevenueSummaryData>(API_PATH.kpiReports.revenueSummary, { params: httpParams }).pipe(catchError(err => {
      return of({}).pipe()
    }));
  }
  getTrendLeadsSummary(params: any) {
    const httpParams = this.commonService.convertObjToHttpParams(params);
    return this.http.get<TrendLeadsSummaryData>(API_PATH.kpiReports.trendLeadsSummary, { params: httpParams }).pipe(catchError(err => {
      return of({}).pipe()
    }));
  }

  getListAdditionOptions(payload) {
    const httpParams = this.commonService.convertObjToHttpParams({
      ...payload,
      page: 1,
      pageSize: 1000,
      entityContext: '-1'
    });
    return this.http.get<any>(API_PATH.kpiReports.listAdditionOptions, { params: httpParams }).pipe(catchError(err => {
      return of({}).pipe()
    }));
  }

  getListBrandServiceOptions(payload) {
    const httpParams = this.commonService.convertObjToHttpParams({
      ...payload,
      page: 1,
      pageSize: 1000,
      entityContext: '-1'
    });
    return this.http.get<any>(API_PATH.kpiReports.listbrandServiceOptions, { params: httpParams }).pipe(catchError(err => {
      return of({}).pipe()
    }));
  }

  getStateServices(payload: {
    brands: number[],
  }) {
    const httpParams = this.commonService.convertObjToHttpParams(payload);
    return this.http.get<any>(API_PATH.kpiReports.stateServices, { params: httpParams }).pipe(catchError(err => {
      return of({}).pipe()
    }));
  }

  getServiceCenterServices(payload:{
    brands: number[],
    states: string[],
  }) {
    const httpParams = this.commonService.convertObjToHttpParams(payload);
    return this.http.get<any>(API_PATH.kpiReports.serviceCenterServices, { params: httpParams }).pipe(catchError(err => {
      return of({}).pipe()
    }));
  }

  protected createParamUrl(queryParams: {
    page?: number
    pageSize?: number
    keyword?: string
    sortingField?: string
  }) {
    const page = queryParams.page || 1;
    const pageSize = queryParams.pageSize || 10;

    let url = `?page=${page}&pageSize=${pageSize}`;

    return url;
  }

  protected addEntityContextParam(isFirstParam: boolean, entityContext: string = '-1') {
    return (isFirstParam ? "?" : "&") + `entityContext=${entityContext}`;
  }

  exportSummary(filter: any, isDowload: boolean = false) {
    // const httpParams = this.commonService.convertObjToHttpParams(params);
    // console.log(httpParams);
    // TODO this is temporary, needs to be replaced with a dedicated api
    return forkJoin([
      this.getBookingSummary(filter)
        .pipe(),
      this.getBookingTypesSummary(filter)
        .pipe(),
      this.getRevenueSummary(filter)
        .pipe(),
      this.getTrendLeadsSummary(filter)
        .pipe()
    ]).pipe(map(results => {
      const [bookingSummary, bookingTypesSummary, revenueSummary, trendLeadsSummary] = results
      return { bookingSummary, bookingTypesSummary, revenueSummary, trendLeadsSummary }
    }))

  }
}
