import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class PersonIconService {

    render() {
        return `<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.976311 9.30932C1.60143 8.6842 2.44928 8.33301 3.33333 8.33301H8.66667C9.55072 8.33301 10.3986 8.6842 11.0237 9.30932C11.6488 9.93444 12 10.7823 12 11.6663V12.9997C12 13.3679 11.7015 13.6663 11.3333 13.6663C10.9651 13.6663 10.6667 13.3679 10.6667 12.9997V11.6663C10.6667 11.1359 10.456 10.6272 10.0809 10.2521C9.70581 9.87706 9.1971 9.66634 8.66667 9.66634H3.33333C2.8029 9.66634 2.29419 9.87706 1.91912 10.2521C1.54405 10.6272 1.33333 11.1359 1.33333 11.6663V12.9997C1.33333 13.3679 1.03486 13.6663 0.666667 13.6663C0.298477 13.6663 0 13.3679 0 12.9997V11.6663C0 10.7823 0.351189 9.93444 0.976311 9.30932Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00033 1.66634C4.89576 1.66634 4.00033 2.56177 4.00033 3.66634C4.00033 4.77091 4.89576 5.66634 6.00033 5.66634C7.10489 5.66634 8.00033 4.77091 8.00033 3.66634C8.00033 2.56177 7.10489 1.66634 6.00033 1.66634ZM2.66699 3.66634C2.66699 1.82539 4.15938 0.333008 6.00033 0.333008C7.84127 0.333008 9.33366 1.82539 9.33366 3.66634C9.33366 5.50729 7.84127 6.99967 6.00033 6.99967C4.15938 6.99967 2.66699 5.50729 2.66699 3.66634Z" fill="white"/>
        </svg>
        `;
    }
}
