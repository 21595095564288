import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ERROR_MESSAGES } from '../../../constants/error-messages';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { BookingDetailsSelectedQuery } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.query';
import { BookingDetails } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.store';
import { BookingDetailsSelectedService } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.service';
import { Router } from '@angular/router';
import { CommonService } from '../../../@core/utils/common.service';

@Component({
  selector: 'booking-cancel-dialog',
  templateUrl: './booking-cancel-dialog.component.html',
  styleUrls: ['./booking-cancel-dialog.component.scss']
})
export class BookingCancelDialogComponent implements OnInit {
  cancelForm: FormGroup;
  errorMessage = ERROR_MESSAGES;
  bookingDetails: BookingDetails;
  paid: any;
  loading: boolean = false;

  constructor(
    protected ref: NbDialogRef<BookingCancelDialogComponent>,
    private bookingQuery: BookingDetailsSelectedQuery,
    private bookingDetailsService: BookingDetailsSelectedService,
    private router: Router,
    private commonService: CommonService,
    private toastrService: NbToastrService
  ) {
    this.bookingDetails = this.bookingQuery.getValue();
    try {
      this.paid = this.bookingDetails.bookingPayments.find(item => item.status === 'completed');
    } catch (e) { }
    this.cancelForm = new FormGroup({
      referenceNo: new FormControl(this.paid ? this.paid.reference : ''),
      reasons: new FormControl('', [Validators.required])
    });
  }

  close() {
    this.ref.close();
  }

  proceed() {
    this.cancelForm.value.reasons.trim();
    if (this.cancelForm.valid) {
      this.loading = true;
      this.bookingDetailsService.cancelBooking({ reason: this.cancelForm.value.reasons }).subscribe(res => {
        this.router.navigateByUrl('/reload', { skipLocationChange: true }).then(() => {
          this.loading = false;
          this.router.navigate(['/pages/booking']).then(() => {
            this.commonService.resetStore();
            setTimeout(() => {
              this.toastrService.success('Booking was cancelled successfully', 'Success', { destroyByClick: true, hasIcon: false });
            }, 500);
          })
        });
      }, err => this.loading = false);
    }
  }

  ngOnInit() {
  }
}
