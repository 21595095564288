import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.scss']
})
export class ServiceCardComponent implements OnInit {
  @Input() data: any;
  @Input() type: any;
  @Input() title: any;
  @Input() icon: any;
  @Input() url: any;
  @Output() clickRow: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onClick() {
    this.clickRow.emit({ id: this.data.id });
  }
}
