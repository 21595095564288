import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_PATH } from './../../constants/api-urls';
import { map } from 'rxjs/internal/operators/map';
import { APIService } from './api.service';
import { ServiceCenterService } from './service-center.service';
import { getEntityId } from '../akita-stores/storage';
import { CommonService } from './common.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService extends ServiceCenterService {

  constructor(
    private http: HttpClient,
    private commonService: CommonService,
  ) {
    super();
  }

  getFilterRequestURL(filter) {
    let paramsStr = '';
    for (let key in filter) {
      if (filter[key]) {
        paramsStr += `&${key}=${filter[key]}`;
      }
    }
    return `?${paramsStr.slice(1)}`;
  }

  getCompanies(filter?) {
    const url = filter ? API_PATH.companies.list + this.getFilterRequestURL(filter) : API_PATH.companies.list;

    return this.http.get(url).pipe(map((res: any) => res.results));
  }

  getCompanyDetails(companyId) {
    const url = APIService.prepareURI(API_PATH.companies.edit, { companyId });

    return this.http.get(url);
  }

  editCompany(companyId, data) {
    const url = APIService.prepareURI(API_PATH.companies.edit, { companyId });

    return this.http.put(url, data);
  }

  getCompanyServiceCenteres(params) {
    return this.http.get(API_PATH.entities.serviceCentresAdmin, { params: params }).pipe(map((res: any) => res));
  }

  getCompanyServiceCentreSuburbs(params) {
    return this.http.get(API_PATH.entities.getAllSuburbAdmin, { params: params }).pipe(map((res: any) => res));
  }

  createServiceCentre(payload) {
    const entityContext = getEntityId();
    if (!entityContext) return;
    const url = APIService.prepareURI(API_PATH.entities.createServiceCentresAdmin) + `?entityContext=${entityContext}`;

    return this.http.post(url, payload);
  }

  getServiceCentreDetails(serviceCenterId) {
    const url = APIService.prepareURI(API_PATH.admin.serviceCentreDetails, { serviceCentreId: serviceCenterId }) + this.addEntityContextParam(true);

    return this.http.get(url);
  }

  updateServiceCentreDetails(payload, serviceCenterId) {
    const url = APIService.prepareURI(API_PATH.admin.serviceCentreDetails, { serviceCentreId: serviceCenterId }) + this.addEntityContextParam(true);

    return this.http.put(url, payload);
  }

  getBrandConfig(entityId: number) {
    const url = `${API_PATH.setting.brandConfig}?valueOnly=true&noCache=true&entityContext=${entityId}`;
    return this.http.get(url);
  }

  editBrandConfig(payload: any, entityId: number) {
    const url = `${API_PATH.setting.editBrandConfig}?entityContext=${entityId}`;
    return this.http.put(url, payload);
  }

  exportCompanies(filter) {
    const url = API_PATH.companies.export + this.getFilterRequestURL(filter);
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob' as 'json',
    }).pipe(tap((res) => {
      this.commonService.exportContentDispositionFile(res)
    }))
  }

  exportServiceCentres(filter) {
    const url = API_PATH.admin.serviceCentersExport + this.getFilterRequestURL(filter);
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob' as 'json',
    }).pipe(tap((res) => {
      this.commonService.exportContentDispositionFile(res)
    }))
  }
}
