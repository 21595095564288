import { EntityState, StoreConfig, EntityStore } from '@datorama/akita';
import { CustomerItem } from './customers.model';
import { Injectable } from '@angular/core';

export interface CustomersState extends EntityState<CustomerItem, number> {
  currentCount: number;
  count: number;
  limit: number;
  backToCustomers: boolean;
  page: number;
  searchTerm: string;
  stateSelected: string;
  sortColumn: string;
  sorts: any[];
}

const initialState = {
  currentCount: 0,
  count: 0,
  limit: 0,
  backToCustomers: false,
  page: 1,
  searchTerm: '',
  stateSelected: '',
  states: [],
  sorts: [],
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'customers', resettable: true })
export class CustomersStore extends EntityStore<CustomersState> {
  constructor() {
    super(initialState);
  }
  
  getCurrentCount() {
    return this.getValue().currentCount;
  }
  
  getBackToCustomer() {
    return this.getValue().backToCustomers;
  }
  
  getPage() {
    return this.getValue().page;
  }

  getStateSelected() {
    return this.getValue().stateSelected;
  }

  getSorts() {
    return this.getValue().sorts;
  }

  getSearchTerm() {
    return this.getValue().searchTerm;
  }

  getSortColumn() {
    return this.getValue().sortColumn;
  }

  getCount() {
    return this.getValue().count;
  }
  
  getLimit() {
    return this.getValue().limit;
  }

  updateCurrentCount(currentCount: number) {
    this.update({ currentCount });
  }

  updateBackToCustomer(backToCustomers: boolean) {
    this.update({ backToCustomers });
  }

  updatePage(page: number) {
    this.update({ page });
  }

  updateStateSelected(stateSelected: string) {
    this.update({ stateSelected });
  }

  updateSorts(sorts: any[]) {
    this.update({ sorts });
  }

  updateSearchTerm(searchTerm: string) {
    this.update({ searchTerm });
  }

  updateSortColumn(sortColumn: string) {
    this.update({ sortColumn });
  }

  updateEntity(entityId, data) {
    this.update(entityId, data);
  }

  updateCount(count) {
    this.update({ count });
  }

  updateLimit(limit) {
    this.update({ limit });
  }
}