import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ServiceCentersService } from '../../../@core/akita-stores/entity-stores/sercice-centers/service-centers.service';
import { Beacon } from '../../../@core/akita-stores/entity-stores/sercice-centers/service-centers.store';
import { BookingDetailsSelectedService } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.service';
import { NbDialogRef } from '@nebular/theme';
import { BookingDetailsSelectedQuery } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { BOOKING_PERMISSIONS } from './../../../constants/booking-permissions';

@Component({
  selector: 'check-in-dlg',
  templateUrl: './check-in-dlg.component.html',
  styleUrls: ['./check-in-dlg.component.scss']
})
export class CheckInDlgComponent implements OnInit, OnDestroy {
  @ViewChild(SignaturePad, { static: false }) public signaturePad: SignaturePad;
  @ViewChild('wrapSignaturePadRef', { static: false }) wrapSignaturePadRef: ElementRef;
  beacons: Beacon[] = [];
  selectedBeacon: any = {};
  isDisableCheckIn: boolean = true;
  loading: boolean = false;
  bookingId: number;
  public signaturePadOptions: Object = {
    'minWidth': 5,
    'canvasWidth': 500,
    'canvasHeight': 200
  };
  isDisabledCheckIn: boolean = true;
  base64Signature: string;
  protected destroy$ = new Subject<void>();
  bookingPermissions = BOOKING_PERMISSIONS;

  constructor(
    private serviceCentersService: ServiceCentersService,
    private bookingDetailsService: BookingDetailsSelectedService,
    private bookingDetailsQuery: BookingDetailsSelectedQuery,
    protected ref: NbDialogRef<CheckInDlgComponent>
  ) {
    this.bookingDetailsQuery.bookingDetailsSelected$.subscribe(val => {
      this.bookingId = val.id;
    })
  }

  ngOnInit() {
    this.serviceCentersService.getBeaconsInCenter()
      .pipe(takeUntil(this.destroy$))
      .subscribe((beacons) => {
        this.beacons = beacons.filter(beacon => beacon.isActive);
        setTimeout(() => this.selectedBeacon = this.beacons && this.beacons[0] ? this.beacons[0] : '');
      });
  }

  ngAfterViewInit() {
    this.signaturePad.set('minWidth', 1);
    this.signaturePad.set('canvasWidth', this.wrapSignaturePadRef.nativeElement.offsetWidth - 2);
    this.clear();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  clear() {
    this.signaturePad.clear();
    this.base64Signature = '';
    this.isDisabledCheckIn = true;
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    this.base64Signature = this.signaturePad.toDataURL();
    this.isDisabledCheckIn = this.signaturePad.toDataURL() && this.hasBeacons() ? false : true;
  }

  hasBeacons() {
    return this.beacons && this.beacons.length > 0 ? true : false;
  }

  onCheckedIn() {
    // get base64
    if (!this.signaturePad.toDataURL() || !this.selectedBeacon.id) {
      return;
    }
    this.loading = true;
    const payloadCheckin = {
      "customerSignature": this.signaturePad.toDataURL(),
      "isForceCheckInEarly": true, //optional
      "isForceCheckInLate": true, //optional
      "beaconId": this.selectedBeacon.id
    }
    this.bookingDetailsService.checkinBooking(payloadCheckin)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const payloadBeacon = { isActive: false };
        this.serviceCentersService.updateBeacon(this.selectedBeacon.id, payloadBeacon)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => this.close(true), () => this.close(true));
      }, () => this.close());
  }

  close(success: boolean = false) {
    this.loading = false;
    this.ref.close(success);
  }
}
