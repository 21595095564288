export const CARD_TYPES = {
  'Visa': '001',
  'Mastercard': '002',
  'American Express': '003',
  'Discover': '004',
  'Diners Club': '005',
  'Carte Blanche': '006',
  'JCB': '007',
  'EnRoute': '014',
  'JAL': '021',
  'Maestro UK Domestic': '024',
  'Delta': '031',
  'Visa Electron': '033',
  'Dankort': '034',
  'Cartes Bancaires': '036',
  'Carta Si': '037',
  'Maestro International': '042',
  'GE Money UK card': '043',
  'Hipercard(sale only)': '050',
  'Elo': '054',
  'Private Label': '055',
}