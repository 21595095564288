import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class ReplyIconService {

    render() {
        return `<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.00383 7.24424L6.24255 7.24424L6.24236 9.50519C6.24234 9.9255 5.74787 10.1528 5.42882 9.87932L0.172005 5.37347C-0.0572534 5.177 -0.0574177 4.82172 0.172005 4.6251L5.42882 0.119258C5.747 -0.153486 6.24234 0.071854 6.24236 0.493385L6.24255 2.75433L9.00383 2.75433C9.8277 2.75433 10.392 2.40604 10.7289 1.68959C11.0038 1.10512 11.0143 0.495168 11.0144 0.490615C11.0159 0.219443 11.2361 0.000580759 11.5071 0.000604577C11.7785 0.000604553 12 0.220405 12 0.493431L12 4.2483C12 5.90026 10.6559 7.24424 9.00383 7.24424Z" fill="#C8C7C7"/>
        </svg>
        `;
    }
}
