import { Injectable } from '@angular/core';

//Icons
import { NbIconLibraries } from '@nebular/theme';
import { CarIconService } from './car.icon';
import { CalendarIconService } from './calendar.icon';
import { BagIconService } from './shopping-bag.icon';
import { FileTextIconService } from './file-text.icon';
import { CreditCardIconService } from './credit-card.icon';
import { ChevronRightIconService } from './chevron-right.icon';
import { ThumbnailIconService } from './thumbnail.icon';
import { CheckIconService } from './check.icon';
import { CheckCircleIconService } from './check-circle.icon';
import { CheckSquareIconService } from './check-square.icon';
import { PlusIconService } from './plus.icon';
import { EditIconService } from './edit.icon';
import { ArrowRightIconService } from './arrow-right.icon';
import { ChevronLeftIconService } from './chevron-left.icon';
import { LockIconService } from './lock.icon';
import { UnlockIconService } from './unlock.icon';
import { CloseIconService } from './close.icon';
import { ArrowLeftIconService } from './arrow-left.icon';
import { MapIconService } from './map.icon';
import { MapMarkerIconService } from './map-marker.icon';
import { MobileIconService } from './mobile.icon';
import { DirectIconService } from './direct.icon';
import { HomeIconService } from './home.icon';
import { PersonIconService } from './person.icon';
import { ClockIconService } from './clock.icon';
import { DocumentIconService } from './document.icon';
import { LoginIconService } from './log-in.icon';
import { LogoutIconService } from './logout.icon';
import { SocialIconService } from './social.icon';
import { EyeIconService } from './eye.icon';
import { ClockServiceIconService } from './clock-service.icon';
import { CheckServiceIconService } from './check-service.icon';
import { EmailIconService } from './email.icon';
import { CarWashServiceIconService } from './car-wash-service.icon';
import { CoffeeServiceIconService } from './coffee-service.icon';
import { SofaServiceIconService } from './sofa-service.icon';
import { ArrowTwoTopIconService } from './arrow-two-top.icon';
import { MenuIconService } from './menu.icon';
import { UserIconService } from './user.icon';
import { UserXIconService } from './user-x.icon';
import { UsersIconService } from './users.icon';
import { UserCheckIconService } from './user-check.icon';
import { UserMinusIconService } from './user-minus.icon';
import { UserPlusIconService } from './user-plus.icon';
import { MoIconService } from './mo.icon';
import { CarretLeftIconService } from './carret-left.icon';
import { CarretRightIconService } from './carret-right.con';
import { CarretUpIconService } from './carret-up.icon';
import { CarretDownIconService } from './carret-down.icon';
import { SettingIconService } from './setting.icon';
import { EyeOffIconService } from './eye-off.icon';
import { ExpressIconService } from './express.icon';
import { BookIconService } from './book.icon';
import { SidebarMenuIconService } from './sidebar-menu';
import { ArrowDownIconService } from './arrow-down.icon';
import { RepeatIconService } from './repeat.icon';
import { BellIconService } from './bell.icon';
import { BellOffIconService } from './noti-bell-off.icon';
import { SidebarsIconService } from './sidebars.icon';
import { AddIconService } from './add.icon';
import { TextMessageIconService } from './text-message.icon';
import { PhoneIconService } from './phone.icon';
import { PhoneCallIconService } from './phone-call.icon';
import { PhoneForwardedIconService } from './phone-forwarded.icon';
import { PhoneIncomingIconService } from './phone-incoming.icon';
import { PhoneOutgoingIconService } from './phone-outgoing.icon';
import { PhoneMissedIconService } from './phone-missed.icon';
import { PhoneOffIconService } from './phone-off.icon';
import { CircleIconService } from './circle.icon';
import { CircleMinusIconService } from './circle-minus.icon';
import { RadioSelectIconService } from './radio-select.icon';
import { CircleCheckIconService } from './circle-check.icon';
import { SquareIconService } from './square.icon';
import { SquareCheckboxIconService } from './square-checkbox.icon';
import { StarIconService } from './star.icon';
import { ToggleLeftIconService } from './toggle-left.icon';
import { ToggleRightIconService } from './toggle-right.icon';
import { MinusSquareIconService } from './minus-square.icon';
import { InfoIconService } from './info.icon';
import { AlertTriangleIconService } from './alert-triangle.icon';
import { AlertCircleIconService } from './alert-circle.icon';
import { AlertOctagonIconService } from './alert-octagon.icon';
import { HelpCircleIconService } from './help-circle.icon';
import { AlignCenterIconService } from './align-center.icon';
import { AlignJustifyIconService } from './align-justify.icon';
import { AlignLeftIconService } from './align-left.icon';
import { AlignRightIconService } from './align-right.icon';
import { ListIconService } from './list.icon';
import { TrashIconService } from './trash.icon';
import { TrashLineIconService } from './trash-line-ins.icon';
import { FileIconService } from './file.icon';
import { FilePlusIconService } from './file-plus.icon';
import { FileMinusIconService } from './file-minus.icon';
import { FolderIconService } from './folder.icon';
import { FolderMinusIconService } from './folder-minus.icon';
import { FolderPlusIconService } from './folder-plus.icon';
import { MinusIconService } from './minus.icon';
import { CirclePlusIconService } from './circle-plus.icon';
import { XCircleIconService } from './x-circle.icon';
import { PlusSquareIconService } from './plus-square.icon';
import { XSquareIconService } from './x-square.icon';
import { PieChartIconService } from './pie-chart.icon';
import { NavMenuIconService } from './nav-menu.icon';
import { EditPenIconService } from './edit-pen.icon';
import { EditSquareIconService } from './edit-square.icon';
import { ArrowDownLeftIconService } from './arrow-down-left.icon';
import { ArrowDownRightIconService } from './arrow-down-right.icon';
import { ArrowLeftCircleIconService } from './arrow-left-circle.icon';
import { ArrowRightCircleIconService } from './arrow-right-circle.icon';
import { ArrowUpIconService } from './arrow-up.icon';
import { ArrowUpCircleIconService } from './arrow-up-circle.icon';
import { ArrowDownCircleIconService } from './arrow-down-circle.icon';
import { ArrowUpLeftIconService } from './arrow-up-left.icon';
import { ArrowUpRightIconService } from './arrow-up-right.icon';
import { ChevronUpIconService } from './chevron-up.icon';
import { ChevronDownIconService } from './chevron-down.icon';
import { ChevronsDownIconService } from './chevrons-down.icon';
import { ChevronsLeftIconService } from './chevrons-left.icon';
import { ChevronsRightIconService } from './chevrons-right.icon';
import { ChevronsUpIconService } from './chevrons-up.icon';
import { CodeIconService } from './code.icon';
import { CornerDownLeftIconService } from './corner-down-left.icon';
import { CornerDownRightIconService } from './corner-down-right.icon';
import { CornerLeftDownIconService } from './corner-left-down.icon';
import { CornerLeftUpIconService } from './corner-left-up.icon';
import { CornerRightDownIconService } from './corner-right-down.icon';
import { CornerRightUpIconService } from './corner-right-up.icon';
import { CornerUpLeftIconService } from './corner-up-left.icon';
import { CornerUpRightIconService } from './corner-up-right.icon';
import { MoreHorizonIconService } from './more-horizon.icon';
import { MoreVerticalIconService } from './more-vertical.icon';
import { MinimizeIconService } from './minimize.icon';
import { MinimizeArrowIconService } from './minimize-arrow.icon'
import { MaximizeIconService } from './maximize.icon';
import { MaximizeArrowIconService } from './maximize-arrow';
import { MoveIconService } from './move.icon';
import { TerminalIconService } from './terminal.icon';
import { XIconService } from './x.icon';
import { GridIconService } from './grid.icon';
import { FilterIconService } from './filter.icon';
import { DownloadIconService } from './download.icon';
import { DownloadCloudIconService } from './download-cloud.icon';
import { BookmarkIconService } from './bookmark.icon';
import { SlidersIconService } from './sliders.icon';
import { SaveIconService } from './save.icon';
import { PrinterIconService } from './printer.icon';
import { ShareIconService } from './share.icon';
import { LayerIconService } from './layers.icon';
import { CopyIconService } from './copy.icon';
import { ThumbsUpIconService } from './thumbs-up.icon';
import { LinkIconService } from './link.icon';
import { LinkAltIconService } from './link-alt.icon';
import { ExternalLinkIconService } from './external-link';
import { PaperclipIconService } from './paperclip.icon';
import { SendIconService } from './send.icon';
import { RefreshIconService } from './refresh.icon';
import { RefreshAltIconService } from './refresh-alt.icon';
import { UploadIconService } from './upload.icon';
import { PocketIconService } from './pocket.icon';
import { HeadsetIconService } from './headset.icon';
import { MessageIconService } from './message.icon';
import { ImageIconService } from './image.icon';
import { CameraIconService } from './camera.icon';
import { CameraOffIconService } from './camera-off.icon';
import { BriefcaseIconService } from './briefcase.icon';
import { OdometerIconService } from './odometer.icon';
import { ReplyIconService } from './reply.icon';
import { PosIconService } from './pos.icon';
import { PaymentCardIconService } from './payment-card.icon';
import { CheckCirclePaymentIconService } from './check-circle-payment.icon';
import { EllipseIconService } from './ellipse.icon';
import { FlagIconService } from './flag.icon';
import { ShieldIconService } from './shield.icon';
import { DropletIconService } from './droplet.icon';
import { DollarSignIconService } from './dollar-sign.icon';
import { CircleCheckTransparentPlusIconService } from './circle-check-transparent.icon';
import { CompanyIconService } from './company.icon';
import { CloseCircleIconService } from './close-circle.icon';
import { InputCursorTextIconService } from './input-cursor-text.icon';

// TienDO
import { ExpressLogbookCarIconService } from './expresslogbook-car.icon';
import { OverTimeIconService } from './overtime.icon';
import { LogbookIconService } from './logbook.icon';
import { EmptyBoxIconService } from './empty-box.icon';

@Injectable({
  providedIn: 'root',
})
export class IconsService {

  constructor(
    private iconLibraries: NbIconLibraries,
    private calendarIconService: CalendarIconService,
    private carIconService: CarIconService,
    private bagIconService: BagIconService,
    private fileTextIcon: FileTextIconService,
    private creditCardIcon: CreditCardIconService,
    private chevronRightIcon: ChevronRightIconService,
    private thumbnailIconService: ThumbnailIconService,
    private checkIconService: CheckIconService,
    private checkCircleIconService: CheckCircleIconService,
    private checkSquareIconService: CheckSquareIconService,
    private plusIconService: PlusIconService,
    private editIconService: EditIconService,
    private arrowRightIconService: ArrowRightIconService,
    private arrowLeftIconService: ArrowLeftIconService,
    private chevronLeftIconService: ChevronLeftIconService,
    private lockIconService: LockIconService,
    private unlockIconService: UnlockIconService,
    private closeIconService: CloseIconService,
    private mapIconService: MapIconService,
    private mapMarkerIconService: MapMarkerIconService,
    private mobileIconService: MobileIconService,
    private directIconService: DirectIconService,
    private homeIconService: HomeIconService,
    private personIconService: PersonIconService,
    private clockIconService: ClockIconService,
    private documentIconService: DocumentIconService,
    private loginIconService: LoginIconService,
    private logoutIconService: LogoutIconService,
    private socialIconService: SocialIconService,
    private eyeIconService: EyeIconService,
    private clockServiceIconService: ClockServiceIconService,
    private checkServiceIconService: CheckServiceIconService,
    private emailIconService: EmailIconService,
    private carWashServiceIconService: CarWashServiceIconService,
    private coffeeServiceIconService: CoffeeServiceIconService,
    private sofaServiceIconService: SofaServiceIconService,
    private arrowTwoTopIconService: ArrowTwoTopIconService,
    private menuIconService: MenuIconService,
    private userIconService: UserIconService,
    private userXIconService: UserXIconService,
    private usersIconService: UsersIconService,
    private userCheckIconService: UserCheckIconService,
    private userMinusIconService: UserMinusIconService,
    private userPlusIconService: UserPlusIconService,
    private moIconService: MoIconService,
    private carretLeftIconService: CarretLeftIconService,
    private carretRightIconService: CarretRightIconService,
    private carretUpIconService: CarretUpIconService,
    private carretDownIconService: CarretDownIconService,
    private settingIconService: SettingIconService,
    private eyeOffIconService: EyeOffIconService,
    private expressIconService: ExpressIconService,
    private logbookIconService: LogbookIconService,
    private bookIconService: BookIconService,
    private sidebarMenuIconService: SidebarMenuIconService,
    private arrowDownIconService: ArrowDownIconService,
    private repeatIconService: RepeatIconService,
    private bellIconService: BellIconService,
    private bellOffIconService: BellOffIconService,
    private sidebarsIconService: SidebarsIconService,
    private addIconService: AddIconService,
    private textMessageService: TextMessageIconService,
    private phoneIconService: PhoneIconService,
    private phoneCallService: PhoneCallIconService,
    private phoneForwardedIconService: PhoneForwardedIconService,
    private phoneIncomingIconService: PhoneIncomingIconService,
    private phoneOutgoingIconService: PhoneOutgoingIconService,
    private phoneMissedIconService: PhoneMissedIconService,
    private phoneOffIconService: PhoneOffIconService,
    private circleIconService: CircleIconService,
    private circleMinusIconService: CircleMinusIconService,
    private radioSelectIconService: RadioSelectIconService,
    private circleCheckIconService: CircleCheckIconService,
    private squareIconService: SquareIconService,
    private squareCheckboxIconService: SquareCheckboxIconService,
    private starIconService: StarIconService,
    private toggleLeftIconService: ToggleLeftIconService,
    private toggleRightIconService: ToggleRightIconService,
    private minusSquareIconService: MinusSquareIconService,
    private infoIconService: InfoIconService,
    private alertTriangleIconService: AlertTriangleIconService,
    private alertCircleIconService: AlertCircleIconService,
    private alertOctagonIconService: AlertOctagonIconService,
    private helpCircleIconService: HelpCircleIconService,
    private alignCenterIconService: AlignCenterIconService,
    private alignJustifyIconService: AlignJustifyIconService,
    private alignLeftIconService: AlignLeftIconService,
    private alignRightIconService: AlignRightIconService,
    private listIconService: ListIconService,
    private trashIconService: TrashIconService,
    private trashLineIconService: TrashLineIconService,
    private fileIconService: FileIconService,
    private filePlusIconService: FilePlusIconService,
    private fileMinusIconService: FileMinusIconService,
    private folderIconService: FolderIconService,
    private folderMinusIconService: FolderMinusIconService,
    private folderPlusIconService: FolderPlusIconService,
    private minusIconService: MinusIconService,
    private circlePlusIconService: CirclePlusIconService,
    private xCircleIconService: XCircleIconService,
    private plusSquareIconService: PlusSquareIconService,
    private xSquareIconService: XSquareIconService,
    private pieChartIconService: PieChartIconService,
    private navMenuIconService: NavMenuIconService,
    private editPenIconService: EditPenIconService,
    private editSquareIconService: EditSquareIconService,
    private arrowDownLeftIconService: ArrowDownLeftIconService,
    private arrowDownRightIconService: ArrowDownRightIconService,
    private arrowLeftCircleIconService: ArrowLeftCircleIconService,
    private arrowRightCircleIconService: ArrowRightCircleIconService,
    private arrowUpIconService: ArrowUpIconService,
    private arrowUpCircleIconService: ArrowUpCircleIconService,
    private arrowDownCircleIconService: ArrowDownCircleIconService,
    private arrowUpLeftIconService: ArrowUpLeftIconService,
    private arrowUpRightIconService: ArrowUpRightIconService,
    private chevronUpIconService: ChevronUpIconService,
    private chevronDownIconService: ChevronDownIconService,
    private chevronsDownIconService: ChevronsDownIconService,
    private chevronsLeftIconService: ChevronsLeftIconService,
    private chevronsRightIconService: ChevronsRightIconService,
    private chevronsUpIconService: ChevronsUpIconService,
    private codeIconService: CodeIconService,
    private cornerDownLeftIconService: CornerDownLeftIconService,
    private cornerDownRightIconService: CornerDownRightIconService,
    private cornerLeftDownIconService: CornerLeftDownIconService,
    private cornerLeftUpIconService: CornerLeftUpIconService,
    private cornerRightDownIconService: CornerRightDownIconService,
    private cornerRightUpIconService: CornerRightUpIconService,
    private cornerUpLeftIconService: CornerUpLeftIconService,
    private cornerUpRightIconService: CornerUpRightIconService,
    private moreHorizonIconService: MoreHorizonIconService,
    private moreVerticalIconService: MoreVerticalIconService,
    private minimizeIconService: MinimizeIconService,
    private minimizeArrowIconService: MinimizeArrowIconService,
    private maximizeIconService: MaximizeIconService,
    private maximizeArrowIconService: MaximizeArrowIconService,
    private moveIconService: MoveIconService,
    private terminalIconService: TerminalIconService,
    private xIconService: XIconService,
    private gridIconService: GridIconService,
    private filterIconService: FilterIconService,
    private downloadIconService: DownloadIconService,
    private downloadCloudIconService: DownloadCloudIconService,
    private bookmarkIconService: BookmarkIconService,
    private slidersIconService: SlidersIconService,
    private saveIconService: SaveIconService,
    private printerIconService: PrinterIconService,
    private shareIconService: ShareIconService,
    private layerIconService: LayerIconService,
    private copyIconService: CopyIconService,
    private thumbsUpIconService: ThumbsUpIconService,
    private linkIconService: LinkIconService,
    private linkAltIconService: LinkAltIconService,
    private externalLinkIconService: ExternalLinkIconService,
    private paperclipIconService: PaperclipIconService,
    private sendIconService: SendIconService,
    private refreshIconService: RefreshIconService,
    private refreshAltIconService: RefreshAltIconService,
    private uploadIconService: UploadIconService,
    private pocketIconService: PocketIconService,
    private headsetIconService: HeadsetIconService,
    private messageIconService: MessageIconService,
    private imageIconService: ImageIconService,
    private cameraIconService: CameraIconService,
    private cameraOffIconService: CameraOffIconService,
    private briefcaseIconService: BriefcaseIconService,
    private odometerIconService: OdometerIconService,
    private replyIconService: ReplyIconService,
    private posIconService: PosIconService,
    private paymentCardIconService: PaymentCardIconService,
    private checkCirclePaymentIconService: CheckCirclePaymentIconService,
    private ellipseIconService: EllipseIconService,
    private flagIconService: FlagIconService,
    private shieldIconService: ShieldIconService,
    private dropletIconService: DropletIconService,
    private dollarSignIconService: DollarSignIconService,
    private circleCheckTransparentPlusIconService: CircleCheckTransparentPlusIconService,
    private expressLogbookCarIconService: ExpressLogbookCarIconService,
    private overTimeIconService: OverTimeIconService,
    private companyIconService: CompanyIconService,
    private closeCircleIconService: CloseCircleIconService,
    private inputCursorTextIconService: InputCursorTextIconService,
    private emptyBoxIconService: EmptyBoxIconService,
  ) { }

  registerResources() {
    this.iconLibraries.registerSvgPack('mo-icons', {
      'chevron-right': this.chevronRightIcon.render(),
      'chevron-right-grey': this.chevronRightIcon.render('#918F8F'),
      'chevron-left-grey': this.chevronLeftIconService.render('#918F8F'),
      'car': this.carIconService.render(),
      'shopping-bag': this.bagIconService.render(),
      'calendar': this.calendarIconService.render(),
      'calendar-grey': this.calendarIconService.render('grey'),
      'calendar-blue': this.calendarIconService.render('blue'),
      'file-text': this.fileTextIcon.render(),
      'credit-card': this.creditCardIcon.render(),
      'thumbnail': this.thumbnailIconService.render(),
      'check': this.checkIconService.render(),
      'check-circle': this.checkCircleIconService.render(),
      'check-square': this.checkSquareIconService.render(),
      'clock': this.clockIconService.render(),
      'plus': this.plusIconService.render(),
      'person': this.personIconService.render(),
      'edit': this.editIconService.render(),
      'arrow-right': this.arrowRightIconService.render(),
      'arrow-left': this.arrowLeftIconService.render(),
      'arrow-down': this.arrowDownIconService.render(false),
      'arrow-down-alt': this.arrowDownIconService.render(true),
      'chevron-left': this.chevronLeftIconService.render(),
      'chevron-left-alt': this.chevronLeftIconService.newRender(),
      'chevron-up': this.chevronUpIconService.render(),
      'chevron-down': this.chevronDownIconService.render(false),
      'chevron-down-alt': this.chevronDownIconService.render(true),
      'chevrons-down': this.chevronsDownIconService.render(),
      'chevrons-left': this.chevronsLeftIconService.render(),
      'chevrons-right': this.chevronsRightIconService.render(),
      'chevrons-up': this.chevronsUpIconService.render(),
      'lock': this.lockIconService.render(),
      'lock-secure': this.lockIconService.render('secure'),
      'unlock': this.unlockIconService.render(),
      'close-outline': this.closeIconService.render(),
      'map': this.mapIconService.render(),
      'map-grey': this.mapIconService.render('grey'),
      'map-marker': this.mapMarkerIconService.render(),
      'map-pin': this.mapMarkerIconService.mapPin(),
      'mobile': this.mobileIconService.render(),
      'mobile-grey': this.mobileIconService.render('grey'),
      'mobile-white': this.mobileIconService.render('white'),
      'mobile-fill': this.mobileIconService.render('fill'),
      'direct': this.directIconService.render(),
      'home': this.homeIconService.render(),
      'document': this.documentIconService.render(),
      'login': this.loginIconService.render(),
      'logout': this.logoutIconService.render(),
      'facebook': this.socialIconService.render('facebook'),
      'google': this.socialIconService.render('google'),
      'facebook-white': this.socialIconService.render('facebook-white'),
      'google-white': this.socialIconService.render('google-white'),
      'instagram-white': this.socialIconService.render('instagram-white'),
      'instagram': this.socialIconService.render('instagram'),
      'eye': this.eyeIconService.render(),
      'clock-service': this.clockServiceIconService.render(),
      'check-service': this.checkServiceIconService.render(),
      'carret-left': this.carretLeftIconService.render(),
      'carret-right': this.carretRightIconService.render(),
      'carret-up': this.carretUpIconService.render(),
      'carret-down': this.carretDownIconService.render(),
      'email': this.emailIconService.render(),
      'email-orange': this.emailIconService.render('orange'),
      'car-wash': this.carWashServiceIconService.render(),
      'coffee': this.coffeeServiceIconService.render(),
      'sofa': this.sofaServiceIconService.render(),
      'arrow-two-top': this.arrowTwoTopIconService.render(),
      'menu': this.menuIconService.render(),
      'user': this.userIconService.render(),
      'user-x': this.userXIconService.render(),
      'users': this.usersIconService.render(),
      'user-check': this.userCheckIconService.render(),
      'user-minus': this.userMinusIconService.render(),
      'user-plus': this.userPlusIconService.render(),
      'mo': this.moIconService.render(),
      'mo-black': this.moIconService.render('black'),
      'mo-orange': this.moIconService.render('orange'),
      'setting': this.settingIconService.render(),
      'eye-off': this.eyeOffIconService.render(),
      'express': this.expressIconService.render(),
      'logbook': this.logbookIconService.render(),
      'book': this.bookIconService.render(),
      'sb-dashboard': this.sidebarMenuIconService.dashboard(),
      'sb-customers': this.sidebarMenuIconService.customers(),
      'sb-booking': this.sidebarMenuIconService.booking(),
      'sb-flightdeck': this.sidebarMenuIconService.flightdeck(),
      'sb-report': this.sidebarMenuIconService.report(),
      'sb-beverages': this.sidebarMenuIconService.beverages(),
      'sb-loungekiosk': this.sidebarMenuIconService.loungekiosk(),
      'sb-faq': this.sidebarMenuIconService.faq(),
      'search': this.sidebarMenuIconService.search(),
      'repeat': this.repeatIconService.render(),
      'bell': this.bellIconService.render(),
      'bell-noti': this.bellIconService.bellNotification(),
      'bell-off': this.bellOffIconService.render(),
      'sidebar-back': this.sidebarsIconService.back(),
      'add': this.addIconService.render(),
      'text-message': this.textMessageService.render(),
      'phone': this.phoneIconService.render(),
      'phone-call': this.phoneCallService.render(),
      'phone-forward': this.phoneForwardedIconService.render(),
      'phone-incoming': this.phoneIncomingIconService.render(),
      'phone-outgoing': this.phoneOutgoingIconService.render(),
      'phone-missed': this.phoneMissedIconService.render(),
      'phone-off': this.phoneOffIconService.render(),
      'circle': this.circleIconService.render(),
      'circle-express': this.circleIconService.renderCircleExpress(),
      'circle-orange': this.circleIconService.renderCircleOrange(),
      'circle-green': this.circleIconService.renderCircleGreen(),
      'circle-red': this.circleIconService.renderCircleRed(),
      'circle-logbook': this.circleIconService.renderCircleLogbook(),
      'circle-oem': this.circleIconService.renderCircleOEM(),
      'circle-minus': this.circleMinusIconService.render(),
      'radio-select': this.radioSelectIconService.render(),
      'radio-select-status': this.radioSelectIconService.render(false),
      'radio-select-checking': this.radioSelectIconService.checking(),
      'circle-check': this.circleCheckIconService.render(),
      'circle-check-ready': this.circleCheckIconService.render('green'),
      'square': this.squareIconService.render(),
      'square-checkbox': this.squareCheckboxIconService.render(),
      'star': this.starIconService.render(),
      'toggle-left': this.toggleLeftIconService.render(),
      'toggle-right': this.toggleRightIconService.render(),
      'minus-square': this.minusSquareIconService.render(),
      'info': this.infoIconService.render(),
      'alert-triangle': this.alertTriangleIconService.render(),
      'alert-circle': this.alertCircleIconService.render(),
      'alert-octagon': this.alertOctagonIconService.render(),
      'help-circle': this.helpCircleIconService.render(),
      'align-center': this.alignCenterIconService.render(),
      'align-justify': this.alignJustifyIconService.render(),
      'align-left': this.alignLeftIconService.render(),
      'align-right': this.alignRightIconService.render(),
      'list': this.listIconService.render(),
      'trash': this.trashIconService.render(),
      'trash-line-ins': this.trashLineIconService.render(),
      'file': this.fileIconService.render(),
      'file-plus': this.filePlusIconService.render(),
      'file-minus':this.fileMinusIconService.render(),
      'folder': this.folderIconService.render(),
      'folder-minus': this.folderMinusIconService.render(),
      'folder-plus': this.folderPlusIconService.render(),
      'minus': this.minusIconService.render(),
      'circle-plus': this.circlePlusIconService.render(),
      'x-circle': this.xCircleIconService.render(),
      'plus-square': this.plusSquareIconService.render(),
      'x-square': this.xSquareIconService.render(),
      'pie-chart': this.pieChartIconService.render(),
      'nav-menu': this.navMenuIconService.render(),
      'edit-pen': this.editPenIconService.render(),
      'edit-square': this.editSquareIconService.render(),
      'arrow-down-left': this.arrowDownLeftIconService.render(),
      'arrow-down-right': this.arrowDownRightIconService.render(),
      'arrow-left-circle': this.arrowLeftCircleIconService.render(),
      'arrow-right-circle': this.arrowRightCircleIconService.render(),
      'arrow-up': this.arrowUpIconService.render(),
      'arrow-up-circle': this.arrowUpCircleIconService.render(),
      'arrow-down-circle': this.arrowDownCircleIconService.render(),
      'arrow-up-left': this.arrowUpLeftIconService.render(),
      'arrow-up-right': this.arrowUpRightIconService.render(),
      'code': this.codeIconService.render(),
      'corner-down-left': this.cornerDownLeftIconService.render(),
      'corner-down-right': this.cornerDownRightIconService.render(),
      'corner-left-down': this.cornerLeftDownIconService.render(),
      'corner-left-up': this.cornerLeftUpIconService.render(),
      'corner-right-down': this.cornerRightDownIconService.render(),
      'corner-right-up': this.cornerRightUpIconService.render(),
      'corner-up-left': this.cornerUpLeftIconService.render(),
      'corner-up-right': this.cornerUpRightIconService.render(),
      'more-horizon': this.moreHorizonIconService.render(),
      'more-vertical': this.moreVerticalIconService.render(),
      'minimize': this.minimizeIconService.render(),
      'minimize-arrow': this.minimizeArrowIconService.render(),
      'maximize': this.maximizeIconService.render(),
      'maximize-arrow': this.maximizeArrowIconService.render(),
      'move': this.moveIconService.render(),
      'terminal': this.terminalIconService.render(),
      'x-icon': this.xIconService.render(),
      'grid': this.gridIconService.render(),
      'filter': this.filterIconService.render(),
      'download-cloud': this.downloadCloudIconService.render(),
      'download': this.downloadIconService.render(),
      'bookmark': this.bookmarkIconService.render(),
      'slider': this.slidersIconService.render(),
      'save': this.saveIconService.render(),
      'printer': this.printerIconService.render(),
      'share': this.shareIconService.render(),
      'layer': this.layerIconService.render(),
      'copy': this.copyIconService.render(),
      'thumbs-up': this.thumbsUpIconService.render(),
      'link': this.linkIconService.render(),
      'link-alt':this.linkAltIconService.render(),
      'external-link':this.externalLinkIconService.render(),
      'paperclip': this.paperclipIconService.render(),
      'send': this.sendIconService.render(),
      'refresh': this.refreshIconService.render(),
      'refresh-alt': this.refreshAltIconService.render(),
      'upload': this.uploadIconService.render(),
      'pocket': this.pocketIconService.render(),
      'headset': this.headsetIconService.render(),
      'message': this.messageIconService.render(),
      'image': this.imageIconService.render(),
      'camera': this.cameraIconService.render(),
      'camera-off': this.cameraOffIconService.render(),
      'briefcase': this.briefcaseIconService.render(),
      'odometer': this.odometerIconService.render(),
      'reply': this.replyIconService.render(),
      'pos' : this.posIconService.render(),
      'payment-card': this.paymentCardIconService.render(),
      'check-circle-payment': this.checkCirclePaymentIconService.render(),
      'ellipse': this.ellipseIconService.render(),
      'flag': this.flagIconService.render(),
      'shield': this.shieldIconService.render(),
      'droplet': this.dropletIconService.render(),
      'dollar-sign': this.dollarSignIconService.render(),
      'circle-check-transparent': this.circleCheckTransparentPlusIconService.render(),
      'express-car-up': this.expressLogbookCarIconService.render('express', 'up'),
      'logbook-car-up': this.expressLogbookCarIconService.render('logbook', 'up'),
      'oem-car-up': this.expressLogbookCarIconService.render('oem', 'up'),
      'express-car-down': this.expressLogbookCarIconService.render('express', 'down'),
      'logbook-car-down': this.expressLogbookCarIconService.render('logbook', 'down'),
      'oem-car-down': this.expressLogbookCarIconService.render('oem', 'down'),
      'express-car-left': this.expressLogbookCarIconService.render('express', 'left'),
      'logbook-car-left': this.expressLogbookCarIconService.render('logbook', 'left'),
      'oem-car-left': this.expressLogbookCarIconService.render('oem', 'left'),
      'express-car-right': this.expressLogbookCarIconService.render('express', 'right'),
      'logbook-car-right': this.expressLogbookCarIconService.render('logbook', 'right'),
      'oem-car-right': this.expressLogbookCarIconService.render('oem', 'right'),
      'overtime': this.overTimeIconService.render(),
      'company': this.companyIconService.render(),
      'close-circle': this.closeCircleIconService.render(),
      'input-cursor-text': this.inputCursorTextIconService.render(),
      'empty-box': this.emptyBoxIconService.render(),
    });
  }

}
