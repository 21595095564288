import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class AlertTriangleIconService {

    render() {
        return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.50011 22C3.00011 22 2.50011 21.9 2.00011 21.6C0.600109 20.8 0.100109 18.9 0.900109 17.5L9.40011 3.30002C9.70011 2.90002 10.0001 2.50002 10.4001 2.30002C11.1001 1.90002 11.9001 1.80002 12.7001 2.00002C13.5001 2.20002 14.1001 2.70002 14.6001 3.40002L23.0001 17.5C23.3001 18 23.4001 18.5 23.4001 19C23.4001 19.8 23.1001 20.6 22.5001 21.1C22.0001 21.7 21.3001 22 20.5001 22H3.50011ZM11.1001 4.40002L2.70011 18.5C2.40011 19 2.60011 19.6 3.10011 19.9C3.20011 20 3.40011 20 3.50011 20H20.4001C20.7001 20 20.9001 19.9 21.1001 19.7C21.3001 19.5 21.4001 19.3 21.4001 19C21.4001 18.8 21.4001 18.7 21.3001 18.5L12.9001 4.40002C12.6001 3.90002 12.0001 3.80002 11.5001 4.00002C11.3001 4.10002 11.2001 4.20002 11.1001 4.40002Z" fill="black"/>
        <path d="M12.0001 14C11.4001 14 11.0001 13.6 11.0001 13V9.00002C11.0001 8.40002 11.4001 8.00002 12.0001 8.00002C12.6001 8.00002 13.0001 8.40002 13.0001 9.00002V13C13.0001 13.6 12.6001 14 12.0001 14Z" fill="black"/>
        <path d="M12.0001 18C11.7001 18 11.5001 17.9 11.3001 17.7C11.1001 17.5 11.0001 17.3 11.0001 17C11.0001 16.9 11.0001 16.7 11.1001 16.6C11.2001 16.5 11.2001 16.4 11.3001 16.3C11.4001 16.2 11.5001 16.1 11.6001 16.1C11.8001 16 12.0001 16 12.2001 16C12.3001 16 12.3001 16 12.4001 16.1C12.5001 16.1 12.5001 16.1 12.6001 16.2C12.6001 16.2 12.7001 16.3 12.8001 16.3C12.9001 16.4 13.0001 16.5 13.0001 16.6C13.0001 16.7 13.1001 16.9 13.1001 17C13.1001 17.3 13.0001 17.5 12.8001 17.7C12.5001 17.9 12.3001 18 12.0001 18Z" fill="black"/>
        </svg>        
        `;
    }
}
