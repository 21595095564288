import { QueryEntity } from '@datorama/akita';
import { EntitiesStore, EntitiesState } from './entities.store';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EntitiesQuery extends QueryEntity<EntitiesState> {
  entities$ = this.selectAll();

  constructor(protected store: EntitiesStore) {
    super(store);
  }

  findParentEntity(id: number) {
    return this.selectAll().pipe(
      map(data => {
        const parentItem = data.find(item => item.id === id);
        return parentItem && parentItem.parentId;
      })
    );
  }

  get getEntities() {
    return this.getAll();
  }

  findEntity(id: number) {
    return this.getAll().find(entity => entity.id === id);
  }
}