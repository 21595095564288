import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import * as moment from 'moment-timezone';
import { getTimezone } from '../../../@core/akita-stores/storage';
import { AddServiceRecommendationDlgComponent } from './add-service-recommendation-dlg/add-service-recommendation-dlg.component';
import { GeneralDialogComponent } from '../general-dialog/general-dialog.component';
import { VehiclesService } from '../../../@core/akita-stores/entity-stores/vehicles/vehicles.service';
import { VEHICLE_PERMISSIONS } from '../../../constants';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'service-recommendations',
  templateUrl: './service-recommendations.component.html',
  styleUrls: ['./service-recommendations.component.scss']
})
export class ServiceRecommendationsComponent implements OnInit, OnDestroy {

  @Input() info: any;
  @Input() isUneditableMode: any;
  serviceRecommendations = [];
  serviceRecommendations$;
  recommendationsSelected: any = {};
  timezone = getTimezone();
  loading: boolean = false;
  vehiclePermissions = VEHICLE_PERMISSIONS;
  protected destroy$ = new Subject<void>();

  constructor(
    private ref: NbDialogRef<ServiceRecommendationsComponent>,
    private dialogService: NbDialogService,
    private vehiclesService: VehiclesService
  ) { }

  ngOnInit() {
    this.getRecommendations();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getRecommendations() {
    this.loading = true;
    this.serviceRecommendations$ = this.vehiclesService.getServiceRecommendations(this.info.customerId)
    .pipe(takeUntil(this.destroy$))
    .subscribe((res: any) => {
      if(res) {
        this.serviceRecommendations = res.results.map(item => {
          return {
            id: item.id,
            bookingId: item.bookingId ? item.bookingId : null,
            time: item.scheduledAt ? moment.utc(item.scheduledAt).tz(this.timezone).format('DD/MM/YYYY') : null,
            content: item.content ? item.content : null,
            scheduledAt: item.scheduledAt
          }
        });
      }
      this.loading = false;
    }),() => this.loading = false;
  }
  closeDlg() {
    this.ref.close();
  }

  openDlg(type) {
    let details;
    if (type === 'edit') {
      details = this.recommendationsSelected;
    } 

    const data = {
      bookingId: this.info.bookingId,
      appointmentTime: this.info.appointmentTime,
      vehicleId: this.info.vehicleId,
      types: type,
      details
    };
    this.dialogService.open(AddServiceRecommendationDlgComponent, {
      closeOnBackdropClick: false,
      context: {
        data: data
      }
    }).onClose.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res) this.getRecommendations();
    });
  }

  openMenu(e, service) {
    e.stopPropagation();
    this.recommendationsSelected = service;
  }

  openConfirmDlg() {
    this.dialogService.open(GeneralDialogComponent, {
      context: {
        text: 'Are you sure you want to delete this?',
        buttonCancelTitle: 'Cancel',
        buttonAcceptTitle: 'Yes',
      }
    }).onClose.pipe(takeUntil(this.destroy$)).subscribe((agree) => {
      if (agree) {
        let details = this.recommendationsSelected;
        const id = details.id;
        this.vehiclesService.deleteRecommendation(id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.getRecommendations())
      }
    })
  }
}

