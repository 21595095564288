import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceCenterService {
  goBack$ = new Subject<boolean>();
  confirmGoBack$ = new Subject<boolean>();
  pageGeneralInfo: boolean = false;
  private _entityContext;

  constructor() { }

  public get entityContext() {
    return this._entityContext;
  }

  public set entityContext(entityContext) {
    this._entityContext = entityContext
  }

  protected createParamUrl(store, isCheckedActive = false) {
    const page: number = store.getPage();
    const pageSize: number = store.getLimit();
    let keyword: string = store.getSearchTerm();
    let sortingField: string = store.getSortColumn();
    if (keyword) {
      keyword = `keyword=${encodeURIComponent(keyword)}&`;
    }
    if (sortingField) {
      sortingField = `sort=${sortingField}&`;
    }

    let url = `?${keyword}${sortingField}page=${page}&pageSize=${pageSize}`;

    if (isCheckedActive && !store.getIsActive()) {
      return `${url}&f[IsActive]=true`;
    }

    return url;
  }

  protected addEntityContextParam(isFirstParam: boolean) {
    return (isFirstParam ? "?" : "&") + `entityContext=${this._entityContext}`;
  }
}
