import { ID } from "@datorama/akita";

export interface Bay {
    id?: ID,
    isActive: boolean,
    bayNo: string,
    serviceType: {
        id: string,
        description: string
    },
    description: string,
    pod: {
        id: string,
        name: string
    },
    gateway: {
        id: string,
        description: string,
        code: string
    }
}

export interface ServiceType {
    id: number,
    code: string,
    description: string
}

export interface POD {
    id: number,
    bayDescription: string,
    bayNo: string,
    isActive: boolean,
    name: string,
    note: string,
    serviceType: string
}

export interface Gateway {
    id: number,
    bayNo: string,
    code: string,
    description: string,
    deviceId: string,
    isActive: boolean,
    serviceType: string
}

export const initialBaseState = {
    currentCount: 0,
    count: 0,
    limit: 0,
    page: 1,
    searchTerm: '',
    stateSelected: '',
    sorts: []
  }

