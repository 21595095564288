<ng-container>
  <nb-card class="input-amount-popup">
    <nb-card-header>Payment Amount<nb-icon icon="x-icon" pack="mo-icons" (click)=close()></nb-icon>
    </nb-card-header>
    <nb-card-body>
      <div>
        <form [formGroup]="form" class="card-details-form">
          <mat-form-field *ngIf="!isExpress" class="full-width box" appearance="legacy" floatLabel="always">
            <mat-label>Payment Amount</mat-label>
            <input name="amount" matInput type="text" formControlName="amount" mask="separator" thousandSeparator=","
              placeholder="Please enter payment amount" prefix="$">
            <mat-error *ngIf="form.controls.amount.errors && form.controls.amount.errors.required">
              {{ errorMessage['paymentAmount'] }}
            </mat-error>
          </mat-form-field>
          <label class="font-weight-600" *ngIf="isExpress" for="">Payment Amount: {{price.total | currency}}</label>
          <div class="confirm-button d-flex flex-row justify-content-end mt-4">
            <button class="small cancel mr-3 font-weight-600 font-family-tw primary-color"
              (click)="close()">Cancel</button>
            <button (click)="open()" class="add small primary">Pay Now {{amount}}</button>
          </div>
        </form>
      </div>
    </nb-card-body>
  </nb-card>
</ng-container>