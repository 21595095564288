export const REGEX = {
  REGEX_EMAIL: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$',
  REGEX_PRICE: '^[0-9]+$',
  REGEX_ALPHANUMERIC: '^[a-zA-Z0-9]+$',
};

export const MAXLENGTH = {
  LEN_JOBCODE: 20,
  LEN_PRICE: 20,
  LEN_DMSJOBCODE: 50,
  LEN_DESCRIPTION: 255,
  LEN_NAME: 50,
  LEN_BEACONCODE: 20,
  LEN_GATEWAYCODE: 20,
  LEN_DEVICEID: 20,
}

export const DEFAULT_VALUE = {
  DEFAULT_LIMIT: 20
}

export const CYLINDERS = {
  LIST_CYLINDERS: ['4', '6', '8'],
  DEFAULT_CYLINDERS: '4'
}

export const BOOKING_LEAD_DAYS_MIN = 1;

export const BOOKING_APPOINTMENT_REF_VALID = REGEX.REGEX_ALPHANUMERIC;
export const BOOKING_APPOINTMENT_REF_MAX_LENGTH = 20;
