import { Injectable } from '@angular/core';
import { UpperCasePipe, TitleCasePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { API_PATH } from './../../constants/api-urls';
import { map } from 'rxjs/internal/operators/map';
import { APIService } from './api.service';
import { PERMISSION_ACTIONS, PermissionAction, Permissions, Role } from '../data/roles-permissions';
import * as _ from 'lodash';

interface RoleCreatePayload {
  name: string;
  isActive: boolean;
  code: string;
  description?: string;
}

interface PermissionRoleUpdatePayload {
  roleCode: string;
  modules: Permissions
}

@Injectable({
  providedIn: 'root'
})
export class RolesPermissionsService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getRoles() {
    const request = this.httpClient.get(API_PATH.rolesPermissions.roles);

    return request.pipe(map((res: any) => res.results as Role[] || []));
  }

  getSearchRoles(key, searchTerm) {
    const url = API_PATH.rolesPermissions.roles + `?${key}=${searchTerm}`;

    return this.httpClient.get(url).pipe(map((res:any) => res.results));
  }

  getSortRoles(sort) {
    const url = API_PATH.rolesPermissions.roles + `?sort=${sort}`
    
    return this.httpClient.get(url).pipe(map((res: any) => res.results));
  }

  getRolePermissions(roleCode: string) {
    const url = APIService.prepareURI(API_PATH.rolesPermissions.permissionsOfRoles, { roleCode });

    return this.httpClient.get(url).pipe(map((res: any) => res.results || []));
  }

  getAllPermissions() {
    const url = API_PATH.rolesPermissions.permissions;

    return this.httpClient.get(url).pipe(map((res: any) => res.results || []));
  }

  addPermissionToRole(roleCode, permissionCode) {
    const url =  APIService.prepareURI(API_PATH.rolesPermissions.addDeletePermissionsToRole, { roleCode, permissionCode });

    return this.httpClient.post(url, null);
  }

  deletePermissionOffRole(roleCode, permissionCode) {
    const url =  APIService.prepareURI(API_PATH.rolesPermissions.addDeletePermissionsToRole, { roleCode, permissionCode });

    return this.httpClient.delete(url);
  }

  handleRoleOperations(payload) {
    const url = API_PATH.rolesPermissions.rights;

    return this.httpClient.put(url, payload);
  }

  updateRoleDetails(roleCode: string, roleDetails: any) {
    const url = APIService.prepareURI(API_PATH.rolesPermissions.updateRole, { roleCode });

    return this.httpClient.put(url, roleDetails);
  }

  updateRolePermission( roleDetails: PermissionRoleUpdatePayload) {
    const url = APIService.prepareURI(API_PATH.rolesPermissions.updatePermissionsOfRoles);

    return this.httpClient.put(url, roleDetails);
  }

  addRole(roleDetails: RoleCreatePayload) {
    // API_PATH.rolesPermissions.roles
    return this.httpClient.post(API_PATH.rolesPermissions.roles, roleDetails);
  }

  getRBACList() {
    const url = API_PATH.rolesPermissions.rbacList;

    return this.httpClient.get(url).pipe(map((res: any) => this.transformRBACList(res.results || [])));
  }

  private convertRBACActions(actions) {
    const convertActions = {};
    PERMISSION_ACTIONS.forEach((action) => {
      const operations = _.get(actions, `${action}.operations`) || [];
      if (operations && operations.length > 0) {
        // have operations mean this action is checkable
        convertActions[action] = false; // default value
      } else {
        convertActions[action] = null; // disable value
      }
    });
    return convertActions;
  }

  private transformRBACList(RBACPermissions) {
    return RBACPermissions.map((permissionModule) => {
      const { actions: actionsModule, permissions } = permissionModule;
      if (permissions) {
        permissions.forEach((permission) => {
          permission.actions = this.convertRBACActions(permission.actions);
        });
      } else {
        permissionModule.actions = this.convertRBACActions(actionsModule);
      }
      return permissionModule;
    })
  }
  
}
