import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ERROR_MESSAGES } from '../../../constants/error-messages';
import { NbDialogRef } from '@nebular/theme';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ConfigStore } from '../../../@core/akita-stores/stores/config/config.store';
import { BookingDetailsSelectedQuery } from './../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.query';
import { CommonService } from './../../../@core/utils/common.service';
import { BookingDetailsSelectedStore } from './../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.store';
import * as _ from 'lodash';
import { BookingDetailsSelectedService } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.service';
import { PaymentService } from '../../../@core/utils/payment.service';

@Component({
  selector: 'input-amount-popup',
  templateUrl: './input-amount-popup.component.html',
  styleUrls: ['./input-amount-popup.component.scss']
})
export class InputAmountPopupComponent implements OnInit {

  amount;
  errorMessage = ERROR_MESSAGES;
  @Output() currentState = new EventEmitter<string>();
  form: FormGroup;
  bookingDetails;
  isExpress: boolean;
  price: any;

  constructor(
    protected ref: NbDialogRef<InputAmountPopupComponent>,
    private configStore: ConfigStore,
    private bookingDetailsQuery: BookingDetailsSelectedQuery,
    private bookingStore: BookingDetailsSelectedStore,
    private commonService: CommonService,
    private paymentService: PaymentService,
    private bookingService: BookingDetailsSelectedService
  ) {
    this.form = new FormGroup({
      amount: new FormControl('', [Validators.required])
    });
    this.configStore.setInputAmount(null);
    this.bookingDetails = this.bookingDetailsQuery.getValue();
    this.isExpress = this.bookingDetails.serviceType.code === 'express';
    this.price = this.commonService.calcTotalPrice(this.bookingDetails);
    this.form.controls.amount.setValue(this.price.total);
  }

  ngOnInit() {

  }

  close() {
    this.ref.close();
  }

  open() {
    if (!this.isExpress && this.form.invalid) return;

    this.paymentService.submitPayment(this.bookingDetailsQuery.getValue().id, this.form.value.amount || this.price.total).subscribe(val => {
      // this.configStore.setInputAmount(this.form.value.amount);
      this.currentState.emit('payment-result');
      this.bookingService.getBookingDetails(this.bookingDetailsQuery.getValue().id).subscribe();
    }, err => {

    })
  }
}
