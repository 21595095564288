<nb-card [nbSpinner]="loading" class="confirm">
  <nb-card-body class="d-flex flex-column align-items-center justify-content-center">
    <label class="body-3 text-center" [innerHTML]="text"></label>
    <div class="confirm-button d-flex flex-row justify-content-end mt-4">
      <button *ngIf="!buttonCancelTitle" class="small cancel mr-3 font-weight-600 font-family-tw primary-color"
        (click)="closeDialog(false)">Cancel</button>
      <button *ngIf="buttonCancelTitle" class="small cancel mr-3 font-weight-600 font-family-tw primary-color"
        (click)="closeDialog(false)">{{buttonCancelTitle}}</button>
      <button *ngIf="!buttonAcceptTitle" class="add small primary">Delete</button>
      <button *ngIf="buttonAcceptTitle && !isSendEmit" (click)="closeDialog(true)"
        class="add small primary">{{buttonAcceptTitle}}</button>
      <button *ngIf="buttonAcceptTitle && isSendEmit" (click)="closeDialog(true)"
        class="add small primary">{{buttonAcceptTitle}}</button>
    </div>
  </nb-card-body>
</nb-card>