<form (ngSubmit)="onSubmit()">
  <nb-card>
    <nb-card-header>Assign Role <nb-icon icon="x-icon" pack="mo-icons" (click)=close()></nb-icon></nb-card-header>

    <nb-card-body class="d-flex flex-column p-3">
      <p>
        <span class="sub-text">Select one or multiple roles to assign to </span>
        {{employeeDetails.firstName | titlecase}} {{employeeDetails.lastName | titlecase}}
      </p>
      <multiple-select [options]="roleOptions" placeholder="Select roles" [noSelectCount]="true"
        [(selected)]="roleSelected" (selectedChange)="handleRoleChange($event)"></multiple-select>

      <div class="tag-container mt-3">
        <span class="title">Assigned role(s): </span>
        <span class="selected-tag" *ngFor="let role of roleSelectedValues">
          {{ role.label }}
          <nb-icon icon="x-icon" pack="mo-icons" (click)="removeRole(role)"></nb-icon>
        </span>
      </div>
    </nb-card-body>

    <nb-card-footer class="d-flex flex-row-reverse border-0">
      <button nbButton class="small ml-2" type="submit">Save</button>
      <button nbButton class="secondary small mx-2" (click)="close()">Cancel</button>
    </nb-card-footer>
  </nb-card>
</form>