<div class="box-search-rego mt-3 col-12 px-2" [nbSpinner]="loading" nbSpinnerStatus="primary">
  <div class="mo-container pt-4 pb-4">
    <h4 class="mb-3 text-center">Select Vehicle manually</h4>
    <form #f="ngForm" (ngSubmit)="onSubmit()">
      <div>
        <input type="text" nbInput fullWidth placeholder="YOUR REGO" class="box-search-rego__input"
          [(ngModel)]="model.rego" [ngModelOptions]="{standalone: true}" (keyup)="removeWhiteSpacesRego(model.rego)">
      </div>
      <div class="my-3">
        <div class="row">
          <div class="col-12">
            <div class="mo-select box">
              <label>Make*</label>
              <nb-select (selectedChange)="onMakeChanged($event)" [(selected)]="model.make"
                [disabled]="!vehicleSearch.makes" fullWidth>
                <nb-select-label>
                  <span class="w-100">{{ model.make }}</span>
                </nb-select-label>
                <nb-option *ngFor="let makeObj of vehicleSearch.makes;let i = index" [value]="makeObj.make">
                  {{ makeObj.make }}
                </nb-option>
              </nb-select>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6 pr-1">
            <div class="mo-select box" [ngClass]="model.variant === 'invalid' ? 'error' : ''">
              <label>Model</label>
              <nb-select [(selected)]="model.model" (selectedChange)="onModelChanged()"
                [disabled]="!vehicleSearch.models" class="box-search-rego__select-state" fullWidth>
                <nb-select-label>
                  <span class="w-100">{{ model.model }}</span>
                </nb-select-label>
                <nb-option *ngFor="let modelObj of vehicleSearch.models" value="{{ modelObj.model }}">
                  {{ modelObj.model }}
                </nb-option>
              </nb-select>
              <mat-hint *ngIf="model.variant === 'invalid'">
                No variant found
              </mat-hint>
            </div>
          </div>
          <div class="col-6 pl-1">
            <div class="mo-select box" [ngClass]="model.variant === 'invalid' ? 'error' : ''">
              <label>Variant</label>
              <nb-select [(selected)]="model.variant" (selectedChange)="onVariantChanged()"
                [disabled]="!vehicleSearch.variants" class="box-search-rego__select-state" fullWidth>
                <nb-select-label>
                  <span class="w-100">{{ model.variant }}</span>
                </nb-select-label>
                <nb-option *ngFor="let variantObj of vehicleSearch.variants" value="{{ variantObj.variant }}">
                  {{ variantObj.variant }}
                </nb-option>
                <mat-hint *ngIf="model.year === 'invalid'">
                  No year found
                </mat-hint>
              </nb-select>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6 pr-1">
            <div class="mo-select box">
              <label>Year</label>
              <nb-select [(selected)]="model.year" (selectedChange)="onYearChanged()" [disabled]="!vehicleSearch.years"
                class="box-search-rego__select-state" fullWidth>
                <nb-option *ngFor="let yearObj of vehicleSearch.years" value="{{ yearObj.year }}">{{ yearObj.year }}
                </nb-option>
              </nb-select>
            </div>
          </div>
          <div class="col-6 pl-1">
            <div class="mo-select box">
              <label>Transmission</label>
              <nb-select [(selected)]="model.transmission" [disabled]="!vehicleSearch.transmissions"
                (selectedChange)="onTransmissionChanged()" class="box-search-rego__select-state" fullWidth>
                <nb-select-label>
                  <span class="w-100">{{ model.transmission }}</span>
                </nb-select-label>
                <nb-option *ngFor="let transmission of vehicleSearch.transmissions"
                  value="{{ transmission.transmission }}" attr.data-slid="{{transmission.slid}}">
                  {{ transmission.transmission }}</nb-option>
              </nb-select>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <div class="mo-select box">
              <label>Cylinder</label>
              <nb-select [(selected)]="model.cylinder" [disabled]="!vehicleSearch.cylinder"
                class="box-search-rego__select-state" fullWidth>
                <nb-option *ngFor="let cylinder of vehicleSearch.cylinder" value="{{ cylinder.cylinder }}">
                  {{ cylinder.cylinder }}
                </nb-option>
              </nb-select>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <button nbButton fullWidth class="box-search-rego__search" [disabled]="disabledSelect()">Select</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>