<form [formGroup]="editRoleForm" (ngSubmit)="submit()">
  <nb-card [nbSpinner]="loading" class="booking-cancel-dialog">
    <nb-card-header>Rename Role <nb-icon icon="x-icon" pack="mo-icons" (click)=close()></nb-icon>
    </nb-card-header>
    <nb-card-body>
      <mat-form-field class="full-width box" appearance="legacy" floatLabel="always">
        <mat-label>New role name</mat-label>
        <input class="p-2" matInput type="text" formControlName="roleName">
        <mat-error *ngIf="editRoleForm.get('roleName')?.hasError('required')">
          {{ errorMessages['roleNameRequired'] }}
        </mat-error>
        <mat-error *ngIf="editRoleForm.get('roleName')?.hasError('exists')">
          {{ errorMessages['roleNameExists'] }}
        </mat-error>
      </mat-form-field>

    </nb-card-body>
    <nb-card-footer class="d-flex flex-row-reverse border-0">
      <button nbButton class="small ml-2" type="submit">Rename</button>
      <button nbButton class="secondary small mx-2" (click)="close()">Cancel</button>
    </nb-card-footer>
  </nb-card>
</form>