export const STAFF_PERMISSIONS = {
  'removeUserRole_technician': 'removeUserRole_technician',
  'assignUserRole_service-advisor': 'assignUserRole_service-advisor',
  'assignUserRole_cleaner': 'assignUserRole_cleaner',
  'assignUserRole_technician': 'assignUserRole_technician',
  'updateServiceCenterStaff': 'updateServiceCenterStaff',
  'createServiceCenterStaff': 'createServiceCenterStaff',
  'getServiceCenterStaffs': 'getServiceCenterStaffs',
  'removeUserRole_cleaner': 'removeUserRole_cleaner',
  'removeUserRole_service-advisor': 'removeUserRole_service-advisor',
  'getCompanies': 'getCompanies',
  'updateCompany': 'updateCompany',
}