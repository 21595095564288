import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class CheckIconService {

    render() {
        return `<svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2559 0.410582C15.5814 0.736019 15.5814 1.26366 15.2559 1.58909L6.08926 10.7558C5.76382 11.0812 5.23618 11.0812 4.91074 10.7558L0.744078 6.58909C0.418641 6.26366 0.418641 5.73602 0.744078 5.41058C1.06951 5.08514 1.59715 5.08514 1.92259 5.41058L5.5 8.98799L14.0774 0.410582C14.4028 0.0851447 14.9305 0.0851447 15.2559 0.410582Z" fill="white"/>
            </svg>
        `;
    }
}
