import { HttpClient } from '@angular/common/http';
import { API_PATH } from '../../../../constants/api-urls';
import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CustomerVehiclesStore } from './customer-vehicles.store';

@Injectable({ providedIn: 'root' })
export class CustomerVehiclesService {
  constructor(
    private http: HttpClient,
    private customerVehiclesStore: CustomerVehiclesStore
  ) { }

  getCustomerVehicles(customerId: number, page: number = 1, pageSize: number = 50) {
    return this.http.get(API_PATH.vehicles.customers + `?customerId=${customerId}&page=${page}&pageSize=${pageSize}`).pipe(
      map((res: any) => res.results),
      tap(vehicles => {
        this.customerVehiclesStore.upsertMany(vehicles);
        this.customerVehiclesStore.updateCurrentCount(vehicles.length);
      })
    )
  }

  getBookings(params) {
    return this.http.get(API_PATH.bookings.bookings, { params }).pipe(map((res: any) => res.results));
  }
}