import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class LogbookIconService {

  render() {
    return `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.16699 4.16683C3.70675 4.16683 3.33366 4.53993 3.33366 5.00016V16.6668C3.33366 17.1271 3.70675 17.5002 4.16699 17.5002H15.8337C16.2939 17.5002 16.667 17.1271 16.667 16.6668V5.00016C16.667 4.53993 16.2939 4.16683 15.8337 4.16683H4.16699ZM1.66699 5.00016C1.66699 3.61945 2.78628 2.50016 4.16699 2.50016H15.8337C17.2144 2.50016 18.3337 3.61945 18.3337 5.00016V16.6668C18.3337 18.0475 17.2144 19.1668 15.8337 19.1668H4.16699C2.78628 19.1668 1.66699 18.0475 1.66699 16.6668V5.00016Z" fill="#231F20"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3337 0.833496C13.7939 0.833496 14.167 1.20659 14.167 1.66683V5.00016C14.167 5.4604 13.7939 5.8335 13.3337 5.8335C12.8734 5.8335 12.5003 5.4604 12.5003 5.00016V1.66683C12.5003 1.20659 12.8734 0.833496 13.3337 0.833496Z" fill="#231F20"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.66699 0.833496C7.12723 0.833496 7.50033 1.20659 7.50033 1.66683V5.00016C7.50033 5.4604 7.12723 5.8335 6.66699 5.8335C6.20676 5.8335 5.83366 5.4604 5.83366 5.00016V1.66683C5.83366 1.20659 6.20676 0.833496 6.66699 0.833496Z" fill="#231F20"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.66699 8.3335C1.66699 7.87326 2.04009 7.50016 2.50033 7.50016H17.5003C17.9606 7.50016 18.3337 7.87326 18.3337 8.3335C18.3337 8.79373 17.9606 9.16683 17.5003 9.16683H2.50033C2.04009 9.16683 1.66699 8.79373 1.66699 8.3335Z" fill="#231F20"/>
    </svg>
    `;
  }
}
