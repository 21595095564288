import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import { API_PATH } from "../../../../constants";
import { APIService } from "../../../utils/api.service";
import { ServiceCentersQuery } from "../sercice-centers/service-centers.query";
import { Bay } from "./bays.model";
import { BayStore } from "./bays.store";
import { Share } from '@ngspot/rxjs/decorators';
import { ServiceCenterService } from '../../../utils/service-center.service';

@Injectable({ providedIn: 'root' })
export class BayService extends ServiceCenterService {
    constructor(
        private http: HttpClient,
        private bayStore: BayStore,
        private serviceCentersQuery: ServiceCentersQuery
    ) {
      super();
    }

    @Share()
    /** serviceTypes using comma string */
    getBays(serviceCentersId?: number, serviceTypes?: string) {
      const centerId = serviceCentersId || this.serviceCentersQuery.getValue().ids[0];
      let url = APIService.prepareURI(API_PATH.entities.bays, { serviceCenterId: centerId });
      if (serviceTypes) url = url + '?serviceTypes=' + serviceTypes;
      return this.http.get(url).pipe(tap((res: any) => {
        this.bayStore.set(res.results);
      }));
    }

    getList() {
        const url = API_PATH.bays.list + this.createParamUrl(this.bayStore, true) + this.addEntityContextParam(false);

        return this.http.get(url).pipe(
            tap((expressService: any) => {
                this.bayStore.set(expressService.results);
                this.bayStore.updateCurrentCount(expressService.results.length);
                this.bayStore.updateCount(expressService.count);
            })
        );
    }

    addItem(data: Bay) {
        const url = API_PATH.bays.add + this.addEntityContextParam(true);

        return this.http.post(url, data);
    }

    editItem(bayId: string, data: Bay) {
        const url = APIService.prepareURI(API_PATH.bays.edit, { bayId }) + this.addEntityContextParam(true);

        return this.http.put(url, data);
    }

    getServiceType() {
        const url = API_PATH.bays.serviceType + this.addEntityContextParam(true);

        return this.http.get(url);
    }

    getAssignPod() {
        const url = API_PATH.pods.assignToBay + this.addEntityContextParam(true);

        return this.http.get(url);
    }

    getAssignGateway() {
        const url = API_PATH.gateways.assignToBay + this.addEntityContextParam(true);

        return this.http.get(url);
    }
}
