<nb-card class="service-recommendation-dlg" [nbSpinner]="loading">
  <nb-card-header class="d-flex flex-row justify-content-between align-items-center px-3 py-3">
    <h5 class="dialog-header m-0"> {{ types === 'add' ? 'Add Recommendation' : 'Edit Recomendation' }}</h5>
    <nb-icon class="cursor-pointer" icon="x-icon" pack="mo-icons" (click)="openConfirmDlg()"></nb-icon>
  </nb-card-header>
  <nb-card-body class="content py-4 px-3">
    <form [formGroup]="form">
      <div class="row mb-4">
        <div class="col-6 flex-column">
          <mat-form-field class="full-width box" appearance="legacy" floatLabel="always">
            <mat-label>Booking No.</mat-label>
            <input matInput formControlName="bookingId" class="form-control edit p-0">
          </mat-form-field>
        </div>
        <div class="col-6 flex-column">
          <mat-form-field class="full-width box" appearance="legacy" floatLabel="always">
            <mat-label>Date & Time</mat-label>
            <input matInput formControlName="time" class="form-control edit p-0">
          </mat-form-field>
        </div>
      </div>
      <div class="w-100">
        <mat-form-field class="full-width box recommendation-text mb-2" appearance="legacy" floatLabel="always">
          <mat-label>Recommendation</mat-label>
          <textarea matInput formControlName="content" class="form-control px-3 py-2" rows="5"></textarea>
        </mat-form-field>
      </div>
      <div class="button-group d-flex justify-content-end">
        <button class="small secondary mr-3" (click)="openConfirmDlg()">Cancel</button>
        <button [disabled]="isRequired" (click)="onSubmit()" class="small primary">{{ types === 'add' ? 'Add' : 'Save' }}</button>
      </div>
    </form>
  </nb-card-body>
</nb-card>
