import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class FileTextIconService {

    render() {
        return `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.26256 1.09557C2.59075 0.767382 3.03587 0.583008 3.5 0.583008H8.16667C8.32138 0.583008 8.46975 0.644466 8.57915 0.753862L12.0791 4.25386C12.1885 4.36326 12.25 4.51163 12.25 4.66634V11.6663C12.25 12.1305 12.0656 12.5756 11.7374 12.9038C11.4092 13.232 10.9641 13.4163 10.5 13.4163H3.5C3.03587 13.4163 2.59075 13.232 2.26256 12.9038C1.93437 12.5756 1.75 12.1305 1.75 11.6663V2.33301C1.75 1.86888 1.93437 1.42376 2.26256 1.09557ZM3.5 1.74967C3.34529 1.74967 3.19692 1.81113 3.08752 1.92053C2.97812 2.02992 2.91667 2.1783 2.91667 2.33301V11.6663C2.91667 11.8211 2.97812 11.9694 3.08752 12.0788C3.19692 12.1882 3.34529 12.2497 3.5 12.2497H10.5C10.6547 12.2497 10.8031 12.1882 10.9125 12.0788C11.0219 11.9694 11.0833 11.821 11.0833 11.6663V4.90797L7.92504 1.74967H3.5Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.16667 0.583008C8.48883 0.583008 8.75 0.844175 8.75 1.16634V4.08301H11.6667C11.9888 4.08301 12.25 4.34417 12.25 4.66634C12.25 4.98851 11.9888 5.24967 11.6667 5.24967H8.16667C7.8445 5.24967 7.58333 4.98851 7.58333 4.66634V1.16634C7.58333 0.844175 7.8445 0.583008 8.16667 0.583008Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.08333 7.58301C4.08333 7.26084 4.3445 6.99967 4.66667 6.99967H9.33333C9.6555 6.99967 9.91667 7.26084 9.91667 7.58301C9.91667 7.90517 9.6555 8.16634 9.33333 8.16634H4.66667C4.3445 8.16634 4.08333 7.90517 4.08333 7.58301Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.08333 9.91634C4.08333 9.59417 4.3445 9.33301 4.66667 9.33301H9.33333C9.6555 9.33301 9.91667 9.59417 9.91667 9.91634C9.91667 10.2385 9.6555 10.4997 9.33333 10.4997H4.66667C4.3445 10.4997 4.08333 10.2385 4.08333 9.91634Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.08333 5.24967C4.08333 4.92751 4.3445 4.66634 4.66667 4.66634H5.83333C6.1555 4.66634 6.41667 4.92751 6.41667 5.24967C6.41667 5.57184 6.1555 5.83301 5.83333 5.83301H4.66667C4.3445 5.83301 4.08333 5.57184 4.08333 5.24967Z" fill="white"/>
        </svg>
        `;
    }
}
