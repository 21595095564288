import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import * as _ from 'lodash';

export interface Vehicle {
  rego: any;
  make: any;
  model: any;
  year: any;
  description: any;
  isSelected: any;
}

export interface VehiclesState extends EntityState<Vehicle> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'vehicles', resettable: true })
export class VehiclesStore extends EntityStore<VehiclesState> {
  constructor() {
    super();
  }
}
