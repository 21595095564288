import { Pipe, PipeTransform } from '@angular/core';
import { JobDetails } from '../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.store';

@Pipe({
  name: 'filterJobs'
})
export class FilterJobsPipe implements PipeTransform {

  transform(job: JobDetails[], provider?: any): any {
    if (provider !== 'custom') {
      return job.filter(m => m.provider === provider && m.provider !== 'custom')
    }
    if (provider === 'custom') {
      return job.filter(m => m.provider === provider)
    }
  }

}
