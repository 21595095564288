import { Injectable } from '@angular/core';
import { SessionStore, SessionState } from './session.store';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { LoginRequest, LoginResponse } from '../../../data/login';
import { UserService } from './../user/user.service';
import { Router } from '@angular/router';
import { API_PATH } from '../../../../constants/api-urls';
import { resetStores } from "@datorama/akita";
import { of, forkJoin } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SessionService {

  constructor(
    private http: HttpClient,
    private sessionStore: SessionStore,
    private router: Router,
    private userService: UserService,
  ) { }

  login(creds: LoginRequest) {
    return this.http.post<LoginResponse>(API_PATH.user.login, creds).pipe(tap((res: SessionState) => {
      this.sessionStore.login({
        accessToken: res.accessToken,
        userId: res.userId
      });
      this.userService.updateUserLogin(true);
    }));
  }

  getProvider() {
    return this.http.get(API_PATH.user.providers).pipe(tap((res: any) => {
      return res.results;
    }));
  }

  loginCognito(code,url) {
    let formData = {
      "code": code,
      "redirectUri": url
    }
    return this.http.post(API_PATH.user.cognito, formData).pipe(tap((res: SessionState) => {
      this.sessionStore.login({
        accessToken: res.accessToken,
        userId: res.userId
      });
      this.userService.updateUserLogin(true);
    }));
  }

  loginAzure(code) {
    let formData = {
      "code": code,
    }
    return this.http.post(API_PATH.user.azure, formData).pipe(tap((res: SessionState) => {
      this.sessionStore.login({
        accessToken: res.accessToken,
        userId: res.userId
      });
      this.userService.updateUserLogin(true);
    }));
  }

  logout() {
    forkJoin([
      of(
        this.userService.clear(),
        resetStores(),
        this.sessionStore.logout(),
      )
    ]).subscribe(() => {
      this.router.navigate(['/auth/login']);
    });
  }

  isAccountSelf(userId: string) {
    return this.sessionStore.getValue().userId === userId;
  }
}
