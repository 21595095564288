import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class CarretLeftIconService {

    render() {
        return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.41294 12.1284L13.1655 8.75103C13.4873 8.46145 14 8.6898 14 9.12268L14 15.8773C14 16.3102 13.4873 16.5386 13.1655 16.249L9.41294 12.8716C9.19225 12.673 9.19225 12.327 9.41294 12.1284Z" fill="black"/>
        </svg>                        
        `;
    }
}
