import { EntityStore, StoreConfig, EntityState } from '@datorama/akita';
import { CustomerVehicleItem } from './customer-vehicles.model';
import { Injectable } from '@angular/core';

export interface CustomerVehiclesState extends EntityState<CustomerVehicleItem, number> {
  currentCount: number;
  page: number;
}

const initialState = {
  currentCount: 0,
  page: 1,
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'customerVehicles' })
export class CustomerVehiclesStore extends EntityStore<CustomerVehiclesState> {
  constructor() {
    super(initialState);
  }

  getCurrentCount() {
    return this.getValue().currentCount;
  }

  updateCurrentCount(currentCount: number) {
    this.update({ currentCount });
  }

  getPage() {
    return this.getValue().page;
  }

  updatePage(page: number) {
    this.update({ page });
  }

  updateEntity(entityId, data) {
    this.update(entityId, data);
  }
}