import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class BellIconService {

  render() {
    return `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99998 2.49992C8.6739 2.49992 7.40213 3.0267 6.46445 3.96438C5.52676 4.90207 4.99998 6.17384 4.99998 7.49992V11.6666C4.99998 12.2568 4.84346 12.8308 4.55341 13.3333H15.4466C15.1565 12.8308 15 12.2568 15 11.6666V7.49992C15 6.17384 14.4732 4.90207 13.5355 3.96438C12.5978 3.0267 11.3261 2.49992 9.99998 2.49992ZM18.3333 13.3333C17.8913 13.3333 17.4674 13.1577 17.1548 12.8451C16.8422 12.5325 16.6666 12.1086 16.6666 11.6666V7.49992C16.6666 5.73181 15.9643 4.03612 14.714 2.78587C13.4638 1.53563 11.7681 0.833252 9.99998 0.833252C8.23187 0.833252 6.53618 1.53563 5.28593 2.78587C4.03569 4.03612 3.33331 5.73181 3.33331 7.49992V11.6666C3.33331 12.1086 3.15772 12.5325 2.84516 12.8451C2.5326 13.1577 2.10867 13.3333 1.66665 13.3333C1.20641 13.3333 0.833313 13.7063 0.833313 14.1666C0.833313 14.6268 1.20641 14.9999 1.66665 14.9999H18.3333V13.3333ZM8.14017 16.7791C8.53827 16.5482 9.04821 16.6837 9.27915 17.0818C9.3524 17.2081 9.45755 17.3129 9.58405 17.3857C9.71056 17.4586 9.85399 17.497 9.99998 17.497C10.146 17.497 10.2894 17.4586 10.4159 17.3857C10.5424 17.3129 10.6476 17.2081 10.7208 17.0818C10.9517 16.6837 11.4617 16.5482 11.8598 16.7791C12.2579 17.01 12.3934 17.52 12.1625 17.9181C11.9427 18.2969 11.6273 18.6114 11.2478 18.83C10.8682 19.0486 10.438 19.1636 9.99998 19.1636C9.56201 19.1636 9.13172 19.0486 8.7522 18.83C8.37268 18.6114 8.05724 18.2969 7.83748 17.9181C7.60655 17.52 7.74206 17.01 8.14017 16.7791Z" fill="#C8C7C7"/>
      </svg>
    `;
  }

  bellNotification() {
    return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0002 6.49992C8.67408 6.49992 7.40231 7.0267 6.46463 7.96438C5.52695 8.90207 5.00016 10.1738 5.00016 11.4999V15.6666C5.00016 16.2568 4.84364 16.8308 4.55359 17.3333H15.4467C15.1567 16.8308 15.0002 16.2568 15.0002 15.6666V11.4999C15.0002 10.1738 14.4734 8.90207 13.5357 7.96438C12.598 7.0267 11.3262 6.49992 10.0002 6.49992ZM18.3335 17.3333C17.8915 17.3333 17.4675 17.1577 17.155 16.8451C16.8424 16.5325 16.6668 16.1086 16.6668 15.6666V11.4999C16.6668 9.73181 15.9644 8.03612 14.7142 6.78587C13.464 5.53563 11.7683 4.83325 10.0002 4.83325C8.23205 4.83325 6.53636 5.53563 5.28612 6.78587C4.03587 8.03612 3.3335 9.73181 3.3335 11.4999V15.6666C3.3335 16.1086 3.1579 16.5325 2.84534 16.8451C2.53278 17.1577 2.10886 17.3333 1.66683 17.3333C1.20659 17.3333 0.833496 17.7063 0.833496 18.1666C0.833496 18.6268 1.20659 18.9999 1.66683 18.9999H18.3335V17.3333ZM8.14035 20.7791C8.53846 20.5482 9.0484 20.6837 9.27933 21.0818C9.35258 21.2081 9.45773 21.3129 9.58424 21.3857C9.71074 21.4586 9.85417 21.497 10.0002 21.497C10.1462 21.497 10.2896 21.4586 10.4161 21.3857C10.5426 21.3129 10.6477 21.2081 10.721 21.0818C10.9519 20.6837 11.4619 20.5482 11.86 20.7791C12.2581 21.01 12.3936 21.52 12.1627 21.9181C11.9429 22.2969 11.6275 22.6114 11.2479 22.83C10.8684 23.0486 10.4381 23.1636 10.0002 23.1636C9.56219 23.1636 9.1319 23.0486 8.75238 22.83C8.37286 22.6114 8.05743 22.2969 7.83766 21.9181C7.60673 21.52 7.74225 21.01 8.14035 20.7791Z" fill="#231F20"/>
      <circle cx="16" cy="6" r="5" fill="var(--block-colour)" stroke="white" stroke-width="2"/>
      </svg>
    `;
  }
}
