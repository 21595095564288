import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class SquareCheckboxIconService {

    render() {
        return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V5Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9142 8.29297C17.3047 8.68349 17.3047 9.31666 16.9142 9.70718L10.7071 16.2072C10.3166 16.5977 9.68342 16.5977 9.29289 16.2072L6.29289 13.2072C5.90237 12.8167 5.90237 12.1835 6.29289 11.793C6.68342 11.4024 7.31658 11.4024 7.70711 11.793L10 14.0859L15.5 8.29297C15.8905 7.90244 16.5237 7.90244 16.9142 8.29297Z" fill="white"/>
        </svg>
        `;
    }
}
