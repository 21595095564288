import { Query } from '@datorama/akita';
import { UserStore, UserState } from './user.store';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UserQuery extends Query<UserState> {
    userProfile$ = this.select();
    isLogin$ = this.select(['isLogin']);
    
    constructor(protected store: UserStore) {
        super(store);
    }
}