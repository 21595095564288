import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Role } from '../../../@core/data/roles-permissions';
import * as _ from 'lodash';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { RolesPermissionsService } from '../../../@core/utils/roles-permissions.service';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { RoleRenameDlgComponent } from './role-rename-dlg/role-rename-dlg.component';
import { API_PERMISSIONS, ERROR_MESSAGES } from '../../../constants';
import { ConfirmDlgComponent } from '../discard-change-confirm-dlg/service-center-dialog-confirm.component';

@Component({
  selector: 'role-list-management',
  templateUrl: './role-list-management.component.html',
  styleUrls: ['./role-list-management.component.scss']
})
export class RoleListManagementComponent implements OnInit, OnChanges {

  @Output()
  onRoleSelectedChange = new EventEmitter<Role>();

  @Output()
  onTriggerCreateMode = new EventEmitter<any>();

  @Output()
  onDuplicateRole = new EventEmitter<Role>();

  @Output()
  onListUpdated = new EventEmitter<boolean>();

  @Output()
  onCreateRoleSubmit = new EventEmitter<string>();

  @Output()
  onCancelCreateRole = new EventEmitter<string>();

  @Input()
  roleList: Role[] = [];

  @Input()
  roleSelected: Role;

  @Input()
  createMode: boolean = false;

  @Input()
  loading: boolean = false;

  @Input()
  isChangedPermission: boolean = false;

  createRoleForm: FormGroup;
  searchValue: string = '';

  @Input()
  activeTab: 'active' | 'inactive' = 'active';

  @Output()
  activeTabChange = new EventEmitter<string>();

  apiPermissions = API_PERMISSIONS;
  errorMessages = ERROR_MESSAGES;

  constructor(
    private rolesPermissionsService: RolesPermissionsService,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
  ) {
    this.createRoleForm = new FormGroup({
      roleName: new FormControl('', [Validators.required, this.checkRoleNameExist()]),
    });
  }

  get searchedRoleList() {
    const searchValue = this.searchValue.trim();
    return this.roleList.filter(v => _.toLower(v.name).includes(_.toLower(searchValue)));
  }

  get activeRoleCount() {
    return this.searchedRoleList.filter(v => v.isActive).length;
  }

  get inactiveRoleCount() {
    return this.searchedRoleList.filter(v => !v.isActive).length;
  }

  get systemActiveRoles() {
    return this.searchedRoleList.filter(v => !v.isCustom && v.isActive);
  }

  get customActiveRoles() {
    return this.searchedRoleList.filter(v => v.isCustom && v.isActive).sort((a, b) => b.id - a.id);
  }

  get systemInactiveRoles() {
    return this.searchedRoleList.filter(v => !v.isCustom && !v.isActive);
  }

  get customInactiveRoles() {
    return this.searchedRoleList.filter(v => v.isCustom && !v.isActive).sort((a, b) => b.id - a.id);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: any) {
    if (changes.roleList) {
      const listOfTab = this.activeTab === 'active' ? [].concat(...this.systemActiveRoles, ...this.customActiveRoles) : [].concat(...this.systemInactiveRoles, ...this.customInactiveRoles);
      const isHasSelected = listOfTab.find(v => v.code === this.roleSelected.code);
      if (!isHasSelected && listOfTab.length > 0) {
        this.roleSelected = listOfTab[0];
        this.onRoleSelectedChange.emit(this.roleSelected);
      } else if (listOfTab.length === 0) {
        this.roleSelected = null;
        this.onRoleSelectedChange.emit(null);
      }
    }
  }

  handleSelectRole(e, code) {
    this.openDiscardChangeDlg(this.isChangedPermission, () => {
      this.roleSelected = this.roleList.find(v => v.code === code)
      this.onRoleSelectedChange.emit(this.roleSelected);
    });
  }

  handleAction(e, role: Role, action: 'duplicate' | 'rename' | 'inactivate' | 'activate') {
    e.stopPropagation();
    if (action === 'inactivate' || action === 'activate') {
      this.openDiscardChangeDlg(this.isChangedPermission, () => {
        this.handleChangeRoleStatus(role, action);
      });
    } else if (action === 'duplicate') {
      this.openDiscardChangeDlg(this.isChangedPermission, () => {
        this.handleDuplicateRole(role);
      });
    } else if (action === 'rename') {
      this.openRenameDialog(role);
    }

    this.closeActionDropdown();
  }

  handleChangeRoleStatus(role, action) {
    if (role.code === 'sysadmin') {
      this.toastrService.warning("Cannot inactivate sysadmin role", "Warning", { destroyByClick: true, hasIcon: false });
      return;
    }
    this.rolesPermissionsService.updateRoleDetails(role.code, {
      ...role,
      isActive: action === 'inactivate' ? false : true
    }).subscribe((res) => {
      this.onListUpdated.emit(true);
      this.toastrService.success("Updated Successfully", "Success", { destroyByClick: true, hasIcon: false });
    });
  }

  openRenameDialog(role: Role) {
    this.dialogService.open(RoleRenameDlgComponent, {
      hasBackdrop: true, closeOnBackdropClick: false, context: {
        role: role,
        roleList: this.roleList,
      }
    }).onClose.subscribe((res) => {
      this.onListUpdated.emit(true);
    });
  }

  handleDuplicateRole(role: Role) {
    const name = this.generateRoleName(role.name);
    this.createRoleForm.setValue({
      roleName: name
    });
    this.onDuplicateRole.emit(role);
    setTimeout(() => {
      const roleNameInputELement = document.getElementById("add-role-name-input");
      roleNameInputELement && roleNameInputELement.focus();
    })
  }

  openDiscardChangeDlg(isConfirm: boolean, callback: Function) {
    if (isConfirm) {
      this.dialogService.open(ConfirmDlgComponent, {
        hasBackdrop: true, closeOnBackdropClick: false,
      }).onClose.subscribe((res) => {
        if (res) {
          callback();
        }
      });
    } else {
      callback();
    }
  }

  generateRoleName(name) {
    const roleNames = this.roleList.map(v => v.name);
    let newName = name;
    let i = 1;
    while (roleNames.includes(newName)) {
      newName = name + ' Copy ' + i;
      i++;
    }
    return newName;
  }

  checkRoleNameExist(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }
      if (this.roleList.find(v => _.toLower(v.name) === _.toLower(value))) {
        return {
          exists: true,
        }
      } else {
        return null;
      }
    }
  }

  handleTriggerCreateRole() {
    this.onTriggerCreateMode.emit(true);
    this.createRoleForm.reset();
    setTimeout(() => {
      const roleNameInputELement = document.getElementById("add-role-name-input");
      roleNameInputELement && roleNameInputELement.focus();
    })
  }

  validateCreateRoleForm() {
    if (this.createRoleForm.invalid) {
      this.createRoleForm.markAsDirty();
      this.createRoleForm.markAllAsTouched();
      return false;
    }
    return true;
  }

  closeActionDropdown() {
    Array.from(document.querySelectorAll('.action-list > .action-item.active')).forEach(
      (el) => { el.classList.remove('active') }
    );
  }

  openActionDropdown(e) {
    e.stopPropagation();
    let isReClick = false;
    const itemListEle = e.target.closest(".action-item");
    if (itemListEle && itemListEle.classList.contains('active')) isReClick = true;
    // close all dropdown
    Array.from(document.querySelectorAll('.action-list > .action-item.active')).forEach(
      (el) => { el.classList.remove('active') }
    );
    // if is open click
    if (itemListEle && !isReClick) {
      itemListEle.classList.add("active");
    }
  }

  debounceSearch = _.debounce((val) => {
    this.handleSearch(val);
  }, 500);

  handleSearch(val) {
    this.searchValue = val;
  }

  handleTabChange(e) {
    this.activeTab = e.tabId;
    this.activeTabChange.emit(this.activeTab);
    if (this.activeTab === 'active') {
      this.roleSelected = this.systemActiveRoles[0] ? this.systemActiveRoles[0] : this.customActiveRoles[0];
    } else if (this.activeTab === 'inactive') {
      this.roleSelected = this.systemInactiveRoles[0] ? this.systemInactiveRoles[0] : this.customInactiveRoles[0];
    }
    this.onRoleSelectedChange.emit(this.roleSelected);
  }

  handleCreateRole() {
    if (this.validateCreateRoleForm()) {
      this.onCreateRoleSubmit.emit(this.createRoleForm.value.roleName);
    }
  }
}
