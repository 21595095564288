import { Component, OnInit, Input, SimpleChanges, SimpleChange, OnChanges, Output, EventEmitter, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import * as _ from 'lodash';
import { MatSelect, MatTreeNestedDataSource } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EntitiesService } from '../../../@core/akita-stores/entity-stores/entities/entities.service';
import { EntityItem, EntitiesTree } from '../../../@core/akita-stores/entity-stores/entities/entities.store';
import { ERROR_MESSAGES } from './../../../constants/error-messages';
import { SessionQuery } from '../../../@core/akita-stores/stores/session/session.query';
import { Router } from '@angular/router';
import { ID } from '@datorama/akita';
import { saveCurrentEntity, getCurrentEntity } from '../../../@core/akita-stores/storage';
import { ServiceCentersService } from '../../../@core/akita-stores/entity-stores/sercice-centers/service-centers.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, takeUntil, tap } from 'rxjs/operators';
import { CommonService } from '../../../@core/utils/common.service';
import { SessionStore } from '../../../@core/akita-stores/stores/session/session.store';
import { PermissionsService } from '../../../@core/utils/permissions.service';
import { UserStore } from '../../../@core/akita-stores/stores/user/user.store';
import { NgxPermissionsService } from 'ngx-permissions';
import { FlightDeckStore } from '../../../@core/akita-stores/stores/flightdeck/flightdeck.store';
import { EntitiesQuery } from '../../../@core/akita-stores/entity-stores/entities/entities.query';
import { ServiceCentersQuery } from '../../../@core/akita-stores/entity-stores/sercice-centers/service-centers.query';
import { ConfigService } from '../../../@core/akita-stores/stores/config/config.service';

@Component({
  selector: 'entity-tree',
  templateUrl: './entity-tree.component.html',
  styleUrls: ['./entity-tree.component.scss']
})
export class EntityTreeComponent implements OnInit, OnChanges, AfterViewInit , OnDestroy {
  @ViewChild(MatSelect, {static: false}) matSelect: MatSelect;
  @Input() form: FormGroup;
  @Input() typeSelect: string;
  @Input() isPublic: boolean = false;
  @Output() sendFormData = new EventEmitter<any>();
  entitiesTree: EntityItem[];
  errorMessage = ERROR_MESSAGES;
  treeControl = new NestedTreeControl<EntityItem>(node => node.childs);
  dataSource = new MatTreeNestedDataSource<EntityItem>();
  entitySelected: EntityItem;
  hasForm: boolean = true;
  loading: boolean = true;
  searchInput = "";
  noSearchResult = false;
  protected destroy$ = new Subject<void>();

  constructor(
    private entitiesService: EntitiesService,
    private sessionQuery: SessionQuery,
    private router: Router,
    private centersService: ServiceCentersService,
    private commonService: CommonService,
    private sessionStore: SessionStore,
    private permissionsService: PermissionsService,
    private userStore: UserStore,
    private ngxpermissionsService: NgxPermissionsService,
    private flightDeckStore: FlightDeckStore,
    private entitiesQuery: EntitiesQuery,
    private serviceCentersQuery: ServiceCentersQuery,
    private configService: ConfigService,
  ) {
    if (!this.form) {
      this.form = new FormGroup({
        entity: new FormControl(''),
        search: new FormControl('')
      });
    }
  }

  ngOnInit() {
    this.getEntities();
    this.form.controls.search.valueChanges
      .pipe(tap(() => {
        this.noSearchResult = false;
      }), debounceTime(200), distinctUntilChanged())
      .subscribe((value) => {
        const dataClone = _.cloneDeep(this.dataSource.data);
        value = value.trim();
        const hasResult = this.searchAndMarkHidden(dataClone[0], value);
        this.noSearchResult = !hasResult;
        this.dataSource.data = dataClone;
      });
    this.serviceCentersQuery.serviceCentersSelectedChange$.pipe(takeUntil(this.destroy$)).subscribe((changed) => {

      if (changed && changed.selected) {
        if (changed.selected.id !== getCurrentEntity()) {
          this.selectionChange({ value: changed.selected }, changed.redirectTo);
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const form: SimpleChange = changes.form;
    if (!form) this.hasForm = false;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  hasChild = (_: number, node: EntityItem) => !!node.childs && node.childs.length > 0;

  getEntities() {
    this.loading = true;
    this.entitiesService.getEntities({
      includeTree: true,
      isPublic: this.isPublic,
    })
    .pipe(takeUntil(this.destroy$))
    .subscribe((entities) => this.handleEntites(entities), () => this.loading = false);
  }

  handleEntites(entities: EntitiesTree) {
    const entityId = this.sessionQuery.getValue().entityId || entities.list[0].id;
    let parentEntity = entities.list[0].parentId;
    this.entitiesQuery
      .findParentEntity(Number(entityId))
      .subscribe((res) => (parentEntity = res));
    this.entitiesService.setTheme(parentEntity);
    saveCurrentEntity(entityId);
    this.sessionStore.update({ entityId });
    this.dataSource.data = [entities.tree];
    if (!this.hasForm) {
      setTimeout(() => {
        this.entitySelected = this.searchEntity(entities.tree, entityId);
        this.loading = false;
      });
    } else {
      setTimeout(() => {
        const currentEntityId = getCurrentEntity();
        this.entitySelected = this.searchEntity(entities.tree, currentEntityId);
        this.form.controls['entity'].patchValue(this.entitySelected);
        this.loading = false;
      });
    }
  }

  onOpenSelection() {
    this.form.controls.search.patchValue('');
  }

  selectionChange($e, forceRedirect?: string) {
    if($e.value.type == 'root'){
      this.router.navigate([`pages/configuration/manage-company`]);
      return;
    }
    var eid = $e.value.id;
    var pid = $e.value.parentId
    if($e.value.type == 'company'){
      eid = $e.value.childs[0].id;
      pid = $e.value.childs[0].parentId;
    }

    this.entitiesService.setTheme(pid);
    if (this.hasForm) {
      this.sendFormData.emit(this.form);
    } else {
      this.permissionsService.getPermissions(eid).subscribe(res=>{
        this.ngxpermissionsService.loadPermissions(res['operations']);
        this.userStore.savePermissions(res['operations']);        
      })
      this.sessionQuery.updateEntityId(eid);
      this.flightDeckStore.update(state => ({
        listView: []
      }));
      saveCurrentEntity(eid);
      this.centersService.getServiceCenters(false, false, '?pageSize=1000')
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            if (forceRedirect) {
              this.configService.setLoading(true);
              setTimeout(() => {
                this.configService.setLoading(false);
                this.router.navigate([forceRedirect]);
              }, 1000)
              return;
            }
            if($e.value.type == 'company'){
              this.router.navigate([`/pages/configuration/manage-company/company-details/${$e.value.objectId}`]);
            }
            else{
              var _routes = this.router.url.split('/');
              if(_routes.indexOf('configuration') != -1
                || _routes.indexOf('customer-details') != -1
                || _routes.indexOf('booking-details') != -1
                || this.router.url.includes('-details')
              ){
                this.router.navigate([`pages/dashboard`]);
              }else this.reloadPage();
            }
          })).subscribe();
    }
  }

  // openedChange($e){
  //   if(this.matSelect.panelOpen){
  //     var sinput = document.createElement("input");
  //     sinput.setAttribute("type", "text");
  //     sinput.setAttribute("class", "");
  //     sinput.setAttribute("style", "width: 100%");
  //     sinput.setAttribute("id", "entities-tree-filter");
  //     document.getElementById('entities-tree').parentNode.parentNode.appendChild(sinput);
  //   }
  // }

  searchEntity(entitiesTree: EntityItem, entityId: ID) {
    if (entitiesTree.id == entityId) {
      return entitiesTree;
    } else if (entitiesTree.childs != null) {
      let result = null;
      entitiesTree.childs.forEach(item => {
        if (!result) result = this.searchEntity(item, entityId);
      })
      return result;
    }
    return null;
  }

  searchAndMarkHidden(node, searchTerm) {
    node.hidden = true;
    let foundChildren = false;
    if (
      node.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
    ) {
      foundChildren = true;
      node.hidden = false;
    }
    if (node.childs) {
      for (const child of node.childs) {
        if (this.searchAndMarkHidden(child, searchTerm)) {
          foundChildren = true;
        }
      }
      if (foundChildren) {
        node.hidden = false;
        return true;
      } else {
        return false;
      }
    }
    return foundChildren;
  }

  reloadPage() {
    this.router.navigate([this.router.url]);
  }

  ngAfterViewInit(){
    this.commonService.openLocation.subscribe(isOpen => {
      if(isOpen) this.matSelect.open();
    })
  }
}
