export const CUSTOMER_PERMISSIONS = {
  adminCreateCustomerUser: 'adminCreateCustomerUser',
  updateCustomerAddress: 'updateCustomerAddress',
  addCustomerAddress: 'addCustomerAddress',
  updateCustomerContact: 'updateCustomerContact',
  addCustomerContact: 'addCustomerContact',
  updateCustomer: 'updateCustomer',
  getCustomer: 'getCustomer',
  getCustomers: 'getCustomers',
  manageCustomers: 'manageCustomers'
}