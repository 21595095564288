import { Injectable } from '@angular/core';
import { VehiclesStore } from './vehicles.store';
import { HttpClient } from '@angular/common/http';
import { API_PATH } from './../../../../constants/api-urls';
import { tap, map, filter, catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { Share } from '@ngspot/rxjs/decorators';
import * as _ from 'lodash';
import { APIService } from '../../../utils/api.service';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VehiclesService {

  constructor(
    private http: HttpClient,
    private vehiclesStore: VehiclesStore,
  ) { }


  searchRegoAPI(term: string, state: string) {
    return this.http.get(API_PATH.vehicles.searchRego + `?Rego=${term}&State=${state}`).pipe(
      map((res: any) => res.results),
      filter((vehicle) => {
        // if (this.sessionQuery.isAuthenticated()) {
        //   return vehicle.customerId != this.sessionQuery.getValue().userId;
        // }
        return vehicle;
      }),
      catchError(err => of([]))
    );
  }


  selectVehicle(index: Number) {
    this.vehiclesStore.update(null, { isSelected: false });
    return this.vehiclesStore.update(index, { isSelected: true });
  }

  addVehicle() {
    const vehicle = {
      id: 2,
      rego: '2222222',
      make: 'TOYOTA',
      model: 'YARIS',
      year: '2020',
      description: '5 Doors Hatchback 1.5L',
      isSelected: false,
    };
    return this.vehiclesStore.add(vehicle);
  }

  updateVehicle() {
    const vehicle = {
      rego: '1111112',
      make: 'TOYOTA',
      model: 'YARIS',
      year: '2018',
      description: '5 Doors Hatchback 1.5L',
      isSelected: false,
    };
    return this.vehiclesStore.set({ 1: vehicle });
  }

  @Share()
  updateAnVehicle(data) {
    const url = APIService.prepareURI(API_PATH.admin.vehicleUpdate, { vehicleId: data.id });

    return this.http.put(url, data);
  }
  getGeoStates(country: string, page: number = 1, pageSize: number = 50) {
    return this.http.get(API_PATH.geos.states + `?country=${country}&page=${page}&pageSize=${pageSize}`).pipe(
      map((states: any) => states.results),
      tap(states => { return states })
    );
  }

  getCustomerVehicles(customerId: number, page: number = 1, pageSize: number = 50) {
    return this.http.get(API_PATH.vehicles.customers + `?customerId=${customerId}&page=${page}&pageSize=${pageSize}`).pipe(
      map((res: any) => res.results),
      tap(vehicles => {
        return vehicles;
      })
    )
  }

  createVehicle(vehiclePayload: VehicleCreatePayload) {
    return this.http.post(API_PATH.admin.createVehicleForCustomer, vehiclePayload);
  }
  
  getVehiclesMakes() {
    return this.http.get(API_PATH.vehicles.makes).pipe(
      map((res: any) => res.results),
      tap((make: any) => {
        return make;
      })
    );
  }

  getVehiclesModels(make) {
    return this.http.get(API_PATH.vehicles.models + `?make=${make}`).pipe(
      map((res: any) => res.results),
      tap((model: any) => {
        return model;
      })
    );
  }

  getVehiclesVariants(make, model) {
    return this.http.get(API_PATH.vehicles.variants + `?make=${make}&model=${model}`).pipe(
      map((res: any) => res.results),
      tap((variant: any) => {
        return variant;
      })
    );
  }

  getVehiclesYears(make, model, variant) {
    return this.http.get(API_PATH.vehicles.years + `?make=${make}&model=${model}&variant=${variant}`).pipe(
      map((res: any) => res.results),
      tap((year: any) => {
        return year;
      })
    );
  }

  getVehicleTransmissions(make, model, variant, year) {
    return this.http.get(API_PATH.vehicles.searchRego + `?make=${make}&model=${model}&variant=${variant}&year=${year}`).pipe(
      map((res: any) => res.results),
      tap((transmissionsObj: any) => {
        return transmissionsObj;
      })
    );
  }

  getVehicleCylinder(slid) {
    return this.http.get(API_PATH.vehicles.searchRego + `?slid=${slid}`).pipe(
      map((res: any) => res.results),
      tap((cylinder: any) => {
        return cylinder;
      })
    );
  }
  
  getServiceRecommendations(id) {
    const url = APIService.prepareURI(API_PATH.serviceRecommendations.list, { customerId: id });

    return this.http.get(url);
  }

  addRecommendation(data) {
    const url = API_PATH.serviceRecommendations.addRecommendation;
    return this.http.post(url, data);
  }

  updateRecommendation(id, data) {
    const url = APIService.prepareURI(API_PATH.serviceRecommendations.updateDeleteRecommendation, { serviceRecommendationId: id });

    return this.http.put(url, data);
  }

  deleteRecommendation(id) {
    const url = APIService.prepareURI(API_PATH.serviceRecommendations.updateDeleteRecommendation, { serviceRecommendationId: id });

    return this.http.delete(url)
  }
}

export interface VehicleCreatePayload {
  cylinder: number,
  rego: string,
  make: string,
  model: string,
  customerId: number
  vin: string
}