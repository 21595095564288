import { Directive, ElementRef, forwardRef, HostListener } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[noProtocol]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => NoProtocolDirective),
    }
  ]
})
export class NoProtocolDirective {

  private regex;
  private onChange;
  private onTouch;

  constructor(private elRef: ElementRef) { }

  ngAfterViewInit(): void {
    this.regex = new RegExp(/(^\w+:|^)\/\//);
  }

  @HostListener('input', ['$event']) onInputChange(event) {
    if (this.regex) {
      const initalValue = this.elRef.nativeElement.value;
      const newVal = initalValue.replace(this.regex, '');
      this.rawValue = newVal;
      if (initalValue !== this.rawValue) {
        event.stopPropagation();
      }
    }

    this.onChange(this.rawValue);
    this.onTouch(this.rawValue)
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.elRef.nativeElement.disabled = isDisabled;
  }

  writeValue(val: any): void {
    if (this.regex) {
      const newVal = (val || '').replace(this.regex, '');
      this.rawValue = newVal
    } else {
      this.rawValue = val;
    }
  }

  get rawValue(): string {
    return this.elRef.nativeElement && this.elRef.nativeElement.value;
  }

  set rawValue(value: string) {
    if (this.elRef.nativeElement) {
      this.elRef.nativeElement.value = value;
    }
  }


}
