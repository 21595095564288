import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class PhoneIncomingIconService {

    render() {
        return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="">
        <path d="M23.7001 0.3C23.3001 -0.1 22.7001 -0.1 22.3001 0.3L17.0001 5.6V2C17.0001 1.4 16.6001 1 16.0001 1C15.4001 1 15.0001 1.4 15.0001 2V8C15.0001 8.1 15.0001 8.3 15.1001 8.4C15.2001 8.6 15.4001 8.8 15.6001 8.9C15.7001 9 15.9001 9 16.0001 9H22.0001C22.6001 9 23.0001 8.6 23.0001 8C23.0001 7.4 22.6001 7 22.0001 7H18.4001L23.7001 1.7C24.1001 1.3 24.1001 0.7 23.7001 0.3Z" fill="black"/>
        <path d="M20.0001 22.9C19.9001 22.9 19.8001 22.9 19.7001 22.9C16.5001 22.5 13.3001 21.4 10.6001 19.7C8.1001 18.1 5.9001 15.9 4.3001 13.4C2.5001 10.7 1.4001 7.5 1.1001 4.3C1.1001 3.5 1.3001 2.7 1.8001 2.1C2.3001 1.5 3.0001 1.1 3.8001 1C3.9001 1 4.0001 1 4.1001 1H7.1001C8.6001 1 9.9001 2.1 10.1001 3.6C10.2001 4.5 10.4001 5.4 10.7001 6.2C11.1001 7.3 10.8001 8.5 10.0001 9.4L9.3001 10.1C10.5001 11.9 12.0001 13.5 13.9001 14.7L14.6001 14C15.4001 13.2 16.7001 12.9 17.8001 13.3C18.6001 13.6 19.5001 13.8 20.4001 13.9C21.9001 14.1 23.0001 15.4 23.0001 16.9V19.9C23.0001 20.7 22.7001 21.5 22.1001 22C21.5001 22.5 20.8001 22.9 20.0001 22.9ZM7.1001 3H4.1001C3.8001 3 3.5001 3.2 3.3001 3.4C3.2001 3.6 3.1001 3.8 3.1001 4.1C3.4001 7 4.4001 9.8 6.0001 12.3C7.5001 14.6 9.4001 16.6 11.7001 18C14.2001 19.6 17.0001 20.6 19.9001 20.9C20.3001 20.9 20.5001 20.8 20.7001 20.6C20.9001 20.4 21.0001 20.2 21.0001 19.9V16.9C21.0001 16.4 20.6001 16 20.1001 15.9C19.1001 15.8 18.1001 15.5 17.1001 15.1C16.7001 15 16.3001 15 16.0001 15.3L14.7001 16.6C14.4001 16.9 13.9001 17 13.5001 16.8C10.8001 15.3 8.6001 13.1 7.1001 10.4C7.0001 10 7.1001 9.5 7.4001 9.2L8.7001 7.9C8.9001 7.7 9.0001 7.2 8.9001 6.9C8.5001 5.9 8.3001 4.9 8.1001 3.9C8.1001 3.4 7.6001 3 7.1001 3Z" fill="black"/>
        </g>
        <defs>
        <clipPath id="clip0">
        <path d="M0 0H24V24H0V0Z" fill="white"/>
        </clipPath>
        </defs>
        </svg>  
        `;
    }
}
