export const UNIX_TIMESTAMP = {
  minute: 1000 * 60,
  hour: 1000 * 3600,
  day: 1000 * 86400,
  week: 1000 * 604800,
  month: 1000 * 2629743,
  year: 1000 * 31556926,
}


export const MODES = {
  ADD: 'add',
  EDIT: 'edit',
}


export const STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
}
