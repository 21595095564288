import { Injectable } from '@angular/core';
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { BookingDetails } from '../../stores/booking-details-selected/booking-details-selected.store';

export interface BookingListState extends EntityState<BookingDetails> {
  sortColumn: string;
  filterStatus: string[];
};

export function createInitialState(): BookingListState {
  return {
    sortColumn: "BookedAt",
    filterStatus: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'bookings', resettable: true })
export class BookingListStore extends EntityStore<BookingListState> {
  constructor() {
    super(createInitialState());
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'searchBookings', resettable: true })
export class SearchBookingListStore extends EntityStore<BookingListState> {
  constructor() {
    super();
  }
}
