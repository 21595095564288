export const STATUS_BEFORE_CHECKED_IN = [
  "booked",
  "service-booked",
  "service-confirmed",
  "qualified",
  "await",
  "awaiting-arrival"
];

export const ORDER_STATUSES = [
  { code: 'service-booked' },
  { code: 'qualified' },
  { code: 'service-confirmed' },
  { code: 'contact-attempted' },
  { code: 'checked-in'},
  { code: 'vehicle-health-check' },
  { code: 'work-in-progress' },
  { code: 'tyres' },
  { code: 'wheel-alignment' },
  { code: 'test-drive' },
  { code: 'wash-bay' },
  { code: 'special-offer-wip' },
  { code: 'quality-assurance' },
  { code: 'invoiced' },
  { code: 'ready-for-pickup' },
  { code: 'pickedup' },
];

export const BOOKING_STATUSES = {
  serviceBooked: 'service-booked',
  serviceConfirmed: 'service-confirmed',
  contactAttempted : 'contact-attempted',
  bookingQualified: 'qualified',
  checkedIn: 'checked-in',
  vehicleHealthCheck: 'vehicle-health-check',
  workInProgress: 'work-in-progress',
  specialOfferWip: 'special-offer-wip',
  qualityAssurance: 'quality-assurance',
  washBay: 'wash-bay',
  invoiced: 'invoiced',
  readyForPickup: 'ready-for-pickup',
  cancelled: 'cancelled',
  pickedup: 'pickedup'
}

export const STATUS_AFTER_CHECKED_IN = [
  "work-in-progress",
  "wheel-alignment",
  "tyres",
  "test-drive",
  "wash-bay",
  "ready-for-pickup"
];

export const LITE_BOOKING_STATUSES = {
  serviceBooked: 'service-booked',
  serviceConfirmed: 'service-confirmed',
  contactAttempted: 'contact-attempted',
}