import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ArrowTwoTopIconService {

  render() {
    return `<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8371 5.21209C11.2032 4.84597 11.7968 4.84597 12.1629 5.21209L16.8504 9.89959C17.2165 10.2657 17.2165 10.8593 16.8504 11.2254C16.4843 11.5915 15.8907 11.5915 15.5246 11.2254L11.5 7.20082L7.47541 11.2254C7.1093 11.5915 6.5157 11.5915 6.14959 11.2254C5.78347 10.8593 5.78347 10.2657 6.14959 9.89959L10.8371 5.21209Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8371 11.7746C11.2032 11.4085 11.7968 11.4085 12.1629 11.7746L16.8504 16.4621C17.2165 16.8282 17.2165 17.4218 16.8504 17.7879C16.4843 18.154 15.8907 18.154 15.5246 17.7879L11.5 13.7633L7.47541 17.7879C7.1093 18.154 6.5157 18.154 6.14959 17.7879C5.78347 17.4218 5.78347 16.8282 6.14959 16.4621L10.8371 11.7746Z" fill="white"/>
      </svg>
    `;
  }
}
