import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class CarretUpIconService {

    render() {
        return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.8716 9.41294L16.249 13.1655C16.5386 13.4873 16.3102 14 15.8773 14L9.12268 14C8.6898 14 8.46145 13.4873 8.75103 13.1655L12.1284 9.41294C12.327 9.19225 12.673 9.19225 12.8716 9.41294Z" fill="black"/>
        </svg>                                
        `;
    }
}
