import { Injectable } from '@angular/core';
import { API_PATH } from '../../../../constants/api-urls';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, share, tap, flatMap, catchError } from 'rxjs/operators';
import { BookingDetailsSelectedStore, BookingDetails, BookingPaymentDetails } from './booking-details-selected.store';
import { ConfigStore } from '../config/config.store';
import { BookingDetailsSelectedQuery } from './booking-details-selected.query';
import { Observable } from 'rxjs/internal/Observable';
import { CustomerService } from '../customer/customer.service';
import { Subject, BehaviorSubject, of } from 'rxjs';
import { Share } from '@ngspot/rxjs/decorators';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { APIService } from '../../../utils/api.service';
import { saveAs } from 'file-saver';
import { EntitiesStore } from '../../entity-stores/entities/entities.store';
import { ServiceCentersQuery } from '../../entity-stores/sercice-centers/service-centers.query';
import * as _ from 'lodash';

export interface BookingDetailsDataUpdate {
  bookingCheckin: {
    customerSignature: string,
    isForceCheckInEarly: boolean,
    isForceCheckInLate: boolean
  },
  bookedAt: any,
  bookingStatus: string,
  bookingJobs: any[],
  bookingAdditionalOptions: any[],
  bayId: number,
  customerNote: string
}

export interface BookingStatus {
  bookingStatus: string,
}

export interface LogbookPackage {
  bookingJobs: [
    {
      id: number,
      jobId: number
    }
  ]
}

export interface BookingPayload {
  odometer: number,
  vehicleId: number,
  customerId: number,
  customerContactId?: number,
  serviceTypeId: number,
  bookedAt: any,
  bookingJobs?: any[],
  isPaylater: boolean,
  npQuoteId: string,
  quoteRequestKey: string,
  isManualBooking: boolean
}

@Injectable({ providedIn: 'root' })
export class BookingDetailsSelectedService {

  logbookPackageObservable: Observable<any>;
  private bookingId;
  private bookingDetails;
  private entityId;
  private includeEnquiry: boolean;

  constructor(
    private http: HttpClient,
    private store: BookingDetailsSelectedStore,
    private configStore: ConfigStore,
    private customerService: CustomerService,
    private bookingQuery: BookingDetailsSelectedQuery,
    private router: Router,
    private toastrService: NbToastrService,
    private entitiesStore: EntitiesStore,
    private serviceCentersQuery: ServiceCentersQuery,
  ) {
    this.bookingQuery.bookingDetailsSelected$.subscribe(val => {
      this.bookingId = val.id;
      this.bookingDetails = val;
    })

    this.serviceCentersQuery.serviceCenterSeleted$.pipe().subscribe(serviceCenterSeleted => {
      const bookingServicesConfigs = _.get(serviceCenterSeleted, '[0]');
      if (!bookingServicesConfigs) {
        return;
      }
      this.includeEnquiry = bookingServicesConfigs.displayLiteBooking;
    }); 
  }

  private getPreferredContactId(contacts) {
    let result;
    let emailContactId;
    contacts.forEach(c => {
      if (c.isPreferred) {
        result = c.id;
      }
      if (c.contact.type === 'email') {
        emailContactId = c.id;
      }
    });

    return result ? result : emailContactId;
  }

  @Share()
  createBooking(bookingPayload: BookingPayload, serviceTyepCode: string, isExpress: boolean) {
    return this.customerService.getCustomerDetails(bookingPayload.customerId).pipe(flatMap((res: any) => {
      const url = isExpress ? API_PATH.bookings.createExpressBooking : API_PATH.bookings.createLogbookBooking;
      bookingPayload.customerContactId = this.getPreferredContactId(res.contacts);

      return this.http.post(url, bookingPayload, { params: { type: serviceTyepCode } });
    }));
  }

  @Share()
  getBookingDetails(id) {
    let url = APIService.prepareURI(API_PATH.bookings.bookingDetails, { bookingId: id });

    url += `?includes=vehicle-details&includes=customer-details&includes=service-center-details`;

    if(this.includeEnquiry) {
      url += `&includes=enquiry`;
    }

    return this.http.get(url).pipe(map(res => {
      if (!res) {
        this.router.navigateByUrl('pages/booking').then(() => {
          this.toastrService.danger('Internal issues encountered. Please refresh the page and try again.', 'Error', { destroyByClick: true, hasIcon: false });
        })
      } else {
        this.entityId = res['entityId'] || 0;
        this.store.reset();
        this.store.updateStore(res);
        this.configStore.updateClonnedBookingDetails(res);
        return res;
      }
    }), catchError(err => {
      this.router.navigateByUrl('pages/booking');
      setTimeout(() => {

      })
      return of(err);
    }));
  }

  @Share()
  updateBookingDetails(payload, bookingId = undefined, entityBookingId = undefined) {
    let url = APIService.prepareURI(API_PATH.bookings.bookingDetails, { bookingId: bookingId ? bookingId : this.bookingId });
    if (entityBookingId) {
      url += "?entityContext=" + entityBookingId;
    }
    return this.http.put(url, payload);
  }

  updateBookingStatus(status: BookingStatus, bookingId = undefined, entityBookingId = undefined) {
    return this.updateBookingDetails(status, bookingId, entityBookingId);
  }

  updateUserComment(data) {
    return this.updateBookingDetails(data);
  }

  updateLogBookPackage(logbookPackage: LogbookPackage,) {

    return this.updateBookingDetails(logbookPackage);
  }

  getTimeSlots(date: string, ServiceTypeId: any, bayId?: any, jobIds?: any) {
    bayId = bayId ? `&bayId=${bayId}` : '';
    return this.http.get(API_PATH.bookings.timeslots + `?Date=${date}&ServiceTypeId=${ServiceTypeId}${bayId}&jobIds=${jobIds}`).pipe(
      map((res: any) => res.results),
      share()
    );
  }

  getAdminTimeSlots(date: string, typeId: number) {
    const url = `${API_PATH.bookings.adminTimeSlots}?date=${date}&serviceTypeId=${typeId}&slotOnly=false&noLeadDays=true`;
    return this.http.get(url).pipe(
      map((res: any) => res.results)
    );
  }

  getExpressJobs(cylinder: number, odometer: number = 0) {
    let url = API_PATH.bookings.expressJobs + `?cylinder=${cylinder}`

    url += `&odometer=${odometer}`;

    return this.http.get(url).pipe(map((res: any) => res.results));
  }

  getLogbookJobs(cylinder: number) {
    return this.http.get(API_PATH.bookings.logbookJobs + `?cylinder=${cylinder}`).pipe(map((res: any) => res.results));
  }

  updateBookingAppointment(payload) {
    const url = APIService.prepareURI(API_PATH.bookings.bookingDetails, { bookingId: this.bookingQuery.getValue().id });

    return this.http.put(url, payload);
  }

  initNewBooking(date, serviceType, bay) {
    this.store.initNewBooking(date, serviceType, bay);
  }

  cancelBooking(reasons) {
    const url = APIService.prepareURI(API_PATH.bookings.cancelBooking, { bookingId: this.bookingId });

    return this.http.put(url, reasons);
  }

  getBookingActivities(id) {
    const url = APIService.prepareURI(API_PATH.bookings.bookingActivities, { bookingId: id });

    return this.http.get(url);
  }


  deleteBookingActivities(bookingId, bookingPaymentId) {
    const url = APIService.prepareURI(API_PATH.bookings.deleteBookingActivities, { bookingId, bookingPaymentId: bookingPaymentId });
    return this.http.delete(url);
  }


  getStatusList() {
    return this.http.get(API_PATH.types.bookingStatus);
  }

  pickUp(bookingId) {
    const url = APIService.prepareURI(API_PATH.bookings.pickUp, { bookingId });

    return this.http.put(url, {});
  }

  checkinBooking(payload) {
    const url = APIService.prepareURI(API_PATH.bookings.bookingStatusCheckin, { bookingId: this.bookingId });

    return this.http.put(url, payload);
  }

  getPaymentCode() {
    if (_.get(this.bookingDetails, 'bookingPayments.length', 0) === 0) {
      return 'unpaid';
    }
    const paid = this.bookingDetails.bookingPayments.find(item => item.status === 'completed');
    return paid ? 'paid' : 'unpaid';
  }

  isPaid(bookingPayments: BookingPaymentDetails[]) {
    if(!bookingPayments) return false;
    return bookingPayments.some(item => item.status === 'completed');
  }

  isLiteBooking(bookingDetails: BookingDetails) {
    return !!(_.get(bookingDetails, 'isEnquiry'));
  }

  downloadReceipt(bookingId) {
    let header = new HttpHeaders();
    header = header.set('Download-Receipt', '');
    const url = APIService.prepareURI(API_PATH.bookings.downloadReceipts, { bookingId: bookingId });

    const allEntities = this.entitiesStore.getValue();
    const companyEntityId = (allEntities.entities[this.entityId] && allEntities.entities[this.entityId]["parentId"]) || null;
    const companyName = companyEntityId && companyEntityId > 2 ? allEntities.entities[companyEntityId]["name"] : "easyauto123";

    return this.http.get(url, { headers: header, responseType: 'text' }).pipe(tap((data) => {
      const pdf = 'data:application/pdf;base64,' + data;
      saveAs(pdf, `${companyName} Tax Receipt - ${bookingId}.pdf`);
      let pdfWindow = window.open("");
      pdfWindow.document.write(
        "<html><head></head><body style='margin:0'><iframe width='100%' height='100%' style='margin:0;padding:0;border:0' src='" +
        encodeURI(pdf) + "'></iframe></body></html>"
      );
    }));
  }

  getTotalBookingTime() {
    let duration = 0;
    duration += this.bookingDetails.serviceTimeInMinutes;
    for (const option of this.bookingDetails.extraOptions) {
      duration += option.serviceTime;
    }

    if (!duration || duration < 60) {
      duration = 60;
    } else {
      const mod = duration % 10;
      if (mod !== 0) duration += 10 - mod;
    }

    return duration;
  }
}
