import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class DocumentIconService {

    render() {
        return `<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.683417 0.71892C1.121 0.258603 1.7145 0 2.33333 0H8.55556C8.76184 0 8.95967 0.086201 9.10553 0.23964L13.7722 5.14873C13.9181 5.30217 14 5.51028 14 5.72727V15.5455C14 16.1964 13.7542 16.8208 13.3166 17.2811C12.879 17.7414 12.2855 18 11.6667 18H2.33333C1.71449 18 1.121 17.7414 0.683417 17.2811C0.245833 16.8208 0 16.1964 0 15.5455V2.45455C0 1.80356 0.245832 1.17924 0.683417 0.71892ZM2.33333 1.63636C2.12705 1.63636 1.92922 1.72256 1.78336 1.876C1.6375 2.02944 1.55556 2.23755 1.55556 2.45455V15.5455C1.55556 15.7625 1.6375 15.9706 1.78336 16.124C1.92922 16.2774 2.12705 16.3636 2.33333 16.3636H11.6667C11.8729 16.3636 12.0708 16.2774 12.2166 16.124C12.3625 15.9706 12.4444 15.7625 12.4444 15.5455V6.06617L8.23339 1.63636H2.33333Z" fill="#A9A9A9"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.55556 0C8.98511 0 9.33333 0.366312 9.33333 0.818182V4.90909H13.2222C13.6518 4.90909 14 5.2754 14 5.72727C14 6.17914 13.6518 6.54545 13.2222 6.54545H8.55556C8.126 6.54545 7.77778 6.17914 7.77778 5.72727V0.818182C7.77778 0.366312 8.126 0 8.55556 0Z" fill="#A9A9A9"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.11111 9.81818C3.11111 9.36631 3.45933 9 3.88889 9H10.1111C10.5407 9 10.8889 9.36631 10.8889 9.81818C10.8889 10.2701 10.5407 10.6364 10.1111 10.6364H3.88889C3.45933 10.6364 3.11111 10.2701 3.11111 9.81818Z" fill="#A9A9A9"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.11111 13.0909C3.11111 12.639 3.45933 12.2727 3.88889 12.2727H10.1111C10.5407 12.2727 10.8889 12.639 10.8889 13.0909C10.8889 13.5428 10.5407 13.9091 10.1111 13.9091H3.88889C3.45933 13.9091 3.11111 13.5428 3.11111 13.0909Z" fill="#A9A9A9"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.11111 6.54545C3.11111 6.09359 3.45933 5.72727 3.88889 5.72727H5.44444C5.874 5.72727 6.22222 6.09359 6.22222 6.54545C6.22222 6.99732 5.874 7.36364 5.44444 7.36364H3.88889C3.45933 7.36364 3.11111 6.99732 3.11111 6.54545Z" fill="#A9A9A9"/>
        </svg>
        `;
    }
}
