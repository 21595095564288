import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'discard-change-confirm-dlg',
  templateUrl: './discard-change-confirm-dlg.component.html',
  styleUrls: ['./discard-change-confirm-dlg.component.scss']
})
export class ConfirmDlgComponent implements OnInit {
  @Input() textBody: string = 'You have made some changes. Are you sure you want to discard and lose your changes?';
  @Input() confirmText: string = 'Discard';
  @Input() cancelText: string = 'Cancel';

  constructor(private dialogRef: NbDialogRef<ConfirmDlgComponent>,) { }

  ngOnInit() {
  }

  onSubmit(){
    this.dialogRef.close(true);
  }

  close(){
    this.dialogRef.close();
  }
}
