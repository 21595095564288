import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class ChevronRightIconService {

    render(color = null) {
        if (!color) color = 'black';

        return `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.52827 3.52827C5.78862 3.26792 6.21073 3.26792 6.47108 3.52827L10.4711 7.52827C10.7314 7.78862 10.7314 8.21073 10.4711 8.47108L6.47108 12.4711C6.21073 12.7314 5.78862 12.7314 5.52827 12.4711C5.26792 12.2107 5.26792 11.7886 5.52827 11.5283L9.05687 7.99967L5.52827 4.47108C5.26792 4.21073 5.26792 3.78862 5.52827 3.52827Z" fill="${color}"/>
        </svg>
    `;
    }
}
