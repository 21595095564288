import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CarWashServiceIconService {

  render() {
    return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.926 5.27577C14.7545 5.27577 15.426 4.63761 15.426 3.85053C15.426 3.06335 13.926 1 13.926 1C13.926 1 12.426 3.06335 12.426 3.85053C12.426 4.63761 13.0975 5.27577 13.926 5.27577ZM20.5115 5.46918L22.3589 12.4916C23.3026 12.7458 24.0001 13.5628 24 14.54V20.241C24 20.6349 23.6646 20.9536 23.25 20.9536H22.5V22.3789C22.5 23.1645 21.827 23.8041 21 23.8041H19.5001C18.6732 23.8041 18.0001 23.1646 18.0001 22.3789V20.9536H6.00003V22.3789C6.00003 23.1645 5.32696 23.8041 4.50005 23.8041H2.99996C2.1731 23.8041 1.49998 23.1646 1.49998 22.3789V20.9536H0.74999C0.335441 20.9536 0 20.6349 0 20.241V14.54C0 13.5629 0.697393 12.7459 1.64122 12.4916L3.48856 5.46989C3.73904 4.51645 4.63702 3.85046 5.67119 3.85046H7.50006V5.27575H5.67119C5.32616 5.27575 5.02735 5.49776 4.94313 5.81578L3.21024 12.402H20.7898L19.0569 5.81507C18.9727 5.49776 18.6739 5.27575 18.3289 5.27575H16.5V3.85046H18.3289C19.3631 3.85046 20.2611 4.51645 20.5115 5.46918ZM2.99996 22.3789H4.49994V20.9536H2.99996V22.3789ZM19.5001 22.3789H21V20.9536H19.5001V22.3789ZM1.49998 19.5283H22.5V14.5399C22.5 14.1467 22.1638 13.8273 21.75 13.8273H2.24997C1.83617 13.8273 1.49998 14.1467 1.49998 14.5399V19.5283ZM7.06041 15.67C7.64622 16.2266 7.64622 17.129 7.06041 17.6856C6.4746 18.2422 5.52487 18.2422 4.93911 17.6856C4.3533 17.129 4.3533 16.2266 4.93911 15.67C5.52487 15.1134 6.47466 15.1134 7.06041 15.67ZM19.0607 15.67C19.6465 16.2266 19.6465 17.129 19.0607 17.6856C18.4748 18.2422 17.5251 18.2422 16.9394 17.6856C16.3535 17.129 16.3535 16.2266 16.9394 15.67C17.5251 15.1134 18.4748 15.1134 19.0607 15.67ZM12.3195 6.80231C12.3195 7.58939 11.648 8.22755 10.8196 8.22755C9.9911 8.22755 9.31953 7.58939 9.31958 6.80231C9.31958 6.01513 10.8196 3.95178 10.8196 3.95178C10.8196 3.95178 12.3195 6.01513 12.3195 6.80231Z" fill="#222222"/>
      </svg>
    `;
  }
}
