<nb-card [nbSpinner]="loading" class="edit-booking-dlg">
  <nb-card-header class="d-flex align-items-center px-3">
    <h4 class="mb-0 flex-fill">Add Booking</h4>
    <div *ngIf="type !== 'edit-booking'"
      class="mr-5 d-flex flex-row align-items-center justify-content-between active-toggle">
    </div>
    <!-- <nb-icon icon="x-icon" pack="mo-icons" (click)="closeDlg()"></nb-icon> -->
    <nb-icon icon="x-icon" pack="mo-icons" (click)="closeDlg()" class="cursor-pointer"></nb-icon>
  </nb-card-header>
  <nb-card-body class="px-3">
    <form [formGroup]="form">
      <div class="d-flex align-items-center mx-n2 control-group">
        <div class="p-2 pb-0" [nbSpinner]="isLoadingService" nbSpinnerStatus="basic">
          <div class="mo-select box icon-left">
            <label>Service</label>
            <nb-icon icon="maximize" pack="mo-icons"></nb-icon>
            <nb-select fullWidth formControlName="service" placeholder="Pick service">
              <nb-option *ngFor="let service of serviceTypes$ | async" [value]="service" class="item">
                {{service.description}}
              </nb-option>
              <nb-option *ngIf="noService">No data</nb-option>
            </nb-select>
          </div>
        </div>

        <div class="p-2 pb-0">
          <div class="appointment mo-control-container">
            <label>Appointment</label>
            <div class="appointment-container mo-control">
              <div class="item d-flex align-items-center justify-content-between">
                <!-- <input matInput [ngxMatDatetimePicker]="picker" [min]="minDate" readonly placeholder="Choose a date" formControlName="dateTime">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="false" [stepHour]="1"
                  [stepMinute]="1">
                </ngx-mat-datetime-picker> -->

                <div class="date d-flex align-items-center justify-content-between border-right">
                  <input matInput [matDatepicker]="picker" placeholder="Pick date" [min]="minDate"
                    formControlName="dateTime">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </div>
                <div class="time d-flex align-items-center justify-content-between">

                  <nb-icon icon="clock" pack="mo-icons" class="d-flex align-items-center justify-content-between"
                    class="mx-2"></nb-icon>
                  <nb-select #timepicker [size]="'small'" fullWidth [(selected)]="timeSlot" class="time-slot-selection"
                    placeholder="Pick time">
                    <nb-option *ngFor="let time of timeSlots$ | async" [(value)]="time" class="item nb-option-small">
                      {{time}}
                    </nb-option>
                    <nb-option *ngIf="nodata">No data</nb-option>
                  </nb-select>

                  <!-- <nb-icon class="d-flex align-items-center justify-content-between" class="mx-2" icon="clock"
                    pack="mo-icons"></nb-icon>
                  <p class="mb-0 body-3 font-weight-600">{{bookingTime}}</p> -->
                </div>
                <!-- <div class="date d-flex align-items-center justify-content-between">
                  <nb-icon class="d-flex align-items-center justify-content-between" class="mr-2" icon="calendar"
                    pack="mo-icons"></nb-icon>
                  <p class="mb-0 body-3 font-weight-600">{{bookingDate}}</p>
                </div>
                <div class="time d-flex align-items-center justify-content-between">
                  <nb-icon class="d-flex align-items-center justify-content-between" class="mr-2" icon="clock"
                    pack="mo-icons"></nb-icon>
                  <p class="mb-0 body-3 font-weight-600">{{bookingTime}}</p>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <mat-form-field *ngIf="!selectedCustomer" class="full-width box mat-autocomplete" appearance="legacy"
        floatLabel="always">
        <mat-label>Customer</mat-label>
        <nb-icon icon="person" pack="mo-icons"></nb-icon>
        <input id="customerCtrl" type="text" matInput name="customerCtrl" formControlName="customerCtrl"
          [matAutocomplete]="auto" placeholder="Seach by name, rego, email,...">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option value="" disabled>
            <div class="row">
              <div class="col-3">
                <h6 class="body-3 font-weight-bold px-1 mb-0">NAME</h6>
              </div>
              <div class="col-3">
                <h6 class="body-3 font-weight-bold px-1 mb-0">PHONE</h6>
              </div>
              <div class="col-6">
                <h6 class="body-3 font-weight-bold px-1 mb-0">EMAIL</h6>
              </div>
            </div>
          </mat-option>
          <mat-option (click)="selectCustomer(customer)" *ngFor="let customer of filteredCustomers"
            [value]="customer.name">
            <div *ngIf="customer" class="row">
              <div class="col-3">
                <p class="body-3 font-weight-500 px-1 mb-0">{{customer.firstName | titlecase}}
                  {{customer.lastName | titlecase}}</p>
              </div>
              <div class="col-3">
                <span *ngIf="customer && customer.phone">
                  {{customer.phone[0] == '0' ? '' : '0'}}{{customer.phone}}
                </span>
              </div>
              <div class="col-6">
                {{customer.email}}
              </div>
            </div>
          </mat-option>
          <mat-option *ngIf="searchingCustomer">Loading...</mat-option>
          <mat-option *ngIf="filteredCustomers && filteredCustomers.length === 0 && !searchingCustomer">No customers
            found</mat-option>
          <mat-option *ngxPermissionsOnly="customerPermissions.adminCreateCustomerUser" value="" disabled>
            <p class="mb-0 text-grey text-right">
              That’s not right user?
              <a (click)="createCustomer()"
                class="mb-0 mr-1 body-3 font-weight-600 text-underline cursor-pointer text-dark">Create Customer</a>
            </p>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div *ngIf="selectedCustomer" class="mo-control-container">
        <label>Customer</label>
        <div class="mo-control">
          <div class="panel p-3">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="font-weight-bold mb-0">
                {{selectedCustomer.firstName | titlecase}}
                {{selectedCustomer.lastName | titlecase}}</h5>
              <button class="secondary small btn-icon " (click)="chooseCustomer()">
                <nb-icon class="btn-icon d-flex align-items-center justify-content-between" icon="repeat"
                  pack="mo-icons">
                </nb-icon>
              </button>
            </div>
            <div class="customer d-flex justify-content-between mx-n2 flex-wrap">
              <div class="w-50 px-2 d-flex align-items-center my-2">
                <nb-icon class="mr-2" icon="phone" pack="mo-icons"></nb-icon>
                <p class="body-3 font-weight-500 mb-0">{{selectedCustomer.phone}}</p>
              </div>
              <div class="w-50 px-2 d-flex align-items-center my-2">
                <nb-icon class="mr-2" icon="home" pack="mo-icons"></nb-icon>
                <p class="body-3 font-weight-500 mb-0">{{selectedCustomer.suburb}} {{selectedCustomer.state}}</p>
                <p *ngIf="!selectedCustomer.suburb && !selectedCustomer.state" class="body-3 font-weight-500 mb-0">N/A
                </p>
              </div>
              <div class="w-50 px-2 d-flex align-items-center my-2">
                <nb-icon class="mr-2" icon="email" pack="mo-icons"></nb-icon>
                <p class="body-3 font-weight-500 mb-0">{{selectedCustomer.email}}</p>
              </div>
              <div class="w-50 px-2 d-flex align-items-center my-2">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center mx-n2 control-group">
        <div class="flex-fill px-2">
          <div [ngClass]="{disabled: !selectedCustomer}" class="mo-select box icon-left">
            <label>Vehicle</label>
            <nb-icon icon="car" pack="mo-icons"></nb-icon>
            <nb-select *ngIf="show" fullWidth (selectedChange)="onChange($event)" [(selected)]="selectedVehicleItem">
              <nb-option *ngIf="customerVehicles && customerVehicles.length > 0" class="header" value="" disabled>
                <div class="d-flex justify-content-start align-items-center mx-n1 full-width header item">
                  <h6 class="body-3 font-weight-bold px-1 mb-0 w-30">REGO</h6>
                  <h6 class="body-3 font-weight-bold px-1 mb-0 w-70">VEHICLE DETAILS</h6>
                </div>
              </nb-option>
              <nb-option *ngFor="let vehicle of customerVehicles" class="item" [(value)]="vehicle">
                <div class="d-flex justify-content-start align-items-center mx-n1 full-width item">
                  <p class="body-3 font-weight-600 px-1 mb-0 w-30">{{vehicle.rego + ' - ' | uppercase}}</p>
                  <p class="body-3 font-weight-normal px-1 mb-0 w-70 one-line">{{vehicle.make | uppercase}}
                    {{vehicle.model | uppercase}} {{vehicle.year}}</p>
                </div>
              </nb-option>
              <nb-option *ngIf="customerVehicles && customerVehicles.length === 0" class="item">
                <div class="d-flex justify-content-start align-items-center mx-n1 full-width item">
                  <p class="body-3 text-center font-weight-600 px-1 mb-0 w-100">N/A</p>
                </div>
              </nb-option>
              <nb-option *ngxPermissionsOnly="vehiclePermissions.adminAddVehicle" value="">
                <a (click)="addVehicle()"
                  class="mb-0 mr-1 body-3 font-weight-600 text-underline cursor-pointer text-dark text-right full-width">
                  Add Vehicle
                </a>
              </nb-option>
            </nb-select>
            <nb-select [disabled]="!selectedCustomer" *ngIf="!show" fullWidth>
            </nb-select>
          </div>
        </div>
        <div class="px-2">
          <mat-form-field [ngClass]="{disabled: !selectedCustomer}" class="full-width box" appearance="legacy"
            floatLabel="always">
            <mat-label>Current Odometer</mat-label>
            <nb-icon icon="odometer" pack="mo-icons"></nb-icon>
            <input mask="separator" thousandSeparator="," matInput formControlName="odo">
            <p class="unit body-3 font-weight-600">KM</p>
          </mat-form-field>
        </div>
      </div>
      <logbook-service isDlg="true" *ngIf="serviceCode === 'logbook' || serviceCode === 'tyre-bonus'" [serviceType]="serviceCode"
        [slideConfig]="slideConfig" [selectedVehicleItem]="selectedVehicleItem" (onSelect)="onSelectKM($event)">
      </logbook-service>
    </form>
  </nb-card-body>
  <nb-card-footer [ngClass]="{disabled: (!selectedVehicleItem?.id || !timeSlot || this.form.invalid)}"
    class="text-right px-3 pt-4 border-0">
    <!-- <button type="button" (click)="createBooking()" class="secondary big mr-3">Quick Booking
    </button> -->
    <button (click)="createBooking()" type="submit" class="primary big">Add Booking</button>
  </nb-card-footer>
</nb-card>