import { Component, OnInit, OnDestroy } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';
import { BookingDetails, BookingDetailsSelectedStore } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.store';
import { BookingDetailsSelectedQuery } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.query';
import { CommonService } from './../../../@core/utils/common.service';
import { PaymentService } from '../../../@core/utils/payment.service';
import { Location } from '@angular/common';
import { ConfigService } from '../../../@core/akita-stores/stores/config/config.service';
import { BookingDetailsSelectedService } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'payment-result-dialog',
  templateUrl: './payment-result-dialog.component.html',
  styleUrls: ['./payment-result-dialog.component.scss']
})
export class PaymentResultDialogComponent implements OnInit, OnDestroy {

  bookingDetails: BookingDetails;
  loading: boolean = true;
  timeout;
  showCardDetailsForm: boolean;
  onError: boolean = false;
  threeTimesFailing: boolean = false;
  protected destroy$ = new Subject<void>();

  constructor(
    protected ref: NbDialogRef<PaymentResultDialogComponent>,
    private router: Router,
    private bookingDetailsQuery: BookingDetailsSelectedQuery,
    private commonService: CommonService,
    private paymentService: PaymentService,
    private location: Location,
    private configService: ConfigService,
    private toastrService: NbToastrService,
    private bookingDetailsService: BookingDetailsSelectedService,
    private bookingDetailsStore: BookingDetailsSelectedStore,
  ) {
    this.bookingDetails = bookingDetailsQuery.getValue();
  }

  ngOnInit() {
    if (window.location.pathname.includes('/complete')) {
      this.location.replaceState('pages/booking/booking-details/' + this.bookingDetails.id)
    }
    this.checkPaymentStatus();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkPaymentStatus() {
    const onFailedPayment = () => {
      this.showCardDetailsForm = true;
      this.loading = false;
      // this.clearTheSecondPopup();
    }

    const isThreeTimesFaling = (arr) => {
      let numberOfFails = 0;
      arr.forEach(item => {
        if (item.status === 'failed') {
          numberOfFails++;
        }
      });

      if (numberOfFails >= 3) {
        this.threeTimesFailing = true;
        setTimeout(() => {
          this.bookingDetailsService.updateBookingStatus({ bookingStatus: 'cancelled' })
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            const bookingStatus = {description: 'Cancelled', code: 'cancelled'};
            this.bookingDetailsStore.updateStore({ bookingStatus });
            this.loading = false;
          }, () => this.loading = false);
        }, 1000);
      }

      return numberOfFails >= 3;
    }

    this.paymentService.getPaymentStatus(this.bookingDetails.id)
    .pipe(takeUntil(this.destroy$))
    .subscribe((res: any) => {
      this.onError = false;

      if (this.isPaymentSuccess(res.results)) {
        this.loading = false;
        this.showCardDetailsForm = false;
        this.configService.removeCreditCardPaymentProcess(this.bookingDetails.id);
      } else {
        if (!isThreeTimesFaling(res.results)) {
          onFailedPayment();
        };
      }
    }, err => {
      this.loading = false;
      this.onError = true;
      this.toastrService.danger('Internal issues encountered. Please refresh the page and try again.', 'Error', { destroyByClick: true, hasIcon: false, preventDuplicates: true });
    });
  }

  /**
   * Fixing an issue: This component is being rendered twice, this causes the PaymentResultDialogComponent popup to be duplicated
   */
  clearTheSecondPopup() {
    setTimeout(() => {
      try {
        const list1 = document.querySelectorAll('.card-details-dialog');
        list1[1].remove();
        const list2 = document.querySelectorAll('.cdk-overlay-backdrop.cdk-overlay-backdrop-showing');
        list2[1].remove();
      } catch (e) { }
    }, 500);
  }

  private isPaymentSuccess(res) {
    if (res && res.results && res.results.length === 0) return false;
    return res.find(item => item.status === 'completed');
  }

  close() {
    this.ref.close(this.threeTimesFailing);
  }

  gotoManageBooking() {
    this.close();
    this.router.navigate(['/pages/booking']).then(() => this.commonService.resetStore());
  }
}
