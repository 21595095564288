import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class ChevronDownIconService {
  render(isNew = false) {
    if (isNew === false) {
      return `<svg width="16" height="39" viewBox="0 0 16 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 0C3.58872 0 0 3.64512 0 8.12537V14.5958C0 19.0761 3.58872 22.721 8 22.721C12.411 22.721 16 19.0871 16 14.6207V8.12537C16 3.64512 12.411 0 8 0ZM14.4953 14.6207C14.4953 18.2575 11.5817 21.2163 8 21.2163C4.41856 21.2163 1.50473 18.2463 1.50473 14.5956V8.12537C1.50473 4.4747 4.41856 1.50469 8 1.50469C11.5817 1.50469 14.4953 4.4747 14.4953 8.12537V14.6207Z" fill="var(--block-colour)"/>
      <path d="M7.99944 6.21924C7.58389 6.21924 7.24707 6.55606 7.24707 6.9716V9.60484C7.24707 10.0204 7.58389 10.3572 7.99944 10.3572C8.41498 10.3572 8.7518 10.0204 8.7518 9.60484V6.9716C8.7518 6.55606 8.41498 6.21924 7.99944 6.21924Z" fill="var(--block-colour)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.52827 28.5286C3.78862 28.2683 4.21073 28.2683 4.47108 28.5286L7.99967 32.0572L11.5283 28.5286C11.7886 28.2683 12.2107 28.2683 12.4711 28.5286C12.7314 28.789 12.7314 29.2111 12.4711 29.4714L8.47108 33.4714C8.21073 33.7318 7.78862 33.7318 7.52827 33.4714L3.52827 29.4714C3.26792 29.2111 3.26792 28.789 3.52827 28.5286Z" fill="var(--block-colour)"/>
      </svg>      
      `;
    }
    if (isNew === true) {
      return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z" fill="black"/>
    </svg>
    `;
    }
  }
}
