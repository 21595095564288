import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { EditBookingDlgComponent } from '../edit-booking-dlg/edit-booking-dlg.component';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ERROR_MESSAGES } from '../../../constants/error-messages';
import { CustomerService } from '../../../@core/akita-stores/stores/customer/customer.service';

@Component({
  selector: 'edit-customer-dlg',
  templateUrl: './edit-customer-dlg.component.html',
  styleUrls: ['./edit-customer-dlg.component.scss']
})
export class EditCustomerDlgComponent implements OnInit {

  customerCreateForm: FormGroup;
  errorMessage = ERROR_MESSAGES;
  suburbTimeout: any;
  suburbs = [];
  show: boolean = true;
  loading: boolean = false;
  suburbSelected: string = '';

  constructor(private dialogService: NbDialogService, protected ref: NbDialogRef<EditCustomerDlgComponent>, private customerService: CustomerService, private cdr: ChangeDetectorRef) {
    this.customerCreateForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required]),
      homeAddress: new FormControl('', []),
      postCode: new FormControl('', []),
      suburb: new FormControl('', []),
      state: new FormControl('', [])
    });
  }

  ngOnInit() {
  }

  closeDlg(hasBackdrop: boolean = true) {
    this.ref.close();
  }

  createCustomer() {
    if (this.customerCreateForm.invalid) return;

    this.loading = true;
    this.customerService.createCustomer(this.customerCreateForm.value).subscribe((val: any) => {
      this.loading = false;
      let data = this.customerCreateForm.value;
      data.id = val.customerId;
      this.ref.close(data);
    }, err => {
      this.loading = false;
    });
  }

  onKeyupPostCode() {
    clearTimeout(this.suburbTimeout);
    this.suburbTimeout = setTimeout(() => {
      this.customerService.getSuburbInfo(this.customerCreateForm.value.postCode).subscribe(res => {
        this.show = false;
        if (res.length > 0) {
          this.customerCreateForm.controls.state.setValue(res[0].state);
          this.suburbs = res.map(val => val.suburb);
          this.customerCreateForm.controls.suburb.setValue(this.suburbs[0]);
          try {
            this.cdr.detectChanges();
          } catch (e) { }
          this.customerCreateForm.controls.postCode.setErrors(null);
        } else {
          this.customerCreateForm.controls.postCode.setErrors({ postCodeUndetectable: true });
        }
        this.show = true;
      });
    }, 500);
  }
}
