export const FLIGHT_DECK_PERMISSIONS = {
  addFlightDeckTrackings: 'addFlightDeckTrackings',
  getFlightDeckTrackings: 'getFlightDeckTrackings',
  removeFlightDeckBay: 'removeFlightDeckBay',
  updateFlightDeckBay: 'updateFlightDeckBay',
  addFlightDeckBay: 'addFlightDeckBay',
  updateFlightDecks: 'updateFlightDecks',
  createFlightDecks: 'createFlightDecks',
  getFlightDecks: 'getFlightDecks',
  manageFlightDecks: 'manageFlightDecks'
}