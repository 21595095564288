import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TextMessageIconService {

  render() {
    return `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 1.5C8.05109 1.5 7.86032 1.57902 7.71967 1.71967C7.57902 1.86032 7.5 2.05109 7.5 2.25V8.53647L9.41459 7.57918C9.51873 7.52711 9.63357 7.5 9.75 7.5H15.75C15.9489 7.5 16.1397 7.42098 16.2803 7.28033C16.421 7.13968 16.5 6.94891 16.5 6.75V2.25C16.5 2.05109 16.421 1.86032 16.2803 1.71967C16.1397 1.57902 15.9489 1.5 15.75 1.5H8.25ZM6.65901 0.65901C7.08097 0.237053 7.65326 0 8.25 0H15.75C16.3467 0 16.919 0.237053 17.341 0.65901C17.7629 1.08097 18 1.65326 18 2.25V6.75C18 7.34674 17.7629 7.91903 17.341 8.34099C16.919 8.76295 16.3467 9 15.75 9H9.92705L7.08541 10.4208C6.85292 10.5371 6.57681 10.5246 6.3557 10.388C6.13459 10.2513 6 10.0099 6 9.75V2.25C6 1.65326 6.23705 1.08097 6.65901 0.65901Z" fill="var(--block-colour)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.40901 2.15901C1.83097 1.73705 2.40326 1.5 3 1.5H4.125C4.53921 1.5 4.875 1.83579 4.875 2.25C4.875 2.66421 4.53921 3 4.125 3H3C2.80109 3 2.61032 3.07902 2.46967 3.21967C2.32902 3.36032 2.25 3.55109 2.25 3.75V15.75C2.25 15.9489 2.32902 16.1397 2.46967 16.2803C2.61032 16.421 2.80109 16.5 3 16.5H11.25C11.4489 16.5 11.6397 16.421 11.7803 16.2803C11.921 16.1397 12 15.9489 12 15.75V11.25C12 10.8358 12.3358 10.5 12.75 10.5C13.1642 10.5 13.5 10.8358 13.5 11.25V15.75C13.5 16.3467 13.2629 16.919 12.841 17.341C12.419 17.7629 11.8467 18 11.25 18H3C2.40326 18 1.83097 17.7629 1.40901 17.341C0.987053 16.919 0.75 16.3467 0.75 15.75V3.75C0.75 3.15326 0.987053 2.58097 1.40901 2.15901Z" fill="var(--block-colour)"/>
    <ellipse cx="7.5" cy="14.25" rx="0.75" ry="0.75" fill="var(--block-colour)"/>
    </g>
    <defs>
    <clipPath id="clip0">
    <path d="M0 0H18V18H0V0Z" fill="white"/>
    </clipPath>
    </defs>
    </svg>    
    `;
  }
}
