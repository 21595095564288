<form [formGroup]="cancelForm" (ngSubmit)="proceed()">
  <nb-card [nbSpinner]="loading" class="booking-cancel-dialog">
    <nb-card-header>Reasons for Booking Cancellation<nb-icon icon="x-icon" pack="mo-icons" (click)=close()></nb-icon>
    </nb-card-header>
    <nb-card-body>
      <mat-form-field *ngIf="paid && paid.reference" class="full-width box" appearance="legacy" floatLabel="always">
        <mat-label>Banking Reference No.</mat-label>
        <input class="p-2" readonly matInput type="text" formControlName="referenceNo">
      </mat-form-field>

      <mat-form-field class="full-width box" appearance="legacy" floatLabel="always">
        <mat-label>Please enter the reasons</mat-label>
        <textarea class="form-control p-2" rows="3" matInput type="text" formControlName="reasons"></textarea>
      </mat-form-field>
    </nb-card-body>
    <nb-card-footer class="d-flex flex-row-reverse border-0">
      <button nbButton class="small ml-2" type="submit">Proceed</button>
      <button nbButton class="secondary small mx-2" (click)="close()">Cancel</button>
    </nb-card-footer>
  </nb-card>
</form>