import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class NavMenuIconService {

    render() {
        return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="1" y1="19" x2="11" y2="19" stroke="#231F20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <line x1="1" y1="5" x2="17" y2="5" stroke="#231F20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <line x1="1" y1="12" x2="23" y2="12" stroke="#231F20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `;
    }
}
