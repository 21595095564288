import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class CarIconService {

    render() {
        return `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.6189 6.99199L16.2593 6.61122L14.9971 3.20897C14.7821 2.63429 14.2321 2.25353 13.6186 2.25H4.60353C3.99006 2.25 3.44006 2.63429 3.225 3.20897L1.98045 6.56891L1.46218 7.0484C1.0109 7.43269 0.75 7.99679 0.75 8.5891V13.5462C0.753526 14.357 1.41282 15.0128 2.22372 15.0128H3.15449C3.96538 15.0128 4.62468 14.357 4.62821 13.5462V12.8269H13.3718V13.5462C13.3753 14.357 14.0346 15.0128 14.8455 15.0128H15.7763C16.5872 15.0128 17.2465 14.357 17.25 13.5462V8.45865C17.2465 7.90513 17.0208 7.37628 16.6189 6.99199ZM3.88782 3.44872C4.00064 3.14904 4.28622 2.95513 4.60353 2.95513H13.6221C13.9394 2.95513 14.225 3.15256 14.3378 3.44872L15.4378 6.41026H14.6163H11.8875H9.83205H2.78782L3.88782 3.44872ZM3.92308 13.5462C3.91955 13.9692 3.57756 14.3077 3.15449 14.3077H2.22372C1.80064 14.3077 1.45865 13.9692 1.45513 13.5462V12.6224C1.66667 12.7599 1.94519 12.8269 2.22372 12.8269H3.92308V13.5462ZM16.5449 13.5462C16.5413 13.9692 16.1994 14.3077 15.7763 14.3077H14.8455C14.4224 14.3077 14.0804 13.9657 14.0769 13.5462V12.8269H15.7763C16.0548 12.8269 16.3333 12.7599 16.5449 12.6224V13.5462ZM15.7763 12.1218H2.22372C1.80417 12.1253 1.45865 11.7904 1.45513 11.3673V8.5891C1.45513 8.20128 1.62788 7.83109 1.92404 7.58077C1.92756 7.57724 1.93462 7.57019 1.93814 7.56667L2.41763 7.11538H15.7692L16.1077 7.47852C16.1112 7.48558 16.1218 7.4891 16.1253 7.49615C16.3897 7.74647 16.5413 8.09551 16.5413 8.45865V11.3673H16.5449C16.5413 11.7869 16.1958 12.1253 15.7763 12.1218Z" fill="#FF5200" stroke="#FF5200" stroke-width="0.5"/>
        <path d="M5.58561 9.34961H3.86686C3.74082 9.34961 3.6377 9.45273 3.6377 9.57878V10.7704C3.6377 10.8965 3.74082 10.9996 3.86686 10.9996H5.58561C5.71165 10.9996 5.81478 10.8965 5.81478 10.7704V9.57878C5.81478 9.45273 5.71165 9.34961 5.58561 9.34961ZM5.35644 10.5413H4.09603V9.80794H5.35644V10.5413Z" fill="#FF5200" stroke="#FF5200" stroke-width="0.5"/>
        <path d="M14.1911 9.34961H12.4723C12.3463 9.34961 12.2432 9.45273 12.2432 9.57878V10.7704C12.2432 10.8965 12.3463 10.9996 12.4723 10.9996H14.1911C14.3171 10.9996 14.4202 10.8965 14.4202 10.7704V9.57878C14.4202 9.45273 14.3171 9.34961 14.1911 9.34961ZM13.9619 10.5413H12.7015V9.80794H13.9619V10.5413Z" fill="#FF5200" stroke="#FF5200" stroke-width="0.5"/>
        </svg>        
        `;
    }
}
