import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { NbDialogService, NbSelectComponent, NbToastrService } from '@nebular/theme';
import { NbDialogRef } from '@nebular/theme';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, of, combineLatest } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, takeUntil, switchMap, filter, tap, catchError } from 'rxjs/operators';
import { BookingDetailsSelectedQuery } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.query';
import { BookingDetails, BookingDetailsSelectedStore } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.store';
import { CommonService } from '../../../@core/utils/common.service';
import * as moment from 'moment-timezone';
import { CustomerService } from '../../../@core/akita-stores/stores/customer/customer.service';
import { CustomerVehiclesService } from '../../../@core/akita-stores/entity-stores/customer-vehicles/customer-vehicles.service';
import { BookingDetailsSelectedService, BookingPayload } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.service';
import { environment } from '../../../../environments/environment';
import { EditCustomerDlgComponent } from '../edit-customer-dlg/edit-customer-dlg.component';
import { Router } from '@angular/router';
import { ConfigService } from '../../../@core/akita-stores/stores/config/config.service';
import { SelectVehicleComponent } from '../select-vehicle/select-vehicle.component';
import { VehiclesService } from '../../../@core/akita-stores/entity-stores/vehicles/vehicles.service';
import { CUSTOMER_PERMISSIONS, VEHICLE_PERMISSIONS } from '../../../constants';
import { BayQuery } from '../../../@core/akita-stores/entity-stores/bays/bays.query';
import { BayService } from '../../../@core/akita-stores/entity-stores/bays/bays.service';
import { ServiceCentersQuery } from '../../../@core/akita-stores/entity-stores/sercice-centers/service-centers.query';
import { MAT_DATE_FORMATS } from '@angular/material';
import { CompaniesService } from '../../../@core/utils/companies.service';
import { Moment } from 'moment';
import { flatten, uniqBy, sortBy } from 'lodash';

const EXPRESS_CODES = ["express", "premium", "premium-plus", "oem-a", "oem-b", "oem-c", "oem-d"];
@Component({
  selector: 'edit-booking-dlg',
  templateUrl: './edit-booking-dlg.component.html',
  styleUrls: ['./edit-booking-dlg.component.scss'],
  providers: [
    {
      provide: MAT_DATE_FORMATS, useValue: {
        parse: {
          dateInput: "l, LTS"
        },
        display: {
          dateInput: "DD MMM YYYY",
          monthYearLabel: "MMM YYYY",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM YYYY"
        }
      }
    }
  ]
})

export class EditBookingDlgComponent implements OnInit, OnDestroy {
  serviceTypes$: any;
  slideConfig = {
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    variableWidth: true,
    adaptiveHeight: true,
  };
  show = true;
  selectedCustomer;
  bookingDetails: BookingDetails;
  isUneditableMode: boolean;
  phoneContact;
  address;
  private selectedVehicle: any = {
    id: null
  }
  email;
  loading: boolean = false;
  // bays;
  @Input('type') type;
  bookingDate: string;
  bookingTime: string;
  filteredCustomers: any[] = [];
  customerSearchTimeout: any;
  form: FormGroup;
  customerKeywordSearch: string;
  customerVehicles: any[] = [];
  expressJobId: number;
  searchingCustomer: boolean = false;
  customerPermissions = CUSTOMER_PERMISSIONS;
  vehiclePermissions = VEHICLE_PERMISSIONS;
  minDate = moment().tz(environment.defaultTimezone);
  timeSlots$;
  nodata = true;
  noService = true;
  timeSlot = null;
  isLoadingService = true;
  protected destroy$ = new Subject<void>();

  set jobId(val) {
    this.form.controls.jobId && this.form.controls.jobId.setValue(val);
  }

  get selectedVehicleItem() {
    return this.selectedVehicle;
  }

  set selectedVehicleItem(vehicle: any) {
    this.selectedVehicle = vehicle
    if (vehicle && vehicle.cylinder) {
      this.loadJobs(vehicle.cylinder);
    } else {
      this.jobId = null;
    }

  }

  @ViewChild('timepicker', { static: true }) timepicker: NbSelectComponent<any>;
  constructor(
    private dialogService: NbDialogService,
    protected ref: NbDialogRef<EditBookingDlgComponent>,
    private bookingDetailsStore: BookingDetailsSelectedStore,
    private bookingDetailsSelectedService: BookingDetailsSelectedService,
    private bookingSelectedQuery: BookingDetailsSelectedQuery,
    private commonService: CommonService,
    private bayQuery: BayQuery,
    private customerService: CustomerService,
    private customerVehicleService: CustomerVehiclesService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private configService: ConfigService,
    private bookingService: BookingDetailsSelectedService,
    private vehicleService: VehiclesService,
    private serviceCentersQuery: ServiceCentersQuery,
    private companiesService: CompaniesService,
    private bookingDetailService: BookingDetailsSelectedService,
    private bayService: BayService,
    private toastrService: NbToastrService
  ) {
    this.form = new FormGroup({
      service: new FormControl(null, Validators.required),
      customerCtrl: new FormControl(''),
      odo: new FormControl(''),
      dateTime: new FormControl(null, Validators.required),
      jobId: new FormControl(null, Validators.required),
    });

    this.form.controls.odo.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(val => {
      if (this.selectedVehicleItem && this.selectedVehicleItem.id) {
        this.configService.setInputVehicle({ id: this.selectedVehicleItem.id, odometer: val });
      }
    });

    this.timeSlots$ = combineLatest([
      this.form.controls.service.valueChanges,
      this.form.controls.dateTime.valueChanges
    ]).pipe(
      filter(([service, date]) => {
        return !!service && !!date
      }),
      switchMap(([service, date]) => {
        const dateStr = moment(date).format('YYYY-MM-DD');
        const serviceTypeId = service.id;
        const odometer = this.form.controls.odo.value;
        this.configService.setInputVehicle({ id: this.selectedVehicleItem.id, odometer });
        return this.bookingDetailService.getAdminTimeSlots(dateStr, serviceTypeId);
      }),
      map(result => result.filter(item => item.isAvailable).map(item => {
        return item.slot
      })),
      tap(result => {
        this.nodata = !result || !result.length;
        const defaultVal = result && result[0] || null;
        // this.form.controls.timeSlot.setValue(defaultVal);
        // this.form.controls.timeSlot.updateValueAndValidity();
        setTimeout(() => {
          if (this.timepicker) {
            this.timepicker.selectedChange.emit(defaultVal);
          }
        });
      })
    )

    this.form.controls.service.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
      if (this.selectedVehicle && this.selectedVehicle.cylinder) {
        this.loadJobs(this.selectedVehicle.cylinder);
      }
    })

    this.serviceCentersQuery.serviceCenterSeleted$
      .pipe(takeUntil(this.destroy$))
      .subscribe(serviceCenterSeleted => {
        if (!serviceCenterSeleted.length) {
          return;
        }
        this.serviceTypes$ = this.commonService.getServiceTypes().pipe(
          switchMap(services => {
            const serviceCenterId = serviceCenterSeleted[0].id;
            const serviceTypes = services.map(service => service.code);
            return this.bayService.getBays(serviceCenterId, serviceTypes.join(",")).pipe(
              map(res => {
                const bays = res && res.results || [];
                const bayServiceTypes = flatten(bays.map(bay => bay.serviceTypes));
                const services = uniqBy(bayServiceTypes, 'code').filter(type => serviceTypes.includes(type.code));
                this.noService = !services.length;
                return sortBy(services, ['description']);
              })
            )
          }),
          tap(() => {
            this.isLoadingService = false;
          })
        );
      })



    this.bookingSelectedQuery.bookingDetailsSelected$
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        this.bookingDetails = val;
        this.bookingDate = moment(val.bookedAt).format('DD MMM YYYY');
        this.bookingTime = moment(val.bookedAt).format('h:mm A');
        this.isUneditableMode = this.commonService.isUneditableMode(this.bookingDetails);
        this.phoneContact = this.commonService.getUserContact(this.bookingDetails.customer.contacts, 'phone');
        this.address = this.commonService.getUserAddress(this.bookingDetails.customer.addresses[0]);
        this.email = this.commonService.getUserContact(this.bookingDetails.customer.contacts, 'email');
      });

    this.searchingCustomer = true;
    this.customerService.getFilteredCustomers('').pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.filteredCustomers = res.results;
      this.searchingCustomer = false;
    }, () => {
      this.searchingCustomer = false;
    });

    this.form.controls.customerCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(keyword => {
      if (keyword === undefined) return;
      this.customerKeywordSearch = encodeURIComponent((keyword || "").trim());
      clearTimeout(this.customerSearchTimeout);
      this.customerSearchTimeout = setTimeout(() => {
        this.searchingCustomer = true;
        this.customerService.getFilteredCustomers(keyword).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
          this.filteredCustomers = res.results;
          this.searchingCustomer = false;
        }, err => this.searchingCustomer = false);
      }, 1000);
    })

    setTimeout(() => {
      this.form.controls.dateTime.setValue(this.minDate);
    });
  }

  get serviceCode() {
    return this.form.get('service').value && this.form.get('service').value.code;
  }

  ngOnInit() {
    setTimeout(() => document.getElementById('customerCtrl').focus(), 200);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSelectKM(item) {
    this.jobId = item.id;
  }

  onChange(vehicle) {
    this.selectedVehicleItem = vehicle;
  }

  openDlg(hasBackdrop: boolean = true) {
    this.ref.close();
  }

  closeDlg() {
    this.ref.close();
  }

  selectCustomer(customer) {
    this.selectedCustomer = customer;
    this.loading = true;
    this.customerVehicleService.getCustomerVehicles(customer.id).pipe(takeUntil(this.destroy$)).subscribe(val => {
      this.show = false;
      this.loading = false;
      if (val.length > 0) {
        this.customerVehicles = val;
        this.form.controls.odo.setValue(val.odometer)
        this.selectedVehicleItem = val[0];
      }
      this.cdr.detectChanges();
      this.show = true;
    }, err => {
      this.loading = false;
    });
  }

  chooseCustomer() {
    this.selectedCustomer = null;
    this.form.controls.customerCtrl.setValue(this.customerKeywordSearch);
    this.selectedVehicleItem = null;
    this.form.controls.odo.setValue('');
    this.resetBookingVehicle();
  }

  private getBookDatetime(dateTime: Moment, timeSlot: string) {
    return `${dateTime.format('YYYY-MM-DD')}T${timeSlot}`;
  }

  createBooking(moreOptions = false) {
    if (this.form.invalid) {
      return;
    }
    const { service, odo, dateTime, jobId } = this.form.value;
    const bookingPayload: BookingPayload = {
      odometer: odo,
      vehicleId: this.selectedVehicleItem.id,
      customerId: this.selectedCustomer.id,
      serviceTypeId: service.id,
      bookedAt: this.getBookDatetime(dateTime, this.timeSlot),
      isPaylater: true,
      npQuoteId: '',
      quoteRequestKey: '',
      bookingJobs: [{ jobId }],
      isManualBooking: true
    }
    const manufactured = this.selectedVehicleItem.manufactured;
    if (manufactured) {
      Object.assign(bookingPayload, { vehicle: { manufactured } });
    }
    const isExpress = EXPRESS_CODES.includes(service.code);
    this.loading = true;
    this.bookingService.createBooking(bookingPayload, service.code, isExpress)
      .pipe(
        switchMap((bookingCreated: any) => {
          return moreOptions ? of(bookingCreated) : this.bookingService.getBookingDetails(bookingCreated.id);
        })
      ).subscribe((res: any) => {
        this.loading = false;
        if (moreOptions) {
          this.configService.updateAction('viewBooking');
          this.router.navigate(['pages/booking/booking-details/' + res.id]);
        } else {
          this.closeDlg();
          this.configService.refreshBookings.next(true);
        }
        this.toastrService.success('Create successfully!', 'Booking');
      }, err => this.loading = false);
  }

  createCustomer() {
    this.dialogService.open(EditCustomerDlgComponent, { hasBackdrop: false })
      .onClose.pipe(takeUntil(this.destroy$)).subscribe(data => {
        this.selectedCustomer = data;
      });
  }

  addVehicle() {
    this.dialogService.open(SelectVehicleComponent, { hasBackdrop: true, closeOnBackdropClick: false, context: { customerId: this.selectedCustomer.id } }).onClose.subscribe(data => {
      if (!data) return;

      const vehicleData = data.vehicle;
      const existedVehicle = this.customerVehicles.find(item => item.rego.toLowerCase() === vehicleData.rego.toLowerCase());
      if (existedVehicle) {
        this.selectedVehicleItem = existedVehicle;
      } else {
        const vehicleCreatePayload: any = {
          cylinder: vehicleData.cylinder,
          rego: vehicleData.rego,
          state: data.state,
          make: vehicleData.make,
          customerId: this.selectedCustomer.id,
          model: vehicleData.model,
          year: vehicleData.year,
          vin: vehicleData.vin,
          colour: vehicleData.colour,
          variant: vehicleData.variant,
          series: vehicleData.series,
          manufactured: vehicleData.manufactured || ''
        };

        this.loading = true;
        this.vehicleService.createVehicle(vehicleCreatePayload).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
          this.show = false;
          this.loading = false;
          this.selectedVehicleItem = data.vehicle;
          this.selectedVehicleItem.id = res.id;
          this.customerVehicles.push(this.selectedVehicleItem);
          try {
            this.cdr.detectChanges();
          } catch (e) { }
          this.show = true;
        }, () => {
          this.loading = false;
        });
      }
    });
  }

  private resetBookingVehicle() {
    const vehicle = Object.assign({}, this.bookingDetails.vehicle);
    vehicle.odometer = 0;
    vehicle.id = 0;
    this.configService.setInputVehicle(null);
    this.vehicleService.updateAnVehicle({ id: vehicle.id, odometer: vehicle.odometer });
    this.bookingDetailsStore.update({
      vehicle: vehicle
    });
  }

  private loadJobs(cylinder) {
    if (!this.serviceCode) {
      this.jobId = null;
      return;
    }

    if (this.serviceCode !== 'logbook' && this.serviceCode !== 'tyre-bonus') {
      this.loading = true;
      this.bookingDetailsSelectedService.getExpressJobs(cylinder).pipe(
        debounceTime(300),
        map(res => {
          return res.find(item => item.serviceType.code === this.serviceCode);
        }),
        catchError(err => of(null))
      ).subscribe(res => {
        this.jobId = res && res.id;
        this.loading = false;
      });
    }
  }
}

