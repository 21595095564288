import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class RepeatIconService {

  render() {
    return `<svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.90794 0.36455C8.08151 0.190983 8.36291 0.190983 8.53648 0.36455L10.3143 2.14233C10.4878 2.31589 10.4878 2.5973 10.3143 2.77087L8.53648 4.54864C8.36291 4.72221 8.08151 4.72221 7.90794 4.54864C7.73437 4.37508 7.73437 4.09367 7.90794 3.92011L9.37145 2.4566L7.90794 0.993089C7.73437 0.819523 7.73437 0.538116 7.90794 0.36455Z" fill="black"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.77776 2.90104C3.42414 2.90104 3.085 3.04152 2.83496 3.29157C2.58491 3.54161 2.44443 3.88075 2.44443 4.23438V5.12326C2.44443 5.36872 2.24545 5.56771 1.99999 5.56771C1.75453 5.56771 1.55554 5.36872 1.55554 5.12326V4.23438C1.55554 3.64501 1.78967 3.07977 2.20642 2.66303C2.62316 2.24628 3.18839 2.01215 3.77776 2.01215H9.99999C10.2454 2.01215 10.4444 2.21114 10.4444 2.4566C10.4444 2.70206 10.2454 2.90104 9.99999 2.90104H3.77776Z" fill="black"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.09203 6.58677C4.2656 6.76034 4.2656 7.04174 4.09203 7.21531L2.62853 8.67882L4.09203 10.1423C4.2656 10.3159 4.2656 10.5973 4.09203 10.7709C3.91847 10.9444 3.63706 10.9444 3.46349 10.7709L1.68572 8.99309C1.51215 8.81952 1.51215 8.53812 1.68572 8.36455L3.46349 6.58677C3.63706 6.41321 3.91847 6.41321 4.09203 6.58677Z" fill="black"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99999 5.56771C10.2454 5.56771 10.4444 5.76669 10.4444 6.01215V6.90104C10.4444 7.49041 10.2103 8.05564 9.79356 8.47239C9.37681 8.88914 8.81158 9.12326 8.22221 9.12326H1.99999C1.75453 9.12326 1.55554 8.92428 1.55554 8.67882C1.55554 8.43336 1.75453 8.23438 1.99999 8.23438H8.22221C8.57583 8.23438 8.91497 8.0939 9.16502 7.84385C9.41507 7.5938 9.55554 7.25466 9.55554 6.90104V6.01215C9.55554 5.76669 9.75453 5.56771 9.99999 5.56771Z" fill="black"/>
      </g>
      <defs>
      <clipPath id="clip0">
      <rect width="10.6667" height="10.6667" fill="white" transform="translate(0.666626 0.234375)"/>
      </clipPath>
      </defs>
      </svg>
    `;
  }
}
