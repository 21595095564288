<div class="export-preview">
  <nb-card>
    <nb-card-header>{{ title }}</nb-card-header>
    <nb-card-footer class="d-flex justify-content-end">
      <button nbButton class="secondary small mx-2" [class.disabled]="isExporting" (click)="closeDlg()">
        Cancel
      </button>
      <button nbButton class="small" [disabled]="isLoading || isExporting" [nbSpinner]="isExporting"
        nbSpinnerStatus="basic" (click)="exportCSV()">
        Download
      </button>
    </nb-card-footer>

    <nb-card-body [nbSpinner]="isLoading" nbSpinnerStatus="basic">
      <div class="export-preview-body">
        <div class="row my-1">
          <div class="col">
            <mat-chip-list #chipList class="summary-filtered-tags" *ngIf="filteredTags?.length">
              <mat-chip disableRipple class="filter-tag-title">Applied filters:</mat-chip>
              <ng-container *ngFor="let tag of filteredTags; index as i">
                <mat-chip disableRipple selectable="false">
                  {{ tag.label }}
                </mat-chip>
              </ng-container>
            </mat-chip-list>
          </div>
        </div>
        <div class="row" *ngFor="let summarySection of data">
          <div class="col">
            <div class="mb-2">
              <h5 class="title m-0 mt-3 text-uppercase">{{summarySection.title}}</h5>
            </div>
            <div *ngFor="let table of summarySection.tables">
              <h6>{{table?.title}}</h6>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" *ngFor="let head of table.headLabels">{{head}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of table.rows">
                    <td *ngFor="let col of row">{{col}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </nb-card-body>

  </nb-card>
</div>