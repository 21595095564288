import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Observable } from 'rxjs';
import { BookingDetailsSelectedService } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.service';

interface FnForAcceptBtn {
  fn: Observable<any>,
  callback: Observable<any>
}
@Component({
  selector: 'general-dialog',
  templateUrl: './general-dialog.component.html',
  styleUrls: ['./general-dialog.component.scss']
})
export class GeneralDialogComponent implements OnInit {
  @Input() text: string;
  @Input('buttonCancelTitle') buttonCancelTitle;
  @Input('buttonAcceptTitle') buttonAcceptTitle;
  @Input('fnForAcceptBtn') fnForAcceptBtn: FnForAcceptBtn;
  @Input() isSendEmit: boolean = false;
  @Output() submitClicked = new EventEmitter<any>();
  loading: boolean = false;

  constructor(
    private generalRef: NbDialogRef<GeneralDialogComponent>,
    private bookingService: BookingDetailsSelectedService,
  ) { }

  ngOnInit() {
  }

  closeDialog(value) {
    if (value == true && this.fnForAcceptBtn) {
      this.loading = true;
      this.fnForAcceptBtn.fn.subscribe(() => {
        this.loading = false;
        if (this.fnForAcceptBtn.callback) {
          this.fnForAcceptBtn.callback.subscribe();
        }
        this.generalRef.close();
      }, () => this.loading = false);
    } else if (value && this.isSendEmit) {
      this.loading = true;
      this.submitClicked.emit();
    } else {
      this.generalRef.close(value);
    }
  }
}
