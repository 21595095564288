import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { SessionStore, SessionState } from './session.store';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as storage from '../../storage';

@Injectable({ providedIn: 'root' })
export class SessionQuery extends Query<SessionState> {
  isLoggedIn$ = this.select(state => !!state.accessToken);
  accessToken$ = this.select(state => state.accessToken);
  user$ = this.select();

  constructor(
    protected store: SessionStore,
    // private jwtHelper: JwtHelperService,
  ) {
    super(store);
  }

  isAuthenticated(): boolean {
    return !!this.getValue().accessToken; // && !this.jwtHelper.isTokenExpired(this.getValue().accessToken);
  }

  updateEntityId(entityId: number) {
    this.store.update({ entityId });
    storage.saveSession(this.store.getValue());
  }

  getEntityId(){
    return this.getValue().entityId;
  }
}
