<nb-card class="service-recommendations" [nbSpinner]="loading">
  <nb-card-header class="d-flex flex-row justify-content-between align-items-center pl-3 py-3">
    <h5 class="dialog-header m-0">Service Recommendations</h5>
    <div class="dialog-header-button d-flex align-items-center">
      <ng-container *ngIf="!isUneditableMode">
        <button *ngxPermissionsOnly="vehiclePermissions.addServiceRecommendation"
          class="small secondary mr-4 d-flex align-items-center justify-content-between py-2 px-3"
          (click)="openDlg('add')">
          <nb-icon icon="plus" pack="mo-icons"></nb-icon>
          Add Recommendation
        </button>
      </ng-container>
      <nb-icon class="cursor-pointer" icon="x-icon" pack="mo-icons" (click)="closeDlg()"></nb-icon>
    </div>
  </nb-card-header>
  <nb-list *ngIf="serviceRecommendations.length > 0">
    <nb-list-item *ngFor="let service of serviceRecommendations" class="d-flex flex-column align-items-start mx-4 px-0 py-4">
      <div class="d-flex justify-content-between mb-2 w-100">
        <p class="mb-0 body-4 font-weight-600">
          BOOKING #{{ service.bookingId }}
        </p>
        <p class="mb-0 text-grey body-4 mr-4 pr-1">{{ service.time }}</p>
      </div>
      <div class="d-flex justify-content-between align-items-center w-100">
        <p class="content mb-0 text-space body-4">{{ service.content }}</p>
        <button *ngxPermissionsOnly="[
            vehiclePermissions.updateServiceRecommendation,
            vehiclePermissions.removeServiceRecommendation
          ]" class="icon-menu p-0 m-0"
          [matMenuTriggerFor]="appMenu"
          (click)="openMenu($event, service)">
          <nb-icon icon="more-vertical" pack="mo-icons"></nb-icon>
        </button>
      </div>
    </nb-list-item>
  </nb-list>
  <div class="p-4" *ngIf="serviceRecommendations.length === 0">
    <span>No data to display</span>
  </div>
</nb-card>

<mat-menu #appMenu="matMenu">
  <ng-template matMenuContent let-name="name">
    <button *ngxPermissionsOnly="vehiclePermissions.updateServiceRecommendation"
      mat-menu-item class="btn-mat-menu d-flex flex-row align-items-center"
      (click)="openDlg('edit')">
      <nb-icon class="mr-2" icon="edit" pack="mo-icons"></nb-icon>
      <p class="m-0 body-3 font-weight-600">Edit Recommendation</p>
    </button>
    <button *ngxPermissionsOnly="vehiclePermissions.removeServiceRecommendation"
      mat-menu-item class="btn-mat-menu d-flex flex-row align-items-center"
      (click)="openConfirmDlg()">
      <nb-icon class="mr-2" icon="trash-line-ins" pack="mo-icons"></nb-icon>
      <p class="m-0 body-3 font-weight-600">Delete</p>
    </button>
  </ng-template>
</mat-menu>
