import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class ExpressIconService {

  render() {
    return `<svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.10577 9.35185C7.25321 5.6737 10.7283 3 14.8372 3C19.8813 3 23.9704 7.02944 23.9704 12C23.9704 16.9706 19.8813 21 14.8372 21C11.9899 21 9.44691 19.7161 7.77193 17.7037H5.29035C7.24814 20.8789 10.7913 23 14.8372 23C21.0022 23 26 18.0751 26 12C26 5.92487 21.0022 1 14.8372 1C9.59863 1 5.20287 4.55588 4 9.35185H6.10577Z" fill="#FF5200"/>
    <path d="M15.852 6C15.852 5.44772 15.3977 5 14.8372 5C14.2767 5 13.8224 5.44772 13.8224 6V12C13.8224 12.3788 14.0396 12.725 14.3834 12.8944L18.4426 14.8944C18.9439 15.1414 19.5534 14.9412 19.8041 14.4472C20.0547 13.9532 19.8515 13.3526 19.3502 13.1056L15.852 11.382V6Z" fill="#FF5200"/>
    <path d="M8.98319 15L1 15M11 12H5.0343" stroke="#FF5200" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `;
  }
}
