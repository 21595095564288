<ng-container *ngIf="currentState == 'payment-method'">
  <nb-card class="payment-method-dialog">
    <nb-card-header>Payment Method<nb-icon icon="x-icon" pack="mo-icons" (click)="close()"></nb-icon>
    </nb-card-header>
    <nb-card-body>
      <p>Please choose payment method</p>
      <div class="group-btn pb-2">
        <button (click)="payByEFTPOS()" nbButton class="btn-eftpos">
          <nb-icon icon="pos" pack="mo-icons"></nb-icon>PAY BY EFTPOS
        </button>
        <button nbButton status="primary" (click)="openDlg(true)">
          <nb-icon icon="payment-card" pack="mo-icons"></nb-icon>ENTER CARD DETAIL (*)
        </button>
      </div>
      <div class="row">
        <div class="col"></div>
        <div class="col pl-0">
          <p class="text-sm">(*Card payment fee will be 0.93% of your total payment)</p>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-container>
<input-amount-popup (currentState)="openPaymentResultDlg($event)" *ngIf="currentState === 'input-amount-popup'">
</input-amount-popup>
<card-details-dialog [discount]="discount" *ngIf="currentState == 'card-detail'"
  (currentState)="openPaymentResultDlg($event)">
</card-details-dialog>
<payment-result-dialog *ngIf="currentState == 'payment-result'"></payment-result-dialog>