<div class="box-search-rego" [nbSpinner]="loading" nbSpinnerStatus="basic">
  <div class="mo-container pt-4 pb-4">
    <h4 class="mb-3 text-center">Search Vehicle by Rego</h4>
    <p class="mb-3 body-3 text-center">Please enter your car registration number and state:</p>
    <form #f="ngForm" (ngSubmit)="onSubmit()">
      <div>
        <input type="text" #regoSearchInput id="regoSearchInput" nbInput fullWidth placeholder="YOUR REGO"
          class="box-search-rego__input" [(ngModel)]="model.myRego" [ngModelOptions]="{standalone: true}" (keyup)="removeWhiteSpacesRego(model.myRego)">
        <p class="mb-0 error" *ngIf="errMsg">{{ errMsg }}</p>
      </div>
      <div class="mt-4">
        <div class="d-flex flex-row align-items-end w-100 mx-0">
          <div class="col-6 pl-0 pr-2">
            <div class="mo-select box">
              <label>State</label>
              <nb-select [(selected)]="model.stateSelected" class="box-search-rego__select-state pb-0" fullWidth>
                <nb-option *ngFor="let state of stateList" [value]="state.state">
                  {{ state.state }}
                </nb-option>
              </nb-select>
            </div>
          </div>
          <div class="col-6 pr-0 pl-2">
            <button nbButton fullWidth class="box-search-rego__search" status="primary"
              [disabled]="(model.myRego === null || model.myRego == '' || model.myRego.length < 2) || model.stateSelected === null">Search</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>