import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { BookingDetails } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.store';
import { BookingDetailsSelectedQuery } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.query';
import { CommonService } from '../../../@core/utils/common.service';
import { BookingDetailsSelectedService } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.service';
import { PaymentService } from '../../../@core/utils/payment.service';
import { ConfigService } from '../../../@core/akita-stores/stores/config/config.service';

@Component({
  selector: 'payment-method-dialog',
  templateUrl: './payment-method-dialog.component.html',
  styleUrls: ['./payment-method-dialog.component.scss']
})
export class PaymentMethodDialogComponent implements OnInit {
  currentState: string;
  bookingDetails: BookingDetails;
  price;
  @Input('discount') discount;

  constructor(
    protected ref: NbDialogRef<PaymentMethodDialogComponent>,
    private commonService: CommonService,
    private paymentService: PaymentService,
    private bookingDetailsQuery: BookingDetailsSelectedQuery,
    private bookingService: BookingDetailsSelectedService,
    private configService: ConfigService
  ) {
    this.currentState = this.bookingDetailsQuery.getValue().serviceType.code === 'express' ? 'payment-method' : 'input-amount-popup';
    this.price = this.commonService.calcTotalPrice(this.bookingDetailsQuery.getValue());
  }

  ngOnInit() {
  }

  openDlg() {
    this.currentState = 'card-detail';
  }

  openPaymentResultDlg($event) {
    this.currentState = $event;
  }

  close() {
    this.ref.close();
  }

  payByEFTPOS() {
    const cardPaymentProcess = {
      bookingId: this.bookingDetailsQuery.getValue().id,
      makingCreditCardPayment: true
    }
    this.configService.setCreditCardPaymentProcess(cardPaymentProcess);
    this.paymentService.submitPayment(this.bookingDetailsQuery.getValue().id, this.price.total).subscribe(val => {

      this.currentState = 'payment-result';
      this.bookingService.getBookingDetails(this.bookingDetailsQuery.getValue().id).subscribe();
    }, err => {

    });
  }
}
