import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class BellOffIconService {

    render() {
        return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6.48575 5.07153L6.48711 5.07289L23.7071 22.2929C24.0976 22.6834 24.0976 23.3166 23.7071 23.7071C23.3166 24.0976 22.6834 24.0976 22.2929 23.7071L16.5858 18H2C1.44772 18 1 17.5523 1 17C1 16.4477 1.44772 16 2 16C2.53043 16 3.03914 15.7893 3.41421 15.4142C3.78929 15.0391 4 14.5304 4 14V9.00067C4 7.96186 4.20204 6.9432 4.58437 5.99859L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893ZM6.16953 7.58374L14.5858 16H5.46411C5.81218 15.3971 6 14.7082 6 14L6 9C6 8.51886 6.05779 8.04368 6.16953 7.58374Z" fill="black"/>
        <path d="M12.0452 2.99659C10.9967 2.9887 9.96455 3.25567 9.05143 3.77092C8.57043 4.04232 7.96049 3.87242 7.68908 3.39143C7.41767 2.91043 7.58758 2.30049 8.06857 2.02908C9.28607 1.34209 10.6623 0.986123 12.0602 0.996649C13.4581 1.00717 14.8289 1.38382 16.0359 2.08907C17.2429 2.79432 18.2441 3.80354 18.9396 5.01619C19.6351 6.22883 20.0007 7.60256 20 9.00051V13C20 13.5523 19.5523 14 19 14C18.4477 14 18 13.5523 18 13V9C18.0005 7.95154 17.7263 6.92073 17.2047 6.01125C16.683 5.10176 15.9322 4.34484 15.0269 3.81591C14.1217 3.28697 13.0936 3.00449 12.0452 2.99659Z" fill="black"/>
        <path d="M9.76823 20.135C10.246 19.8579 10.8579 20.0205 11.135 20.4982C11.2229 20.6498 11.3491 20.7756 11.5009 20.863C11.6527 20.9504 11.8248 20.9965 12 20.9965C12.1752 20.9965 12.3473 20.9504 12.4991 20.863C12.6509 20.7756 12.7771 20.6498 12.865 20.4982C13.1421 20.0205 13.754 19.8579 14.2318 20.135C14.7095 20.4121 14.8721 21.024 14.595 21.5018C14.3313 21.9564 13.9528 22.3337 13.4973 22.5961C13.0419 22.8584 12.5256 22.9965 12 22.9965C11.4744 22.9965 10.9581 22.8584 10.5027 22.5961C10.0472 22.3337 9.66872 21.9564 9.405 21.5018C9.12788 21.024 9.2905 20.4121 9.76823 20.135Z" fill="black"/>
        </svg>                                
        `;
    }
}
