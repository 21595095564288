import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class RefreshAltIconService {

    render() {
        return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M23 3C23.5523 3 24 3.44772 24 4V10C24 10.5523 23.5523 11 23 11H17C16.4477 11 16 10.5523 16 10C16 9.44772 16.4477 9 17 9H22V4C22 3.44772 22.4477 3 23 3Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.01877e-06 14C3.01877e-06 13.4477 0.447718 13 1 13H7C7.55229 13 8 13.4477 8 14C8 14.5523 7.55229 15 7 15H2V20C2 20.5523 1.55229 21 1 21C0.447718 21 3.01877e-06 20.5523 3.01877e-06 20V14Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2173 4.31503C12.9192 3.93953 11.547 3.89901 10.229 4.19724C8.91091 4.49547 7.68987 5.12273 6.6798 6.02049C5.66973 6.91825 4.90353 8.05726 4.45272 9.33123C4.26848 9.85188 3.69705 10.1246 3.17641 9.94035C2.65576 9.75611 2.38305 9.18469 2.56729 8.66404C3.13081 7.07158 4.08855 5.64782 5.35114 4.52561C6.61373 3.40341 8.14003 2.61933 9.78761 2.24655C11.4352 1.87376 13.1503 1.92442 14.7731 2.39379C16.3906 2.86166 17.8637 3.7304 19.0559 4.91932L23.6848 9.26888C24.0873 9.64707 24.1069 10.2799 23.7288 10.6824C23.3506 11.0849 22.7177 11.1046 22.3152 10.7264L17.6752 6.36639C17.6676 6.35923 17.6601 6.35196 17.6527 6.34456C16.6974 5.38875 15.5155 4.69053 14.2173 4.31503ZM0.27125 13.3129C0.649442 12.9104 1.2823 12.8907 1.68478 13.2689L6.32478 17.6289C6.3324 17.636 6.3399 17.6433 6.34729 17.6507C7.30262 18.6065 8.48451 19.3047 9.78267 19.6802C11.0808 20.0557 12.453 20.0963 13.771 19.798C15.0891 19.4998 16.3101 18.8725 17.3202 17.9748C18.3303 17.077 19.0965 15.938 19.5473 14.664C19.7315 14.1434 20.303 13.8707 20.8236 14.0549C21.3442 14.2392 21.617 14.8106 21.4327 15.3312C20.8692 16.9237 19.9115 18.3474 18.6489 19.4697C17.3863 20.5919 15.86 21.3759 14.2124 21.7487C12.5648 22.1215 10.8497 22.0709 9.22695 21.6015C7.6094 21.1336 6.13627 20.2649 4.94411 19.0759L0.315226 14.7264C-0.0872528 14.3482 -0.106942 13.7153 0.27125 13.3129Z" fill="black"/>
        </g>
        <defs>
        <clipPath id="clip0">
        <path d="M0 0H24V24H0V0Z" fill="white"/>
        </clipPath>
        </defs>
        </svg>        
        `;
    }
}
