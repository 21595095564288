import { Component, OnInit, Input } from '@angular/core';
import { BookingEnum } from '../../../@core/akita-stores/stores/config/config.store';

@Component({
  selector: 'receipt-noted',
  templateUrl: './receipt-noted.component.html',
  styleUrls: ['./receipt-noted.component.scss']
})
export class ReceiptNotedComponent implements OnInit {
  @Input() serviceType: string;
  bookingEnum = BookingEnum;


  constructor() { }

  ngOnInit() {
  }

}
