import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class LockIconService {

    render(type = null) {
        if (type == 'secure') {
            return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 12C4.44772 12 4 12.4477 4 13V20C4 20.5523 4.44772 21 5 21H19C19.5523 21 20 20.5523 20 20V13C20 12.4477 19.5523 12 19 12H5ZM2 13C2 11.3431 3.34315 10 5 10H19C20.6569 10 22 11.3431 22 13V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V13Z" fill="#5A5758"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7V11C8 11.5523 7.55228 12 7 12C6.44772 12 6 11.5523 6 11V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V11C18 11.5523 17.5523 12 17 12C16.4477 12 16 11.5523 16 11V7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3Z" fill="#5A5758"/>
                </svg>
            `;
        }

        return `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.3335 8.00008C2.96531 8.00008 2.66683 8.29856 2.66683 8.66675V13.3334C2.66683 13.7016 2.96531 14.0001 3.3335 14.0001H12.6668C13.035 14.0001 13.3335 13.7016 13.3335 13.3334V8.66675C13.3335 8.29856 13.035 8.00008 12.6668 8.00008H3.3335ZM1.3335 8.66675C1.3335 7.56218 2.22893 6.66675 3.3335 6.66675H12.6668C13.7714 6.66675 14.6668 7.56218 14.6668 8.66675V13.3334C14.6668 14.438 13.7714 15.3334 12.6668 15.3334H3.3335C2.22893 15.3334 1.3335 14.438 1.3335 13.3334V8.66675Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 2.00008C7.29276 2.00008 6.61448 2.28103 6.11438 2.78113C5.61428 3.28123 5.33333 3.9595 5.33333 4.66675V7.33342C5.33333 7.7016 5.03486 8.00008 4.66667 8.00008C4.29848 8.00008 4 7.7016 4 7.33342V4.66675C4 3.60588 4.42143 2.58847 5.17157 1.83832C5.92172 1.08818 6.93913 0.666748 8 0.666748C9.06087 0.666748 10.0783 1.08818 10.8284 1.83832C11.5786 2.58847 12 3.60588 12 4.66675V7.33342C12 7.7016 11.7015 8.00008 11.3333 8.00008C10.9651 8.00008 10.6667 7.7016 10.6667 7.33342V4.66675C10.6667 3.9595 10.3857 3.28123 9.88562 2.78113C9.38552 2.28103 8.70724 2.00008 8 2.00008Z" fill="white"/>
            </svg>
        `;
    }
}
