import { Component, Input, OnInit } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import { SelectVehicleComponent } from "../../../@theme/components";
import { BookingSummaryData, BookingTypesSummaryData, KpiReportsService, RevenueSummaryData, TrendLeadsSummaryData } from "../../../@core/akita-stores/stores/kpi-reports/kpi-reports.service";
import { delay, takeUntil, tap } from "rxjs/operators";
import { Subject } from "rxjs";
import * as _ from 'lodash';
import * as moment from "moment";
import { C } from "@angular/cdk/keycodes";

@Component({
  selector: "export-preview",
  templateUrl: "./kpi-report-export-preview.component.html",
  styleUrls: ["./kpi-report-export-preview.component.scss"],
})
export class KpiReportExportPreviewComponent implements OnInit {
  // Component logic and properties go here

  @Input("title") title;
  @Input("filter") filter;
  @Input("filteredTags") filteredTags;

  isLoading = true;
  isExporting = false;
  data = [] as any;

  commonLabels = {
    commonSummary: "Common Summary",
    valueHeadLabel: "Value",
    currencyValueHeadLabel: "Value ($)",
    percentGrowthHeadLabel: "Growth (%)",
    month: "Month",
  }

  labels = {
    commonBookingSummarySectionTitle: "BOOKING SUMMARY",
    commonBookingSummaryTitle: "Common Booking Summary",
    commonBookingSummaryHead1: this.commonLabels.commonSummary,
    commonBookingSummaryHead2: this.commonLabels.valueHeadLabel,
    commonBookingSummaryHead3: this.commonLabels.percentGrowthHeadLabel,

    bookingSummaryByBrandTitle: "Booking Summary by Brand",
    bookingSummaryByBrandHead1: "Brand",
    bookingSummaryByBrandHead2: this.commonLabels.valueHeadLabel,
    bookingSummaryByBrandHead3: this.commonLabels.percentGrowthHeadLabel,

    bookingSummaryByStateTitle: "Booking Summary by State",
    bookingSummaryByStateHead1: "State",
    bookingSummaryByStateHead2: this.commonLabels.valueHeadLabel,
    bookingSummaryByStateHead3: "Distribution Rate (%)",

    bookingTypesSummarySectionTitle: "BOOKING TYPES SUMMARY",
    bookingTypesSummaryTitle: "Booking Types Summary",
    bookingTypesSummaryHead1: this.commonLabels.commonSummary,
    bookingTypesSummaryHead2: this.commonLabels.valueHeadLabel,
    bookingTypesSummaryHead3: this.commonLabels.percentGrowthHeadLabel,

    servicesSummaryTitle: "Services Summary",
    servicesSummaryHead1: "Services",
    servicesSummaryHead2: "Number Of Bookings",
    servicesSummaryHead3: "Distribution Rate (%)",
    servicesSummaryHead4: "Revenue ($)",

    additionalSpecialOffersSummaryTitle: "Additional/Special Offers Summary",
    additionalSpecialOffersSummaryHead1: "Additional/Special Offers",
    additionalSpecialOffersSummaryHead2: "Number Of Bookings",
    additionalSpecialOffersSummaryHead3: "Penetration Rate (%)",
    additionalSpecialOffersSummaryHead4: "Revenue ($)",

    revenueSummarySectionTitle: "REVENUE SUMMARY",
    revenueSummaryTitle: "Revenue Summary",
    revenueSummaryHead1: this.commonLabels.commonSummary,
    revenueSummaryHead2: this.commonLabels.currencyValueHeadLabel,
    revenueSummaryHead3: this.commonLabels.percentGrowthHeadLabel,

    trendLeadsSummarySectionTitle: "TREND LEADS SUMMARY",
    trendLeadsSummaryTitle: "Trend Leads Summary",
    trendLeadsSummaryHead1: this.commonLabels.month,
    trendLeadsSummaryHead2: "Total Number of Leads",

    // totalNumberOfBookings: "Total Number of Bookings",
    // totalRevenueOfBookingServices: "Total Revenue of Booking Services",
    // totalRevenue: "Total Revenue",
    // totalRevenuePerYear: "Total Revenue Per Year",
    // totalNumberOfLeads: "Total Number of Leads",
    // totalNumberOfLeadsPerYear: "Total Number of Leads Per Year",
  }


  protected destroy$ = new Subject<void>();

  constructor(
    protected ref: NbDialogRef<SelectVehicleComponent>,
    private kpiReportsService: KpiReportsService
  ) { }

  ngOnInit() {
    this.kpiReportsService
      .exportSummary(this.filter, false)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          const {
            bookingSummary,
            bookingTypesSummary,
            revenueSummary,
            trendLeadsSummary,
          } = res as {
            bookingSummary: BookingSummaryData;
            bookingTypesSummary: BookingTypesSummaryData;
            revenueSummary: RevenueSummaryData;
            trendLeadsSummary: TrendLeadsSummaryData;
          };
          try {
            this.data = [
              {
                title: _.get(this.labels, 'commonBookingSummarySectionTitle'),
                tables: [
                  {
                    title: _.get(this.labels, 'commonBookingSummaryTitle'),
                    headLabels: [_.get(this.labels, 'commonBookingSummaryHead1'), _.get(this.labels, 'commonBookingSummaryHead2'), _.get(this.labels, 'commonBookingSummaryHead3')],
                    rows: [
                      [_.get(bookingSummary, 'totalBooking.label'), _.get(bookingSummary, 'totalBooking.value'), _.get(bookingSummary, 'totalBooking.growth')],
                    ]
                  },
                  {
                    title: _.get(this.labels, 'bookingSummaryByBrandTitle'),
                    headLabels: [_.get(this.labels, 'bookingSummaryByBrandHead1'), _.get(this.labels, 'bookingSummaryByBrandHead2'), _.get(this.labels, 'bookingSummaryByBrandHead3')],
                    rows: _.get(bookingSummary, 'brandBookingSummary', []).map((brand, index) => {
                      if (!brand) brand = {}
                      return [brand.label, brand.value, brand.growth]
                    })
                  },
                  {
                    title: _.get(this.labels, 'bookingSummaryByStateTitle'),
                    headLabels: [_.get(this.labels, 'bookingSummaryByStateHead1'), _.get(this.labels, 'bookingSummaryByStateHead2'), _.get(this.labels, 'bookingSummaryByStateHead3')],
                    rows: _.get(bookingSummary, 'stateSummaryChart.labels', []).map((label, index) => {
                      return [label, _.get(bookingSummary, `stateSummaryChart.datasets.numberOfBookings.${index}`, 0), _.get(bookingSummary, `stateSummaryChart.datasets.distributionRate.${index}`, 0)]
                    })
                  },
                ]
              },
              {
                title: _.get(this.labels, 'bookingTypesSummarySectionTitle'),
                tables: [
                  {
                    title: _.get(this.labels, 'bookingTypesSummaryTitle'),
                    headLabels: [_.get(this.labels, 'bookingTypesSummaryHead1'), _.get(this.labels, 'bookingTypesSummaryHead2'), _.get(this.labels, 'bookingTypesSummaryHead3')],
                    rows: [
                      [_.get(bookingTypesSummary, 'totalBookingServiceRevenue.label'),
                        new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          maximumFractionDigits: 0,
                        }).format(_.get(bookingTypesSummary, 'totalBookingServiceRevenue.value', 0)), _.get(bookingTypesSummary, 'totalBookingServiceRevenue.growth')],
                      [_.get(bookingTypesSummary, 'totalASR.label'), _.get(bookingTypesSummary, 'totalASR.value'), _.get(bookingTypesSummary, 'totalASR.growth')],
                      [
                        _.get(bookingTypesSummary, 'totalASRRevenue.label'),
                        new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          maximumFractionDigits: 0,
                        }).format(_.get(bookingTypesSummary, 'totalASRRevenue.value', 0)),
                        _.get(bookingTypesSummary, 'totalASRRevenue.growth')
                      ],
                      [_.get(bookingTypesSummary, 'totalASRPenetrationRate.label'), _.get(bookingTypesSummary, 'totalASRPenetrationRate.value', 0) + '%', _.get(bookingTypesSummary, 'totalASRPenetrationRate.growth')],
                    ]
                  },
                  {
                    title: _.get(this.labels, 'servicesSummaryTitle'),
                    headLabels: [_.get(this.labels, 'servicesSummaryHead1'), _.get(this.labels, 'servicesSummaryHead2'), _.get(this.labels, 'servicesSummaryHead3'), _.get(this.labels, 'servicesSummaryHead4')],
                    rows: _.get(bookingTypesSummary, 'servicesSummaryChart.labels', []).map((label, index) => {
                      return [label, _.get(bookingTypesSummary, `servicesSummaryChart.datasets.numberOfBookings.${index}`, 0), _.get(bookingTypesSummary, `servicesSummaryChart.datasets.penetrationRate.${index}`, 0), _.get(bookingTypesSummary, `servicesSummaryChart.datasets.revenue.${index}`, 0)]
                    })
                  },
                  {
                    title: _.get(this.labels, 'additionalSpecialOffersSummaryTitle'),
                    headLabels: [_.get(this.labels, 'additionalSpecialOffersSummaryHead1', 0), _.get(this.labels, 'additionalSpecialOffersSummaryHead2', 0), _.get(this.labels, 'additionalSpecialOffersSummaryHead3', 0), _.get(this.labels, `additionalSpecialOffersSummaryHead4`, 0)],
                    rows: _.get(bookingTypesSummary, 'brandSummaryChart.labels', []).map((label, index) => {
                      return [label, _.get(bookingTypesSummary, `brandSummaryChart.datasets.numberOfBookings.${index}`, 0), _.get(bookingTypesSummary, `brandSummaryChart.datasets.penetrationRate.${index}`, 0), _.get(bookingTypesSummary, `brandSummaryChart.datasets.revenue.${index}`, 0)]
                    })
                  }
                ]
              },
              {
                title: _.get(this.labels, 'revenueSummarySectionTitle'),
                tables: [
                  {
                    title: '',
                    headLabels: [_.get(this.labels, 'revenueSummaryHead1'), _.get(this.labels, 'revenueSummaryHead2'), _.get(this.labels, 'revenueSummaryHead3')],
                    rows: [
                      [_.get(revenueSummary, 'totalRevenue.label') || 'Total Revenue', _.get(revenueSummary, 'totalRevenue.value', 0), _.get(revenueSummary, 'totalRevenue.growth', 0)],
                      [_.get(revenueSummary, 'totalRevenuePerYear.label') || 'Total Revenue Per Year', _.get(revenueSummary, 'totalRevenuePerYear.value', 0), _.get(revenueSummary, 'totalRevenuePerYear.growth', 'n/a') || 'n/a'],
                    ]
                  },
                ]
              },
              {
                title: _.get(this.labels, 'trendLeadsSummarySectionTitle'),
                tables: [
                  {
                    title: '',
                    headLabels: [_.get(this.labels, 'trendLeadsSummaryHead1'), _.get(this.labels, 'trendLeadsSummaryHead2'), ..._.get(trendLeadsSummary, 'leadsSummaryChart.brandLeadDatesets', []).map((brand) => brand.label)],
                    rows: _.get(trendLeadsSummary, 'leadsSummaryChart.labels', []).map((label, index) => {
                      return [label, _.get(trendLeadsSummary, `leadsSummaryChart.totalLeadDatesets.${index}`, 0), ..._.get(trendLeadsSummary, 'leadsSummaryChart.brandLeadDatesets', []).map((brand) => _.get(brand, `data.${index}`, 0))]
                    })
                  },
                ]
              },
            ]
          } catch (err) {
            this.data = []
          }
          this.isLoading = false;
          this.isExporting = false;
        },
        (err) => {
          this.data = []
          this.isExporting = false;
        }
      );
  }

  download = function (data) {

    const blob = new Blob([data], { type: 'text/csv' });

    const url = window.URL.createObjectURL(blob)

    const a = document.createElement('a')

    a.setAttribute('href', url)

    const nowDate = moment().format('DD-MM-YYYY');

    a.setAttribute('download', `KPI Reports dashboard ${nowDate}.csv`);

    a.click()
  }

  csvmaker = function (data) {
    const csvRows = [];
    csvRows.push('Applied filters');
    csvRows.push(["Brands", "States", "Service Centers", "Booking Types"].join(','));
    const filterValues = this.filteredTags.reduce((acc, curr) => {
      if (curr.type === 'brands') acc[0].push(curr);
      if (curr.type === 'states') acc[1].push(curr);
      if (curr.type === 'serviceCenters') acc[2].push(curr);
      if (curr.type === 'bookingTypes') acc[3].push(curr);
      return acc;
    }, [[], [], [], []])
    
    csvRows.push(filterValues.map((filterItem) => {
      if (filterItem[0] && !filterItem[0].removable) return 'All'; // if not removable, it means it's all
      return `"${filterItem.map(i => i.label).join(', ')}"`
    }).join(','));

    data.forEach((summarySection) => {
      csvRows.push('');
      csvRows.push(summarySection.title);
      summarySection.tables.forEach((table) => {
        csvRows.push(table.title);
        csvRows.push(table.headLabels.join(','));
        table.rows.forEach((row) => {
          csvRows.push(row.join(','));
        });
        csvRows.push('');
      });
    }
    );

    return csvRows.join('\n')
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSelectFromResults() {
    this.ref.close({});
  }
  exportCSV() {
    // this.isExporting = true;
    // this.kpiReportsService
    //   .exportSummary(this.filter, true)
    //   .pipe(delay(4000), takeUntil(this.destroy$))
    //   .subscribe(
    //     (res) => { 
    //       this.isExporting = false;
    //       this.closeDlg();
    //     },
    //     (err) => {
    //       this.isExporting = false;
    //     }
    //   );

    this.isExporting = true;
    const csvdata = this.csvmaker(this.data);
    this.download(csvdata);

    setTimeout(() => {
      this.isExporting = false;
      this.closeDlg();
    }, 1000);
  }
  closeDlg() {
    this.ref.close();
  }
}
