import { StoreConfig, Store } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface CustomerState {
  details: any;
  states: any[];
  stateSelected: string;
  suburbs: any[];
  suburbSelected: string;
  customerDetails: CustomerDetails[];
  currentCustomerDetails: any;
}

export interface CustomerDetails {
  id: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  gender?: string;
  dateBirth?: string;
  streetAddress?: string;
  postCode?: string;
  suburb?: string;
  state?: string;
}

export function createInitialState(): CustomerState {
  return {
    details: {},
    states: [],
    stateSelected: '',
    suburbs: [],
    suburbSelected: '',
    customerDetails: [],
    currentCustomerDetails: {},
  }
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'customer'})
export class CustomerStore extends Store<CustomerState> {
  constructor() {
    super(createInitialState());
  }

  getStates() {
    return this.getValue().states;
  }

  updateStates(states: any[]) {
    this.update({ states });
  }

  updateStateSelected(stateSelected: string) {
    this.update({ stateSelected });
  }

  getSubrubs() {
    return this.getValue().suburbs;
  }

  updateSubrubs(suburbs: any[]) {
    this.update({ suburbs });
  }

  updateSubrubSelected(suburbSelected: string) {
    this.update({ suburbSelected });
  }

  getCustomerDetails() {
    return this.getValue().customerDetails;
  }

  updateCustomerDetails(customerDetails: CustomerDetails[]) {
    return this.update({ customerDetails });
  }

  getCurrentCustomerDetails() {
    return this.getValue().currentCustomerDetails;
  }

  updateCurrentCustomerDetails(currentCustomerDetails) {
    return this.update({ currentCustomerDetails });
  }
}