<div *ngIf="serviceProcess && serviceProcess.length > 0" class="process-status" [class.horizontal]="horizontal">
  <label class="body-2 font-weight-600 mb-3" *ngIf="isShownTitle">Service Progress</label>
  <table *ngFor="let process of serviceProcess; let i = index; last as isLast">
    <tr>
      <td class="process-icon">
        <nb-icon *ngIf="!isLast && !process.createdUtc" icon="circle" pack="mo-icons" class="mt-minus-2px"></nb-icon>
        <nb-icon *ngIf="i === indexLastActiveIcon && lastActiveIcon && !isLast && process.createdUtc" icon="{{ lastActiveIcon }}" pack="mo-icons" class="mt-minus-2px status" [ngClass]="lastActiveIcon"></nb-icon>
        <nb-icon *ngIf="(i !== indexLastActiveIcon || !lastActiveIcon) && !isLast && process.createdUtc" icon="circle-check-ready" pack="mo-icons" class="mt-minus-2px"></nb-icon>
        <nb-icon *ngIf="isLast" icon="flag" [ngClass]="{'active': process.createdUtc}" pack="mo-icons" class="mt-minus-2px"></nb-icon>
      </td>
      <td>
        <div class="process-title">
          <p class="status-name text-truncate font-weight-600" [ngClass]="{'active': process.createdUtc}">{{process.bookingStatus.description}}</p>
          <div class="line" *ngIf="horizontal"></div>
        </div>
      </td>
    </tr>
    <tr>
      <td class="col-line">
        <div class="line" *ngIf="!horizontal"></div>
      </td>
      <td>
        <p class="col-time font-weight-600 pb-1" *ngIf="!isReception"
          [ngClass]="{'green': process.color === 'green', 'red': process.color === 'red', 'mb-2': !isLast, 'mb-0': isLast}">{{ process.time }}</p>
      </td>
    </tr>
  </table>
</div>