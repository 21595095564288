import { ExtraOptions, RouterModule, Routes, PreloadAllModules, UrlSegment } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import { AuthGuardService } from './@core/guards/auth-guard.service';
import { NoAuthGuard } from './@core/guards/no-auth.guard';
import { NoPermissionsComponent } from './pages/no-permissions/no-permissions.component';
import * as _ from 'lodash';

const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'auth',
    loadChildren: './auth/auth.module#NgxAuthModule',
    canActivate: [NoAuthGuard]
    // component: NbAuthComponent,
    // children: [
    //   {
    //     path: '',
    //     component: NbLoginComponent,
    //   },
    //   {
    //     path: 'login',
    //     component: NbLoginComponent,
    //   },
    //   {
    //     path: 'register',
    //     component: NbRegisterComponent,
    //   },
    //   {
    //     path: 'logout',
    //     component: NbLogoutComponent,
    //   },
    //   {
    //     path: 'request-password',
    //     component: NbRequestPasswordComponent,
    //   },
    //   {
    //     path: 'reset-password',
    //     component: NbResetPasswordComponent,
    //   },
    // ],
  },
  // {
  //   path: 'pages/customers',
  //   loadChildren: () => import('./pages/customers/customers.module')
  //     .then(m => m.CustomersModule),
  // },
  {
    redirectTo: 'pages/booking/:bookingUuid',
    matcher: redirectMatcher,
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' }
];


/**
 * This will match the '/booking/{:bookingUuid}' url and extract the bookingUuid from the url to redirectTo url
 */
export function redirectMatcher(url: UrlSegment[]) {
  const pathStart = _.get(url, '[0].path', '');
  if (pathStart.includes('booking')) {
    const bookingUuid = _.get(url, '[1].path', '');
    const pathMore = _.get(url, '[2].path', ''); // check if there is more path after bookingUuid
    if (bookingUuid && !pathMore) {
      return {
        consumed: url,
        posParams: {
          bookingUuid: new UrlSegment(bookingUuid, {}),
        }
      }
    }
  }
  return null;
}

const config: ExtraOptions = {
  useHash: false,
  onSameUrlNavigation: 'reload',
  preloadingStrategy: PreloadAllModules
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
