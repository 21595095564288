import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class UnlockIconService {

    render() {
        return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 23H5C3.3 23 2 21.7 2 20V13C2 11.3 3.3 10 5 10H19C20.7 10 22 11.3 22 13V20C22 21.7 20.7 23 19 23ZM5 12C4.4 12 4 12.4 4 13V20C4 20.6 4.4 21 5 21H19C19.6 21 20 20.6 20 20V13C20 12.4 19.6 12 19 12H5Z" fill="black"/>
        <path d="M7 12C6.4 12 6 11.6 6 11V7C6 5.4 6.6 3.9 7.8 2.8C8.9 1.6 10.4 1 12 1C14.8 1 17.3 3 17.9 5.8C18 6.3 17.6 6.9 17.1 7C16.6 7.1 16 6.8 15.9 6.2C15.5 4.3 13.9 3 12 3C10.9 3 9.9 3.4 9.2 4.2C8.4 4.9 8 5.9 8 7V11C8 11.6 7.6 12 7 12Z" fill="black"/>
        </svg>                                
        `;
    }
}
