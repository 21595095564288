import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class DirectIconService {

    render() {
        return `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7197 2.46967C11.0126 2.17678 11.4874 2.17678 11.7803 2.46967L15.5303 6.21967C15.8232 6.51256 15.8232 6.98744 15.5303 7.28033L11.7803 11.0303C11.4874 11.3232 11.0126 11.3232 10.7197 11.0303C10.4268 10.7374 10.4268 10.2626 10.7197 9.96967L13.9393 6.75L10.7197 3.53033C10.4268 3.23744 10.4268 2.76256 10.7197 2.46967Z" fill="var(--block-colour)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 7.5C5.40326 7.5 4.83097 7.73705 4.40901 8.15901C3.98705 8.58097 3.75 9.15326 3.75 9.75V15C3.75 15.4142 3.41421 15.75 3 15.75C2.58579 15.75 2.25 15.4142 2.25 15V9.75C2.25 8.75544 2.64509 7.80161 3.34835 7.09835C4.05161 6.39509 5.00544 6 6 6H15C15.4142 6 15.75 6.33579 15.75 6.75C15.75 7.16421 15.4142 7.5 15 7.5H6Z" fill="var(--block-colour)"/>
        </svg>
        `;
    }
}
