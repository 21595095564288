import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SofaServiceIconService {

  render() {
    return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 13.6607C24 12.3431 22.9281 11.2712 21.6105 11.2712C21.2602 11.2712 20.9277 11.3476 20.6277 11.4836V7H3.37234V11.4836C3.07234 11.3476 2.73975 11.2713 2.38949 11.2713C1.07189 11.2713 0 12.3432 0 13.6608V21.006H1.68615V22.3818H3.09283V21.006H20.9072V22.3818H22.3139V21.006H24V13.6607ZM19.221 14.29C18.8359 14.0786 18.3942 13.9581 17.9247 13.9581H6.07523C5.60582 13.9581 5.16408 14.0786 4.77897 14.29V8.40664H19.2209V14.29H19.221ZM19.2149 16.5372H4.78507C4.84762 15.8804 5.40228 15.3648 6.07523 15.3648H17.9247C18.5977 15.3648 19.1524 15.8804 19.2149 16.5372ZM3.37234 17.9439H20.6277V13.6607C20.6277 13.1188 21.0686 12.6779 21.6105 12.6779C22.1525 12.6779 22.5933 13.1188 22.5933 13.6607V19.5992H1.40668V13.6607C1.40668 13.1188 1.84759 12.6779 2.38953 12.6779C2.93148 12.6779 3.37234 13.1188 3.37234 13.6607V17.9439Z" fill="#222222"/>
      </svg>
    `;
  }
}
