import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class DownloadCloudIconService {

    render() {
        return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.29289 16.2929C7.68342 15.9024 8.31658 15.9024 8.70711 16.2929L12 19.5858L15.2929 16.2929C15.6834 15.9024 16.3166 15.9024 16.7071 16.2929C17.0976 16.6834 17.0976 17.3166 16.7071 17.7071L12.7071 21.7071C12.3166 22.0976 11.6834 22.0976 11.2929 21.7071L7.29289 17.7071C6.90237 17.3166 6.90237 16.6834 7.29289 16.2929Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 11C12.5523 11 13 11.4477 13 12V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V12C11 11.4477 11.4477 11 12 11Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.66752 1.99416C10.028 1.94523 11.3819 2.20558 12.6272 2.7556C13.8725 3.30562 14.9768 4.13098 15.8569 5.16954C16.5674 6.0079 17.1169 6.96755 17.4804 7.99999L17.9992 7.99999C19.2743 7.99916 20.5173 8.40459 21.5464 9.15747C22.5758 9.9105 23.3384 10.972 23.7236 12.1878C24.1088 13.4036 24.0966 14.7106 23.6887 15.919C23.2808 17.1274 22.4984 18.1744 21.4552 18.908C21.0034 19.2257 20.3796 19.117 20.0619 18.6652C19.7442 18.2134 19.8529 17.5897 20.3047 17.272C21.0002 16.7829 21.5218 16.0849 21.7937 15.2793C22.0657 14.4737 22.0738 13.6024 21.817 12.7919C21.5602 11.9813 21.0518 11.2737 20.3656 10.7716C19.6793 10.2696 18.851 9.99933 18.0007 9.99999H16.7399C16.2834 9.99999 15.8848 9.69077 15.7713 9.24854C15.5081 8.22293 15.0157 7.27038 14.3312 6.46261C13.6466 5.65484 12.7877 5.01289 11.8191 4.5851C10.8505 4.1573 9.79755 3.95481 8.7394 3.99286C7.68124 4.03092 6.64551 4.30852 5.71017 4.80479C4.77483 5.30105 3.96426 6.00303 3.33948 6.85789C2.71469 7.71275 2.29198 8.6982 2.10317 9.74007C1.91435 10.7819 1.96435 11.8531 2.24939 12.8728C2.53444 13.8926 3.04711 14.8343 3.74881 15.6273C4.11481 16.0409 4.07623 16.6729 3.66264 17.0389C3.24904 17.4049 2.61706 17.3663 2.25105 16.9527C1.34887 15.9332 0.689722 14.7223 0.323231 13.4112C-0.0432598 12.1001 -0.107542 10.723 0.135222 9.38342C0.377987 8.04388 0.921473 6.77686 1.72476 5.67776C2.52806 4.57865 3.57022 3.67611 4.7728 3.03806C5.97538 2.40001 7.30704 2.04308 8.66752 1.99416Z" fill="black"/>
        </g>
        <defs>
        <clipPath id="clip0">
        <path d="M0 0H24V24H0V0Z" fill="white"/>
        </clipPath>
        </defs>
        </svg>                               
        `;
    }
}
