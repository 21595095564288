import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class CloseCircleIconService {

    render() {
        return `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.9987 2.50016C5.85656 2.50016 2.4987 5.85803 2.4987 10.0002C2.4987 14.1423 5.85656 17.5002 9.9987 17.5002C14.1408 17.5002 17.4987 14.1423 17.4987 10.0002C17.4987 5.85803 14.1408 2.50016 9.9987 2.50016ZM0.832031 10.0002C0.832031 4.93755 4.93609 0.833496 9.9987 0.833496C15.0613 0.833496 19.1654 4.93755 19.1654 10.0002C19.1654 15.0628 15.0613 19.1668 9.9987 19.1668C4.93609 19.1668 0.832031 15.0628 0.832031 10.0002Z" fill="#BEBEBE"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.088 6.91091C13.4134 7.23634 13.4134 7.76398 13.088 8.08942L8.08795 13.0894C7.76252 13.4149 7.23488 13.4149 6.90944 13.0894C6.584 12.764 6.584 12.2363 6.90944 11.9109L11.9094 6.91091C12.2349 6.58547 12.7625 6.58547 13.088 6.91091Z" fill="#BEBEBE"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.90944 6.91091C7.23488 6.58547 7.76252 6.58547 8.08795 6.91091L13.088 11.9109C13.4134 12.2363 13.4134 12.764 13.088 13.0894C12.7625 13.4149 12.2349 13.4149 11.9094 13.0894L6.90944 8.08942C6.584 7.76398 6.584 7.23634 6.90944 6.91091Z" fill="#BEBEBE"/>
        </svg>
        `;
    }
}