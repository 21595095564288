const SESSION_KEY = 'session';
const TIMEZONE_KEY = 'timezone';
const CENTRE_KEY = 'centre';
const MO_ADMIN = 'MO:Admin';
const ENTITY_KEY = 'entity';
const ENTITY_ID = 'entityId';
const RO_KEY = 'repairOrders';

export function getSession() {
  const session = localStorage.getItem(SESSION_KEY);
  return session ? JSON.parse(session) : {};
}

export function saveSession( session ) {
  localStorage.setItem(SESSION_KEY, JSON.stringify(session));
}

export function clearSession() {
  localStorage.removeItem(SESSION_KEY);
}

export function getTimezone() {
  const timezone = localStorage.getItem(TIMEZONE_KEY);
  return timezone ? JSON.parse(timezone) : null;
}

export function saveTimezone( timezone ) {
  localStorage.setItem(TIMEZONE_KEY, JSON.stringify(timezone));
}

export function getCurrentCentre() {
  const centre = localStorage.getItem(CENTRE_KEY);
  return centre ? JSON.parse(centre) : null;
}

export function saveCurrentCentre( centre ) {
  localStorage.setItem(CENTRE_KEY, JSON.stringify(centre));
}

export function clearStore() {
  localStorage.removeItem(MO_ADMIN);
}

export function getCurrentEntity() {
  const entity = localStorage.getItem(ENTITY_KEY);
  return entity && entity !== 'undefined' ? JSON.parse(entity) : null;
}

export function saveCurrentEntity(entity) {
  localStorage.setItem(ENTITY_KEY, JSON.stringify(entity));
}

export function saveEntityId(entityId) {
  localStorage.setItem(ENTITY_ID, entityId);
}

export function getEntityId() {
  return localStorage.getItem(ENTITY_ID) || null;
}

export function getRepairOrderTime(repairOrderId) {
  let allROs = localStorage.getItem(RO_KEY);
  if (allROs) {
    allROs = JSON.parse(allROs);
    return allROs[repairOrderId] ? allROs[repairOrderId] : null;
  }
  return null;
}

export function saveRepairOrderTime(repairOrders) {
  localStorage.setItem(RO_KEY, JSON.stringify(repairOrders));
}