import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ServiceCentersStore, ServiceCentersState } from './service-centers.store';
import { ServiceCentersService } from './service-centers.service';
import { BehaviorSubject } from 'rxjs';
import { EntityItem } from '../entities/entities.store';

@Injectable({ providedIn: 'root' })
export class ServiceCentersQuery extends QueryEntity<ServiceCentersState> {
  serviceCenters$ = this.selectAll();
  serviceCenterSeleted$ = this.selectAll({
    filterBy: entity => entity.isSelected === true,
  });
  serviceCentersSelectedChange$ = new BehaviorSubject<{
    selected: EntityItem;
    redirectTo?: string;
  }>(null);

  constructor(protected store: ServiceCentersStore) {
    super(store);
  }

  get serviceCenterSeleted() {
    return this.getAll({
      filterBy: entity => entity.isSelected === true,
    });
  }
}
