import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { MultipleOptions } from '../multiple-select/multiple-select.component';

@Component({
  selector: 'assign-role-dlg',
  templateUrl: './assign-role-dlg.component.html',
  styleUrls: ['./assign-role-dlg.component.scss']
})
export class AssignRoleDlgComponent implements OnInit {

  @Input()
  employeeDetails: any = {};

  @Input()
  roleOptions: MultipleOptions = []

  roleSelected: MultipleOptions = [];

  get roleSelectedValues() {
    return this.roleOptions.filter(option => this.roleSelected.find(selected => selected === option.value));
  }

  constructor(private dialogRef: NbDialogRef<AssignRoleDlgComponent>,) { }

  ngOnInit() {
  }

  onSubmit() {
    this.dialogRef.close(this.roleSelected);
  }

  close() {
    this.dialogRef.close();
  }

  handleRoleChange(event) {
    // console.log(this.roleSelected);
  }

  removeRole (role) {
    this.roleSelected = this.roleSelected.filter(selected => selected !== role.value);
  }
}
