import { Component, OnInit, OnDestroy } from '@angular/core';
import { BookingListService } from '../../../../@core/akita-stores/entity-stores/bookings/bookings.service';
import * as _moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import { CommonService } from '../../../../@core/utils/common.service';
import { Subscription } from 'rxjs';

const timezone = 'UTC';
_moment.tz.setDefault(timezone);
const moment = extendMoment(_moment);
const today = _moment().format("DD/MM/YYYY");

@Component({
  selector: 'booking-stats',
  templateUrl: './booking-stats.component.html',
  styleUrls: ['./booking-stats.component.scss']
})
export class BookingStatsComponent implements OnInit, OnDestroy {
  public totalBookingList: any;
  public totalBooking: number;
  public totalPercent: number;
  public selectedStats = "today";
  public listService = [];
  public total: number;
  subscriptions: Subscription = new Subscription();
  constructor(
    private bookingListService: BookingListService,
    private commonService: CommonService 
    ) {
  }

  ngOnInit() {
    this.commonService.getServiceTypes().subscribe(res => {
      this.listService = res;
    });
    this.subscriptions.add(this.bookingListService.callApiListBooking$.subscribe(isCallApiBooking => {
      if(!isCallApiBooking) return;
      this.bookingListService.getTotalBookingsAPI().subscribe(value => {
        this.totalBookingList = value.results;
        this.selectionChange();
      });
    }))
  }

  filterStats(fil,type) {
    return fil[0].results
    .filter(m => m.bookingStatusCode !== "cancelled" && m.serviceTypeCode === type)
    .map(m => m.count).reduce((item1, item2) => item1 + item2, 0);
  }

  getPercentageChange(oldNumber, newNumber){
    if(oldNumber === 0) {
      return newNumber * 100;
    }
    var decreaseValue = newNumber - oldNumber;
    return (decreaseValue / oldNumber) * 100;
  }

  getTypeOfStats(past,present) {
    let presentFilter = this.totalBookingList.filter(t => t.type === present);
    let pastFilter = this.totalBookingList.filter(t => t.type === past);
    let totalBookings = 0;
    let pastTotalBookings = 0;
    this.listService.map(service => {
      let total = 0;
      let totalPast = 0;
      total = this.filterStats(presentFilter, service.code);
      totalPast = this.filterStats(pastFilter, service.code);
      totalBookings +=  total;
      pastTotalBookings +=  totalPast;

      if (past === 'lastWeek' || past === 'lastMonth') {
        service.total = totalPast;
        this.totalBooking = pastTotalBookings;
      } else {
        service.total = total;
        this.totalBooking = totalBookings;
      }

      service.percent = Math.ceil(this.getPercentageChange(totalPast, total));
      this.totalPercent = Math.ceil(this.getPercentageChange(pastTotalBookings, totalBookings));
    });
  }

  selectionChange() {
    switch (this.selectedStats) {
      case "today":
        this.getTypeOfStats("yesterday","today")
        break;
      case "yesterday":
        this.getTypeOfStats("beforeYesterday","yesterday");
        break;
      case "last week":
        this.getTypeOfStats("lastWeek","currentWeek");
        break;
      case "last month":
        this.getTypeOfStats("lastMonth","currentMonth");
        break;
    }
  }

  ngOnDestroy(): void {
      if(this.subscriptions) this.subscriptions.unsubscribe();
  }
}
