import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { BookingDetails, BookingDetailsSelectedStore } from './booking-details-selected.store';

@Injectable({ providedIn: 'root' })
export class BookingDetailsSelectedQuery extends Query<BookingDetails> {
  bookingDetailsSelected$ = this.select();
  selectTimeSlots$ = this.select('timeSlots');

  constructor(protected store: BookingDetailsSelectedStore) {
    super(store);
  }
}
