import { Pipe, PipeTransform } from '@angular/core';
import { CommonService } from '../../@core/utils/common.service';

@Pipe({ name: 'kmService' })
export class KmServicePipe implements PipeTransform {

  constructor(private commonService: CommonService) {}

  transform(km: string, text: string): string {
    return this.commonService.getKmService(km, text);
  }
}
