import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class MoveIconService {

    render() {
        return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.70711 8.29289C6.09763 8.68342 6.09763 9.31658 5.70711 9.70711L3.41421 12L5.70711 14.2929C6.09763 14.6834 6.09763 15.3166 5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071L1.29289 12.7071C0.902369 12.3166 0.902369 11.6834 1.29289 11.2929L4.29289 8.29289C4.68342 7.90237 5.31658 7.90237 5.70711 8.29289Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2929 1.29289C11.6834 0.902369 12.3166 0.902369 12.7071 1.29289L15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711C15.3166 6.09763 14.6834 6.09763 14.2929 5.70711L12 3.41421L9.70711 5.70711C9.31658 6.09763 8.68342 6.09763 8.29289 5.70711C7.90237 5.31658 7.90237 4.68342 8.29289 4.29289L11.2929 1.29289Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.29289 18.2929C8.68342 17.9024 9.31658 17.9024 9.70711 18.2929L12 20.5858L14.2929 18.2929C14.6834 17.9024 15.3166 17.9024 15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071L12.7071 22.7071C12.3166 23.0976 11.6834 23.0976 11.2929 22.7071L8.29289 19.7071C7.90237 19.3166 7.90237 18.6834 8.29289 18.2929Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.2929 8.29289C18.6834 7.90237 19.3166 7.90237 19.7071 8.29289L22.7071 11.2929C23.0976 11.6834 23.0976 12.3166 22.7071 12.7071L19.7071 15.7071C19.3166 16.0976 18.6834 16.0976 18.2929 15.7071C17.9024 15.3166 17.9024 14.6834 18.2929 14.2929L20.5858 12L18.2929 9.70711C17.9024 9.31658 17.9024 8.68342 18.2929 8.29289Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1 12C1 11.4477 1.44772 11 2 11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13H2C1.44772 13 1 12.5523 1 12Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1C12.5523 1 13 1.44772 13 2V22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22V2C11 1.44772 11.4477 1 12 1Z" fill="black"/>
        </svg>                
        `;
    }
}
