import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lazy-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
  @Input() image;
  @Input() defaultImage = 'https://dummyimage.com/1x1/000/cecece';
  @Input() classImg = '';

  constructor() { }

  ngOnInit() {
  }
}

export class ImageSnippet {
  pending: boolean = false;
  status: string = 'init';
  constructor(public src: string, public file: File) { }
}

