import { Component, Renderer2, Inject, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService, NB_WINDOW, NbToastrService } from '@nebular/theme';

import { SessionService } from '../../../@core/akita-stores/stores/session/session.service';
import { ServiceCentersQuery } from '../../../@core/akita-stores/entity-stores/sercice-centers/service-centers.query';
import { UserService } from '../../../@core/akita-stores/stores/user/user.service';

// import { UserData } from '../../../@core/data/login';
import { map, takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ERROR_MESSAGES } from '../../../constants/error-messages';
import { CommonService } from '../../../@core/utils/common.service';
import { EntitiesService } from '../../../@core/akita-stores/entity-stores/entities/entities.service';
import { LINK_IMAGE } from '../../../constants/image';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  // userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';
  currentCentre = '1';
  enableSCSelection = false;

  userMenu = [
    // { title: 'Profile', icon: { icon: 'person', pack: 'mo-icons' } },
    // { title: 'Account Setting', icon: { icon: 'setting', pack: 'mo-icons' } },
    // { title: 'Switch to Super Admin', icon: { icon: 'repeat', pack: 'mo-icons' } },
    { title: 'Log out', icon: { icon: 'logout', pack: 'mo-icons' } },
  ];
  errorMessages = ERROR_MESSAGES;
  fullNameUser: string = '';
  logo = LINK_IMAGE.LOGO;

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    @Inject(NB_WINDOW) private window,
    private renderer: Renderer2,
    private sessionService: SessionService,
    public serviceCentersQuery: ServiceCentersQuery,
    private breakpointService: NbMediaBreakpointsService,
    private userService: UserService,
    private router: Router,
    private toastrService: NbToastrService,
    private commonService: CommonService,
    private entitiesService: EntitiesService
  ) {
    this.entitiesService.locationAutomall.subscribe((res) => {
      if (res) this.logo = LINK_IMAGE.LOGO_AUTOMALL;
    });
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.menuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'my-context-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
        if (title == 'Log out') {
          this.sessionService.logout();
        }
      });

    this.userService.getUserData()
    .pipe(takeUntil(this.destroy$))
    .subscribe((user: any) => {
      this.user = user.customer || user.staff;
      this.fullNameUser = this.user
        ? this.commonService.getFullName(this.user.firstName, this.user.lastName)
        : ((user.email === 'admin@gmail.com') ? 'Admin' : '');
    }, () => {
      this.sessionService.logout();
      this.router.navigateByUrl('/auth/login').then(() => {
        this.toastrService.danger(this.errorMessages.userHasNoPermissions, 'Error');
      });
    });

    this.enableSCSelection = !this.router.url.includes('/pages/kpi-reports');

    // const { xl } = this.breakpointService.getBreakpointsMap();
    // this.themeService.onMediaQueryChange()
    //   .pipe(
    //     map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
    //     takeUntil(this.destroy$),
    //   )
    //   .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    // this.themeService.onThemeChange()
    //   .pipe(
    //     map(({ name }) => name),
    //     takeUntil(this.destroy$),
    //   )
    //   .subscribe(themeName => this.currentTheme = themeName);
  }

  openUserMenu() {
    setTimeout(() => {
      const el = document.getElementsByClassName('my-context-menu');
      if (el.length) {
        const parent = el[0].parentElement.parentElement.parentElement;
        this.renderer.addClass(parent, 'user-menu');
      }
    }, 100);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  navigateHome() {
    this.router.navigateByUrl('/pages/dashboard');
    return false;
  }
}
