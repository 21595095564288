import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ClockIconService {

    render() {
        return `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00033 2.00033C4.68662 2.00033 2.00033 4.68662 2.00033 8.00033C2.00033 11.314 4.68662 14.0003 8.00033 14.0003C11.314 14.0003 14.0003 11.314 14.0003 8.00033C14.0003 4.68662 11.314 2.00033 8.00033 2.00033ZM0.666992 8.00033C0.666992 3.95024 3.95024 0.666992 8.00033 0.666992C12.0504 0.666992 15.3337 3.95024 15.3337 8.00033C15.3337 12.0504 12.0504 15.3337 8.00033 15.3337C3.95024 15.3337 0.666992 12.0504 0.666992 8.00033Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00033 3.33366C8.36852 3.33366 8.66699 3.63214 8.66699 4.00033V7.5883L10.9651 8.73737C11.2945 8.90203 11.4279 9.30248 11.2633 9.6318C11.0986 9.96112 10.6982 10.0946 10.3688 9.92994L7.70218 8.59661C7.47633 8.48368 7.33366 8.25284 7.33366 8.00033V4.00033C7.33366 3.63214 7.63214 3.33366 8.00033 3.33366Z" fill="white"/>
        </svg>
        `;
    }
}
