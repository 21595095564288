import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class HomeIconService {

    render() {
        return `<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.5894 0.807424C6.83014 0.620182 7.16725 0.620182 7.40799 0.807424L13.408 5.47409C13.5704 5.60039 13.6654 5.7946 13.6654 6.00033V13.3337C13.6654 13.8641 13.4547 14.3728 13.0796 14.7479C12.7045 15.1229 12.1958 15.3337 11.6654 15.3337H2.33203C1.8016 15.3337 1.29289 15.1229 0.917818 14.7479C0.542745 14.3728 0.332031 13.8641 0.332031 13.3337V6.00033C0.332031 5.7946 0.427013 5.60039 0.589404 5.47409L6.5894 0.807424ZM1.66536 6.32638V13.3337C1.66536 13.5105 1.7356 13.68 1.86063 13.8051C1.98565 13.9301 2.15522 14.0003 2.33203 14.0003H11.6654C11.8422 14.0003 12.0117 13.9301 12.1368 13.8051C12.2618 13.68 12.332 13.5105 12.332 13.3337V6.32638L6.9987 2.17823L1.66536 6.32638Z" fill="var(--block-colour)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.33203 8.00033C4.33203 7.63214 4.63051 7.33366 4.9987 7.33366H8.9987C9.36689 7.33366 9.66537 7.63214 9.66537 8.00033V14.667C9.66537 15.0352 9.36689 15.3337 8.9987 15.3337C8.63051 15.3337 8.33203 15.0352 8.33203 14.667V8.66699H5.66536V14.667C5.66536 15.0352 5.36689 15.3337 4.9987 15.3337C4.63051 15.3337 4.33203 15.0352 4.33203 14.667V8.00033Z" fill="var(--block-colour)"/>
        </svg>
        `;
    }
}
