import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class SaveIconService {

    render() {
        return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 4C4.73478 4 4.48043 4.10536 4.29289 4.29289C4.10536 4.48043 4 4.73478 4 5V19C4 19.2652 4.10536 19.5196 4.29289 19.7071C4.48043 19.8946 4.73478 20 5 20H19C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V8.41421L15.5858 4H5ZM2.87868 2.87868C3.44129 2.31607 4.20435 2 5 2H16C16.2652 2 16.5196 2.10536 16.7071 2.29289L21.7071 7.29289C21.8946 7.48043 22 7.73478 22 8V19C22 19.7957 21.6839 20.5587 21.1213 21.1213C20.5587 21.6839 19.7957 22 19 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7957 2 19V5C2 4.20435 2.31607 3.44129 2.87868 2.87868Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 13C6 12.4477 6.44772 12 7 12H17C17.5523 12 18 12.4477 18 13V21C18 21.5523 17.5523 22 17 22C16.4477 22 16 21.5523 16 21V14H8V21C8 21.5523 7.55228 22 7 22C6.44772 22 6 21.5523 6 21V13Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 2C7.55228 2 8 2.44772 8 3V7H15C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H7C6.44772 9 6 8.55228 6 8V3C6 2.44772 6.44772 2 7 2Z" fill="black"/>
        </svg>                        
        `;
    }
}
