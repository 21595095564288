import { QueryEntity } from '@datorama/akita';
import { CustomersState, CustomersStore } from './customers.store';
import { Injectable } from '@angular/core';


@Injectable({ providedIn: 'root' })
export class CustomersQuery extends QueryEntity<CustomersState> {
  constructor(protected store: CustomersStore) {
    super(store);
  }
}