import { CustomerStore } from './customer.store';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_PATH } from '../../../../constants/api-urls';
import { tap, map } from 'rxjs/operators';
import { CustomersQuery } from '../../entity-stores/customers/customers.query';

export interface CustomerContact {
  contact?: {
    contactTypeId?: number;
    contactType?: string;
    value: any;
  };
  isPreferred: boolean;
}

export interface CustomerAddress {
  address?: {
    addressTypeId?: number;
    addressType?: string;
    streetLine1?: string;
    streetLine2?: string;
    city?: string;
    state?: string;
    postCode?: string;
    suburb?: string;
    country?: string;
    longitude?: number;
    latitude?: number;
    others?: string;
  };
}

@Injectable({ providedIn: 'root' })
export class CustomerService {
  constructor(
    private customerStore: CustomerStore,
    private http: HttpClient
  ) { }

  getCustomerDetails(customerId: number) {
    return this.http.get(API_PATH.customers.customers + `/${customerId}`).pipe(tap(details => {
    }));
  }

  getStates(country: string, page: number = 1, pageSize: number = 50) {
    return this.http.get(API_PATH.geos.states + `?country=${country}&page=${page}&pageSize=${pageSize}`).pipe(
      map((res: any) => res.results),
      tap((states) => {
        this.customerStore.updateStates(states);
      })
    )
  }

  getSuburbs(country: string, postCode: string, page: number = 1, pageSize: number = 50) {
    return this.http.get(API_PATH.geos.suburbs + `?country=${country}&postCode=${postCode}&page=${page}&pageSize=${pageSize}`).pipe(
      map((res: any) => res.results),
      tap((suburbs) => {
        this.customerStore.updateSubrubs(suburbs);
      })
    )
  }

  getSuburbInfo(postCode: string) {
    return this.http.get(API_PATH.geos.suburbs, {
      params: {
        country: 'au',
        postCode: postCode
      }
    }).pipe(map((res: any) => res.results));
  }

  updateCustomer(customerID: number, body) {
    return this.http.put(API_PATH.customers.customers + `/${customerID}`, body);
  }

  addContacts(customerID: number, body: CustomerContact) {
    return this.http.post(API_PATH.customers.customers + `/${customerID}/contacts`, body);
  }

  addAddresses(customerID: number, body: CustomerAddress) {
    return this.http.post(API_PATH.customers.customers + `/${customerID}/addresses`, body);
  }

  getBookings(customerId: number, includes: string, sort?: string) {
    sort = sort ? `&sort=${sort}` : '';
    return this.http.get(API_PATH.bookings.bookings + `?customerId=${customerId}&includes=${includes}${sort}`).pipe(map((res: any) => res.results));
  }

  updateVehicleDetail(vehicleId: number, odometer: number) {
    const data = {
      "odometer": odometer
    };
    return this.http.put(API_PATH.admin.vehicles + `/${vehicleId}`, data);
  }

  getFilteredCustomers(keyword: string) {
    const pageSize = "1000";
    return this.http.get(API_PATH.customers.customers, { params: { keyword, pageSize } });
  }

  createCustomer(data) {
    const createCustomerContact = (contactType, value) => {
      return {
        contact: {
          contactType: contactType,
          value: value,
        },
        isPreferred: contactType === 'email' ? true : false
      }
    };

    const payload = {
      customer: {
        firstName: data.firstName,
        lastName: data.lastName,
        customerAddresses: [
          {
            address: {
              addressType: 'delivery',
              streetLine1: data.homeAddress,
              city: data.city,
              state: data.state,
              postCode: data.postCode,
              suburb: data.suburb,
              country: data.country,
            }
          }
        ],
        customerContacts: [],
      },
      email: data.email
    };
    const emailContact = createCustomerContact('email', data.email);
    payload.customer.customerContacts.push(emailContact);
    const phoneContact = createCustomerContact('phone', data.phone);
    payload.customer.customerContacts.push(phoneContact);

    return this.http.post(API_PATH.admin.customerCreate, payload);
  }
}