import * as _ from 'lodash';
import { Component, Input, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { UserQuery } from '../../../@core/akita-stores/stores/user/user.query';
import { ConfigQuery } from '../../../@core/akita-stores/stores/config/config.store';
import { BookingDetailsSelectedQuery } from './../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.query';
import { NbToastrService } from '@nebular/theme';
import { BookingDetails } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.store';
import { CommonService } from '../../../@core/utils/common.service';
import { CustomersQuery } from '../../../@core/akita-stores/entity-stores/customers/customers.query';
import { NgxPermissionsService } from 'ngx-permissions';
import { BOOKING_PERMISSIONS } from '../../../constants/booking-permissions';
import { VEHICLE_PERMISSIONS } from '../../../constants';
import { Subject } from 'rxjs';
import { finalize, first, takeUntil } from 'rxjs/operators';
import { BOOKING_STATUSES } from './../../../constants/status'; 
import { BookingDetailsSelectedService } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.service';
import { ServiceCentersService } from '../../../@core/akita-stores/entity-stores/sercice-centers/service-centers.service';
import { FlightDeckService } from '../../../@core/akita-stores/stores/flightdeck/flightdeck.service';
import { ServiceCentersQuery } from '../../../@core/akita-stores/entity-stores/sercice-centers/service-centers.query';

@Component({
  selector: 'ngx-two-columns-layout',
  styleUrls: ['./two-columns.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-column class="small">
        <sidebar-customer *ngIf="type==='customers'"></sidebar-customer>
        <sidebar-booking [action]="action" *ngIf="type==='booking'"></sidebar-booking>
      </nb-layout-column>
      <nb-layout-column [nbSpinner]="(isLogin$ | async) == false">
        <div class="position-relative" #test>
          <nb-route-tabset #nbTabset (changeTab)="tabChanged($event)" [id]="type==='customers' ? 'tabCustomer' : 'tabBooking'" [tabs]="tabs"></nb-route-tabset>
          <ng-container *ngxPermissionsOnly="[bookingPermissions.updateBookingToPickup]">
              <!--[disabled]="!isBookingPaid" -->
            <button *ngIf="canPickUp" (click)="pickUp()" class="primary small save-btn" nbButton>
              Pick up
            </button>
          </ng-container>
          <mo-tag class="picked-up" *ngIf="isPickedUp" [tag]="{types: 'status', code: 'picked-up'}"></mo-tag>
        </div>
      </nb-layout-column>
    </nb-layout>
  `,
})
export class TwoColumnsLayoutComponent implements OnInit, OnDestroy {

  @Input() type: string;
  @Input() action: string;

  tabs: any[];
  isLogin$ = this.userQuery.isLogin$;
  bookingDetails: BookingDetails;
  isUneditableMode: boolean = false;
  bookingDetails$;
  @ViewChild('nbTabset', { static: false }) nbTabset: any;
  @ViewChild('test', { static: false }) nbTabsetHTML: ElementRef;
  isPermGetBookingActivityLogs: boolean = false;
  isPermGetCustomerVehicles: boolean = false;
  isPermGetVehicleConditions: boolean = false;
  destroy$ = new Subject<void>();
  isPickedUp: boolean = false;
  canPickUp: boolean = false;
  bookingPermissions = BOOKING_PERMISSIONS;
  isBookingPaid: boolean = false;
  isHeavyWorkflow: boolean = false;
  hasFDService: boolean = false;

  constructor(
    private userQuery: UserQuery,
    private commonService: CommonService,
    private bookingDetailsQuery: BookingDetailsSelectedQuery,
    private configQuery: ConfigQuery,
    private ngxPermissionsService: NgxPermissionsService,
    private customersQuery: CustomersQuery,
    private bookingService: BookingDetailsSelectedService,
    private centersService: ServiceCentersService,
    private toastrService: NbToastrService,
    private flightDeckService: FlightDeckService,
    private serviceCentersQuery: ServiceCentersQuery,
  ) { }

  tabChanged(e) {
  }

  async ngOnInit() {
    await Promise.all([
      this.ngxPermissionsService.hasPermission(BOOKING_PERMISSIONS.getBookingActivityLogs),
      this.ngxPermissionsService.hasPermission(VEHICLE_PERMISSIONS.getCustomerVehicles),
      this.ngxPermissionsService.hasPermission(VEHICLE_PERMISSIONS.getVehicleConditions)
    ]).then(res => {
      this.isPermGetBookingActivityLogs = res[0];
      this.isPermGetCustomerVehicles = res[1];
      this.isPermGetVehicleConditions = res[2];
    }).catch(err => console.error(err));
    
    if (this.bookingDetails$) this.bookingDetails$.unsubscribe();

    if (this.type === 'customers') {
      this.tabs = [
        {
          title: 'Customer Information',
          route: `./customer-details/${this.customersQuery.getActiveId()}`,
        }
      ];
      if (this.isPermGetCustomerVehicles) {
        this.tabs.push({
          title: 'Vehicle',
          route: `./vehicles/${this.customersQuery.getActiveId()}`,
        });
      }
    } else if (this.type === 'booking') {
      this.bookingDetails$ = this.bookingDetailsQuery.bookingDetailsSelected$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (val) => {
        this.bookingDetails = Object.assign({}, val);
        this.isUneditableMode = this.commonService.isUneditableMode(this.bookingDetails);
        try {
          if (this.nbTabset) {
            this.nbTabset.tabs[2].icon = !this.isUneditableMode ? 'alert-circle-outline' : 'checkmark-circle-2-outline';
          };
        } catch (e) { }
        const isLiteBooking = this.bookingService.isLiteBooking(this.bookingDetails);
        const serviceCenterSelected = await this.serviceCentersQuery.serviceCenterSeleted$.pipe(first()).toPromise();

        this.hasFDService = _.get(serviceCenterSelected, "[0].hasFlightDeck", false);
        this.isHeavyWorkflow = _.get(serviceCenterSelected, "[0].bookingServicesConfigs.useEraPower", false);

        this.tabs = [
          {
            title: 'Booking Details',
            route: `./booking-details/${this.bookingDetails.id}`,
          },
        ];

        if(this.isHeavyWorkflow && !isLiteBooking) {
          this.tabs.push({
              title: 'Technical Details',
              route: `./technical-details/${this.bookingDetails.id}`,
            });
        }

        if (this.isHeavyWorkflow && !isLiteBooking && this.isPermGetVehicleConditions) {
          this.tabs.push({
            title: 'Conditions',
            route: [`./conditions/${this.bookingDetails.id}`],
            icon: !this.isUneditableMode ? 'alert-circle-outline' : 'checkmark-circle-2-outline',
          });
        }
        if (this.isPermGetBookingActivityLogs) {
          this.tabs.push({
            title: 'Activity Log',
            route: `./booking-activity/${this.bookingDetails.id}`,
          });
        }
        if (true) {
          this.tabs.push({
            title: 'Comments & Notes',
            route: `./booking-comments/${this.bookingDetails.id}`,
            icon: (this.bookingDetails.customerNote || this.bookingDetails.adminBookingNote) ? 'radio-button-off-outline' : null,
          });
        }
        setTimeout(() => {
          let arrTabText = this.nbTabsetHTML.nativeElement.children[0].querySelectorAll('span.tab-text');
          arrTabText.forEach(el => {
            if (el.innerText === 'Technical Details') {
              try {
                if (el.nextElementSibling && el.nextElementSibling.outerHTML.toString().includes('badge')) {
                  el.nextElementSibling.outerHTML = '';
                }
                el.outerHTML = el.outerHTML + '<h6 class="badge body-4 my-0 ml-2">' + this.getNumberOfQualifiedJobs() + '</h6>'
              } catch (e) { }
            }
          });
        }, 1000);

        const isReadyToPickup = this.bookingDetails.bookingStatus.code === BOOKING_STATUSES.readyForPickup && this.bookingDetails.bookingStatus.code !== BOOKING_STATUSES.cancelled;
        this.isPickedUp = !this.bookingDetails.beacon && isReadyToPickup;
        this.canPickUp = this.bookingDetails.beacon && isReadyToPickup;
        this.isBookingPaid = this.bookingService.isPaid(this.bookingDetails.bookingPayments);
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getNumberOfQualifiedJobs() {
    const items = [...this.bookingDetails.jobs, ...this.bookingDetails.extraOptions];
    let total = items.length;
    let count = 0;
    items.forEach(item => {
      if (item['isBeverage']) {
        total--;
      };

      if (item.qualifiedAtUtc && !item['isBeverage']) count++;
    });

    return `${count}/${total}`;
  }

  async pickUp() {
    const dlgPickup = this.commonService.pickUp();
    const instancePickup = dlgPickup.componentRef.instance;
    instancePickup.submitClicked
    .pipe(takeUntil(this.destroy$))
    .subscribe(async () => {
      this.flightDeckService.pickUpRO(this.bookingDetails.id);
      await this.bookingService.pickUp(this.bookingDetails.id).toPromise().catch(err => { throw err });
      const beaconId = this.bookingDetails.beacon.id;
      await this.centersService.updateBeacon(beaconId, { isActive: true }).toPromise().catch(err => { throw err });
      this.bookingService.getBookingDetails(this.bookingDetails.id)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          instancePickup.loading = false;
          dlgPickup.close();
        })
      ).subscribe(() => {
        this.toastrService.success('Picked Up successfully', 'Success', { destroyByClick: true, hasIcon: false });
      });
    });
  }
}
