import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class RefreshIconService {

    render() {
        return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1 3C1.55228 3 2 3.44772 2 4V9H7C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11H1C0.447715 11 0 10.5523 0 10V4C0 3.44772 0.447715 3 1 3Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 14C16 13.4477 16.4477 13 17 13H23C23.5523 13 24 13.4477 24 14V20C24 20.5523 23.5523 21 23 21C22.4477 21 22 20.5523 22 20V15H17C16.4477 15 16 14.5523 16 14Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.22695 2.39617C10.8497 1.9268 12.5648 1.87614 14.2124 2.24893C15.86 2.62171 17.3863 3.40579 18.6489 4.52799C19.9115 5.6502 20.8692 7.07396 21.4327 8.66642C21.617 9.18707 21.3442 9.75849 20.8236 9.94273C20.303 10.127 19.7315 9.85426 19.5473 9.33361C19.0965 8.05964 18.3303 6.92064 17.3202 6.02287C16.3101 5.12511 15.0891 4.49785 13.771 4.19962C12.453 3.90139 11.0808 3.94191 9.78267 4.31741C8.48451 4.69291 7.30262 5.39113 6.34729 6.34694C6.3399 6.35434 6.3324 6.36161 6.32478 6.36877L1.68478 10.7288C1.2823 11.107 0.649442 11.0873 0.27125 10.6848C-0.106942 10.2823 -0.0872528 9.64945 0.315226 9.27126L4.9441 4.92171C6.13627 3.73278 7.6094 2.86405 9.22695 2.39617ZM23.7288 13.3152C24.1069 13.7177 24.0873 14.3506 23.6848 14.7288L19.0559 19.0783C17.8637 20.2673 16.3906 21.136 14.7731 21.6039C13.1503 22.0732 11.4352 22.1239 9.78761 21.7511C8.14003 21.3783 6.61373 20.5942 5.35114 19.472C4.08855 18.3498 3.13081 16.9261 2.56729 15.3336C2.38305 14.813 2.65576 14.2415 3.17641 14.0573C3.69705 13.8731 4.26848 14.1458 4.45272 14.6664C4.90353 15.9404 5.66973 17.0794 6.6798 17.9772C7.68988 18.8749 8.91091 19.5022 10.229 19.8004C11.547 20.0986 12.9192 20.0581 14.2173 19.6826C15.5155 19.3071 16.6974 18.6089 17.6527 17.6531C17.6572 17.6486 17.6616 17.6442 17.6662 17.6399C17.6692 17.637 17.6722 17.6341 17.6752 17.6313L22.3152 13.2713C22.7177 12.8931 23.3506 12.9128 23.7288 13.3152Z" fill="black"/>
        </g>
        <defs>
        <clipPath id="clip0">
        <path d="M0 0H24V24H0V0Z" fill="white"/>
        </clipPath>
        </defs>
        </svg>                                               
        `;
    }
}
