<ng-container>
  <nb-card class="add-signature-dialog general-dialog">
    <nb-card-header>Add Signature<nb-icon icon="x-icon" pack="mo-icons" (click)="close()"></nb-icon></nb-card-header>
    <nb-card-body #bodyCard>
      <div class="mt-1 wrap--signature-pad" #wrapSignaturePadRef>
        <signature-pad #sigPad [options]="signaturePadOptions" (onEndEvent)="drawComplete()"></signature-pad>
      </div>
      <div class="d-flex justify-content-end mt-3">
        <button nbButton class="secondary mr-3" (click)="clear()">Clear</button>
        <button class="primary" (click)="checkIn()" [disabled]='isDisabled'>Check in</button>
      </div>
    </nb-card-body>
  </nb-card>
</ng-container>