import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  ViewChild,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import {
  CUSTOMER_PERMISSIONS,
  SERVICE_CENTER_PERMISSIONS,
} from '../../../constants';

/**
 *  The custom format the value in the tooltip
 */
type TooltipValueFormat = {
  format?: ((value: number) => string) | 'percent' | 'currency';
  suffix?: string;
  prefix?: string;
};

export interface CustomChartData {
  labels: string[] | string[][];
  datasets: (
    | {
      type: 'bar';
      label: string;
      data: number[];
      borderColor: string;
      backgroundColor: string;
      hoverBackgroundColor?: string;
      barPercentage: number;
      tooltipValueFormat?: TooltipValueFormat;
      maxBarThickness?: number;
    }
    | {
      type: 'line';
      label: string;
      data: number[];
      borderColor?: string;
      hidden?: boolean;
      tooltipValueFormat?: TooltipValueFormat;
    }
    | {
      type: 'pie';
      label: string;
      data: number[];
      backgroundColor: string[];
      hoverOffset: number;
      tooltipValueFormat?: TooltipValueFormat;
    }
  )[];
}

@Component({
  selector: 'custom-chart',
  templateUrl: './custom-chart.component.html',
  styleUrls: ['./custom-chart.component.scss'],
})
export class CustomChartComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('chartCanvas', { static: true }) chartEle;

  @Input() chartType: 'bar-line' | 'pie' = 'bar-line';
  @Input() chartData: CustomChartData;
  @Input() animation: boolean = true;
  @Input() xAxisLabelWrap: {
    minLines?: number; // min line to wrap text. If possible, it will at least wrap into minLines lines
    maxLinesLength?: number; // max length of each line. If the text is longer than this, it will be wrapped into multiple lines
  } | boolean = false;
  @Input() leftYAxisLabel: string = ''; // label for left y axis when chartType is bar-line
  @Input() rightYAxisLabel: string = ''; // label for right y axis when chartType is bar-line
  @Input() rightYAxisValueFormat: ((value: number) => string); // format the value in the right y axis when chartType is bar-line

  isPermissions = {
    getCustomer: false,
    adminGetCenterDetails: false,
  };
  protected chart;
  protected lineStyleConfig = {
    order: 2,

    pointStyle: 'circle',
    fill: false,

    backgroundColor: 'white',

    borderWidth: 2,
    pointHoverBorderWidth: 2,

    pointRadius: 3,
    pointHoverRadius: 4,

    pointBackgroundColor: '#fff',

    pointBorderWidth: 2,
    pointHoverBackgroundColor: '#fff',
  };
  protected pieOptions = {
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        // Disable the on-canvas tooltip to use external tooltips
        enabled: false,
        external: this.externalTooltip,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
  };
  protected barLineOptions = {
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        // Disable the on-canvas tooltip to use external tooltips
        enabled: false,
        external: this.externalTooltip,
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    scales: {
      barAxis: {
        type: 'linear',
        display: true,
        title: {
          // title for left y axis
          display: false,
          text: '',
          color: '#ccc',
          padding: { bottom: 20 }, // space between title and axis
        },
        position: 'left',
        grid: {},
        border: {
          dash: [4, 4],
          display: false,
        },
        suggestedMin: 0,
        suggestedMax: 10,
        ticks: {
          // stepSize: 50,
          precision: 1,
          count: 6,
        },
      },
      lineAxis: {
        type: 'linear',
        display: true,
        title: {
          // title for right y axis
          display: false,
          text: '',
          color: '#ccc',
          padding: { bottom: 20 }, // space between title and axis
        },
        position: 'right',
        text: '',
        grid: {
          drawOnChartArea: false,
        },
        border: {
          display: false,
        },
        suggestedMin: 0,
        suggestedMax: 1,
        ticks: {
          precision: 1,
          count: 6,
          callback: (value) => value// This is to prevent the default value format
        },
      },
      x: {
        grid: {
          drawOnChartArea: false,
          drawBorder: false,
        },
        border: {
          dash: [4, 4],
          display: false,
        },
        ticks: {
          autoSkip: false,
        }
      },
    },
    elements: {
      line: {
        tension: 0,
      },
    },
  };
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('ngOnChanges', changes);
    if (
      this.chart &&
      changes.chartData.currentValue !== changes.chartData.previousValue
    ) {
      const config = changes.chartData.currentValue;
      if (this.xAxisLabelWrap && config.labels) {
        this.chart.data.labels = this.convertToWrapText(
          config.labels || [],
          (this.xAxisLabelWrap as any).minLines,
          (this.xAxisLabelWrap as any).maxLinesLength
        )
      } else {
        this.chart.data.labels = config.labels || [];
      }
      this.chart.data.datasets = config.datasets
        ? config.datasets.map((item) => {
          let dataset = item;
          if (item.type === 'bar') {
            dataset.yAxisID = 'barAxis';
            dataset = {
              ...dataset,
              order: 3,
            };
          }
          if (
            item.type === 'line' &&
            !(this.chartType === 'pie' && item.hidden)
          ) {
            dataset.yAxisID = 'lineAxis';
            dataset = {
              ...dataset,
              ...this.lineStyleConfig,
            };
          }
          if (item.type === 'pie') {
          }
          return dataset;
        })
        : [];
      this.chart.update();
      // console.log('after update', this.chart.data);
    }
  }

  ngOnInit() {
    const options =
      this.chartType === 'pie' ? this.pieOptions : this.barLineOptions;
    const defaultData =
      this.chartType === 'pie'
        ? { labels: [], datasets: [{ type: 'pie', label: '', data: [1] }] }
        : {
          labels: [],
          datasets: [
            { type: 'bar', label: '', yAxisID: 'barAxis', data: [] },
          ],
        };
    if (this.rightYAxisValueFormat) {
      this.barLineOptions.scales.lineAxis.ticks.callback = this.rightYAxisValueFormat;
    }
    if (this.leftYAxisLabel) {
      this.barLineOptions.scales.barAxis.title.display = true;
      this.barLineOptions.scales.barAxis.title.text = this.leftYAxisLabel;
    }
    if (this.rightYAxisLabel) {
      this.barLineOptions.scales.lineAxis.title.display = true;
      this.barLineOptions.scales.lineAxis.title.text = this.rightYAxisLabel;
    }

    this.chart = new Chart(this.chartEle.nativeElement, {
      options: options,
      data: defaultData,
    });
    // console.log('init', this.chart);
  }

  ngOnDestroy(): void { }

  convertToWrapText(labels: string[], minLine = 2, maxTextLength = 15) {
    return labels.map((cur) => {
      const splitString = cur.split(' ')
      if (splitString.length <= minLine) return splitString
      return splitString.reduce((acc, cur, index) => {
        if (acc[acc.length - 1]) {
          const stringCheck = acc[acc.length - 1] + " " + cur
          if (stringCheck.length <= maxTextLength) {
            acc[acc.length - 1] = stringCheck
          } else {
            acc.push(cur)
          }
        } else {
          acc.push(cur)
        }
        return acc;
      }, []);
    }, []);
  }

  /**
   *
   *  This function is used to render the tooltip in the external html tooltip
   *
   *  This Tooltip has a style specific to the KPI Report design
   *
   *  Reference: https://www.chartjs.org/docs/latest/configuration/tooltip.html#external-custom-tooltips
   */
  externalTooltip(context) {
    // Tooltip Element
    let tooltipEl = document.getElementById('chartjs-tooltip');

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip';
      tooltipEl.style.background = '#fff';
      tooltipEl.style.padding = '10px';
      tooltipEl.innerHTML = '<table></table>';
      document.body.appendChild(tooltipEl);
    }
    // Hide if no tooltip
    const tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
    }

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add('no-transform');
    }

    // Set Text
    if (tooltipModel.dataPoints) {
      const titleLines = tooltipModel.title || [];
      // console.log(tooltipModel);

      const chartData = tooltipModel.chart.data.datasets; // Array of all datasets
      const dataIndex = tooltipModel.dataPoints[0].dataIndex; // Index of the hovered element

      const tableBody = document.createElement('tbody');
      const tableHead = document.createElement('thead');

      const pieChartDataIndex = chartData.findIndex(
        (dataset) => dataset.type === 'pie'
      );
      if (pieChartDataIndex > -1) {
        // If the chart is a pie, chart the title has a square
        const tableRow = document.createElement('tr');
        const tableRowLabel = document.createElement('th');
        const pointLegend = document.createElement('span');

        pointLegend.classList.add('square-point-legend');
        pointLegend.style.backgroundColor =
          chartData[pieChartDataIndex].backgroundColor[dataIndex];

        tableRowLabel.appendChild(pointLegend);
        tableRowLabel.innerHTML += titleLines[0];

        tableRow.classList.add('chartjs-tooltip-header-row');
        tableRow.appendChild(tableRowLabel);

        tableHead.appendChild(tableRow);
      } else {
        titleLines.forEach(function (title) {
          tableHead.innerHTML += '<tr><th>' + title.replace(',', ' ') + '</th></tr>';
        });
      }
      // Add the dataset rows
      chartData.forEach(function (dataset, i) {
        const tableRow = document.createElement('tr');
        const tableRowLabel = document.createElement('td');
        const pointLegend = document.createElement('span');

        if (dataset.type === 'line' && !dataset.hidden) {
          pointLegend.classList.add('line-point-legend');
          pointLegend.style.backgroundColor = dataset.borderColor;
          tableRowLabel.appendChild(pointLegend);
        }
        if (dataset.type === 'bar') {
          pointLegend.classList.add('square-point-legend');
          pointLegend.style.backgroundColor = dataset.backgroundColor;
          tableRowLabel.appendChild(pointLegend);
        }
        tableRowLabel.innerHTML += dataset.label;

        const tableRowData = document.createElement('td');

        // Handle the custom tooltip value format
        const tooltipValueFormat = dataset.tooltipValueFormat as
          | TooltipValueFormat
          | undefined;
        let valuePrefix = '';
        let valueSuffix = '';
        let value = dataset.data[dataIndex] || 0;
        if (tooltipValueFormat) {
          const formatter = tooltipValueFormat.format;
          valuePrefix = tooltipValueFormat.prefix || '';
          valueSuffix = tooltipValueFormat.suffix || '';
          switch (formatter) {
            case 'percent':
              value = Number(value / 100).toLocaleString(undefined, {
                style: 'percent',
                minimumFractionDigits: 1,
              });
              break;
            case 'currency':
              value = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
              }).format(value);
              break;
            default:
              if (typeof formatter === 'function')
                value = formatter(value) || '';
              break;
          }
        } else {
          value = Intl.NumberFormat('en-US').format(value);
        }
        tableRowData.innerHTML = valuePrefix + value + valueSuffix;

        tableRow.classList.add('chartjs-tooltip-row');
        tableRow.appendChild(tableRowLabel);
        tableRow.appendChild(tableRowData);

        // Make sure the 'bar' is brought to the top to match the design
        if (dataset.type === 'bar') {
          tableBody.prepend(tableRow);
        } else tableBody.appendChild(tableRow);
      });

      const tableRoot = tooltipEl.querySelector('table');
      if (tableRoot) {
        tableRoot.innerHTML = '';
        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
      }
    }

    const position = context.chart.canvas.getBoundingClientRect();
    const bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);

    // Display, position, and set styles for font
    tooltipEl.style.opacity = '1';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left =
      position.left + window.scrollX + tooltipModel.caretX + 'px';
    tooltipEl.style.top =
      position.top + window.scrollY + tooltipModel.caretY + 'px';
    tooltipEl.style.font = bodyFont.string;
    tooltipEl.style.padding =
      tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.transform = 'translate(-50%, calc(-100% - 10px))';
  }
}
