<ng-container *ngIf="bookingDetails && bookingDetails.id || bookingDetails.vehicle.id">
  <h6 [ngClass]="isDlg ? 'font-weight-600 mb-2 text-capitalize' : 'mb-3 text-uppercase'">logbook service</h6>
  <ngx-slick-carousel *ngIf="show" [ngClass]="isDlg ? 'logbook-dlg' : ''" class="carousel" [config]="slideConfig"
    #slickCarousel>
    <button [disabled]="(!item.active && isAfterQualifiedStatus) || loading"
      [ngClass]="{'active': item.active, 'click-disabled': item.active && isAfterQualifiedStatus}" ngxSlickItem
      *ngFor="let item of list; let i = index" class="slide cursor-pointer" (click)="chooseItem($event, item)">
      <div class="items d-flex justify-content-center">
        <h6 [ngClass]="isDlg ? '' : 'px-3'" class="align-self-center mb-0 ">{{ item.km | number }} <span
            class="font-weight-bold">KM</span></h6>
      </div>
    </button>
  </ngx-slick-carousel>
</ng-container>