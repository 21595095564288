import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import * as storage from "../../storage";

export interface UserState {
  profile: {
    email: any;
    customer: {
      addresses: any[],
      contacts: any[],
      id: number,
      firstName: string,
      lastName: string,
      title: string,
      gender: string,
      dob: any,
    },
    id: string
  },
  permisions: string[],
  vehicles: any[],
  isLogin: boolean,
}

export function createInitialState(): UserState {
  return {
    profile: {
      customer: {
        addresses: [],
        contacts: [],
        id: null,
        firstName: '',
        lastName: '',
        title: '',
        gender: '',
        dob: '',
      },
      id: '',
      email: ''
    },
    vehicles: [],
    permisions: [],
    isLogin: false,
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user', resettable: true })
export class UserStore extends Store<UserState> {
  constructor() {
    super(createInitialState());
  }

  // saveUser(user) {
  //   this.update({
  //     profile: user
  //   });
  // }

  // saveVehicles(vehicles) {
  //   this.update({
  //     vehicles: vehicles
  //   });
  // }

  // clearUser() {
  //   this.update(createInitialState());
  // }
  savePermissions(permissions) {
    this.update({
      permisions: permissions
    });
  }

  clearUser() {
    this.update(createInitialState());
  }
}