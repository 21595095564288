import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class MapIconService {

  render(color?: string) {
    if (color == 'grey') {
      return `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.00257 0.754342C5.19611 0.643752 5.43211 0.637201 5.63148 0.736886L10.6457 3.24399L15.0026 0.754342C15.2089 0.636436 15.4624 0.637283 15.668 0.756564C15.8735 0.875845 16 1.09552 16 1.33317V11.9998C16 12.2391 15.8718 12.46 15.6641 12.5787L10.9974 15.2453C10.8039 15.3559 10.5679 15.3625 10.3685 15.2628L5.35431 12.7557L0.997426 15.2453C0.791091 15.3632 0.537592 15.3624 0.332049 15.2431C0.126506 15.1238 0 14.9042 0 14.6665V3.99984C0 3.7606 0.128191 3.5397 0.335907 3.42101L5.00257 0.754342ZM5.35431 2.08902L1.33333 4.38672V13.5177L5.00257 11.421C5.19611 11.3104 5.43211 11.3039 5.63148 11.4036L10.6457 13.9107L14.6667 11.613V2.48196L10.9974 4.57867C10.8039 4.68926 10.5679 4.69581 10.3685 4.59612L5.35431 2.08902Z" fill="#777777"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.33329 0.666504C5.70148 0.666504 5.99996 0.964981 5.99996 1.33317V11.9998C5.99996 12.368 5.70148 12.6665 5.33329 12.6665C4.9651 12.6665 4.66663 12.368 4.66663 11.9998V1.33317C4.66663 0.964981 4.9651 0.666504 5.33329 0.666504Z" fill="#777777"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6667 3.3335C11.0349 3.3335 11.3333 3.63197 11.3333 4.00016V14.6668C11.3333 15.035 11.0349 15.3335 10.6667 15.3335C10.2985 15.3335 10 15.035 10 14.6668V4.00016C10 3.63197 10.2985 3.3335 10.6667 3.3335Z" fill="#777777"/>
        </svg>
        `;
    }
    return `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.00257 0.754342C5.19611 0.643752 5.43211 0.637201 5.63148 0.736886L10.6457 3.24399L15.0026 0.754342C15.2089 0.636436 15.4624 0.637283 15.668 0.756564C15.8735 0.875845 16 1.09552 16 1.33317V11.9998C16 12.2391 15.8718 12.46 15.6641 12.5787L10.9974 15.2453C10.8039 15.3559 10.5679 15.3625 10.3685 15.2628L5.35431 12.7557L0.997426 15.2453C0.791091 15.3632 0.537592 15.3624 0.332049 15.2431C0.126506 15.1238 0 14.9042 0 14.6665V3.99984C0 3.7606 0.128191 3.5397 0.335907 3.42101L5.00257 0.754342ZM5.35431 2.08902L1.33333 4.38672V13.5177L5.00257 11.421C5.19611 11.3104 5.43211 11.3039 5.63148 11.4036L10.6457 13.9107L14.6667 11.613V2.48196L10.9974 4.57867C10.8039 4.68926 10.5679 4.69581 10.3685 4.59612L5.35431 2.08902Z" fill="var(--block-colour)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.33329 0.666504C5.70148 0.666504 5.99996 0.964981 5.99996 1.33317V11.9998C5.99996 12.368 5.70148 12.6665 5.33329 12.6665C4.9651 12.6665 4.66663 12.368 4.66663 11.9998V1.33317C4.66663 0.964981 4.9651 0.666504 5.33329 0.666504Z" fill="var(--block-colour)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6667 3.3335C11.0349 3.3335 11.3333 3.63197 11.3333 4.00016V14.6668C11.3333 15.035 11.0349 15.3335 10.6667 15.3335C10.2985 15.3335 10 15.035 10 14.6668V4.00016C10 3.63197 10.2985 3.3335 10.6667 3.3335Z" fill="var(--block-colour)"/>
      </svg>
      `;
  }

}
