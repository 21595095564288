import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class MoIconService {

  render(color = 'white') {
    if (color == 'black') {
      return `<svg width="62" height="24" viewBox="0 0 62 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.86487 23.6023H0L3.07622 4.70937C3.54079 1.85504 4.97217 0.426758 7.26192 0.426758C9.44438 0.426758 10.8746 1.72135 11.5549 4.31052L14.3812 15.4449L17.2074 4.31052C17.8866 1.72135 19.3179 0.426758 21.5004 0.426758C23.8255 0.426758 25.2204 1.8205 25.6861 4.70937L28.7623 23.6023H23.8975L21.594 5.37894L17.083 21.0455C16.5499 22.9372 15.9792 23.7672 14.3789 23.7672C12.8174 23.7672 12.2078 22.8381 11.7124 21.0455L7.23795 5.37894L4.86487 23.6023Z" fill="#231F20"/>
        <path d="M33.1478 3.48603C35.4261 1.22885 38.4532 0 41.7189 0C44.9835 0 47.9764 1.22774 50.289 3.51834C52.6015 5.80893 53.8263 8.76353 53.8263 11.9833C53.8263 15.203 52.6015 18.1911 50.289 20.4817C47.9764 22.7723 44.9835 24 41.7189 24C38.4544 24 35.4273 22.7388 33.1478 20.4817C30.8009 18.1576 29.5762 15.2365 29.5762 11.9833C29.5762 8.73011 30.8009 5.80893 33.1478 3.48603ZM36.4466 17.3945C37.8414 18.8216 39.7111 19.6182 41.7178 19.6182C43.7245 19.6182 45.5611 18.8216 46.9548 17.3945C48.3827 15.9339 49.1658 14.0087 49.1658 11.951C49.1658 9.89323 48.3839 8.00037 46.9548 6.53978C45.5599 5.11262 43.7233 4.31603 41.7178 4.31603C39.7111 4.31603 37.8403 5.11262 36.4466 6.53978C34.9844 8.03379 34.2356 9.89212 34.2356 11.951C34.2356 14.0098 34.9844 15.9005 36.4466 17.3945Z" fill="#231F20"/>
        <path d="M58.4399 3.56055C59.9706 3.56055 61.1611 4.72256 61.1611 6.21657C61.1611 7.67716 59.9364 8.83916 58.4399 8.83916C56.9435 8.83916 55.7529 7.67716 55.7529 6.21657C55.7529 4.75486 56.875 3.56055 58.4399 3.56055ZM58.4399 15.0614C59.9706 15.0614 61.1611 16.2234 61.1611 17.7175C61.1611 19.178 59.9364 20.3735 58.4399 20.3735C56.9435 20.3735 55.7529 19.178 55.7529 17.7175C55.7529 16.2569 56.875 15.0614 58.4399 15.0614Z" fill="#231F20"/>
        </svg>
      `;
    } else if (color == 'orange') {
      return `<svg width="60" height="24" viewBox="0 0 60 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.7725 23.6023H0L3.01781 4.70937C3.47356 1.85504 4.87776 0.426758 7.12404 0.426758C9.26505 0.426758 10.6681 1.72135 11.3355 4.31052L14.1081 15.4449L16.8807 4.31052C17.5469 1.72135 18.9511 0.426758 21.0922 0.426758C23.3732 0.426758 24.7415 1.8205 25.1984 4.70937L28.2162 23.6023H23.4437L21.184 5.37894L16.7586 21.0455C16.2357 22.9372 15.6758 23.7672 14.1059 23.7672C12.574 23.7672 11.976 22.8381 11.4901 21.0455L7.10052 5.37894L4.7725 23.6023Z" fill="#FF5200"/>
        <path d="M32.5184 3.48603C34.7535 1.22885 37.7232 0 40.9269 0C44.1294 0 47.0655 1.22774 49.3342 3.51834C51.6028 5.80893 52.8044 8.76353 52.8044 11.9833C52.8044 15.203 51.6028 18.1911 49.3342 20.4817C47.0655 22.7723 44.1294 24 40.9269 24C37.7243 24 34.7546 22.7388 32.5184 20.4817C30.2162 18.1576 29.0146 15.2365 29.0146 11.9833C29.0146 8.73011 30.2162 5.80893 32.5184 3.48603ZM35.7546 17.3945C37.123 18.8216 38.9572 19.6182 40.9257 19.6182C42.8943 19.6182 44.696 18.8216 46.0633 17.3945C47.4641 15.9339 48.2323 14.0087 48.2323 11.951C48.2323 9.89323 47.4653 8.00037 46.0633 6.53978C44.6949 5.11262 42.8932 4.31603 40.9257 4.31603C38.9572 4.31603 37.1219 5.11262 35.7546 6.53978C34.3202 8.03379 33.5856 9.89212 33.5856 11.951C33.5856 14.0098 34.3202 15.9005 35.7546 17.3945Z" fill="#FF5200"/>
        <path d="M57.3303 3.56055C58.8319 3.56055 59.9999 4.72256 59.9999 6.21657C59.9999 7.67716 58.7983 8.83916 57.3303 8.83916C55.8623 8.83916 54.6943 7.67716 54.6943 6.21657C54.6943 4.75486 55.7951 3.56055 57.3303 3.56055ZM57.3303 15.0614C58.8319 15.0614 59.9999 16.2234 59.9999 17.7175C59.9999 19.178 58.7983 20.3735 57.3303 20.3735C55.8623 20.3735 54.6943 19.178 54.6943 17.7175C54.6943 16.2569 55.7951 15.0614 57.3303 15.0614Z" fill="#FF5200"/>
        </svg>
      `;
    }

    return `<svg width="82" height="32" viewBox="0 0 82 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.52242 31.4701H0L4.12433 6.27948C4.74719 2.47371 6.66627 0.569336 9.73618 0.569336C12.6622 0.569336 14.5798 2.29545 15.4919 5.74769L19.2811 20.5935L23.0703 5.74769C23.9808 2.29545 25.8999 0.569336 28.826 0.569336C31.9433 0.569336 33.8134 2.42766 34.4378 6.27948L38.5621 31.4701H32.0397L28.9514 7.17225L22.9034 28.0609C22.1888 30.5833 21.4236 31.6899 19.278 31.6899C17.1845 31.6899 16.3673 30.4511 15.7031 28.0609L9.70404 7.17225L6.52242 31.4701Z" fill="white"/>
      <path d="M44.4409 4.64804C47.4955 1.63847 51.554 0 55.9324 0C60.3092 0 64.3218 1.63699 67.4224 4.69112C70.5229 7.74524 72.165 11.6847 72.165 15.9777C72.165 20.2707 70.5229 24.2548 67.4224 27.3089C64.3218 30.363 60.3092 32 55.9324 32C51.5555 32 47.497 30.3184 44.4409 27.3089C41.2944 24.2102 39.6523 20.3153 39.6523 15.9777C39.6523 11.6401 41.2944 7.74524 44.4409 4.64804ZM48.8636 23.1926C50.7337 25.0955 53.2405 26.1576 55.9308 26.1576C58.6212 26.1576 61.0836 25.0955 62.9522 23.1926C64.8666 21.2452 65.9165 18.6783 65.9165 15.9346C65.9165 13.191 64.8682 10.6672 62.9522 8.71971C61.082 6.81682 58.6197 5.75471 55.9308 5.75471C53.2405 5.75471 50.7322 6.81682 48.8636 8.71971C46.9032 10.7117 45.8993 13.1895 45.8993 15.9346C45.8993 18.6798 46.9032 21.2006 48.8636 23.1926Z" fill="white"/>
      <path d="M78.3525 4.74805C80.4047 4.74805 82.0009 6.29739 82.0009 8.28941C82.0009 10.2369 80.3588 11.7862 78.3525 11.7862C76.3462 11.7862 74.75 10.2369 74.75 8.28941C74.75 6.34047 76.2543 4.74805 78.3525 4.74805ZM78.3525 20.0826C80.4047 20.0826 82.0009 21.6319 82.0009 23.6239C82.0009 25.5714 80.3588 27.1653 78.3525 27.1653C76.3462 27.1653 74.75 25.5714 74.75 23.6239C74.75 21.6765 76.2543 20.0826 78.3525 20.0826Z" fill="white"/>
      </svg>
    `;
  }
}
