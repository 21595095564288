import { Injectable } from "@angular/core";
import { QueryEntity } from "@datorama/akita";
import { BayState, BayStore } from "./bays.store";

@Injectable({ providedIn: 'root' })
export class BayQuery extends QueryEntity<BayState> {
    constructor(protected store: BayStore) {
        super(store);
    }
}
