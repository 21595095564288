export const API_PERMISSIONS = {
  hasUserRole: 'hasUserRole',
  getPermissions: 'getPermissions',
  getRoles: 'getRoles',
  getRolePermissions: 'getRolePermissions',
  getPermissionOperations: 'getPermissionOperations',
  getUserRoles: 'getUserRoles',
  getUserPermissions: 'getUserPermissions',
  addRole: 'addRole',
  updateRole: 'updateRole',
  getEntities: 'getEntities',
  getQuickLinks: 'getQuickLinks',
}