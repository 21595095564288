import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class CircleIconService {

    render() {
        return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="black"/>
        </svg>        
        `;
    }

    renderCircleExpress() {
        return `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.1665 7.99996C2.1665 4.7783 4.77818 2.16663 7.99984 2.16663C11.2215 2.16663 13.8332 4.7783 13.8332 7.99996C13.8332 11.2216 11.2215 13.8333 7.99984 13.8333C4.77818 13.8333 2.1665 11.2216 2.1665 7.99996Z" fill="white" stroke="#FF5200" stroke-width="3"/>
            </svg>
        `;
    }

    renderCircleLogbook() {
        return `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.1665 7.99605C2.1665 4.77439 4.77818 2.16272 7.99984 2.16272C11.2215 2.16272 13.8332 4.77439 13.8332 7.99605C13.8332 11.2177 11.2215 13.8294 7.99984 13.8294C4.77818 13.8294 2.1665 11.2177 2.1665 7.99605Z" fill="white" stroke="black" stroke-width="3"/>
            </svg>
        `;
    }

    renderCircleOEM() {
        return `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.1665 7.99605C2.1665 4.77439 4.77818 2.16272 7.99984 2.16272C11.2215 2.16272 13.8332 4.77439 13.8332 7.99605C13.8332 11.2177 11.2215 13.8294 7.99984 13.8294C4.77818 13.8294 2.1665 11.2177 2.1665 7.99605Z" fill="white" stroke="#3366ff" stroke-width="3"/>
            </svg>
        `;
    }

    renderCircleOrange() {
        return `<svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.1665 7.99996C2.1665 4.7783 4.77818 2.16663 7.99984 2.16663C11.2215 2.16663 13.8332 4.7783 13.8332 7.99996C13.8332 11.2216 11.2215 13.8333 7.99984 13.8333C4.77818 13.8333 2.1665 11.2216 2.1665 7.99996Z" fill="#FFA500" stroke="#FFA500" stroke-width="3"/>
            </svg>
        `;
    }

    renderCircleGreen() {
        return `<svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.1665 7.99996C2.1665 4.7783 4.77818 2.16663 7.99984 2.16663C11.2215 2.16663 13.8332 4.7783 13.8332 7.99996C13.8332 11.2216 11.2215 13.8333 7.99984 13.8333C4.77818 13.8333 2.1665 11.2216 2.1665 7.99996Z" fill="green" stroke="green" stroke-width="3"/>
            </svg>
        `;
    }

    renderCircleRed() {
        return `<svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.1665 7.99996C2.1665 4.7783 4.77818 2.16663 7.99984 2.16663C11.2215 2.16663 13.8332 4.7783 13.8332 7.99996C13.8332 11.2216 11.2215 13.8333 7.99984 13.8333C4.77818 13.8333 2.1665 11.2216 2.1665 7.99996Z" fill="red" stroke="red" stroke-width="3"/>
            </svg>
        `;
    }
}
