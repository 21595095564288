import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class PaperclipIconService {

    render() {
        return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.78 2.38779C14.9838 2.38779 14.2202 2.70409 13.6572 3.26712L4.46715 12.4571C3.52884 13.3954 3.00171 14.668 3.00171 15.995C3.00171 17.322 3.52884 18.5946 4.46715 19.5329C5.40546 20.4712 6.67808 20.9983 8.00505 20.9983C9.33201 20.9983 10.6046 20.4712 11.5429 19.5329L20.7329 10.3429C21.1235 9.95238 21.7566 9.95238 22.1472 10.3429C22.5377 10.7334 22.5377 11.3666 22.1472 11.7571L12.9572 20.9471C11.6438 22.2605 9.86245 22.9983 8.00505 22.9983C6.14765 22.9983 4.36632 22.2605 3.05294 20.9471C1.73956 19.6337 1.00171 17.8524 1.00171 15.995C1.00171 14.1376 1.73956 12.3563 3.05294 11.0429L12.2429 1.8529C13.181 0.914805 14.4534 0.387787 15.78 0.387787C17.1067 0.387787 18.3791 0.914805 19.3172 1.8529C20.2553 2.791 20.7823 4.06334 20.7823 5.39001C20.7823 6.71668 20.2553 7.98902 19.3172 8.92712L10.1172 18.1171C9.55434 18.6799 8.79099 18.9961 7.99505 18.9961C7.1991 18.9961 6.43576 18.6799 5.87294 18.1171C5.31012 17.5543 4.99393 16.791 4.99393 15.995C4.99393 15.1991 5.31012 14.4357 5.87294 13.8729L14.3634 5.39249C14.7541 5.00219 15.3873 5.00257 15.7776 5.39332C16.1679 5.78408 16.1675 6.41724 15.7767 6.80753L7.28715 15.2871C7.09967 15.4748 6.99393 15.7297 6.99393 15.995C6.99393 16.2605 7.09941 16.5152 7.28715 16.7029C7.4749 16.8906 7.72954 16.9961 7.99505 16.9961C8.26056 16.9961 8.51519 16.8906 8.70294 16.7029L17.9029 7.51291C18.4657 6.94992 18.7823 6.18607 18.7823 5.39001C18.7823 4.59377 18.466 3.83014 17.9029 3.26712C17.3399 2.70409 16.5763 2.38779 15.78 2.38779Z" fill="black"/>
        </svg>                                
        `;
    }
}
