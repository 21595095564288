import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Renderer2,
  OnDestroy
} from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import { NativeDateAdapter } from "@angular/material";
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _moment from 'moment';

@Component({
  selector: 'mo-calendar',
  templateUrl: './mo-calendar.component.html',
  styleUrls: ['./mo-calendar.component.scss'],
  providers: [
    // { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    // { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoCalendarComponent<D> implements OnDestroy {

  private _destroyed = new Subject<void>();
  fnTimeout: any;
  show: boolean = true;

  constructor(
    private _calendar: MatCalendar<D>, private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats, private cdr: ChangeDetectorRef,
    private renderer: Renderer2
  ) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());
  }

  ngOnInit() { }

  ngAfterViewInit() {
    this.handleUpdateUI();
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
    if (this.fnTimeout) clearTimeout(this.fnTimeout);
  }

  get periodLabel() {
    return this._dateAdapter
      .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
      .toLocaleLowerCase();
  }

  previousClicked(mode: 'month' | 'year') {
    this.show = false;
    try {
      this.cdr.detectChanges();
    } catch (e) { }

    this.handleUpdateUI();
    this._calendar.activeDate = mode === 'month' ?
      this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1) :
      this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
    this.show = true;
  }

  nextClicked(mode: 'month' | 'year') {
    this.show = false;
    try {
      this.cdr.detectChanges();
    } catch (e) { }

    this.handleUpdateUI();
    this._calendar.activeDate = mode === 'month' ?
      this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1) :
      this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
    this.show = true;
  }

  handleUpdateUI() {
    if (this.fnTimeout) clearTimeout(this.fnTimeout);
    this.fnTimeout = setTimeout(() => {
      const listEl = document.getElementsByClassName('mat-calendar-body-label');
      const ths = document.querySelectorAll('.mat-calendar-table-header tr:nth-of-type(1) th');
      if (ths.length > 0) {
        ths.forEach((th) => {
          th.innerHTML = th.getAttribute('aria-label').slice(0, 3);
        })
      }

      let el = null;
      if (listEl.length > 0) {
        el = listEl[0];
        const colspan = el.getAttribute('colspan');
        if (colspan == 7) {
          this.renderer.setStyle(el, 'display', 'none');
        }
      }
      if (this._calendar.minDate && this._calendar.minDate >= this._calendar.activeDate) {
        this.renderer.addClass(document.getElementsByClassName('btn-calendar-prev')[0], 'disabled');
      } else {
        this.renderer.removeClass(document.getElementsByClassName('btn-calendar-prev')[0], 'disabled');
      }
    }, 100);
  }
}
