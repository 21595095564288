import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class PlusIconService {

    render() {
        return `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.00141 0.777344C7.43097 0.777344 7.77919 1.12557 7.77919 1.55512V12.444C7.77919 12.8736 7.43097 13.2218 7.00141 13.2218C6.57186 13.2218 6.22363 12.8736 6.22363 12.444V1.55512C6.22363 1.12557 6.57186 0.777344 7.00141 0.777344Z" fill="#DCDCDC"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.776367 7.00043C0.776367 6.57088 1.12459 6.22266 1.55414 6.22266H12.443C12.8726 6.22266 13.2208 6.57088 13.2208 7.00043C13.2208 7.42999 12.8726 7.77821 12.443 7.77821H1.55414C1.12459 7.77821 0.776367 7.42999 0.776367 7.00043Z" fill="#DCDCDC"/>
            </svg>
        `;
    }
}
