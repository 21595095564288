import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { Role } from '../../../../@core/data/roles-permissions';
import { RolesPermissionsService } from '../../../../@core/utils/roles-permissions.service';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { ERROR_MESSAGES } from '../../../../constants';

@Component({
  selector: 'role-rename-dlg',
  templateUrl: './role-rename-dlg.component.html',
  styleUrls: ['./role-rename-dlg.component.scss']
})
export class RoleRenameDlgComponent implements OnInit, OnChanges {
  editRoleForm: FormGroup;
  loading: boolean = false;
  errorMessages = ERROR_MESSAGES;
  @Input()
  role: Role;

  @Input()
  roleList: Role[] = null;

  constructor(
    private toastService: NbToastrService,
    protected ref: NbDialogRef<RoleRenameDlgComponent>,
    private rolesPermissionsService: RolesPermissionsService,
  ) {
    this.editRoleForm = new FormGroup({
      roleName: new FormControl('', [Validators.required, this.checkRoleNameExist()]),
    });
  }

  close() {
    this.ref.close();
  }
  submit() {
    if (this.editRoleForm.valid) {
      this.loading = true;
      this.rolesPermissionsService.updateRoleDetails(this.role.code, {
        ...this.role,
        name: this.editRoleForm.get('roleName').value,
      }).pipe(finalize(() => {
        this.ref.close();
        this.loading = false;
      })).subscribe((res) => {
        this.toastService.success("Updated Successfully", "Success", { destroyByClick: true, hasIcon: false });
      });
    }
  }

  checkRoleNameExist(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value || !this.roleList) {
        return null;
      }

      if (_.toLower(value) === _.toLower(this.role.name)) {
        return null;
      }

      if (this.roleList.find(v => _.toLower(v.name) === _.toLower(value))) {
        return {
          exists: true,
        }
      } else {
        return null;
      }
    }
  }

  ngOnInit() {
    this.editRoleForm.get('roleName').setValue(this.role.name);
  }

  ngOnChanges(changes: any) {
  }
}
