import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class CircleCheckTransparentPlusIconService {

  render() {
    return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="black"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.3107 8.04291C17.7012 8.43343 17.7012 9.0666 17.3107 9.45712L11.1036 15.9571C10.7131 16.3476 10.0799 16.3476 9.68938 15.9571L6.68938 12.9571C6.29885 12.5666 6.29885 11.9334 6.68938 11.5429C7.0799 11.1524 7.71307 11.1524 8.10359 11.5429L10.3965 13.8358L15.8965 8.04291C16.287 7.65238 16.9202 7.65238 17.3107 8.04291Z" fill="#222222"/>
      </svg>
    `;
  }
}
