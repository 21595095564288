import { Component } from '@angular/core';
import { NbSidebarService } from '@nebular/theme';
// import { FOOTER_SIDEBAR_MENU_ITEMS } from '../../../pages/pages-menu';
import { UserQuery } from '../../../@core/akita-stores/stores/user/user.query';
import { ConfigQuery } from '../../../@core/akita-stores/stores/config/config.store';
import { FlightDeckQuery } from '../../../@core/akita-stores/stores/flightdeck/flightdeck.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout>
      <nb-layout-header *ngIf="!((flightDeckQuery.isOnFullScreen$ | async) && isReception)" subheader>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar *ngIf="!((flightDeckQuery.isOnFullScreen$ | async) && isReception)" class="menu-sidebar compacted" state="compacted" tag="menu-sidebar" responsive start [tag]="'menu-sidebar'">
        <nb-sidebar-header class="d-flex align-self-center">
          <a class="logo d-flex justify-content-center" href="#" (click)="toggleSidebar()">
            <div class="sidebar-toogle"></div>
          </a>
        </nb-sidebar-header>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column [nbSpinner]="(isLogin$ | async) == false || (loading$ | async) === true" [ngClass]="(flightDeckQuery.isOnFullScreen$ | async) && isReception ? 'pt-0' : ''">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {

  // footerItems = FOOTER_SIDEBAR_MENU_ITEMS;
  isLogin$ = this.userQuery.isLogin$;
  loading$;
  isReception: boolean = false;

  constructor(
    private sidebarService: NbSidebarService,
    private configQuery: ConfigQuery,
    private userQuery: UserQuery,
    public flightDeckQuery: FlightDeckQuery,
    private router: Router
  ) {
    this.loading$ = configQuery.$loading;
    this.isReception = this.router.url.includes('reception');
  }

  ngOnInit() {
    setTimeout(() => {
      this.sidebarService.compact('menu-sidebar');
    }, 100);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }
}
