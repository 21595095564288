import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { FormGroup, FormControl } from '@angular/forms';
import { VehiclesService } from '../../../../@core/akita-stores/entity-stores/vehicles/vehicles.service';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import { getTimezone } from '../../../../@core/akita-stores/storage';
import { GeneralDialogComponent } from '../../general-dialog/general-dialog.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'add-service-recommendation-dlg',
  templateUrl: './add-service-recommendation-dlg.component.html',
  styleUrls: ['./add-service-recommendation-dlg.component.scss']
})
export class AddServiceRecommendationDlgComponent implements OnInit, OnDestroy {

  @Input() data;
  form: FormGroup;
  types;
  bookingId;
  scheduledAt;
  content;
  timezone = getTimezone();
  loading: boolean = false;
  isRequired = true;
  protected destroy$ = new Subject<void>();
  originalContent: any;

  constructor(
    private ref: NbDialogRef<AddServiceRecommendationDlgComponent>,
    private vehiclesService: VehiclesService,
    private dialogService: NbDialogService
  ) {
    this.form = new FormGroup({
      bookingId: new FormControl({value:'', disabled: true}),
      time: new FormControl({value:'', disabled: true}),
      content: new FormControl('')
    });

    this.form.get('content').valueChanges
    .pipe(takeUntil(this.destroy$))
    .subscribe((res) => {
      if (this.types === 'add') {
        let content = res ? res.trim() : '';
        this.isRequired = !content;
      }
      if (this.types === 'edit') {
        let content = res ? res.trim() : '';
        this.isRequired = !(content !== this.originalContent);
      }
    })
   }

  ngOnInit() {
    this.types = this.data.types;
    if (this.types === 'add') {
      this.bookingId = this.data.bookingId;
      this.scheduledAt = this.data.appointmentTime;
      this.content = '';
    }
    if (this.types === 'edit') {
      this.bookingId = this.data.details.bookingId;
      this.scheduledAt = this.data.details.scheduledAt;
      this.content = this.data.details.content;
      this.originalContent = _.cloneWith(this.data.details.content);
    }
    this.form.patchValue({
      bookingId: `Booking #${this.bookingId}`,
      time: moment.utc(this.scheduledAt).tz(this.timezone).format('DD MMM YYYY - HH:MM A'),
      content: this.content
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSubmit() {
    if (this.types === 'edit'){
      this.loading = true;
      const data = {
        bookingId: this.data.details.bookingId,
        scheduledAt: this.data.details.scheduledAt,
        content: this.form.value.content.trim()
      }
      this.vehiclesService.updateRecommendation(this.data.details.id, JSON.stringify(data))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.loading = false;
        this.ref.close(true);
      }, () => this.loading = false);
    }

    if (this.types === 'add') {
      const data = {
        bookingId: this.data.bookingId,
        scheduledAt: this.data.appointmentTime,
        vehicleId: this.data.vehicleId,
        content: this.form.value.content.trim()
      }
      this.loading = true;
      this.vehiclesService.addRecommendation(JSON.stringify(data))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.loading = false;
        this.ref.close(true);
      }, () => this.loading = false);
    }
  }

  openConfirmDlg() {
    this.dialogService.open(GeneralDialogComponent, {
      closeOnBackdropClick: false,
      context: {
        text: 'Are you sure you want to cancel this?',
        buttonCancelTitle: 'Cancel',
        buttonAcceptTitle: 'Yes',
      }
    }).onClose.pipe(takeUntil(this.destroy$)).subscribe((agree) => {
      if (agree) {
        this.ref.close();
      }
    })
  }
}
