

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CarretRightIconService {

    render() {
        return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.5871 12.1284L10.8345 8.75103C10.5127 8.46145 10 8.6898 10 9.12268L10 15.8773C10 16.3102 10.5127 16.5386 10.8345 16.249L14.5871 12.8716C14.8077 12.673 14.8077 12.327 14.5871 12.1284Z" fill="black"/>
        </svg>        
        `;
    }
}
