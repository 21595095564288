import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { SessionQuery } from '../akita-stores/stores/session/session.query';
import { SessionService } from '../akita-stores/stores/session/session.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { NbToastrService } from '@nebular/theme';
import { ERROR_MESSAGES } from '../../constants/error-messages';
import { UserQuery } from '../akita-stores/stores/user/user.query';
import { UserService } from '../akita-stores/stores/user/user.service';
import { map } from 'rxjs/internal/operators/map';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {

  errorMessages = ERROR_MESSAGES;
  constructor(
    public router: Router,
    private sessionQuery: SessionQuery,
    private sessionService: SessionService,
    private ngxpermissionsService: NgxPermissionsService,
    private toastrService: NbToastrService,
    private userQuery: UserQuery,
    private userService: UserService
  ) { }
  canActivate(route: import("@angular/router").ActivatedRouteSnapshot, state: import("@angular/router").RouterStateSnapshot): boolean | import("@angular/router").UrlTree | Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {

    // console.log(this.userQuery.getValue())
    if (!this.sessionQuery.isAuthenticated()) {
      this.sessionService.logout();
      this.router.navigateByUrl('/auth/login');

      return false;
    }

    return this.userHasPermissions().pipe(map((hasPermission) => {
      if (!hasPermission) {
        this.sessionService.logout();
        this.router.navigateByUrl('/auth/login').then(() => {
          this.toastrService.danger(this.errorMessages.userHasNoPermissions, 'Error');
        });

        return false;
      }
      return hasPermission;
    }));
  }

  private userHasPermissions(): Observable<boolean> {
    return this.userService.getUserPermissions().pipe(map(() => {
    //   console.log(this.ngxpermissionsService.getPermissions());
      return Object.keys(this.ngxpermissionsService.getPermissions()).length > 0;
    }));
  }
}
