import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { MoCalendarComponent } from '../../../../@theme/components/mo-calendar/mo-calendar.component'
import { BeveragesQuery } from '../../../../@core/akita-stores/stores/beverages/beverages.query';
import * as moment from 'moment-timezone';
import { getTimezone } from '../../../../@core/akita-stores/storage';

const timezone = getTimezone();
moment.tz.setDefault(timezone);

@Component({
  selector: 'beverage-calendar',
  templateUrl: './beverage-calendar.component.html',
  styleUrls: ['./beverage-calendar.component.scss']
})
export class BeverageCalendarComponent implements OnInit, OnDestroy {
  moCalendarComponent = MoCalendarComponent;
  selectedDate = new Date();
  startAt = new Date();
  minDate = new Date(2001, 0, 1);

  @Input() type = 'manage-beverage';
  @Output() calendarChange: EventEmitter<any> = new EventEmitter();

  beverageCalendar$;

  constructor(
    public beveragesQuery: BeveragesQuery,
  ) { }

  ngOnInit() {
    if (this.type == 'manage-beverage') {
      if (this.beverageCalendar$) this.beverageCalendar$.unsubscribe();
      this.beveragesQuery.beverageCalendar$.subscribe((data) => {
        this.selectedDate = moment(data.viewDate).toDate();
      });
    }
  }

  onSelect(event) {
    this.selectedDate = event;
    this.calendarChange.emit(event);
    if (document.querySelectorAll('.box-calendar-sidebar-beverage .mo-calendar__header-label').length > 0) {
      document.querySelectorAll('.box-calendar-sidebar-beverage .mo-calendar__header-label')[0].innerHTML = moment(this.selectedDate).format('DD MMMM YYYY');
    }
  }

  ngOnDestroy() {
    if (this.beverageCalendar$) this.beverageCalendar$.unsubscribe();
  }
}
