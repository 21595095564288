import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DropletIconService {

  render() {
    return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9915 4.63958L11.6241 3.26104C11.4573 3.0938 11.231 2.9999 10.9952 3C10.7593 3.0001 10.5331 3.09421 10.3665 3.26159L5.34447 8.30576C4.22542 9.42725 3.46316 10.8563 3.15411 12.4121C2.845 13.9682 3.00312 15.5812 3.60847 17.0472C4.21381 18.5132 5.23919 19.7662 6.5549 20.6478C7.8706 21.5294 9.41753 22 11 22C12.5825 22 14.1294 21.5294 15.4451 20.6478C16.7608 19.7662 17.7862 18.5132 18.3915 17.0472C18.7428 16.1965 18.9435 15.2962 18.9897 14.3863C18.4106 14.605 17.798 14.7279 17.1748 14.7473C17.1064 15.3004 16.9639 15.8448 16.749 16.3654C16.2781 17.5056 15.4806 18.4802 14.4573 19.1658C13.434 19.8515 12.2308 20.2175 11 20.2175C9.76919 20.2175 8.56602 19.8515 7.5427 19.1658C6.51937 18.4801 5.72185 17.5056 5.25103 16.3654C4.7802 15.2252 4.65722 13.9706 4.89764 12.7603C5.13805 11.55 5.73107 10.4384 6.60167 9.56604L10.9961 5.15219L11.9643 6.0199C12.2216 5.54194 12.5441 5.0999 12.925 4.70813L12.9915 4.63958Z" fill="black"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6781 2.28951C17.4985 2.10475 17.2536 1.99988 16.997 2C16.7405 2.00012 16.4957 2.10521 16.3162 2.29013L13.463 5.23066C12.7642 5.94936 12.2888 6.86439 12.0961 7.85973C11.9034 8.85528 12.0019 9.88733 12.3795 10.8256C12.7571 11.7639 13.3971 12.5669 14.2195 13.1323C15.042 13.6978 16.0096 14 17 14C17.9904 14 18.958 13.6978 19.7805 13.1323C20.6029 12.5669 21.2429 11.7639 21.6205 10.8256C21.9981 9.88733 22.0966 8.85528 21.9039 7.85973C21.7112 6.8642 21.2355 5.94891 20.5365 5.23016L17.6781 2.28951ZM14.8247 6.61343L16.9981 4.37359L19.1756 6.61383C19.6072 7.05748 19.9018 7.62355 20.0212 8.24076C20.1407 8.858 20.0796 9.49777 19.8457 10.0789C19.6118 10.66 19.2161 11.1557 18.7095 11.504C18.203 11.8522 17.6081 12.0377 17 12.0377C16.3919 12.0377 15.797 11.8522 15.2905 11.504C14.7839 11.1557 14.3882 10.66 14.1543 10.0789C13.9204 9.49777 13.8593 8.858 13.9788 8.24076C14.0982 7.62355 14.3932 7.05708 14.8247 6.61343Z" fill="black"/>
      </svg>
    `;
  }
}
