import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import * as _ from 'lodash';

export interface Contact {
  id: number;
  type: string;
  code: string;
  value: string;
}

export interface Address {
  id: number;
  address: {
    addressType: string;
    streetLine1: string;
    streetLine2: string;
    city: string;
    state: string;
    postCode: string;
    country: string;
    longitude: number;
    latitude: number;
    others: any;
  }
}

export interface ServiceCenter {
  id: number;
  open?: string;
  close?: string;
  name: string;
  entityId: number;
  contacts: Contact[];
  addresses: Address[];
  isSelected: boolean;
  hasFlightDeck?: boolean;
}

export interface Beacon {
  id: number,
  code: string,
  description: string,
  deviceId: string,
  isActive: boolean
}

export interface ServiceCentersState extends EntityState<ServiceCenter> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'serviceCenters' })
export class ServiceCentersStore extends EntityStore<ServiceCentersState> {
  constructor() {
    super();
  }
}
