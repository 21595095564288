<nb-card [nbSpinner]="loading" class="check-in-dlg wrapper-popup">
  <nb-card-header class="d-flex align-items-center px-3">
    Check In
    <nb-icon icon="x-icon" pack="mo-icons" (click)=close()></nb-icon>
  </nb-card-header>
  <nb-card-body>
    <div *ngxPermissionsOnly="[bookingPermissions.getAvailableBeacons]" class="mo-select box icon-left">
      <label>Beacon ID</label>
      <nb-icon icon="minimize" pack="mo-icons"></nb-icon>
      <nb-select fullWidth [(selected)]="selectedBeacon">
        <nb-select-label>
          {{ selectedBeacon | beacon }}
        </nb-select-label>
        <nb-option *ngFor="let beacon of beacons" class="item" [(value)]="beacon">
          {{ beacon | beacon }}
        </nb-option>
        <nb-option *ngIf="!beacons || beacons.length === 0" class="item" value="">-</nb-option>
      </nb-select>
    </div>
    <div class="wrap--signature-pad" #wrapSignaturePadRef>
      <label>Signature</label>
      <signature-pad #sigPad [options]="signaturePadOptions" (onEndEvent)="drawComplete()"></signature-pad>
    </div>
    <!-- <a class="d-flex align-items-center primary mr-2 mb-2" href="#">
      <nb-icon icon="download" pack="mo-icons" class="mr-2"></nb-icon>Download Booking Confirmation
    </a> -->
    <!-- <a *ngIf="base64Signature" class="d-flex align-items-center primary" download="signature.png"
      href="{{ base64Signature }}">
      <nb-icon icon="download" pack="mo-icons" class="mr-2"></nb-icon>Download Signature
    </a> -->
  </nb-card-body>
  <nb-card-footer class="text-right px-3 pt-0 border-0 d-flex">
    <button (click)="close()" type="button" class="secondary big fixed-size mr-3">Cancel</button>
    <button (click)="clear()" class="secondary big fixed-size mr-3">Clear</button>
    <button (click)="onCheckedIn()" type="submit" class="primary big fixed-size" [disabled]="isDisabledCheckIn">Check
      In</button>
  </nb-card-footer>
</nb-card>