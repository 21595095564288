export const VEHICLE_PERMISSIONS = {
  getServiceRecommendations: 'getServiceRecommendations',
  updateServiceRecommendation: 'updateServiceRecommendation',
  removeServiceRecommendation: 'removeServiceRecommendation',
  addServiceRecommendation: 'addServiceRecommendation',
  adminUpdateVehicle: 'adminUpdateVehicle',
  adminAddVehicle: 'adminAddVehicle',
  removeVehicleCondition: 'removeVehicleCondition',
  updateVehicleCondition: 'updateVehicleCondition',
  addVehicleCondition: 'addVehicleCondition',
  getVehicleConditions: 'getVehicleConditions',
  getCustomerVehicles: 'getCustomerVehicles'
}