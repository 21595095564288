import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BookingDetails } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.store';
import { CommonService } from '../../../@core/utils/common.service';
import { DataReceipt } from '../../../@core/data/common';

@Component({
  selector: 'receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit {
  @Input() bookingDetails: BookingDetails;
  isExpress: boolean = false;
  moTag = {
    types: 'type',
    code: '',
  }
  dataReceipt: DataReceipt;

  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.dataReceipt = this.commonService.getReceiptValue(this.bookingDetails);
    this.isExpress = this.commonService.isExpress(this.bookingDetails.serviceType.code);
    this.moTag.code = this.dataReceipt.isPaymentCompleted ? 'paid' : 'unpaid';
  }
}
