import { Injectable } from '@angular/core';
import { BeveragesStore, BeveragesState } from './beverages.store';
import { Query } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class BeveragesQuery extends Query<BeveragesState> {
  beverageCalendar$ = this.select(['viewDate']);
  searchTerm$ = this.select(['searchTerm']);
  constructor(protected store: BeveragesStore) {
    super(store);
  }
}