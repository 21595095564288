import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_PATH } from './../../constants/api-urls';
import { NgxPermissionsService } from 'ngx-permissions';
import { BOOKING_PERMISSIONS } from '../../constants/booking-permissions';
import { NbToastrService } from '@nebular/theme';
import { ERROR_MESSAGES } from '../../constants/error-messages';
import { API_PERMISSIONS } from '../../constants/api-permissions';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(
    private httpClient: HttpClient,
    private ngxPermissionsService: NgxPermissionsService,
    private toastrService: NbToastrService
  ) { }

  getPermissions(entityId?) {
    if (entityId) {
      let header = new HttpHeaders();
      header = header.set('Entity-Context', entityId.toString());
      return this.httpClient.get(API_PATH.self.userOperations, { headers: header });
    }
    return this.httpClient.get(API_PATH.self.userOperations);
  }

  public async userHasPermissionsToCreateBookings(serviceType) {
    if (!serviceType) return false;

    const adminCreateExpressServiceBooking = await this.ngxPermissionsService.hasPermission(BOOKING_PERMISSIONS.adminCreateExpressServiceBooking);
    const adminCreateLogBookServiceBooking = await this.ngxPermissionsService.hasPermission(BOOKING_PERMISSIONS.adminCreateLogBookServiceBooking);

    return (((serviceType.includes('express') || serviceType.includes('premium') || serviceType.includes('premium-plus')) && adminCreateExpressServiceBooking))
        || (((serviceType.includes('logbook') || serviceType.includes('tyre-bonus')) && adminCreateLogBookServiceBooking))
  }

  public async userHasPermissionsToUpdateBooking(showErrorMessage: boolean = false) {
    const hasPermission = await this.ngxPermissionsService.hasPermission(BOOKING_PERMISSIONS.updateBooking);
    if (!hasPermission && showErrorMessage) {
      this.showPermissionError();
    }

    return hasPermission;
  }

  showPermissionError() {
    this.toastrService.danger(ERROR_MESSAGES.userHasNoPermissions, 'Error');
  }

  canViewEmployeePermissions() {
    return Promise.all([
      this.ngxPermissionsService.hasPermission(API_PERMISSIONS.getPermissions),
      this.ngxPermissionsService.hasPermission(API_PERMISSIONS.getRolePermissions),
      this.ngxPermissionsService.hasPermission(API_PERMISSIONS.getRoles),
      this.ngxPermissionsService.hasPermission(API_PERMISSIONS.getUserRoles),
      this.ngxPermissionsService.hasPermission(API_PERMISSIONS.getPermissionOperations),
      this.ngxPermissionsService.hasPermission(API_PERMISSIONS.getUserPermissions),
      this.ngxPermissionsService.hasPermission(API_PERMISSIONS.getEntities)
    ]);
  }

  public async hasEveryPermissions(permisions: string[]) {
    let permissonPromises = [];
    permisions.forEach(item => permissonPromises.push(this.ngxPermissionsService.hasPermission(item)));
    const resPromiseAll: any = await Promise.all(permissonPromises).catch(err => console.error(err));
    const noPermission = resPromiseAll.find(hasPermission => !hasPermission);
    return (noPermission === undefined) ? true : false;
  }

  public async hasPermissions(permisions: string[], every?: boolean) {
    let permissonPromises = [];
    permisions.forEach(item => permissonPromises.push(this.ngxPermissionsService.hasPermission(item)));
    const resPromiseAll: any = await Promise.all(permissonPromises).catch(err => console.error(err));
    const checkPermission = permission => permission === true;
    return every ? resPromiseAll.every(checkPermission) : resPromiseAll.some(checkPermission);
  }
}
