import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class EmailIconService {

  render(color = null) {
    if (color == 'orange') {
      return `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.3335 4.16667C2.87707 4.16667 2.50016 4.54357 2.50016 5V15C2.50016 15.4564 2.87707 15.8333 3.3335 15.8333H16.6668C17.1233 15.8333 17.5002 15.4564 17.5002 15V5C17.5002 4.54357 17.1233 4.16667 16.6668 4.16667H3.3335ZM0.833496 5C0.833496 3.6231 1.95659 2.5 3.3335 2.5H16.6668C18.0437 2.5 19.1668 3.6231 19.1668 5V15C19.1668 16.3769 18.0437 17.5 16.6668 17.5H3.3335C1.95659 17.5 0.833496 16.3769 0.833496 15V5Z" fill="var(--block-colour)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.984208 4.52202C1.24814 4.14498 1.76775 4.05329 2.14479 4.31722L10.0002 9.81603L17.8557 4.31722C18.2327 4.05329 18.7523 4.14498 19.0163 4.52202C19.2802 4.89906 19.1885 5.41867 18.8115 5.6826L10.4781 11.5159C10.1912 11.7168 9.80928 11.7168 9.52235 11.5159L1.18902 5.6826C0.811975 5.41867 0.720279 4.89906 0.984208 4.52202Z" fill="var(--block-colour)"/>
        </svg>
      `;
    }

    return `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.33301 4.16667C2.87658 4.16667 2.49967 4.54357 2.49967 5V15C2.49967 15.4564 2.87658 15.8333 3.33301 15.8333H16.6663C17.1228 15.8333 17.4997 15.4564 17.4997 15V5C17.4997 4.54357 17.1228 4.16667 16.6663 4.16667H3.33301ZM0.833008 5C0.833008 3.6231 1.9561 2.5 3.33301 2.5H16.6663C18.0432 2.5 19.1663 3.6231 19.1663 5V15C19.1663 16.3769 18.0432 17.5 16.6663 17.5H3.33301C1.9561 17.5 0.833008 16.3769 0.833008 15V5Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.983719 4.52202C1.24765 4.14498 1.76726 4.05329 2.1443 4.31722L9.99975 9.81603L17.8552 4.31722C18.2322 4.05329 18.7518 4.14498 19.0158 4.52202C19.2797 4.89906 19.188 5.41867 18.811 5.6826L10.4776 11.5159C10.1907 11.7168 9.80879 11.7168 9.52186 11.5159L1.18853 5.6826C0.811486 5.41867 0.719791 4.89906 0.983719 4.52202Z" fill="white"/>
      </svg>
    `;
  }
}
