<form (ngSubmit)="createCustomer()" [formGroup]="customerCreateForm">
  <nb-card [nbSpinner]="loading" class="edit-customer-dlg">
    <nb-card-header class="d-flex align-items-center px-3">
      <h4 class="d-flex flex-row justify-content-between align-items-center">
        <nb-icon class="bc-arrow mr-2 d-flex align-items-center justify-content-center cursor-pointer"
          (click)="closeDlg()" icon="chevron-left" pack="mo-icons"></nb-icon>Create Customer
      </h4>
    </nb-card-header>
    <nb-card-body class="px-3">
      <div class="item d-flex px-n2">
        <div class="w-50 px-2">
          <mat-form-field class="full-width box" appearance="legacy" floatLabel="always">
            <mat-label>First Name</mat-label>
            <input formControlName="firstName" matInput>
            <mat-error
              *ngIf="customerCreateForm.controls.firstName.errors && customerCreateForm.controls.firstName.errors.required">
              {{ errorMessage['firstNameRequired'] }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="w-50 px-2">
          <mat-form-field class="full-width box" appearance="legacy" floatLabel="always">
            <mat-label>Last Name</mat-label>
            <input formControlName="lastName" matInput>
            <mat-error
              *ngIf="customerCreateForm.controls.lastName.errors && customerCreateForm.controls.lastName.errors.required">
              {{ errorMessage['lastNameRequired'] }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="item d-flex px-n2">
        <div class="w-50 px-2">
          <mat-form-field class="full-width box" appearance="legacy" floatLabel="always">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" type="email">
            <mat-error
              *ngIf="customerCreateForm.controls.email.errors && customerCreateForm.controls.email.errors.required">
              {{ errorMessage['emailRequired'] }}
            </mat-error>
            <mat-error
              *ngIf="customerCreateForm.controls.email.errors && customerCreateForm.controls.email.errors.email">
              {{ errorMessage['emailInvalid'] }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="w-50 px-2">
          <mat-form-field class="full-width box" appearance="legacy" floatLabel="always">
            <mat-label>Phone</mat-label>
            <input pattern="[0-9]*" mask="0000 000 000" matInput formControlName="phone" type="text">
            <mat-error
              *ngIf="customerCreateForm.controls.phone.errors && customerCreateForm.controls.phone.errors.required">
              {{ errorMessage['phoneNumberRequired'] }}
            </mat-error>
            <mat-error
              *ngIf="customerCreateForm.controls.phone.errors && customerCreateForm.controls.phone.errors['Mask error']">
              {{ errorMessage['phoneNumberInvalid'] }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="item d-flex px-n2">
        <div class="flex-fill px-2">
          <mat-form-field class="full-width box" appearance="legacy" floatLabel="always">
            <mat-label>Home address</mat-label>
            <input formControlName="homeAddress" matInput type="text">
            <mat-error
              *ngIf="customerCreateForm.controls.homeAddress.errors && customerCreateForm.controls.homeAddress.errors.required">
              {{ errorMessage['homeAddressRequired'] }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="item d-flex px-n2">
        <div class="full-width px-2">
          <mat-form-field class="full-width box" appearance="legacy" floatLabel="always">
            <mat-label>Postcode</mat-label>
            <input matInput type="text" mask="0000" placeholder="XXXX" formControlName="postCode"
              (keyup)="onKeyupPostCode()">
            <mat-error
              *ngIf="customerCreateForm.controls.postCode.errors && customerCreateForm.controls.postCode.errors.postCodeUndetectable">
              {{ errorMessage['postCodeUndetectable'] }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="full-width px-2">
          <div class="mo-select box">
            <label>Suburb</label>
            <nb-select [ngClass]="{disabled: customerCreateForm.controls.postCode.invalid}" *ngIf="show" fullWidth
              formControlName="suburb">
              <nb-option value="{{s}}" *ngFor="let s of suburbs">{{s}}</nb-option>
            </nb-select>
          </div>
        </div>
      </div>
      <div class="item d-flex px-n2">
        <div class="w-50 px-2">
          <div class="mo-select box">
            <label>State</label>
            <nb-select [ngClass]="{disabled: customerCreateForm.controls.postCode.invalid}" *ngIf="show" fullWidth
              formControlName="state">
              <nb-option value="{{customerCreateForm.value.state}}">{{customerCreateForm.value.state}}</nb-option>
            </nb-select>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer class="text-right px-3 pt-3 border-0">
      <button type="button" (click)="closeDlg()" class="secondary big mr-3">Cancel</button>
      <button type="submit" class="primary big">Save</button>
    </nb-card-footer>
  </nb-card>
</form>