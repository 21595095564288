<div>
  <nb-select
    (selectedChange)="onSelect($event)"
    [formControl]="selectedControl"
    [placeholder]="placeholder + (!noSelectCount ? ': None' : '')"
    (click)="onSelectClick()"
    multiple
  >
    <nb-select-label>
      <span class="placeholder">{{ placeholder }}</span>
      <span *ngIf="!noSelectCount">{{
        isCheckAll ? ": All" : selected.length > 0 ? ': ' + selected.length + " Selected" : ": None"
      }}</span>
    </nb-select-label>

    <nb-option
      *ngIf="options?.length > 0 && canSearch"
      value="search-option"
      class="search-option"
    >
      <div (click)="$event.stopPropagation()" class="position-relative">
        <input
          type="text"
          id="search-input"
          nbInput
          fullWidth
          fieldSize="tiny"
          class="search-input"
          placeholder="Search"
          icon="email"
          pack="mo-icons"
          [(ngModel)]="searchInput"
          (input)="handleSearch($event.target.value)"
          (keydown)="$event.stopPropagation()"
          (keyup)="$event.stopPropagation()"
        />
        <label for="search-input"><nb-icon icon="search" pack="mo-icons"></nb-icon></label>
      </div>
    </nb-option>

    <nb-option
      *ngIf="!searchInput && canSelectAll && options?.length > 0"
      [value]="allOptionValue"
      [class]="'all-option px-2 ' + (canSearch ? 'option-2nd' : '')"
      (click)="handleCheckAll()"
    >
      <p class="mb-0">All</p>
    </nb-option>

    <ng-container
      ngProjectAs="nb-option"
      *ngFor="let option of isGroupOption ? filteredOptions : []"
    >
      <nb-option-group
        *ngIf="option?.isGroupOption"
        [title]="option?.label"
        [class]="option?.hide ? 'd-none' : ''"
        >{{ option?.label }}
      </nb-option-group>
      <nb-option
        *ngIf="!option?.isGroupOption"
        [value]="option.value"
        [class]="option?.hide ? 'd-none' : ''"
      >
        <p class="label mb-0" [title]="option?.label">
          {{ option?.label + (option?.hide ? "d-none" : "") }}
        </p>
      </nb-option>
    </ng-container>

    <nb-option
      *ngFor="let option of isGroupOption ? [] : filteredOptions"
      [value]="option?.value"
      [class]="option?.hide ? 'd-none' : 'px-2'"
    >
      <p class="mb-0" [title]="option?.label">{{ option?.label }}</p>
    </nb-option>

    <nb-option
      *ngIf="options?.length == 0"
      value=""
      class="no-option px-2"
      disabled
    >
      <p class="mb-0">No data</p>
    </nb-option>
  </nb-select>
</div>
