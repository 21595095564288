import { Component, ChangeDetectorRef, Output, EventEmitter, ViewChild, ElementRef, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { VehiclesService } from '../../../../@core/akita-stores/entity-stores/vehicles/vehicles.service';
import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter,
  tap
} from "rxjs/operators";
import { fromEvent, from } from 'rxjs';
import * as _ from 'lodash';
import { CommonService } from '../../../../@core/utils/common.service';

@Component({
  selector: 'search-by-rego',
  templateUrl: './search-by-rego.component.html',
  styleUrls: ['./search-by-rego.component.scss']
})
export class SearchByRegoComponent implements OnInit {
  stateList = [];
  model = {
    stateSelected: null,
    myRego: null,
    recaptcha: null,
  };

  errMsg = null;
  loading = false;

  searchRegoAPI$: any;
  regoSearchInput$: any;
  // customerId = 131; //720
  geoStates$; customerVehicle$;
  customerVehicleData = [];
  @ViewChild('regoSearchInput', { static: false }) regoSearchInput: ElementRef;

  @Input('customerId') customerId;
  @Output() onSearchRego: EventEmitter<any> = new EventEmitter();
  constructor(
    private cdr: ChangeDetectorRef,
    private vehiclesService: VehiclesService,
    private commonService: CommonService,
  ) {
  }

  ngOnInit() {
    this.geoStates$ = this.vehiclesService.getGeoStates('au').subscribe(v => {
      this.stateList = v;
    });
    this.customerVehicle$ = this.vehiclesService.getCustomerVehicles(this.customerId).subscribe(v => {
      this.customerVehicleData = v;
    });
    setTimeout(() => {
      if (this.regoSearchInput$) this.regoSearchInput$.unsubscribe();
      this.regoSearchInput$ = fromEvent(document.getElementById('regoSearchInput'), 'keyup').pipe(
        map((event: any) => {
          return event.target.value;
        }),
        filter(res => res.length > 2),
        debounceTime(500),
        distinctUntilChanged()
      ).subscribe(() => {
        this.errMsg = null;
      });
    }, 500);
  }
  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    if (this.regoSearchInput$) this.regoSearchInput$.unsubscribe();
    if (this.searchRegoAPI$) {
      this.searchRegoAPI$.unsubscribe();
    }
    if (this.geoStates$) this.geoStates$.unsubscribe();
    if (this.customerVehicle$) this.customerVehicle$.unsubscribe();
  }

  onSubmit() {
    this.cdr.detectChanges();
    this.loading = true;
    if (this.searchRegoAPI$) {
      this.searchRegoAPI$.unsubscribe();
    }
    this.searchRegoAPI$ = this.vehiclesService.searchRegoAPI(this.model.myRego, this.model.stateSelected).subscribe((vehicles) => {
      if (_.isEmpty(vehicles)) {
        this.errMsg = 'Not finding what are you looking for? Search again!';
        this.loading = false;
      } else {
        this.onSearchRego.emit({ vehicles, selectedState: this.model.stateSelected });
      }
    });
  }

  removeWhiteSpacesRego($rego) {
    this.model.myRego = this.commonService.removeWhiteSpacesStr($rego);
  }
}
